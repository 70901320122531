import styled from 'styled-components'

const TableBox = styled.div`
  .scroll-x {
    overflow-x: auto;
    border-radius: 0em;
    // box-shadow: 4px -2px 6px 4px #f2f2f4;
  }
  .height-unset {
    line-height: unset !important;
  }
  .main-import {
    padding: 0px 0px 20px 0px !important;
    color: #6a707e;
    width: 100% !important;
    transition: width 0.5s;
  }
  .container {
    margin: unset;
    padding: 20px;
    width: 100% !important;
    max-width: 100% !important;
    #search-field {
      border-radius: 5px;
      border: 1px solid #cfdce5;
      margin-left: 10px;
    }
    #page-menu {
      border-radius: 5px;
      border-color: #cfdce5;
      margin-left: 10px;
    }
    .row {
      display: none;
    }
  }
  .section-table {
    overflow: scroll;
  }

  .section-filename {
    background: #ffffff;
    border: 1px dashed #d2d2d2;
    box-sizing: border-box;
    width: 550px;
    padding: 12px 0px;
    text-align: center;
    margin: 15px auto;
    height: 50px;
  }
  .section-info {
    text-align: center;
    .-small {
      font-size: 14px;
      color: rgba(51, 77, 110, 0.6);
    }
  }
  .section-table {
    background-color: #fff;
  }
  .collect-icon {
    margin-right: 10px;
  }
  .btn-del-file {
    cursor: pointer;
    width: 19px;
    margin-left: 10px;
    margin-top: -6px;
  }
  .section-title-sample {
    padding-bottom: 10px;
    padding-top: 40px;
    text-align: left;
    padding-left: 25px;
    font-size: 16px;
    font-weight: 600;
    .-blue {
      margin: 0px 5px;
    }
    a {
      margin: 0px 10px;
    }
    span:first-child {
      font-weight: bold;
      color: #334d6e;
    }
  }

  .section-option {
    padding-bottom: 0px;
    padding-top: 23px;
    text-align: right;
    a {
      margin: 0px 10px;
    }
  }

  .btn-next {
    cursor: pointer;
    background-color: #24b0c3 !important;
    width: 170px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: white;
    padding: 9px;
    :hover {
      color: white;
    }
  }

  .btn-back {
    cursor: pointer;
    background-color: #eeeeee !important;
    width: 170px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: #334d6e;
    padding: 9px;
    :hover {
      color: #334d6e;
    }
  }

  .title {
    font-family: 'Inter';
    color: #334d6e;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .-blue {
    color: #0698be !important;
  }

  table {
    border: unset;
    thead {
      tr {
        background-color: #f1f5f9;
      }
    }
    tr {
      height: 60px;
      background-color: #fff;
    }
    th {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
      padding: 10px;
    }
    td {
      padding: 10px 0px 10px 10px;
      border: unset;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }
    tr:nth-child(even) {
      background: #fafcfe;
    }
    tbody {
      tr:hover {
        background: #efeff7 !important;
      }
    }
  }
`
const ImportTypeBox = styled.div`
.w-20{
  width: 20%;
}
.w-10{
  width: 10%;
}
.w-5{
  width: 5%;
}
.btn-150 {
  padding: 7px !important;
  width: 150px !important;
  height: 44px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}
btn-blue {
  background-color: #24b0c3;
  color: #fff !important;
  font-size: 16px !important;
  padding: 9px 21px;
  font-weight: 500;
  width: 160px;
  height: 50px !important;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}
.btn-blue {
  img {
    width: 16px;
    margin-right: 5px;
    margin-top: -2px;
  }
}

.line-height-1 {
  line-height: 1.5;
}
.-ellipsis{
  width: 220px;
    display: flex;
    span{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
}

.btn-blue {

  border-radius: 10px !important;

}
.navigation-bar{
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color:#1e334e;
  span{
    color: #9daeb9;
  }
}
table{
  .box-dropdown{
    width:210px;
    margin-left: -150px;
    li{
      padding: 2px 10px!important;
    }
  }
}
.icon-option {
  margin-top: 11px;
  cursor: pointer;
  width: 25px;
  height: 7px;
  background: url(/assets/icons/option.png) no-repeat;
  display: inline-block;
  background-size: 17px 5px;
}
.icon-option:hover {
  background: url(/assets/icons/option-active.png) no-repeat;
  background-size: 17px 5px;
}

.section-footer {
  padding: 20px 20px 35px 20px;
  display: flex;
  .align-left {
    text-align: left;
  }
  .align-right {
    text-align: right;
  }
  .btn-blue {
    background-color: #24b0c3;
    color: #fff;
  }
  .btn {
    width: 120px;
    height: 44px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    border: solid 1.5px #24b0c3;
    color: #24b0c3;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .arrow-right {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 4px;
    margin-left: 16px;
    position: absolute;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .arrow-left {
    border: solid #24b0c3;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    margin-top: 3px;
    margin-left: -17px;
    position: absolute;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}
.main {
  padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
  color: #6a707e;
  width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
  margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
  transition: margin 700ms;
}
  .pd-20 {
    padding: 20px !important;
  }

  .-center {
    padding: 200px 0 !important;
  }

  .input_modal{
    margin-top:10px!important;
  }

  .body-model{
    b{
      font-weight: 900;
      color: #6A707E;
    }
    .input_modal{
      font-size:14px;
    }
  }

  .circle-close{
    margin-top:20px;
  }

  .-active-btn {
    background: #aabaca!important;
    color:#fff!important;
    cursor: no-drop!important;
    border:1px solid #aabaca!important;
  }

  .title-type {
    
    color: #334d6e;
    font-size: 16px;
    font-weight: 600;
  }
  .desc-type {
    font-size: 14px;
    color: #6a707e;
    margin: auto 15px;
  }

  .section-progress {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
    li {
      padding: unset;
    }
    .progressbar {
      padding: unset;
    }
    .progressbar li {
      list-style-type: none;
      width: 165px;
      float: left;
      font-size: 14px;
      position: relative;
      text-align: center;
      color: #c2cfe0;
      display: flex;
      span {
        padding: 0px 0px 0px 10px;
        line-height: 1.5;
      }
    }
    .progressbar li:before {
      background-image: url('/assets/icons/unselect.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 20px;
      height: 20px;
      content: '';
      color: transparent;
      line-height: 35px;
      display: inline-table;
      margin: 0px;
      border-radius: 50%;
      background-color: #c2cfe0;
    }
    .progressbar li:after {
      width: 30px;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #c2cfe0;
      top: 12px;
      left: -60px;
    }
    .progressbar li:first-child:after {
      content: none;
    }
    .progressbar li.active {
      color: #334D6E;
    }

    .progressbar li.step-pass {
      color: #334D6E;
    }
    .progressbar li.active:before {
    background-color: transparent;
    background-image: url(/assets/icons/active_trans.png);
    z-index: 0;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;
    }
    .progressbar li.active + li:after {
      background-color: rgba(194, 207, 224, 0.58);
    }
  }

  .progressbar li.step-pass:before {
    background-color: #20adc2;
    background-image: url(/assets/icons/check.png);
    background-size: 12px 12px;
    z-index: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #20adc2;
    color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;
}
    
    line-height: 30px;
    border-radius: 100%;
    text-align: center;
  }
  .progressbar li.step-pass + li:after {
    background-color: rgba(194, 207, 224, 0.58);
  }
}
.top-row {
  display: flex;
}


  .section-main-landding {
    width: 860px;
    height: 490px;
    background-color: #fff;
    margin: 40px auto;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 1px 2px #888888;
    display: flex;
    img {
      width: 300px;
      margin-top: 50px;
      margin-right: 10px;
    }
    a{
      width: 170px;
      margin: 0px;
    }
    .-right{
      text-align:right;
    }

    .center-verticle {
      margin: 17% 30px;
      .title {
        margin: 0px;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.3;
      }
      p:first-child {
        color: #00252f;
      }
      .desc {
        color: #6A707E;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
  .section-main.-upload {
      border-radius: 16px;
      box-shadow: 6px -4px 8px 4px #f2f2f4;
  }

  .section-main {
    background-color: #fff;
    margin: 20px auto;
    padding: 40px 56px;
    text-align: center;
    border-left: 0;
    border-right: 0;

    .section-tooltip{
      text-align: right;
      .icon-tooltip{
        width: 12px;
        margin-top: -2px;
        margin-right: 8px;
        position: relative;
        display: inline-block;
      }
    }
 
    
    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  
    .title {
      font-family: 'Inter';
      color: #334d6e;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    .section-body {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .-selected {
        border: 3px solid #39BCDE;
      }
      ._loading_overlay_wrapper {
        width: 100% !important;
        height: auto;
        circle {
          stroke: #20adc2;
        }
      }
      ._loading_overlay_overlay {
        height: 55px;
        margin-top: 0px;
        border-radius: 10px !important;
        background-color: #f9f9f9 !important;
      }
      .backdrop {
        div{
          justify-content: normal!important;
          div{
            justify-content: center!important;
          }
        }
        height: 280px;
        .-f14{
          font-size:14px;
        }
        span {
          font-size: 10px;
        }
        img {
          width: 85px;
          margin-bottom: 10px;
        }
        .btn-blue {
          width: 234px;
          height: 44px;
          font-size: 14px !important;
          font-weight: 500;
          line-height: 2;
        }
      }
    }
    .-gray {
      color: rgba(106, 112, 126, 0.6);
      font-size: 10px;
    }

    .-lightBlue {
      color: #34BCC5;
    }
    .-small {
      font-size: 14px;
      line-height: 18px;
      color: rgba(51, 77, 110, 0.6);
    }
    .-download {
      cursor:pointer;
      line-height: 4;
      font-size: 14px;
      font-family: inter;
      img {
        width: 16px;
        margin-top:-5px;
      }
    }
    .-download:hover{
      color:#334D6E;
    }
    .list-type-box {
      cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    width: 300px;
    height: 300px;
    margin: 20px;
    border: 1px solid #aabaca;
    padding: 2px 20px;
      .list-icon {
        height: 90px;
      }
      img { 
        margin-top: 20px;
        width: 90px; 
      }
    }

    .list-type-box-active, .list-type-box:hover{
      border: 3px solid #39BCDE;
      padding: 0px 18px;
    }

    .list-center-box {
      border-radius: 10px;
      background-color: #fff;
      margin: 15px 20px;
      img {
        // margin-top: 20px;
        width: 90px;
        margin-bottom: 5px;
      }
      .title-type{
        margin-bottom: 0px; 
      }
      .btn-create-group{
        cursor:pointer;
        color:#334D6E;
        width: 320px;
        margin: auto;
        background: white;
        height: 45px;
        border: 2px solid #24B0C3!important;
        border-radius: 5px;
        margin-top: 10px;
        .icon-create{
          width:20px;
          margin-top: 4px;
          margin-right:5px;
        }
        span{
          font-size:14px;
          color:#334D6E;
          font-weight:bold;
          margin-top: -5px;
          display: block;
        }
        p{
          margin-top: 5px;
          font-size:14px;
        }
      }
      span{
        font-size:12px;
        color:#6A707E;
        font-weight:400;
      }
    }
    .mini{
      font-weight: 100!important;
    }

  }

  .-upload{
    width:750px!important;
    height:470px!important;
    padding-top: 32px;
  }

  .section-upload {
    background-color: #F9F9FB;
    border: 1px dotted #C4C4C4;
    box-sizing :  border-box ;
    border-radius: 10px;
    width: 550px;
    
    margin: 10px auto;
    .row {
      display: block;
    }

    ._loading_overlay_wrapper{
      margin-top:unset;
      ._loading_overlay_overlay{
        margin-top:0px;
      }
    }
    div {
      border: none !important;
    }
  }

  .-uploadError{
    border: dotted 3px #ff2c65;
    background-color: #fffcfc;
    .text-error{
      color:#ff2c65;
      font-family: Inter;
      font-size: 12px;
      width: max-content;
      margin: -52px auto;
      img{
        width: 15px;
        margin-top: 8px;
        margin-left: 2px;
      }
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: #00252f;
    color: #fff;
    border-radius: 0px;
    padding: 5px 0;
    position: absolute;
    opacity: 1;
    z-index: 1;
    bottom: 24px;
    margin-left: -132px;
    padding: 15px;
    font-family: inter;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 55px;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .section-group {
    background-color: #FFF;
    border: 1px solid #adadad;
    border-radius: 10px;
    width: 492px;
    height: 250px;
    margin: 20px 0px 0px 0px;
    .row {
      display: block;
    }
    div {
      border: none;
    }
    ._loading_overlay_wrapper{
      height:unset!important;
    } 
  }

    circle {
      stroke: #20adc2;
    }

    .section-history{
      padding: 0px 30px 10px 20px;
      margin-top:20px;
      .-time{
        color: rgba(112, 118, 131, 0.42);
      }
      
      .scroll-x {
      overflow: auto;
      // border-radius: 1em;
      // box-shadow: 4px -2px 6px 4px #f2f2f4;
      height: 100%;
      }

      table{
        border-collapse: collapse;
         width: 100%;
         color: #334D6E;
         font-family: Inter;
         
      }
     th {
        padding: 10px 0px 10px 5px;
        background-color:#edf1f4;
        border-bottom: 0;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        height: 50px;
      }
      td{
        padding: 5px 20px 5px 5px;
        text-align: left;
        font-size: 12px;
        .icon-type{
          width: 18px;
          height: 18px;
          margin-right: 10px;
          line-height: 3;
          margin-top: 10px;
        }
      }
      tr {
        background-color: #fff !important;
      } 
      tr:nth-child(even) {
        background-color: #fafcfe !important;
      }
      tbody {
        tr:hover {
          background: #efeff7 !important;
        }
      }
      .row-top{
        margin-bottom:20px;
      }
      .-centered{
        text-align:center;
      }
      .-right{
        text-align:right;
      }
      .btn-blue{
        margin:0px;
      }

      .-status{
        display: inline-block;
        padding: 5px 20px;
        font-size: 12px;
        font-weight: normal;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 10px;
        
        width: auto;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      .-warning{
        background-color: #fff5da;
        color: #f0b61d;
      }
      .-fail{
        background-color: #ffd4e0;
        color: #ff2c65;
      }
      .-completed{
        background: #d4f5eb;
        color: #28cc97;
      }
      .-cancle{
        background-color:#eef1f5;
        color: #aabaca;
      }
      .-success{
        background: #d4f5eb;
        color: #28cc97;
      }
      .-canceled{
        background-color: #eef1f5;
        color: #aabaca;
      }
      .-active{
        background-color: #dcf3f8;
        color: #67cde0;
      }
      .-waiting{
        background-color: #dcf3f8;
        color: #67cde0;
      }

      .onLoadmore{
        padding: 40px 0px 80px 0px;
        ._loading_overlay_wrapper{
          margin-top:0px;
          widht: 100%;
        }
      }
    }
 
`

const MappingBox = styled.div`
  .head-map {
    color: #385172;
  }
  .title-map {
    color: #1e334e;
  }
  .btn-royal-bule {
    background-color: #385172;
    color: #fff;
  }
  .btn-validate {
    width: 300px;
  }
  .height-unset {
    line-height: unset !important;
  }
  .main {
    font-family: 'inter';
    padding: 40px 30px 105px !important;
    color: #6a707e;
    width: 100% !important;
    transition: width 0.5s;
    background-color: #fff;
    margin-left: 0px;
    ._loading_overlay_wrapper {
      height: 50px;
      // margin-top: 10px;
      margin: 5px auto;
      svg {
        margin-left: -25px;
      }
      circle {
        stroke: #0698be;
      }
    }
    ._loading_overlay_overlay {
      background: transparent;
    }
  }

  .-titleField {
    text-transform: capitalize;
    font-weight: 600;
    margin-left: 7px;
  }
  .no-cursor {
    cursor: unset !important;
  }

  .loadding-overlay {
    background: white;
    margin-top: -190px;
    color: #334d6e;
    opacity: 0.9;
    position: absolute;
    width: 82%;
    height: 80vw;
    z-index: 99999;
    text-align: center;
    ._loading_overlay_wrapper {
      margin-top: 30%;
    }
  }
  .section-option {
    padding-top: 20px;
    text-align: right;
    a {
      margin: 0px 10px;
    }
  }

  .row-btn {
    margin: 0px;
    display: flex;
    padding: 15px 10px;
  }
  .-disable {
    cursor: no-drop !important;
    background-color: #c2cfe0 !important;
  }

  .box_field {
    font-weight: 100;
    font-family: inter;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #dfe0eb;
    height: 50px;
    margin-top: 10px;
    font-size: 14px;
    color: #334d6e;
    text-align: left;
    padding: 10px 10px;
  }
  .icon-key {
    margin-right: 10px;
    width: 26px;
  }
  .-border {
    border: 1px solid #0698be !important;
  }

  .active_field {
    background-color: #f4f5f5;
    border: 1px solid #0698be;
  }

  .arrow_select {
    width: 13px;
    margin-right: 7px;
    float: right;
  }

  .box-dropdown {
    width: 300px;
    height: auto;
    position: absolute;
    z-index: 999;
    background-color: white;
    border: 1px solid #0698be;
    border-radius: 5px;
    ul {
      padding: 0px;
      overflow: auto;
      max-height: 252px;
      margin: 0px;
      li {
        padding-left: 25px;
        font-size: 14px;
        color: #707683;
      }
      li:hover {
        border-left: 3px solid #0698be;
        background: #eff9f8;
      }
    }

    .box-dropdown-footer {
      cursor: pointer;
      border-top: 1px solid #cedae4;
      font-size: 13px;
      color: #334d6e;
      padding: 12px 24px;
      .icon-create {
        width: 20px;
        margin: 0px 5px 0px 0px;
      }
    }
  }

  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    float: right;
    margin-top: 25px;
  }

  /* Hide the browser's default checkbox */
  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #c2cfe0;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark {
    background-color: #0698be;
  }

  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark {
    background-color: #0698be;
    border-radius: 3px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .title-inner {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bold;
  }

  .desc-inner {
    font-size: 16px;
  }

  table {
    margin-top: 40px;
    width: 100%;
  }

  th {
    background-color: #f9f9fb;
    color: #334d6e;
    padding: 15px;
    text-align: center;
    font-weight: bold;
  }

  tr {
    border: 1px solid #c2cfe0;
  }

  td {
    font-weight: 100;
    padding: 5px 0px;
    color: #334d6e;
    padding: 15px;
    text-align: center;
  }

  .selectType {
    width: 100%;
    word-wrap: normal;
    border-color: #cfdce5;
    border-radius: 5px;
    color: #323c47;
    padding: 3px 5px;
    font-size: 13px;
    height: 51px;
  }

  .container {
    margin: unset;
    padding: 0px;
    width: 100% !important;
    max-width: 100% !important;
    #search-field {
      border-radius: 5px;
      border: 1px solid #cfdce5;
      margin-left: 10px;
    }
    .row {
      display: none;
    }
  }
  .-hide {
    display: none;
  }
  .section-table {
    overflow: auto;
    display: flex;
    border: 1px solid #dfe0eb;
    margin-top: 15px;
    font-weight: bold;
  }

  .box-show-mapping {
    width: 100%;
    font-size: 13px;
    text-align: center;
    color: #334d6e;
    .box-column {
      padding: 20px;
      background-color: #f9f9fb;
    }
    .box-rows {
      padding: 20px;
      border-bottom: 1px solid #dfe0eb;
    }
    .box-rows: last-child {
      border-bottom: unset;
    }
  }

  .btn-next {
    cursor: pointer;
    background-color: #24b0c3;
    width: 170px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: white;
    padding: 9px;
    :hover {
      color: white;
    }
  }

  .btn-back {
    cursor: pointer;
    background-color: #eeeeee !important;
    width: 170px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: #334d6e;
    padding: 9px;
    :hover {
      color: #334d6e;
    }
    span {
      font-weight: 600;
    }
  }

  .btn-blue {
    margin: 0px;
    width: auto;
    span {
      font-weight: 600;
    }
  }

  .warning-text {
    color: #e4b3b4;
    margin-left: 20px;
  }

  .custom-selects {
    height: auto;
    width: 100%;
    .-textvalid {
      display: contents;
      color: #e86580;
      font-family: Inter;
      font-size: 12px;
      position: absolute;
      line-height: normal;
      letter-spacing: 0.12px;
      margin-top: 5px;
    }
  }

  .sample-box {
    padding-top: 19px;
    span {
      margin-top: 10px;
      background: #f9f9fb;
      color: #6f809e;
      font-size: 12px;
      text-align: center;
      margin-right: 10px;
      border-radius: 5px;
      padding: 13px 16px;
    }
  }
  .col-3 {
    padding-left: 10px !important;
  }
  .col-4 {
    flex: 0 0 28.333333% !important;
    padding-left: 10px;
    display: flex;
  }

  .-fixTooltip {
    margin-left: -30px;
    margin-top: 23px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: #00252f;
    color: #fff;
    text-align: left;
    opacity: 1;
    border-radius: 0px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 24px;
    margin-left: -123px;
    padding: 15px;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    left: 55px;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .box-validate {
    transition: width 0.5s;
    width: 100%;
    height: 80px;
    flex-grow: 0;
    padding: 30px;
    border: solid 1px #f3ebeb;
    background-color: rgba(254, 246, 210, 0.9);
    font-size: 14px;
    text-align: left;
    img {
      width: 18px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }

  .box-validate-red {
    transition: width 0.5s;
    width: 100%;
    height: 100px;
    flex-grow: 0;
    padding: 20px 0 34px 30px;
    border: solid 1px #ff2c65;
    background-color: #fff7f9;
    border-radius: 10px;
    color: #435266;
    font-weight: 600;
    img {
      width: 21px;
      margin-right: 10px;
      margin-top: -2px;
    }
    p {
      color: #ff2c65;
      font-weight: 500;
      padding: 10px 35px;
    }
  }

  .box-validate-success {
    transition: width 0.5s;
    width: 100%;
    height: 136px;
    flex-grow: 0;
    padding: 57px 20px;
    color: #28cc97;
    font-weight: 600;
    border: 1px solid #e8e9f1;
    background-color: #f9f9fb;
    text-align: center;
    img {
      width: 18px;
      margin-right: 3px;
      margin-top: -2px;
      margin-left: -30px;
    }
  }
`

const ReviewBox = styled.div`
  .height-unset {
    line-height: unset !important;
  }
  .head-map {
    color: #385172;
  }
  .title-review {
    color: #385172;
    font-size: 18px;
    font-weight: 600;
  }
  .btn-royal-bule {
    background-color: #385172;
    color: #fff;
  }

  .box-review {
    display: flex;
  }

  .datatype {
    width: 95%;
    height: 240px;
    margin: 30px 24px 0 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #aabaca;
    border-radius: 30px;

    .bt-footer {
      top: 540px;
      position: fixed;
    }

    .correct-icon {
      width: 14px;
    }

    .mgt100 {
      margin-top: 100px;
    }
    .mgt60 {
      margin-top: 60px;
    }
    .box-status {
      span {
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 600;
        color: #334d6e;
      }
    }
    p {
      margin-bottom: 2px;
      span {
        color: #39bcde;
      }
    }

    .icon {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }

    .-fix-icon {
      width: 40px !important;
      height: 40px !important;
      margin-left: -12px !important;
      margin-top: -10px !important;
    }

    .-title {
      font-family: 'Inter';
      letter-spacing: 0.18px;
      font-weight: 600;
      color: #0698be;
      margin-bottom: 10px;
    }
    .-desc {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #707683;
      font-weight: 400;
    }

    .-left {
      width: 75%;
    }
    .-right {
      width: 25%;
    }
  }

  .top-row {
    text-align: right;
    margin: auto 24px;
    display: block;
  }

  .btn-next {
    cursor: pointer;
    background-color: #24b0c3 !important;
    width: 165px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: white;
    padding: 9px;
    margin-left: 5px;
    :hover {
      color: white;
    }
  }

  .btn-back {
    cursor: pointer;
    background-color: #eeeeee !important;
    width: 165px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    height: 40px;
    color: #334d6e;
    padding: 9px;
    :hover {
      color: #334d6e;
    }
  }

  .loadding {
    text-align: center;
    background: #f9f9fb;
    margin-top: -570px;
    margin-left: -30px;
    color: #334d6e;
    position: absolute;
    width: ${props => (props.isShowMenu ? '82%' : '100%')};
    height: 100%;
    z-index: 99999;
    img {
      border-radius: 50%;
      margin: 10% auto 20px auto;
    }
    .-blue {
      font-size: 25.87px;
      font-weight: 600;
    }
    .-gray-light {
      font-family: Inter;
      font-size: 15.5226px;
      line-height: 19px;
    }
  }
  .loadding-fail {
    text-align: center;
    background: #f9f9fb;
    margin-top: -560px;
    margin-left: -50px;
    color: #334d6e;
    position: absolute;
    width: 82%;
    height: 100%;
    z-index: 99999;
    img {
      width: 260px;
      margin: 10% auto 20px auto;
    }
    .-red {
      font-size: 25.87px;
      font-weight: 600;
      color: #eeb3c1;
      margin-bottom: 1rem;
    }
    .-gray-light {
      font-family: Inter;
      font-size: 15.5226px;
      line-height: 19px;
    }
  }
`

const SelectSourceBox = styled.div`
  .section-main {
    width: 100%;
    height: 490px;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 10px;
    padding: 50px;
    text-align: center;

    border-radius: unset;
    .title-source {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #385172;
    }
  }
  .-disable {
    background: #aaa5 !important;
    cursor: no-drop !important;
    border-color: #aaa5 !important;
  }

  .-disable .-active {
    padding: 19px !important;
    border: solid 2px #aaa5 !important;
  }
  .section-select-source {
    display: flex;
    margin: 20px 0px;
    align-items: center;
    justify-content: center;
  }
  .-active {
    padding: 19px !important;
    border: solid 2px #24b0c3 !important;
  }
  .box-select-source {
    cursor: pointer;
    width: 320px;
    height: 110px;
    margin: 0 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px #aabaca;
    background-color: #fff;
    display: flex;
    .box-image-source {
      cursor: pointer;
      img {
        width: 43px;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .box-name-source {
      padding: 5px 20px;
      text-align: left;
      cursor: pointer;
      p {
        font-size: 16px;
        color: #334d6e;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 5px;
      }
      label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #6f809e;
        cursor: pointer;
        font-weight: 400;
        
      }
    }
    :hover {
      padding: 19px;
      border: solid 2px #24b0c3;
    }
  }

  .section-footer {
    padding: 20px 20px 35px 20px;
    display: flex;
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .btn-blue {
      background-color: #24b0c3;
      color: #fff;
    }
    .btn {
      width: 120px;
      height: 44px;
      margin: 0 0 0 10px;
      border-radius: 8px;
      border: solid 1.5px #24b0c3;
      color: #24b0c3;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .arrow-right {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 4px;
      margin-left: 16px;
      position: absolute;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .arrow-left {
      border: solid #24b0c3;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 3px;
      margin-left: -17px;
      position: absolute;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
`
const ProgressbarBox = styled.div`

.section-progress {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
  li {
    padding: unset;
  }
  .progressbar {
    padding: unset;
  }
  .progressbar li {
    list-style-type: none;
    width: 200px;
    float: left;
    font-size: 14px;
    position: relative;
    text-align: center;
    color: #c2cfe0;
    display: flex;
    span {
      padding: 0px 0px 0px 10px;
      line-height: 1.2;
      font-weight: 500;
      font-size: 16px;
      margin-top: 0px;
    }
  }
  .progressbar li:before {
    background-image: url('/assets/icons/unselect.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 20px;
    height: 20px;
    content: '';
    color: transparent;
    line-height: 35px;
    display: inline-table;
    margin: 0px;
    border-radius: 50%;
    background-color: #c2cfe0;
  }
  .progressbar li:after {
    width: 50px;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #c2cfe0;
    top: 10px;
    left: -60px;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
    color: #334D6E;
  }

  .progressbar li.step-pass {
    color: #334D6E;
  }
  .progressbar li.active:before {
    background-color: transparent;
    background-image: url(/assets/icons/active_trans.png);
    z-index: 0;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;
  }
  .progressbar li.active + li:after {
    background-color: rgba(194, 207, 224, 0.58);
  }
}

.progressbar li.step-pass:before {
  background-color: #20adc2;
  background-image: url(/assets/icons/check.png);
  background-size: 12px 12px;
  z-index: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #20adc2;
  color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: inline-table;
}
  
  line-height: 30px;
  border-radius: 100%;
  text-align: center;
}
.progressbar li.step-pass + li:after {
  background-color: #24b0c3;
}
}
.top-row {
display: flex;
}


.section-main-landding {
  width: 860px;
  height: 490px;
  background-color: #fff;
  margin: 40px auto;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 1px 2px #888888;
  display: flex;
  img {
    width: 300px;
    margin-top: 50px;
    margin-right: 10px;
  }
  a{
    width: 170px;
    margin: 0px;
  }
  .-right{
    text-align:right;
  }

  .center-verticle {
    margin: 17% 30px;
    .title {
      margin: 0px;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
    }
    p:first-child {
      color: #00252f;
    }
    .desc {
      color: #6A707E;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
}

.section-main {
  width: 860px;
  height: 420px;
  background-color: #fff;
  margin: 20px auto;
  border-radius: 10px;
  padding: 40px 56px;
  text-align: center;
  box-shadow: 0px 1px 2px #888888;

  .section-tooltip{
    text-align: right;
    .icon-tooltip{
      width: 11px;
      margin-top: 5px;
      margin-right: 8px;
      position: relative;
      display: inline-block;
    }
  }


  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .title {
    font-family: 'Inter';
    color: #334d6e;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .section-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .-selected {
      border: 3px solid #39BCDE;
    }
    ._loading_overlay_wrapper {
      width: 100% !important;
      height: auto;
      circle {
        stroke: #20adc2;
      }
    }
    ._loading_overlay_overlay {
      border-radius: 10px !important;
      background-color: #f9f9f9 !important;
    }
    .backdrop {
      div{
        justify-content: normal!important;
        div{
          justify-content: center!important;
        }
      }
      height: 215px;
      .-f14{
        font-size:14px;
      }
      span {
        font-size: 10px;
      }
      img {
        width: 85px;
        margin-bottom: 10px;
      }
      .btn-blue {
        margin-top:10px;
        width: 200px;
      }
    }
  }
  .-gray {
    color: rgba(106, 112, 126, 0.6);
    font-size: 10px;
  }

  .-lightBlue {
    color: #34BCC5;
  }
  .-small {
    font-size: 14px;
    line-height: 18px;
    color: rgba(51, 77, 110, 0.6);
  }
  .-download {
    cursor:pointer;
    line-height: 4;
    font-size: 14px;
    font-family: inter;
    img {
      width: 16px;
      margin-top:-5px;
    }
  }
  .-download:hover{
    color:#334D6E;
  }
  .list-type-box {
    cursor: pointer;
    border-radius: 10px;
    background-color: #f9f9f9;
    width: 220px;
    height: 250px;
    margin: 20px;
    border: 1px solid #e8e8e8;
    padding: 2px 20px;
    .list-icon {
      height: 90px;
    }
    img { 
      margin-top: 20px;
      width: 90px; 
    }
  }

  .list-type-box:hover{
    border: 3px solid #39BCDE;
    padding: 0px 18px;
  }

  .list-center-box {
    border-radius: 10px;
    background-color: #fff;
    margin: 15px 20px;
    img {
      // margin-top: 20px;
      width: 90px;
      margin-bottom: 5px;
    }
    .title-type{
      margin-bottom: 0px; 
    }
    .btn-create-group{
      cursor:pointer;
      color:#334D6E;
      width: 320px;
      margin: auto;
      background: white;
      height: 45px;
      border: 2px solid #24B0C3!important;
      border-radius: 5px;
      margin-top: 10px;
      .icon-create{
        width:20px;
        margin-top: 17px;
        margin-right:5px;
      }
      span{
        font-size:14px;
        color:#334D6E;
        font-weight:bold;
        margin-top: -5px;
        display: block;
      }
      p{
        margin-top: 5px;
        font-size:14px;
      }
    }
    span{
      font-size:14px;
      color:#6A707E;
      font-weight:700;
    }
  }
  .mini{
    font-weight: 100!important;
  }

}

.-upload{
  width:750px!important;
  height:430px!important;
  padding-top: 32px;
}

.section-upload {
  background-color: #F9F9FB;
  border: 1px dotted #C4C4C4;
  box-sizing :  border-box ;
  border-radius: 10px;
  width: 550px;
  height: 220px;
  margin: 10px auto;
  .row {
    display: block;
  }

  ._loading_overlay_wrapper{
    margin-top:unset;
    ._loading_overlay_overlay{
      margin-top:0px;
    }
  }
  div {
    border: none !important;
  }
}

.-uploadError{
  border: dotted 3px #ff2c65;
  background-color: #fffcfc;
  .text-error{
    color:#ff2c65;
    font-family: Inter;
    font-size: 12px;
    width: max-content;
    margin: -52px auto;
    img{
      width: 15px;
      margin-top: 8px;
      margin-left: 2px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: #00252f;
  color: #fff;
  border-radius: 0px;
  padding: 5px 0;
  position: absolute;
  opacity: 1;
  z-index: 1;
  bottom: 24px;
  margin-left: -132px;
  padding: 15px;
  font-family: inter;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 55px;
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.section-group {
  background-color: #F9F9FB;
  border: 1px solid #ECECED;
  border-radius: 10px;
  width: 492px;
  height: 250px;
  margin: 20px 0px 0px 0px;
  .row {
    display: block;
  }
  div {
    border: none;
  }
  ._loading_overlay_wrapper{
    height:unset!important;
  } 
}

  circle {
    stroke: #20adc2;
  }

  .section-history{
    padding: 0px 30px 10px 20px;
    margin-top:20px;
    .-time{
      color: rgba(112, 118, 131, 0.42);
    }
    table{
      border-collapse: collapse;
       width: 100%;
       color: #334D6E;
       font-family: Inter;
       font-size: 13px;
       border: 1px solid #DFE0EB;
    }
   th {
      text-align: left;
      padding: 5px 10px;
      background-color:#F2F5F9;
      border-bottom: 1px solid #DFE0EB;
    }
    td{
      padding: 10px 30px;
    }
    .row-top{
      margin-bottom:20px;
    }
    .-centered{
      text-align:center;
    }
    .-right{
      text-align:right;
    }
    .btn-blue{
      margin:0px;
    }

    .-status{
      display: inline-block;
      padding: 5px 20px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 10px;
      
      width: auto;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .-warning{
      background-color: #fff5da;
      color: #f0b61d;
    }
    .-fail{
      background-color: #ffd4e0;
      color: #ff2c65;
    }
    .-completed{
      background: #d4f5eb;
      color: #28cc97;
    }
    .-cancle{
      background-color:#eef1f5;
      color: #aabaca;
    }
    .-success{
      background: #d4f5eb;
      color: #28cc97;
    }
    .-canceled{
      background-color: #eef1f5;
      color: #aabaca;
    }
    .-active{
      background-color: #c2f7fe;
      color: #67cde0;
    }
    .-waiting{
      background: #afeef6;
      color: #24b0c3;
    }


    .onLoadmore{
      padding: 40px 0px 80px 0px;
      ._loading_overlay_wrapper{
        margin-top:0px;
        widht: 100%;
      }
    }
  }
  

 
`
const DropdownBox = styled.div` 
.box-dropdown {
    position: absolute;
    min-width:${props => (props.isFlex ? '400px' : '400px')};
    max-height: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 9;
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px;
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  
  .box_field{
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 20px;
    cursor : pointer;
    padding-right:40px;
    
  }
  .-no-box-shadow{
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  }
 
  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: -28px;

  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    margin: -28px;
  }
.text-dropdown{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-dropdown-small {
  width: 119px !important;
  min-width: 80% !important;

  li{
    width: 100% !important;
    min-width: 80% !important;
  }
}
  .time-box{
    width: 130px !important;
  }
  .date-box{
    width: 234px !important;
    
  }

`
export {
  TableBox,
  ImportTypeBox,
  MappingBox,
  ReviewBox,
  SelectSourceBox,
  ProgressbarBox,
  DropdownBox
}
