import React, { Component, Fragment, PureComponent } from 'react'
import { BuilderBox, ConditionBox } from './style'
import { isEmpty } from '../../helper/helper'
import NotFoundComponent from '../error/notFound'
import { verifyToken } from '../../apis/login/api'

import DropdownV2Component from '../dropdownV2/dropdownV2'
import { getFieldsList } from '../../apis/mapping/api'
import { getGroupList } from '../../apis/group/api'
import {
  segmentPreview,
  segmentEditPreview,
  segmentCreate,
  segmentUpdate,
  segmentDetail,
  segmentExportCSV
} from '../../apis/segmentation/api'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { DatePicker } from 'antd'
import DataTable from 'react-data-table-component'
import { withRouter } from 'react-router'
import moment from 'moment'
import Modal from 'react-modal'

import 'react-nice-dates/build/style.css'
import 'antd/dist/antd.css'

class BuilderComponent extends PureComponent {
  state = {
    numCondition: 0,
    renderCondition: [
      {
        no: 0,
        data: {}
      }
    ],
    segmentName: '',
    customerFieldList: [],
    eventFieldList: [],
    groupList: [],
    condition: [], //['AND'],
    isLeft: true,
    isUpdate: true,
    openModel: false,
    schedlue: false,
    segmentIsNull: false,
    isLoadingUpdate: false,
    conditionIsNull: [],
    conditionList: {},
    idUpdate: '',
    paginationPage: 1,
    operationList: [
      {
        data_type: '>',
        field_name: '>' //'is greater than'
      },
      {
        data_type: '<',
        field_name: '<' //'is less than'
      },
      {
        data_type: '>=',
        field_name: '>=' //'is greater than or equal to'
      },
      {
        data_type: '<=',
        field_name: '<=' //'is less than or equal to'
      },
      {
        data_type: '==',
        field_name: '==' //'is equal to'
      },
      {
        data_type: '!=',
        field_name: '!=' //'is not equal to'
      }
    ],
    operationStringList: [
      {
        data_type: '==',
        field_name: '==' //'is equal to'
      },
      {
        data_type: '!=',
        field_name: '!=' //'is not equal to'
      }
    ],
    isTab: 'customer',
    resultPreview: {},
    columns: {
      columns_profile: []
    },
    data: {
      customer_profile: []
    },
    isPreviewLoadding: false,
    dataNotFound: false
  }

  getSegmentDetail = async id => {
    let res = {}
    let detail = await segmentDetail(id)
    if (!isEmpty(detail) && detail.status === 200) {
      res = detail.data
    }

    return res
  }

  creatCondition = () => {
    const { renderCondition, numCondition, condition = [] } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    let showCondition = {
      no: renderCondition.length,
      data: {}
    }
    renderCondition.push(showCondition)
    condition.push('AND')
    this.setState({ renderCondition, condition, numCondition: numCondition + 1 })
  }

  removeCondition = no => {
    const { renderCondition, numCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    let { condition } = this.state
    if (no === 0) {
      condition.splice(no, 1)
    } else {
      condition.splice(no - 1, 1)
    }
    renderCondition.splice(no, 1)
    this.setState(renderCondition)
    this.setState({ numCondition: numCondition - 1, isUpdate: !isUpdate, condition })
  }

  selectCondition = (no, option) => {
    const { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    let { condition } = this.state
    for (let i = 0; i < renderCondition.length; i++) {
      if (renderCondition[i].no === no) {
        renderCondition[i].data.condition = option
      }
    }

    for (let l = 0; l < condition.length; l++) {
      if (l === no - 1) {
        condition[l] = option
      }
    }

    this.setState({ renderCondition, condition, isUpdate: !isUpdate })
  }

  resetType = no => {
    let { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    renderCondition[no].data = {}
    this.setState(renderCondition)
    this.setState({ isUpdate: !isUpdate })
  }

  getGroupList = async dataType => {
    let pastType = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'
    let res = {}
    let wrap = []
    try {
      let response = await getGroupList(pastType)

      if (!isEmpty(response) && response.status === 200) {
        let temp = {}
        for (let i = 0; i < response.data.length; i++) {
          temp = {
            field_name: response.data[i].name,
            data_type: 'string'
          }
          wrap.push(temp)
        }
        res = wrap
      }
    } catch (error) {
      res = { error }
    }
    return res
  }

  getFieldsList = async dataType => {
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    let res = {}
    try {
      let response = await getFieldsList(paramApi)
      if (!isEmpty(response) && response.status === 200) {
        res = response.data
      }
    } catch (error) {
      res = error.message
    }
    return res
  }

  UNSAFE_componentWillReceiveProps = newProps => {
    const { renderCondition } = this.state
    const { history } = this.props
    let su = localStorage.getItem('su') //su = segment update
    let p = localStorage.getItem('p') //p = path
    if (newProps.outerSegment) {
      if (!isEmpty(su)) {
        if (!isEmpty(renderCondition[0].data)) {
          this.setState({ openModel: true })
        } else {
          // this.setState({ openModel: false })
          // if (history.location.pathname !== p) {
          // history.push(p)
          // } else {
          window.location.reload()
          // }
        }
      } else {
        this.setState({ openModel: false })
        if (history.location.pathname !== p) {
          history.push(p)
        } else {
          window.location.reload()
        }
      }
    }
  }
  setRole = async () => {
    const { history } = this.props
    let role = await verifyToken()
    if (role.role === 'viewer') {
      history.push('/segmentation')
    }
  }
  componentDidMount = async () => {
    const { outerSegment, match } = this.props
    await this.setRole()
    let idUpdate = !isEmpty(match.params) && !isEmpty(match.params.id) ? match.params.id : ''
    let conditionList = {}
    let customerFieldList = await this.getFieldsList('customer')
    let eventFieldList = await this.getFieldsList('event')
    let groupList = await this.getGroupList('event')
    this.setState({ segmentId: idUpdate, customerFieldList, eventFieldList, groupList })
    this.setState({ outerSegment: outerSegment })
    if (!isEmpty(idUpdate)) {
      this.setState({ idUpdate, isLoadingUpdate: true })
      conditionList = await this.getSegmentDetail(idUpdate)
      this.setState({ conditionList })
      this.setMainLoadding(true)
      localStorage.removeItem('su')

      let _wrap = {}
      let res = []
      if (
        !isEmpty(conditionList) &&
        !isEmpty(conditionList.config)
        // &&
        // !isEmpty(conditionList.config.conditions)
      ) {
        if (conditionList.sourceType !== 'rfm') {
          for (let i = 0; i < conditionList.config.conditions.length; i++) {
            conditionList.config.conditions[i].condition =
              conditionList.config.condition_operation.toString()
            _wrap = {
              data: conditionList.config.conditions[i],
              no: i
            }
            res.push(_wrap)
          }
          this.setState({
            renderCondition: res,
            condition: conditionList.config.condition_operation
              ? conditionList.config.condition_operation
              : []
          })
        }

        this.setState({
          dataNotFound: false,
          segmentName: conditionList.config.segment_name,
          schedlue: conditionList.config.auto_update
        })
        setTimeout(() => {
          this.queryPreview(false)
        }, 1000)
      } else if (conditionList.sourceType === 'rfm') {
        this.setMainLoadding(false)
      } else {
        this.setMainLoadding(false)
        this.setState({ dataNotFound: true })
      }
    }
  }

  setValueCondition = (no, data, dropdownType) => {
    let { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    for (let i = 0; i < renderCondition.length; i++) {
      if (i === no) {
        if (dropdownType === 'group') {
          renderCondition[i].data.group_name = data.select_field
        } else if (dropdownType === 'field') {
          renderCondition[i].data.select_field = data.select_field
          renderCondition[i].data.value_type = data.value_type
          if (!isEmpty(renderCondition[i].data.operation)) {
            delete renderCondition[i].data.operation
          }
          if (!isEmpty(renderCondition[i].data.value)) {
            delete renderCondition[i].data.value
          }
        } else if (dropdownType === 'eventCondition') {
          renderCondition[i].data.count_event.operation = data.value_type
        } else {
          renderCondition[i].data.operation = data.value_type
        }
      }
    }
    this.setState({ renderCondition })
    this.setState({ isUpdate: !isUpdate })
  }

  selectType = async (no, type) => {
    localStorage.setItem('su', 'Y') //su = segment update
    let { renderCondition, isUpdate, groupList } = this.state
    renderCondition[no].data.data_type = type
    this.setState(renderCondition)
    this.setState({ isUpdate: !isUpdate })
  }

  toIsoString = date => {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        var norm = Math.floor(Math.abs(num))
        return (norm < 10 ? '0' : '') + norm
      }

    return (
      date.getFullYear() +
      '-' +
      pad(date.getMonth() + 1) +
      '-' +
      pad(date.getDate()) +
      'T' +
      pad(date.getHours()) +
      ':' +
      pad(date.getMinutes()) +
      ':' +
      pad(date.getSeconds()) +
      '.000Z'
    )
  }

  setDate = (no, e) => {
    const { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update

    for (let i = 0; i < renderCondition.length; i++) {
      if (renderCondition[i].no === no) {
        let date = e._d.toDateString()
        let res = new Date(date)
        let last = this.toIsoString(res)

        renderCondition[i].data.value = last
        // renderCondition[i].data.showDate = e
      }
    }
    this.setState({ isUpdate: !isUpdate })
  }

  setSegment = (optionName, value) => {
    const { isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update
    if (optionName === 'segment') {
      this.setState({
        segmentName: value.target.value
      })
    } else {
      this.setState({
        schedlue: value
      })
    }
    this.setState({ isUpdate: !isUpdate })
  }

  setValueInput = (no, { target }) => {
    const { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update
    for (let i = 0; i < renderCondition.length; i++) {
      if (i === no) {
        renderCondition[i].data.value = target.value
      }
    }
    this.setState({ isUpdate: !isUpdate })
  }

  setValueCountEvent = (no, { target }) => {
    const { renderCondition, isUpdate } = this.state
    localStorage.setItem('su', 'Y') //su = segment update
    for (let i = 0; i < renderCondition.length; i++) {
      if (i === no) {
        renderCondition[i].data.count_event.value = target.value
      }
    }
    this.setState({ isUpdate: !isUpdate })
  }

  validateSegment = renderCondition => {
    let isValid = false

    let _tempCheckCondition = {}
    let checkCondition = []
    for (let i = 0; i < renderCondition.length; i++) {
      if (
        isEmpty(renderCondition[i].data) ||
        isEmpty(renderCondition[i].data['select_field']) ||
        isEmpty(renderCondition[i].data['operation']) ||
        isEmpty(renderCondition[i].data['value'])
      ) {
        _tempCheckCondition = {
          no: i,
          valid: false
        }
        isValid = false
      } else if (renderCondition[i].data.data_type === 'event') {
        if (isEmpty(renderCondition[i].data['group_name'])) {
          _tempCheckCondition = {
            no: i,
            valid: false
          }
          isValid = false
        } else {
          _tempCheckCondition = {
            no: i,
            valid: true
          }
          isValid = true
        }
      } else {
        _tempCheckCondition = {
          no: i,
          valid: true
        }
        isValid = true
      }
      checkCondition.push(_tempCheckCondition)
    }
    this.setState({ conditionIsNull: checkCondition })

    return isValid
  }

  queryPreview = async clicked => {
    const { setMainLoaddingFn, match } = this.props
    const { renderCondition, segmentName, schedlue, condition } = this.state
    const segment_id = match && match.params && match.params.id ? match.params.id : ''
    let isValid = this.validateSegment(renderCondition)
    // if (isValid) {
    if (clicked) {
      this.setState({ isPreviewLoadding: true })
    }
    let _tempCondition = []
    let operation = []
    for (let i = 0; i < renderCondition.length; i++) {
      if (!isEmpty(renderCondition[i].data.condition)) {
        operation.push(renderCondition[i].data.condition)
      }
      _tempCondition.push(renderCondition[i].data)
    }
    for (let l = 0; l < _tempCondition.length; l++) {
      delete _tempCondition[l].condition
    }

    this.setState({ condition })
    let response = {}
    if (clicked) {
      let objPreview = {
        segment_name: segmentName,
        auto_update: schedlue,
        conditions: _tempCondition,
        condition_operation: condition
      }
      response = await segmentPreview(objPreview)
      this.setState({ objSaveSegment: objPreview })
    } else {
      let objEditPreview = {
        segment_id: segment_id,
        page: 1,
        limit: 10
      }
      response = await segmentEditPreview(objEditPreview)
    }
    if (response.status === 200 && response.data) {
      let columnProfile = []
      let _temp_columnProfile = {}
      if (response.data.columns_profile && response.data.columns_profile.length > 0) {
        for (let i = 0; i < response.data.columns_profile.length; i++) {
          _temp_columnProfile = {
            name: response.data.columns_profile[i],
            selector: response.data.columns_profile[i],
            sortable: true
          }
          columnProfile.push(_temp_columnProfile)
        }
        for (let l = 0; l < response.data.customer_profile.length; l++) {
          if (!isEmpty(response.data.customer_profile[l].birthday)) {
            response.data.customer_profile[l].birthday = this.convertDate(
              response.data.customer_profile[l].birthday
            )
          }
          if (!isEmpty(response.data.customer_profile[l].timestamp)) {
            response.data.customer_profile[l].timestamp = this.convertDate(
              response.data.customer_profile[l].timestamp
            )
          }
          if (!isEmpty(response.data.customer_profile[l].updated_at)) {
            response.data.customer_profile[l].updated_at = this.convertDate(
              response.data.customer_profile[l].updated_at
            )
          }
          delete response.data.customer_profile[l].metadata
        }
      }

      this.setState({
        columns: { columns_profile: columnProfile },
        data: {
          customer_profile: response.data.customer_profile
        }
      })
      this.setMainLoadding(false)
      this.setState({ resultPreview: response.data, isPreviewLoadding: false })
    } else {
      this.setMainLoadding(false)
      this.setState({ isPreviewLoadding: false })
      window.scrollTo({ top: 400, behavior: 'smooth' })
    }
  }

  setMainLoadding = isLoad => {
    this.setState({ isLoadingUpdate: isLoad })
  }

  createSegment = async () => {
    const { setMainLoaddingFn, history, match } = this.props
    const { objSaveSegment, segmentName, schedlue, renderCondition, condition } = this.state
    let isNameNull = false
    let objPreview = {}
    let idUpdate = !isEmpty(match) ? match.params.id : ''
    if (isEmpty(segmentName)) {
      this.setState({ segmentIsNull: true, openModel: false })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      this.setState({ segmentIsNull: false })
      isNameNull = true
      let isValid = this.validateSegment(renderCondition)
      if (!isValid) {
        window.scrollTo({ top: 400, behavior: 'smooth' })
      } else {
        if (!isEmpty(objSaveSegment)) {
          objSaveSegment.segment_name = segmentName
          objSaveSegment.auto_update = schedlue
        } else {
          let _tempCondition = []
          let operation = []
          for (let i = 0; i < renderCondition.length; i++) {
            if (!isEmpty(renderCondition[i].data.condition)) {
              operation.push(renderCondition[i].data.condition)
            }
            _tempCondition.push(renderCondition[i].data)
          }
          for (let l = 0; l < _tempCondition.length; l++) {
            // delete _tempCondition[l].showDate
            delete _tempCondition[l].condition
          }
          objPreview = {
            segment_name: segmentName,
            auto_update: schedlue,
            conditions: _tempCondition,
            condition_operation: condition //!isEmpty(condition) ? condition : operation.length == 0 ? operation : [operation.shift()]
          }
        }

        this.setMainLoadding(true)
        localStorage.removeItem('su')
        if (isEmpty(idUpdate)) {
          // create
          await segmentCreate(!isEmpty(objSaveSegment) ? objSaveSegment : objPreview)
          this.setMainLoadding(false)
          // this.props.displaySegmentListFn()
          history.push('/segmentation')
        } else {
          //update
          let res = await segmentUpdate(
            idUpdate,
            !isEmpty(objSaveSegment) ? objSaveSegment : objPreview
          )
          if (!isEmpty(res) && res.status === 200) {
            setTimeout(() => {
              this.setMainLoadding(false)
              history.push('/segmentation')
            }, 1500)
          }
        }
      }
    }
  }

  convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })

    let res = day + '/' + month + '/' + year + ', ' + time

    return res
  }

  percentage = (partialValue, totalValue) => {
    let res = (100 * partialValue) / totalValue
    return parseInt(res)
  }

  toLetters = num => {
    num = num + 1
    var mod = num % 26
    var pow = (num / 26) | 0
    var out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z')
    return out
  }

  closeModal = option => {
    const { outerSegment, history } = this.props
    const { isInnerPopup } = this.state
    let p = localStorage.getItem('p')
    this.setState({ openModel: false })
    if (option !== 'exit') {
      if (isInnerPopup) {
        localStorage.removeItem('su')
        // window.location = p
        history.push(p)
      }
      if (outerSegment) {
        localStorage.removeItem('su')
      }
      history.push(p)
      // window.location = p
    }
  }

  openModal = (isInnerPopup = false) => {
    const { history } = this.props
    const { renderCondition } = this.state
    if (isInnerPopup) {
      localStorage.setItem('p', '/segmentation')
    }
    let su = localStorage.getItem('su') //su = segment update
    if (!isEmpty(su)) {
      if (!isEmpty(renderCondition[0].data)) {
        this.setState({ openModel: true })
      } else {
        history.push('/segmentation')
      }
    } else {
      history.push('/segmentation')
    }
  }
  addFrequency = no => {
    const { renderCondition, isUpdate } = this.state
    if (!isEmpty(renderCondition[no].data.count_event)) {
      delete renderCondition[no].data.count_event
    } else {
      renderCondition[no].data.count_event = {
        operation: '',
        value: ''
      }
    }
    this.setState(renderCondition)
    this.setState({ isUpdate: !isUpdate })
  }

  exportSegmentCSV = async id => {
    const { segmentName } = this.state
    let newDate = new Date()
    let nowDate = this.convertDate(newDate).substring(0, this.convertDate(newDate).indexOf(','))

    if (id) {
      await segmentExportCSV(id, segmentName, nowDate)
    }
  }

  handleChangePage = async e => {
    const { match } = this.props
    const { resultPreview, paginationPage, data } = this.state
    const segment_id = match && match.params && match.params.id ? match.params.id : ''

    if (e * 10 === resultPreview.customer_profile.length) {
      let page = paginationPage + 1
      this.setState({ paginationPage: page })
      // await getCampaignList(page, 'all')

      let objPreview = {
        segment_id: segment_id,
        page: page,
        limit: 20
      }
      let response = await segmentPreview(objPreview)
      if (response.status === 200 && response.data) {
        let columnProfile = []
        let columnProfileCSV = []
        let _temp_columnProfile = {}
        let _temp_columnProfile_csv = {}

        if (response.data.columns_profile && response.data.columns_profile.length > 0) {
          // for (let i = 0; i < response.data.columns_profile.length; i++) {
          //   _temp_columnProfile = {
          //     name: response.data.columns_profile[i],
          //     selector: response.data.columns_profile[i],
          //     sortable: true
          //   }
          //   columnProfile.push(_temp_columnProfile)
          // }

          for (let l = 0; l < response.data.customer_profile.length; l++) {
            if (!isEmpty(response.data.customer_profile[l].birthday)) {
              response.data.customer_profile[l].birthday = this.convertDate(
                response.data.customer_profile[l].birthday
              )
            }
            if (!isEmpty(response.data.customer_profile[l].timestamp)) {
              response.data.customer_profile[l].timestamp = this.convertDate(
                response.data.customer_profile[l].timestamp
              )
            }
            if (!isEmpty(response.data.customer_profile[l].updated_at)) {
              response.data.customer_profile[l].updated_at = this.convertDate(
                response.data.customer_profile[l].updated_at
              )
            }
            delete response.data.customer_profile[l].metadata
          }
        }
        if (page > 1) {
          let mergeData = data.customer_profile
          mergeData.push(...response.data.customer_profile)
          // this.setState({ columns: { columns_profile: columnProfile } })
          this.setState({
            data: {
              customer_profile: mergeData.map(item => item)
            }
          })
        }
        // this.setState({ resultPreview: response.data })
      }
    }
  }

  render() {
    const {
      renderCondition,
      columnEventCSV,
      columnProfileCSV,
      customerFieldList,
      eventFieldList,
      operationList,
      groupList,
      operationStringList,
      resultPreview,
      segmentName,
      columns,
      data,
      condition,
      openModel,
      schedlue,
      isPreviewLoadding,
      segmentIsNull,
      conditionIsNull,
      isLoadingUpdate,
      idUpdate,
      conditionList,
      dataNotFound
    } = this.state
    const { isShowMenu, history } = this.props
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-20%, -50%)', //isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
        transition: 'width 0.5s',
        width: '470px',
        height: 'auto',
        border: '2px solid #0698BE',
        borderRadius: '10px'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
    let su = localStorage.getItem('su') // segment update

    return (
      <BuilderBox isShowMenu={isShowMenu}>
        {!dataNotFound ? (
          <div className="main-loadding">
            {isLoadingUpdate ? (
              <div className="overlay-loadding">
                <img className="icon-loadding" src="/assets/images/flipball.gif" />
              </div>
            ) : (
              <Fragment />
            )}

            <div className="main-section">
              <div className="top-title">
                <p className="title">
                  <span
                    onClick={() =>
                      !isEmpty(su)
                        ? this.setState({ openModel: true })
                        : history.push('/segmentation')
                    }
                  >
                    Segmentation
                  </span>{' '}
                  <span className="-gray">
                    {!isEmpty(idUpdate) ? '/ Edit segment' : '/ Create new'}
                  </span>
                </p>
              </div>
            </div>

            <div className="main-section">
              <div className="col-8">
                <div className="box-segment">
                  {/* <p className="title">New Segment</p> */}
                  <p className="sub-title">Segment Name</p>
                  <input
                    value={segmentName}
                    onChange={e => this.setSegment('segment', e)}
                    type="text"
                    className={`segment-name ${segmentIsNull ? '-red-bd' : ''}`}
                    placeholder="Segment Name.."
                  />
                  <p className="sub-title">Schedule Options</p>
                  <div className="row-option">
                    <label className="container">
                      <input
                        id="preview1"
                        onChange={() => this.setSegment('schedule', false)}
                        value="false"
                        type="radio"
                        checked={!schedlue ? true : false}
                        name="radio"
                      />
                      <span className="checkmark"></span>

                      <span>One-time</span>
                    </label>
                    <label className="container">
                      <input
                        id="preview2"
                        type="radio"
                        onChange={() => this.setSegment('schedule', true)}
                        value="true"
                        name="radio"
                        checked={schedlue ? true : false}
                      />
                      <span className="checkmark"></span>
                      <span>Daily</span>
                    </label>
                  </div>
                </div>
                {conditionList &&
                  conditionList.sourceType !== 'rfm' &&
                  renderCondition.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        {i != 0 ? (
                          <ConditionBox
                            isLeft={
                              condition[i - 1] === 'AND' || isEmpty(condition[i - 1]) ? true : false
                            }
                          >
                            <div className="box-condition">
                              <div className="inner-condition">
                                <div className="active-condition" />
                                <label
                                  className={`${
                                    condition[i - 1] === 'AND' || isEmpty(condition[i - 1])
                                      ? '-active'
                                      : ''
                                  }`}
                                  onClick={() => this.selectCondition(i, 'AND')}
                                >
                                  AND
                                </label>
                                <label
                                  className={`${condition[i - 1] === 'OR' ? '-active' : ''}`}
                                  onClick={() => this.selectCondition(i, 'OR')}
                                >
                                  OR
                                </label>
                              </div>
                            </div>
                          </ConditionBox>
                        ) : (
                          <Fragment />
                        )}

                        <div
                          className={`create-segment ${
                            !isEmpty(conditionIsNull) &&
                            !isEmpty(conditionIsNull[i]) &&
                            !conditionIsNull[i].valid
                              ? '-red-bd'
                              : ''
                          }`}
                        >
                          <p className="title">
                            <span className="-gray">{this.toLetters(i)} | </span> Select Audience
                            Type{' '}
                            <span className="font-darkgreen ">
                              :{' '}
                              {!isEmpty(item.data) && !isEmpty(item.data.data_type)
                                ? item.data.data_type === 'profile'
                                  ? 'Customer attribute'
                                  : item.data.data_type
                                : ''}
                            </span>
                            <span
                              className={`btn-close`}
                              onClick={() => this.removeCondition(i)}
                            ></span>
                          </p>
                          <hr className="-line" />
                          {!isEmpty(item.data) && !isEmpty(item.data.data_type) ? (
                            <Fragment>
                              <div className="inner-segment">
                                {!isEmpty(item.data.data_type) &&
                                item.data.data_type === 'event' ? (
                                  <DropdownV2Component
                                    selectedValue={item.data.group_name}
                                    isFlex={item.data.data_type === 'event' ? true : false}
                                    dropdownType={'group'}
                                    setValueConditionFn={this.setValueCondition}
                                    no={i}
                                    fieldList={groupList}
                                  />
                                ) : (
                                  <Fragment />
                                )}

                                <DropdownV2Component
                                  selectedValue={item.data.select_field}
                                  isFlex={
                                    !isEmpty(item.data.data_type) && item.data.data_type === 'event'
                                      ? true
                                      : false
                                  }
                                  dropdownType={'field'}
                                  setValueConditionFn={this.setValueCondition}
                                  no={i}
                                  fieldList={
                                    item.data.data_type === 'profile'
                                      ? customerFieldList
                                      : eventFieldList
                                  }
                                />
                                {!isEmpty(item.data.select_field) && (
                                  <DropdownV2Component
                                    selectedValue={
                                      !isEmpty(item.data.operation) ? item.data.operation : ''
                                    }
                                    isFlex={
                                      !isEmpty(item.data.data_type) &&
                                      item.data.data_type === 'event'
                                        ? true
                                        : false
                                    }
                                    dropdownType={'operation'}
                                    setValueConditionFn={this.setValueCondition}
                                    no={i}
                                    fieldList={
                                      item.data.value_type === 'string'
                                        ? operationStringList
                                        : operationList
                                    }
                                  />
                                )}

                                {!isEmpty(item.data.operation) ? (
                                  !isEmpty(item.data.value_type) &&
                                  item.data.value_type === 'date' ? (
                                    <DatePicker
                                      value={
                                        !isEmpty(item.data.value) ? moment(item.data.value) : ''
                                      }
                                      placeholder="Ex. 2022-12-22"
                                      onChange={e => this.setDate(i, e)}
                                    />
                                  ) : (
                                    <input
                                      value={!isEmpty(item.data.value) ? item.data.value : ''}
                                      onChange={e => this.setValueInput(i, e)}
                                      className={`input-basic ${
                                        !isEmpty(item.data.data_type) &&
                                        item.data.data_type === 'event'
                                          ? '-isFlex'
                                          : ''
                                      }`}
                                      placeholder="Enter value"
                                    />
                                  )
                                ) : (
                                  <Fragment />
                                )}

                                <span
                                  className={`btn-close `}
                                  onClick={() => this.resetType(i)}
                                ></span>
                              </div>
                              {!isEmpty(item.data.data_type) && item.data.data_type === 'event' ? (
                                <div className="inner-segment inner-event-condition">
                                  <label className="container-checkbox">
                                    Add Frequency
                                    <input
                                      type="checkbox"
                                      checked={!isEmpty(item.data['count_event']) ? true : false}
                                      onChange={() => this.addFrequency(i)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              ) : (
                                <Fragment />
                              )}
                              {!isEmpty(item.data.count_event) ? (
                                <div className="inner-segment inner-event-condition -fadeIn">
                                  <DropdownV2Component
                                    selectedValue={
                                      !isEmpty(item.data.count_event.operation)
                                        ? item.data.count_event.operation
                                        : ''
                                    }
                                    isFlex={false}
                                    dropdownType={'eventCondition'}
                                    setValueConditionFn={this.setValueCondition}
                                    no={i}
                                    fieldList={operationList}
                                  />

                                  <input
                                    type="number"
                                    value={
                                      !isEmpty(item.data.count_event.value)
                                        ? item.data.count_event.value
                                        : ''
                                    }
                                    onChange={e => this.setValueCountEvent(i, e)}
                                    className={`input-basic`}
                                    placeholder="Enter value"
                                  />
                                  <span className="-times">Times</span>
                                </div>
                              ) : (
                                <Fragment />
                              )}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <span
                                className="font-darkgreen -pointer sub-title-14"
                                onClick={() => this.selectType(i, 'profile')}
                              >
                                <b>+</b> Add a customer attribute{' '}
                              </span>
                              <span
                                className="font-darkgreen -pointer sub-title-14"
                                onClick={() => this.selectType(i, 'event')}
                              >
                                | <b>+</b> Add an event
                              </span>
                            </Fragment>
                          )}
                        </div>
                      </Fragment>
                    )
                  })}
                <br />
                <a
                  className={`btn-basic bg-darkblue font-white ${
                    conditionList.sourceType === 'rfm' ? 'd-none' : ''
                  }`}
                  onClick={() => this.creatCondition()}
                >
                  <img src="/assets/icons/create-white.png" className="w-px-14" /> Add Condition
                </a>
              </div>

              <div className="col-4">
                <div className="box-summary">
                  {isPreviewLoadding ? (
                    <div className="overlay-loadding">
                      <img className="icon-loadding" src="/assets/images/flipball.gif" />
                    </div>
                  ) : (
                    <Fragment />
                  )}

                  <p className="sub-title">
                    Summary{' '}
                    <a className="btn-refresh" onClick={() => this.queryPreview(true)}>
                      <img src="/assets/icons/refresh.png" />
                    </a>
                  </p>
                  <hr className="-line" />
                  <CircularProgressbar
                    value={
                      !isEmpty(resultPreview)
                        ? this.percentage(
                            resultPreview.total_customer_segment,
                            resultPreview.total_customer
                          )
                        : '100'
                    }
                    text={
                      !isEmpty(resultPreview)
                        ? this.percentage(
                            resultPreview.total_customer_segment,
                            resultPreview.total_customer
                          ) + '%'
                        : '100%'
                    }
                  />

                  {!isEmpty(resultPreview) ? (
                    <p className="-res_total">
                      {' '}
                      {resultPreview.total_customer_segment}{' '}
                      <span className="-total">{' / ' + resultPreview.total_customer}</span>
                    </p>
                  ) : (
                    <p className="-res_total">-</p>
                  )}
                  <p className="-total sub-title-14">Of your customers.</p>
                </div>
              </div>
            </div>
            <div className="main-section bd-top">
              <p className="sub-title">Audience Preview</p>

              <div className="box-preview">
                {isPreviewLoadding ? (
                  <div className="overlay-loadding">
                    <img className="icon-loadding" src="/assets/images/flipball.gif" />
                  </div>
                ) : (
                  <Fragment />
                )}

                {!isEmpty(resultPreview) && !isEmpty(resultPreview.customer_profile) ? (
                  <div className="section-tab">
                    <div className="box-table">
                      <Fragment>
                        <a
                          className="btn-basic bg-darkblue font-white float-right"
                          onClick={() => this.exportSegmentCSV(idUpdate)}
                        >
                          <img className="w-px-14 -mt-3" src="/assets/icons/export_light.png" />{' '}
                          Export
                        </a>
                        <DataTable
                          pagination
                          rowsPerPage={1}
                          paginationRowsPerPageOptions={[1, 10, 25, 50, 100]}
                          onChangePage={this.handleChangePage}
                          center={true}
                          columns={columns.columns_profile}
                          data={data.customer_profile}
                        />{' '}
                      </Fragment>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <img className="-nodata" src="/assets/images/carry.png" />
                    <p className="txt-no-data">
                      No filter conditions added. Please add filter conditions to see audience
                      preview.
                    </p>
                  </Fragment>
                )}
                <div className="box-footer">
                  <a
                    className="btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
                    onClick={() => this.openModal(true)}
                  >
                    Cancel
                  </a>
                  <a
                    onClick={() => this.createSegment()}
                    className={`btn-basic bg-darkgreen font-white ${
                      conditionList.sourceType === 'rfm' ? 'd-none' : ''
                    }`}
                  >
                    Save
                  </a>
                </div>
                <br />
              </div>
            </div>
          </div>
        ) : (
          <div className="main-section">
            <NotFoundComponent errorType={'404'} callback={'/segmentation'} />
          </div>
        )}

        <Modal
          closeTimeoutMS={500}
          ariaHideApp={false}
          isOpen={openModel} //openModel
          onRequestClose={() => this.closeModal('exit')}
          style={customStyles}
        >
          <div className="body-popup">
            <b className="title-popup">Save changes?</b>
            <p className="desc-popup">
              Your unsaved changes will be lost. Save changes before leaving this page?
            </p>
            <div className="footer-popup">
              <a
                onClick={() => this.closeModal('exit')}
                className="btn-basic bg-gray font-white -left"
              >
                Cancel
              </a>

              <a
                onClick={() => this.closeModal('golist')}
                className=" btn-basic bg-whiter border-darkblue font-darkblue mx-2"
              >
                Don't save
              </a>
              <a className="btn-basic bg-darkgreen font-white" onClick={() => this.createSegment()}>
                Save
              </a>
            </div>
          </div>
        </Modal>
      </BuilderBox>
    )
  }
}
export default withRouter(BuilderComponent)
