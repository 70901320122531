import * as axios from 'axios'
import { redirectLogin } from '../../helper/helper'
const { REACT_APP_API_AUTHENTICATION } = process.env

let token = localStorage.getItem('token')
const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}
const connectLogin = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/login`
    res = await axios.post(URL, {
      email: params.email,
      password: params.password
    })
  } catch (error) {
    console.log('error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const sendOTP = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp`
    res = await axios.post(URL, {
      email: params.email,
      otp_id: params.otpId
    })
  } catch (error) {
    console.error('error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const verifyOTP = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-verify`
    res = await axios.post(
      URL,
      {
        email: params.email.toLowerCase(),
        otp_id: params.otpId,
        otp: params.otp
      },
      {
        withCredentials: true
      }
    )
  } catch (error) {
    console.error('error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const forgotPassword = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/reset-password`
    const response = await axios.post(URL, {
      email: params.email
    })
    res = response
  } catch (error) {
    console.error('forgotPassword : ', error)
    res = {
      status: error.status,
      data: error.message
    }
  }
  return res
}

const changePassword = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/change-password`
    const response = await axios.patch(URL, {
      email: params.email,
      password: params.password,
      token: params.token
    })
    res = response
  } catch (error) {
    res = {
      status: error.status,
      data: error.message
    }
  }
  return res
}

const verifyToken = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/verify`
    const { data, status } = await axios.get(URL, config)
    if (status === 200) {
      res = data
    } else {
      redirectLogin()
    }
  } catch (error) {
    redirectLogin()
  }
  return res
}

const accountDetail = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/account-detail`
    const { data, status } = await axios.get(URL, config)
    if (status === 200) {
      res = data
    } else {
      redirectLogin()
    }
  } catch (error) {
    redirectLogin()
  }
  return res
}

export {
  connectLogin,
  forgotPassword,
  changePassword,
  sendOTP,
  verifyOTP,
  verifyToken,
  accountDetail
}
