import React from 'react'
import { NotFoundBox } from './style'

const NotFoundComponent = () => {
  return (
    <NotFoundBox>
      <img src="/assets/images/notfound.png" alt="predictive" />
      <p className="-dark -medium -bold">No results found</p>
      <p className="-gray -small">
        Try adjusting your search or filter to find what you are looking for.
      </p>
      <br />
      <a href={'/dashboard'} className="btn-blue">
        Back to Home page
      </a>{' '}
    </NotFoundBox>
  )
}
export default NotFoundComponent
