import styled from 'styled-components'

const DashboardBox = styled.div`
  .main {
    padding: 40px 0px 10px ${props => (props.isShowMenu ? '0px' : '60px')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    color: #6a707e;
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    width: ${props => (props.isShowMenu ? '82%;' : '100%')};
    transition: width 0.5s;
  }
  .piechart {
    display: flex;
    width: 50%;
  }

  .section-main-landding {
    width: 860px;
    height: 490px;

    background-color: #fff;
    margin: ${props => (props.isShowMenu ? '60px 50px 50px 435px' : '60px 50px 50px 310px')};
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 1px 2px #888888;
    display: flex;
    img {
      width: 100%;
      margin-top: 10px;
    }

    .center-verticle {
      margin: 25% 30px;
      .title {
        margin: 0px;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.3;
      }
      p:first-child {
        color: #00252f;
      }
      .desc {
        color: rgba(106, 112, 126, 0.7);
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
`

export { DashboardBox }
