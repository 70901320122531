import React, { Component } from 'react'
import { AudienceTableBox } from './style'
import DataTable from 'react-data-table-component'
import { columns, data } from './mockData'
import FilterComponent from './FilterComponent'
import { isEmpty } from '../../helper/helper'
import { getCustomerProfile } from '../../apis/customer/api'

export default class AudienceTable extends Component {
  constructor(props) {
    super(props)
    this.state = { filterText: '', resetPaginationToggle: false }
    this.filteredItems = data.filter(
      item => item.title && item.title.toLowerCase().includes(this.state.filterText.toLowerCase())
    )
  }
  selectRow = perPage => {
    console.log(perPage)
  }

  setFilterText = data => {
    console.log('setFilterText : ', data)
  }

  handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state
    if (filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ''
      })
      this.filteredItems = data
    }
  }

  goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  componentDidMount() {
    var scrollComponent = this
    document.addEventListener('scroll', function (e) {
      scrollComponent.toggleVisibility()
    })
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true
      })
    } else {
      this.setState({
        is_visible: false
      })
    }
  }

  render() {
    const { historyList, getDetailFn } = this.props
    const { is_visible } = this.state
    return (
      <AudienceTableBox>
        <div className="box-data-table">
          {/* <div className="box-top">
            <div className="box-search">
              <img src="/assets/icons/icon-search.png" alt="predictive" />
              <input id="search" type="text" placeholder="Search name, id,  …" />
              <img></img>
            </div>
            <div className="box-filter">
              <p>Groups</p>
            </div>
          </div> */}
          {/* <img src="/assets/icons/icon-close.png" alt="predictive" /> */}
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Last Activity</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(historyList) &&
                historyList.map((item, i) => {
                  return (
                    <tr key={i} onClick={() => getDetailFn(item.customer_id)}>
                      <td>{item.customer_id}</td>
                      <td className={`${!isEmpty(item.email) ? '' : ''} `}>
                        {!isEmpty(item.email) ? item.email : '-'}
                      </td>
                      <td>{!isEmpty(item.firstname) ? item.firstname : '-'}</td>
                      <td>{!isEmpty(item.lastname) ? item.lastname : '-'}</td>
                      <td>
                        {!isEmpty(item.latest_event) ? item.latest_event.metadata.group_name : '-'}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          {is_visible && (
            <img id="gototop" onClick={() => this.goToTop()} src="/assets/icons/goTop.png" />
          )}
        </div>
      </AudienceTableBox>
    )
  }
}
