import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import LoginComponent from './components/login/login'
import ResetPasswordComponent from './components/login/resetPassword'
import NavComponent from './components/navigation/navigation'
import NotFoundComponent from './components/error/errorv2'
import './index1.css'
import 'bootstrap/dist/css/bootstrap.min.css'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <LoginComponent />
          </Route>
          <Route path="/reset">
            <ResetPasswordComponent />
          </Route>
          <Route path="/invite">
            <ResetPasswordComponent />
          </Route>
          <Route path="/dashboard">
            <NavComponent />
          </Route>
          <Route path="/imports">
            <NavComponent />
          </Route>
          <Route exact path="/imports/create">
            <NavComponent />
          </Route>
          <Route exact path="/imports/create?step=:step">
            <NavComponent />
          </Route>
          <Route exact path="/audience">
            <NavComponent />
          </Route>
          <Route exact path="/audience/:id">
            <NavComponent />
          </Route>
          <Route exact path="/segmentation">
            <NavComponent />
          </Route>
          <Route exact path="/segmentation/create">
            <NavComponent />
          </Route>
          <Route exact path="/segmentation/:id/edit">
            <NavComponent />
          </Route>
          <Route exact path="/campaign">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/sms">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/facebookads">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/create">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/create?step=typeSource">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/create?step=smsCampaign">
            <NavComponent />
          </Route>
          <Route exact path="/campaign/:option/:id">
            <NavComponent />
          </Route>
          <Route exact path="/rmf/create">
            <NavComponent />
          </Route>
          <Route exact path="/document">
            <NavComponent />
          </Route>
          <Route exact path="/setting/generals">
            <NavComponent />
          </Route>
          <Route exact path="/setting/generals/:id/edit">
            <NavComponent />
          </Route>
          <Route exact path="/setting/integrations">
            <NavComponent />
          </Route>
          <Route exact path="/setting/integrations/:id/edit">
            <NavComponent />
          </Route>
          <Route path="/">
            <NavComponent />
          </Route>
          <Route component={NotFoundComponent} />{' '}
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
