import React, { Component, Fragment } from 'react'
import { SegmentBox } from './style'
import Modal from 'react-modal'
import OptionDropdownComponent from '../dropdown/optionDropdown'

import { isEmpty } from '../../helper/helper'
import DataTable from 'react-data-table-component'
import { getSegmentList, segmentDetail, segmentDelete } from '../../apis/segmentation/api'
import { verifyToken } from '../../apis/login/api'
import { withRouter } from 'react-router'
class SegmentationComponent extends Component {
  state = {
    segmentList: [],
    isFrom: false,
    conditionList: [],
    openModel: false,
    delPopupIsOpen: false,
    idUpdate: '',
    idDel: '',
    outerSegment: false,
    isLoaddingList: true,
    isLoaddingUpdate: false,
    when: false,
    role: ''
  }

  convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })

    let res = day + '/' + month + '/' + year + ', ' + time

    return res
  }

  segmentOption = async (id, option) => {
    if (option === 'update') {
      let res = await segmentDetail(id)
      if (!isEmpty(res) && res.status === 200) {
        this.setState({ conditionList: res.data.config, isFrom: true, idUpdate: id })
      }
    } else if (option === 'delete') {
      this.setState({ delPopupIsOpen: true, idDel: id })
    }
  }
  deleteSegment = async id => {
    let res = await segmentDelete(id)
    if (!isEmpty(res) && res.status === 200) {
      let segmentList = await this.getSegmentList()
      this.setState({ delPopupIsOpen: false, segmentList })
    }
  }

  getSegmentList = async () => {
    const { role } = this.state
    let res = {}
    let data = []
    let list = await getSegmentList()
    if (!isEmpty(list) && list.status === 200) {
      let _tempData = {}
      if (role === 'viewer') {
        for (let i = 0; i < list.data.length; i++) {
          let createName = !isEmpty(list.data[i].created_by)
            ? list.data[i].created_by.firstname + ' ' + list.data[i].created_by.lastname
            : '-'
          _tempData = {
            segment_name: list.data[i].segment_name,
            created_by: createName,
            customers: list.data[i].customers,
            created_at: this.convertDate(list.data[i].created_at),
            last_modified: this.convertDate(list.data[i].updated_at),
            id: list.data[i].id
          }
          data.push(_tempData)
        }
      } else {
        for (let i = 0; i < list.data.length; i++) {
          let createName = !isEmpty(list.data[i].created_by)
            ? list.data[i].created_by.firstname + ' ' + list.data[i].created_by.lastname
            : '-'
          _tempData = {
            segment_name: list.data[i].segment_name,
            created_by: createName,
            customers: list.data[i].customers,
            created_at: this.convertDate(list.data[i].created_at),
            last_modified: this.convertDate(list.data[i].updated_at),
            id: list.data[i].id,
            options: (
              <OptionDropdownComponent segmentOptionFn={this.segmentOption} id={list.data[i].id} />
            )
          }
          data.push(_tempData)
        }
      }

      res = {
        data: data
      }
    }
    return res
  }

  closeModal = () => {
    this.setState({ delPopupIsOpen: false })
  }

  setRole = async () => {
    let role = await verifyToken()
    this.setState({ role: role.role })
  }

  componentDidMount = async () => {
    await this.setRole()
    let res = await this.getSegmentList()
    this.setState({ segmentList: res })
    setTimeout(() => {
      this.setState({ isLoaddingList: false })
    }, 1500)
  }

  displaySegmentList = async () => {
    let res = await this.getSegmentList()
    this.setState({ isFrom: false, segmentList: res, isLoaddingList: true })
    setTimeout(() => {
      this.setState({ isLoaddingList: false })
    }, 1500)
  }

  // rowUpdate = async e => {
  //   let res = await getSegmentDetail(e.id)
  //   if (!isEmpty(res) && res.status === 200) {
  //     this.setState({ conditionList: res.data.config, isFrom: true, idUpdate: e.id })
  //   }
  // }

  UNSAFE_componentWillReceiveProps = newProps => {
    if (newProps.isShowMenu !== this.props.isShowMenu) {
      return
    }

    if (newProps.outerSegment) {
      this.setState({ isFrom: true, outerSegment: true })
    } else {
      this.setState({ isFrom: false })
    }
  }

  setIsFrom = () => {
    this.setState({ isFrom: false })
  }

  checkBack = () => {
    let su = localStorage.getItem('su') //su = segment update
    if (isEmpty(su)) {
      window.location = '/segmentation'
    } else {
      this.setState({ outerSegment: true })
      localStorage.setItem('p', '/segmentation')
    }
  }

  render() {
    const { isShowMenu, outerPath, history } = this.props
    const {
      outerSegment,
      segmentList,
      conditionList,
      isLoaddingUpdate,
      idUpdate,
      delPopupIsOpen,
      idDel,
      role
    } = this.state
    let { isFrom, isLoaddingList } = this.state
    const columns = [
      {
        name: 'Segment Name',
        selector: row => row.segment_name,
        sortable: true,
        width: '250px'
      },
      {
        name: 'Created by',
        selector: row => row.created_by,
        width: '180px'
      },
      {
        name: 'Customers',
        selector: row => row.customers
      },
      {
        name: 'Created at',
        selector: row => row.created_at
      },
      {
        name: 'Last Updated',
        selector: row => row.last_modified
      },
      {
        name: '',
        selector: row => row.options,
        width: '70px'
      }
    ]
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
        transition: 'width 0.5s',
        width: '470px',
        height: 'auto',
        border: '2px solid #0698BE',
        borderRadius: '10px'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
    // if (outerSegment) {
    //   isFrom = true
    // }

    return (
      <SegmentBox isShowMenu={isShowMenu}>
        <div className="main">
          <div className="py-4 px-4">
            <div className="main-loadding">
              {isLoaddingUpdate ? (
                <div className="overlay-loadding">
                  <img className="icon-loadding" src="/assets/images/flipball.gif" />
                </div>
              ) : (
                <Fragment />
              )}

              {/* <div className="row py-3 px-5">
              <div className="col-6 text-left">
                <p className="navigation-bar">
                  <span> Segmentation</span>
                </p>
              </div>
              <div className="col-6 text-right">
                {role !== 'viewer' && !isFrom && (
                  <a
                    className="btn-blue btn-150 font-weight-lighter"
                    onClick={() => history.push('/segmentation/create')}
                  >
                    <img src="/assets/icons/create-white-2.png" />
                    {`Create New`}
                  </a>
                )}
              </div>
            </div> */}
              <div className="row">
                <div className="col-6 mb-0">
                  <p className="top-head-title">
                    <span>Segmentation</span>{' '}
                  </p>
                </div>
                <div className="col-6 mb-0 text-right">
                  {role !== 'viewer' && !isFrom ? (
                    <a
                      className="btn-basic bg-darkgreen font-white"
                      onClick={() => history.push('/segmentation/create')}
                    >
                      <img src="/assets/icons/create-white-2.png" className="w-px-14 mx-1" />
                      Create New
                    </a>
                  ) : (
                    <Fragment />
                  )}
                </div>
              </div>

              {isLoaddingList ? (
                <div className="ball-loadding">
                  <img src="/assets/images/flipball.gif" />
                </div>
              ) : !isFrom && !isEmpty(segmentList) ? (
                <DataTable
                  pagination
                  className="fadeIn"
                  center={true}
                  columns={columns}
                  data={segmentList.data}
                />
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </div>
        <Modal
          closeTimeoutMS={500}
          ariaHideApp={false}
          isOpen={delPopupIsOpen}
          onRequestClose={() => this.closeModal()}
          style={customStyles}
        >
          <div className="body-popup">
            <b className="title-popup">Delete segment ?</b>
            <p className="desc-popup">
              Do you really want to delete this segment ? <br /> You can’t undo this action.
            </p>
            <div className="footer-popup">
              <a onClick={() => this.closeModal()} className="btn-basic bg-gray font-white mx-1">
                Cancel
              </a>
              <a
                onClick={() => this.deleteSegment(idDel)}
                className=" btn-basic  bg-darkpink font-white"
              >
                Yes, delete
              </a>
            </div>
          </div>
        </Modal>
      </SegmentBox>
    )
  }
}

export default withRouter(SegmentationComponent)
