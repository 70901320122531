import { PureComponent } from 'react'
import { HomeBox } from './style'

export default class HomeComponent extends PureComponent {
  state = {
    step: 'start'
  }

  nextStep = step => {
    this.setState({ step: step })
  }
  componentDidMount = () => {
    localStorage.removeItem('su')
  }
  render() {
    const { isShowMenu } = this.props

    return (
      <HomeBox isShowMenu={isShowMenu}>
        <div className="section-main-landding">
          <div className="col-6">
            <img src="/assets/images/landding3.png" alt="predictive" />
          </div>
          <div className="col-6">
            <div className="center-verticle">
              <p className="title">Welcome to</p>
              <p className="title">CDP</p>
              <p className="desc">
                Collect data from all <br /> possible sources and <br />
                build a unified Single <br />
                Customer View
              </p>
              <a className="btn-blue" onClick={() => this.nextStep('/dashboard')}>
                {`+ GET STARTED`}
              </a>
            </div>
          </div>
        </div>
      </HomeBox>
    )
  }
}
