import styled from 'styled-components'

const CampaignBox = styled.div`
  .main {
    padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
    color: #6a707e;
    width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    transition: margin 700ms;
  }
`
const LoaddingBox = styled.div`
  .main-loadding {
    width: 100%;
    height: 100%;
  }
  .icon-loadding {
    z-index: 9;
    width: 80px;
  }
  .overlay-loadding {
    width: 100%;
    display: table;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
    opacity: 1;
    padding: 10%;
    position: relative;
    text-align: center;
    height: 100%;
  }
`
const TableBox = styled.div`
  .overflow-auto {
    overflow: auto;
  }
  .border-radius-0 {
    border-radius: 0;
  }
  .paused {
    background-color: #f0e3ff;
    color: #7d0dff;
  }
  .upcoming {
    background-color: #fff5da;
    color: #f0b61d;
  }
  .stopped {
    background-color: #ffd4e0;
    color: #ff2c65;
  }
  .ended {
    background-color: #eef1f5;
    color: #aabaca;
  }
  .draft {
    background-color: #dcf3f8;
    color: #67cde0;
  }

  .running {
    background-color: #d4f5eb;
    color: #28cc97;
  }

  .htkVSe {
    position: relative;
    width: 100%;
    border-radius: 0;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
    
  }
  .rdt_TableHeader {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 22px;
    color: rgba(0,0,0,0.87);
    background-color: #FFFFFF;
    min-height: 0px;
    padding-left: 16px;
    padding-right: 8px;
}
  .mlKPF {
    position: relative;
    width: 100%;
    border-radius: 0;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
    height: 555px;
}
  .rdt_TableHeadRow {
    background-color: #edf1f4;
    border: 0;
    min-height: 50px;
  }
  .rdt_TableCol_Sortable {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #334d6e;
  }
  .rdt_TableRow:nth-child(even) {
    background-color: #fafcfe;
  }
  .rdt_TableRow {
    background: #fff;
    height: 40px;
    border: 0px;
    vertical-align: middle;
  }
  .rdt_TableRow:hover {
    background: #efeff7 !important;
  }

  .rdt_TableCell {
    padding: 5px 0px 5px 16px;
    text-align: left;
    color: #6f809e;
    font-size: 12px;
  }

  .rdt_Table {
    background-color: unset;
    box-shadow: 12px -4px 6px 4px #f2f2f4;
    border-radius: 8px;
    // margin-top: 20px;
    .rdt_TableRow {
      border: unset;
      cursor: default;
    }
  }

  // .iXwQUM{
  //   display: contents;
  // }
  .rdt_TableCell:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 250px;
  }
  .scroll-x {
    overflow-x: auto;
    border-radius: 0em;
    // box-shadow: 4px -2px 6px 4px #f2f2f4;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    color: #334d6e;
    font-family: Inter;
    font-size: 13px;

    thead {
      tr {
        background-color: #f1f5f9;
      }
    }
    tr {
      height: 60px;
      background-color: #fff;
    }
    th {
      padding: 10px 10px;
      background-color: #edf1f4;
      border-bottom: 1px solid #dfe0eb;
      text-align: left;
      font-weight: normal;
      font-size: 14px;
      vertical-align: middle;
      border: 0px;
    }
    td {
      padding: 10px;
      border: unset;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
      vertical-align: middle;
    }
    tr:nth-child(even) {
      background: #fafcfe;
    }
    tbody {
      tr:hover {
        background: #efeff7 !important;
      }
    }
  }
  .w-5 {
    width: 5%;
  }
  .icon-option {
    margin-top: 11px;
    cursor: pointer;
    width: 25px;
    height: 7px;
    background: url(/assets/icons/option.png) no-repeat;
    display: inline-block;
    background-size: 17px 5px;
  }
  .icon-option:hover {
    background: url(/assets/icons/option-active.png) no-repeat;
    background-size: 17px 5px;
  }

  .box-dropdown {
    position: absolute;
    z-index: 999;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 17%);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin-left: -150px;
    margin-top: -25px;
    padding: 10px;
    width: auto;
    height: auto;
    display: block;
}
`
const CampaignListBox = styled.div`
  .main {
    padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
    color: #6a707e;
    width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    transition: margin 700ms;
  }
  .btn-blue {
    margin-left: auto;
    width: 160px;
    height: 50px;
    padding: 12.5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    line-height: 1.8;
    img {
      width: 16px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }

  .box {
    line-height: 30px;
    border-radius: 100%;
    text-align: center;
  }

  .-status {
    display: inline-block;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    width: 100%;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .span-next {
    font-size: x-small;
    color: #aabaca;
  }
`

const SelectSourceBox = styled.div`
.title-type {
  color: #334d6e;
  font-size: 20px;
  font-weight: 600;
}
.-disable {
  cursor: no-drop !important;
    background: rgba(170, 170, 170, 0.333) !important;
}
.sub-title{
  font-size: 16px;
  color: #385172;
  font-weight: normal;
  span{
    font-weight: 600;
    color: #24b0c3; 
  }
}
.desc-type {
  font-size: 14px;
  color: #6a707e;
  margin: auto 15px;
}
  .navigation-bar {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #1e334e;
    span {
      color: #9daeb9;
    }
  }
  .breadcrumb-box {
    color: #334d6e;
    margin-left: 24px;
    cursor: pointer;
  }
  .section-main {
    width: 100%;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 0px;
    padding: 40px 56px;
    text-align: center;
    border: 2px solid #efeff7;
    border-left: 0;
    border-right: 0;
    
  }
  .title {
    font-family: 'Inter';
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .section-body {
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .list-type-box {
    cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    width: 300px;
    height: 300px;
    margin: 20px;
    border: 1px solid #aabaca;
    padding: 2px 20px;
  }
  .section-tooltip {
    text-align: right;

    .icon-tooltip {
      width: 12px;
      margin-top: -2px;
      margin-right: 8px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .list-type-box {
    .list-icon {
      height: 90px;
    }
    img {
      margin-top: 20px;
      width: 60px;
    }
  }
  .list-type-box:hover , .list-type-box-active{
    border: 3px solid #39BCDE;
    padding: 0px 18px;
}
  .tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 400px;
      background-color: #00252f;
      color: #fff;
      border-radius: 0px;
      padding: 5px 0;
      position: absolute;
      opacity: 1;
      z-index: 1;
      bottom: 24px;
      margin-left: -132px;
      padding: 15px;
      font-family: inter;
    }
    .tooltiptext::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 55px;
      border-width: 8px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
.section-select-source {
  display: flex;
  margin: 20px 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.box-select-source:hover  {
  padding: 19px;
  border: solid 2px #24b0c3;
}
.box-select-source-active{
  border: solid 2px #24b0c3 !important;
  padding: 19px !important;

}
.box-select-source {
  cursor: pointer;
  width: 320px;
  height: 110px;
  margin: 0 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #aabaca;
  background-color: #fff;
  display: flex;
  .box-image-source {
    cursor: pointer;
    img {
      width: 43px;
      margin-top: 14px;
      margin-left: 4px;
    }
  }
  .box-name-source {
    padding: 5px 20px;
    text-align: left;
    cursor: pointer;
    p {
      font-size: 16px;
      color: #334d6e;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 5px;
    }
    label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #6f809e;
    cursor: pointer;
    }
  }
}
`
const ProgressbarBox = styled.div`
  .section-progress {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
    li {
      padding: unset;
    }
    .progressbar {
      padding: unset;
    }
    .progressbar li {
      list-style-type: none;
      width: 205px;
      float: left;
      font-size: 14px;
      position: relative;
      text-align: center;
      color: #c2cfe0;
      display: flex;
      span {
        padding: 0px 0px 0px 10px;
        line-height: 1.2;
        font-weight: 500;
        font-size: 16px;
        margin-top: 0px;
      }
    }
    .progressbar li:before {
      background-image: url('/assets/icons/unselect.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 20px;
      height: 20px;
      content: '';
      color: transparent;
      line-height: 35px;
      display: inline-table;
      margin: 0px;
      border-radius: 50%;
      background-color: #c2cfe0;
    }
    .progressbar li:after {
      width: 50px;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #c2cfe0;
      top: 10px;
      left: -60px;
    }
    .progressbar li:first-child:after {
      content: none;
    }
    .progressbar li.active {
      color: #334d6e;
    }

    .progressbar li.step-pass {
      color: #334d6e;
    }
    .progressbar li.active:before {
      background-color: transparent;
      background-image: url(/assets/icons/active_trans.png);
      z-index: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: inline-table;
    }
    .progressbar li.active + li:after {
      background-color: rgba(194, 207, 224, 0.58);
    }
  }

  .progressbar li.step-pass:before {
    background-color: #20adc2;
    background-image: url(/assets/icons/check.png);
    background-size: 12px 12px;
    z-index: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #20adc2;
    color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;

    .progressbar li.step-pass + li:after {
      background-color: #24b0c3;
    }
  }
`

const FooterBox = styled.div`
  .section-footer {
    padding: 20px 20px 35px 20px;
    display: flex;
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .btn {
      width: 120px;
      height: 44px;
      margin: 0 0 0 10px;
      border-radius: 8px;
      border: solid 1.5px #24b0c3;
      color: #24b0c3;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .-active-btn {
      background: #aabaca !important;
      color: #fff !important;
      cursor: no-drop !important;
      border: 1px solid #aabaca !important;
    }
    .btn-blue {
      text-align: center;
      display: inline-block;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
      background-color: #24b0c3;
    }
  }
`

export {
  CampaignBox,
  CampaignListBox,
  SelectSourceBox,
  TableBox,
  ProgressbarBox,
  FooterBox,
  LoaddingBox
}
