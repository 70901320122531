import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_UPLOAD_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const uploadRawData = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/customer-${params.wrapDataType}`
    const response = await axios.post(
      URL,
      {
        filename: params.filename,
        group_name: params.group_name,
        data: params.data
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const validateRawData = async params => {
  let res = {}
  try {
    let customParams =
      params.wrapDataType === 'profile'
        ? 'customer-validate'
        : params.wrapDataType === 'event'
        ? 'customer-event-validate'
        : ''
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/${customParams}`
    const response = await axios.post(
      URL,
      {
        ref_id: params.ref_id,
        rules: params.rules
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getServiceAccount = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/google-cloud-platform/get-service-account`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const connectBucketGoogle = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/google-cloud-storage/auth-bucket`
    const response = await axios.post(
      URL,
      {
        bucket_name: params.bucket_name,
        path_folder: params.path_folder
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const previewDataGoogleStorage = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/google-cloud-storage/preview-data`
    const response = await axios.post(
      URL,
      {
        bucket_name: params.bucket_name,
        file_lists: params.file_lists
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.message
      }
    }
  }
  return res
}

export {
  uploadRawData,
  validateRawData,
  getServiceAccount,
  connectBucketGoogle,
  previewDataGoogleStorage
}
