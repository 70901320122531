import React, { Fragment, PureComponent } from 'react'
import { AudienceBox } from './style'
import NotFoundComponent from '../error/notFound'
import { verifyToken } from '../../apis/login/api'
import { isEmpty } from '../../helper/helper'
import { Collapse } from 'react-collapse'
import { withRouter } from 'react-router'

import { getCustomerProfile, getEventList } from '../../apis/customer/api'

class AudienceDetailComponent extends PureComponent {
  state = {
    historyList: [],
    activityList: [],
    isOpened: true,
    isLoading: true
  }

  onChange = () => {
    const { isOpened } = this.state
    this.setState({ isOpened: !isOpened })
  }

  settingCollapse = () => {
    const { activityList } = this.state
    let res = []
    for (let i = 0; i < activityList.length; i++) {
      let _temp = {
        no: i,
        open: false
      }
      res.push(_temp)
    }
    this.setState({ settings: res })
  }

  getEvent = async customer_id => {
    let res = {}
    try {
      let obj = {
        cutomer_id: customer_id,
        pageSize: 25
      }
      res = await getEventList(obj)
      if (res.status === 200) {
        this.setState({ activityList: res.data })
      }
    } catch (error) {
      console.log('error : ', error)
    }
  }

  getDetail = async customer_id => {
    let res = {}
    try {
      let obj = {
        cutomer_id: customer_id
      }
      res = await getCustomerProfile(obj)
      if (res.status === 200) {
        res = res.data
        await this.getEvent(customer_id)
        await this.settingCollapse()
      } else {
        res = {}
      }
    } catch (error) {
      console.log('error : ', error)
    }
    return res
  }

  convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })

    let res = day + ' ' + month + ' ' + year + ' ' + time

    return res
  }

  handleClick = index => {
    const { settings, activityList } = this.state
    let res = []
    for (let i = 0; i < activityList.length; i++) {
      let _temp = {
        no: i,
        open: !isEmpty(settings)
          ? settings[i].no === index
            ? !settings[i].open
            : settings[i].open
          : false
      }
      res.push(_temp)
    }
    this.setState({ settings: res })
  }
  setRole = async () => {
    const { history } = this.props
    let role = await verifyToken()
    if (role.role === 'viewer') {
      history.push('/audience')
    }
  }
  componentDidMount = async () => {
    const { match } = this.props
    await this.setRole()
    if (!isEmpty(match) && !isEmpty(match.params.id)) {
      let id = match.params.id
      let res = await this.getDetail(id)
      if (!isEmpty(res)) {
        this.setState({ customerDetail: res })
        setTimeout(() => {
          this.setState({ isLoading: false })
        }, 1500)
      } else {
        setTimeout(() => {
          this.setState({ isLoading: false })
        }, 1500)
      }
    }
  }

  render() {
    const { customerDetail, isOpened, activityList, settings, isLoading } = this.state
    const { isShowMenu, history } = this.props
    const firstName = !isEmpty(customerDetail)
      ? customerDetail.filter(l => l.name.toLowerCase().substring(0, 5) === 'first')
      : ''
    const lastName = !isEmpty(customerDetail)
      ? customerDetail.filter(l => l.name.toLowerCase().substring(0, 4) === 'last')
      : ''
    return (
      <AudienceBox isShowMenu={isShowMenu}>
        <div className="main-loadding">
          <div className="main-table">
            <p className="title">
              <a className="font-b" onClick={() => history.push('/audience')}>
                Audience
              </a>{' '}
              <span className="sub-menu">/ Detail</span>
            </p>
          </div>
          {isLoading ? (
            <div className="overlay-loadding">
              <img className="icon-loadding" src="/assets/images/flipball.gif" />
            </div>
          ) : !isEmpty(customerDetail) ? (
            <section className="main-table d-flex -fadeIn">
              <div className="col-5">
                <div className="box-task">
                  <div className="-center">
                    <div className="Ellipse-25">
                      <span>
                        {!isEmpty(firstName) ? firstName[0].data.charAt(0) : ''}
                        {!isEmpty(lastName) ? lastName[0].data.charAt(0) : ''}
                      </span>
                    </div>
                    <b className="font-dark">
                      {!isEmpty(firstName) ? firstName[0].data : ''}{' '}
                      {!isEmpty(lastName) ? lastName[0].data : ''}
                    </b>
                    <br />
                  </div>
                </div>
                <div className="box-task">
                  <p className="title-col font-dark" onClick={() => this.onChange()}>
                    Customer Attribute
                    <img
                      className="icon-arrow"
                      src={`/assets/icons/${isOpened ? 'up' : 'down'}-arrow.png`}
                    />
                  </p>

                  <Collapse isOpened={isOpened}>
                    {!isEmpty(customerDetail) &&
                      customerDetail.map((item, i) => {
                        return (
                          <p key={i}>
                            {item.name} :{' '}
                            <span>
                              {new Date(item.data).getTime() > 0 && item.data.length > 20
                                ? this.convertDate(item.data)
                                : item.name === 'birthday'
                                ? this.convertDate(item.data)
                                : item.data}
                            </span>
                          </p>
                        )
                      })}
                  </Collapse>
                </div>
              </div>
              <div className={`col-7`}>
                <div className="row">
                  <div className="col-12">
                    <div className="box-task box-activity">
                      <p className="font-dark title-col">Activities</p>
                      {!isEmpty(activityList) ? (
                        activityList.map((item, i) => {
                          return (
                            <Fragment key={i}>
                              <div className="row row-title">
                                <div className="col-5">
                                  <img src="/assets/icons/clock.png" />{' '}
                                  <span className="-title"> {item.metadata.group_name}</span>
                                </div>
                                <div className="col-6">
                                  <span className="-time">{this.convertDate(item.timestamp)}</span>
                                </div>
                                <div className="col-1">
                                  <span className="-icon" onClick={() => this.handleClick(i)}>
                                    {!isEmpty(settings) && settings[i].open ? '-' : '+'}
                                  </span>
                                </div>
                              </div>

                              <Collapse
                                key={i}
                                isOpened={
                                  !isEmpty(settings) && settings[i].no === i
                                    ? settings[i].open
                                    : false
                                }
                                timeout="auto"
                                unmountOnExit={true}
                              >
                                {Object.keys(item)
                                  .filter(l => l !== 'metadata' && l !== 'timestamp')
                                  .map((items, i) => {
                                    return (
                                      <div className="row" key={i}>
                                        <div className="col-5">
                                          <span className="-title">{items}</span>
                                        </div>
                                        <div className="col-6">
                                          <span className="-time">
                                            {items === 'timestamp' ||
                                            items === 'updated_at' ||
                                            items === 'time' ||
                                            items === 'birthday'
                                              ? this.convertDate(item[items])
                                              : item[items]}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  })}
                              </Collapse>
                            </Fragment>
                          )
                        })
                      ) : (
                        <Fragment />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            // <Fragment />
            <section className="main-table -center">
              <NotFoundComponent errorType={'404'} callback={'/audience'} />
            </section>
          )}
        </div>
      </AudienceBox>
    )
  }
}
export default withRouter(AudienceDetailComponent)
