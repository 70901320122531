import React, { Fragment, useState, useEffect, useRef } from 'react'
import { ConnectBigqueryBox, DropdownBox } from './style'
import { useHistory } from 'react-router-dom'
import DataMappingComponent from '../dataMapping/mapping'

import {
  connectBigquery,
  tableListBigQuery,
  sampleDataBigQuery
} from '../../../apis/filestatus/api'
import 'antd/dist/antd.css'
import { DatePicker } from 'antd'

const ConnectBigqueryComponent = props => {
  const ref = useRef()
  const [isConnect, setStep] = useState(true)
  const [fileName, setFileName] = useState('')

  const [projectId, setProjectId] = useState('')
  const [isTableLoading, setIsTableLoading] = useState(false)

  const [connectPass, setConnectPass] = useState(false)

  const [objSchedule, setObjSchedule] = useState()

  const [schedule, setSchedule] = useState()
  const [timeSchedule, setTimeSchedule] = useState()
  const [repeatTime, setRepeatTime] = useState()
  const [incremental, setIncremental] = useState()
  const [day, setDay] = useState()
  const [date, setDate] = useState()

  const [dataSetId, setDataSetId] = useState()
  const [tableId, setTableId] = useState()
  const [dataSet, setDataSet] = useState(null)
  const [tableList, setTableList] = useState()

  const [openDropdownDataSet, setOpenDropdownDataSet] = useState(false)
  const [openDropdownTable, setOpenDropdownTable] = useState(false)
  const [openDropdownTimeSet, setOpenDropdownTimeSet] = useState(false)
  const [openDropdownRepeatTimeSet, setOpenDropdownRepeatTimeSet] = useState(false)
  const [openDropdownIncrementalSet, setOpenDropdownIncrementalSet] = useState(false)
  const [openDropdownDaySet, setOpenDropdownDaySet] = useState(false)
  const [openDropdownDateSet, setOpenDropdownDateSet] = useState(false)

  const [handlingNotfound, setHandlingNotfound] = useState(false)
  const [handlingDefault, setHandlingDefault] = useState(true)

  const [objTask, setObjTask] = useState()

  const [errorMsg, setErrorMsg] = useState()

  const [column, setColumn] = useState()
  const [rows, setRow] = useState()

  const history = useHistory()

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      // callback()
    } else {
      handleCloseAll()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
  useEffect(async () => {
    if (dataSetId) {
      setOpenDropdownDataSet(false)
      setTableId('')
      setColumn('')
      setRow('')
      await getListBigQuery()
    }
  }, [dataSetId])

  useEffect(async () => {
    if (tableId) {
      setOpenDropdownTable(false)
      await getSampleData()
    }
  }, [tableId])

  const setSyncSchedule = () => {
    let obj = {}
    let now = new Date()
    now.setHours(timeSchedule.index, 0, 0)
    let utc_hours = now.getUTCHours()
    if (utc_hours < 10) {
      utc_hours = '0' + utc_hours + ':00'
    } else {
      utc_hours = utc_hours + ':00'
    }
    if (repeatTime === 'Only once') {
      let startDate = schedule._d
      startDate.setHours(timeSchedule.index, 0, 0)
      obj = {
        type: repeatTime.toLowerCase(),
        schedule: startDate
      }
    } else if (repeatTime === 'Daily') {
      obj = {
        column_timestamp: incremental,
        type: repeatTime.toLowerCase(),
        time: utc_hours
      }
    } else if (repeatTime === 'Weekly') {
      obj = {
        column_timestamp: incremental,
        type: repeatTime.toLowerCase(),
        time: utc_hours,
        day_of_type_schedule: day.index + 1
      }
    } else {
      obj = {
        column_timestamp: incremental,
        type: repeatTime.toLowerCase(),
        time: utc_hours,
        day_of_type_schedule: date
      }
    }
    setObjSchedule(obj)
  }

  const setStepFn = () => {
    setSyncSchedule()
    props.setStepMapFn()
    setStep(false)
  }

  const connect = async () => {
    if (!fileName) {
      setConnectPass(false)
      setErrorMsg('Please enter file name.')
    } else if (!projectId) {
      setConnectPass(false)
      setErrorMsg('Please enter project name.')
    } else {
      const { data, status } = await connectBigquery(projectId)
      setConnectPass(true)
      if (data && status === 200) {
        setDataSet(data.data)
      } else {
        setErrorMsg(data.error)
        setConnectPass(false)
      }
    }
  }

  const getListBigQuery = async () => {
    let obj = {
      project_id: projectId,
      dataset_id: dataSetId
    }
    const { data, status } = await tableListBigQuery(obj)
    if (data && status === 200) {
      setTableList(data.data)
      setHandlingNotfound(false)
      setHandlingDefault(true)
    } else {
      setHandlingNotfound(true)
      setHandlingDefault(false)
      console.log('getListBigQuery Error')
    }
  }

  const getSampleData = async () => {
    setIsTableLoading(true)
    let obj = {
      filename: fileName,
      project_id: projectId,
      dataset_id: dataSetId,
      table_id: tableId
    }
    setObjTask(obj)
    const { data, status } = await sampleDataBigQuery(obj)
    if (data && status === 200) {
      setColumn(data.data.metadata.table_schema.fields)
      setRow(data.data.row)
      setTimeout(() => {
        setIsTableLoading(false)
      }, 3000)
    } else {
      setTimeout(() => {
        setIsTableLoading(false)
      }, 3000)
      setHandlingNotfound(true)
      setHandlingDefault(false)
      console.log('getListBigQuery Error')
    }
    if (incrementals.length === 0) {
      setRepeatTime('Only once')
    } else {
      setRepeatTime(repeatTime)
    }
  }

  const handleCloseAll = () => {
    setOpenDropdownDataSet(false)
    setOpenDropdownTable(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const setValueRepeatTime = time => {
    setRepeatTime(time)
    // setValidateRepeatTime(true)
    handleCloseAll()
  }

  const setValueTime = (time, index) => {
    let obj = {
      time,
      index
    }
    setTimeSchedule(obj)
    // setValidateTimeSchedule(true)
    handleCloseAll()
  }

  const setValueIncremental = Incremental => {
    setIncremental(Incremental)
    // setValidateIncremental(true)
    handleCloseAll()
  }
  const setValueDay = (day, index) => {
    let obj = {
      day,
      index
    }
    // setValidateDay(true)
    setDay(obj)
    handleCloseAll()
  }
  const setValueDate = date => {
    // setValidateValueDate(true)
    setDate(date)
    handleCloseAll()
  }
  let numberTime = []

  const repeatTimes = ['Only once', 'Daily', 'Weekly', 'Monthly']
  const incrementals = column ? column.filter(l => l.type === 'TIMESTAMP') : [] //[' column name #1', ' column name #2']

  const days = [' Sunday', ' Monday', ' Tuesday', ' Wednesday', ' Thursday', ' Friday', ' Saturday']
  let dates = []
  for (let x = 1; x < 32; x++) {
    dates.push(x)
  }
  for (let t = 0; t < 24; t++) {
    numberTime.push((t < 10 ? '0' + t : t) + ':00')
  }
  const UseSetOpenDropdownDataSet = open => {
    setOpenDropdownDataSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownTable = open => {
    setOpenDropdownTable(open)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownRepeatTimeSet = open => {
    setOpenDropdownRepeatTimeSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownDaySet = open => {
    setOpenDropdownDaySet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownTimeSet = open => {
    setOpenDropdownTimeSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownIncrementalSet = open => {
    setOpenDropdownIncrementalSet(open)
    setOpenDropdownTimeSet(false)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownDateSet = open => {
    setOpenDropdownDateSet(open)
    setOpenDropdownIncrementalSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const TableSample = () => {
    return (
      <Fragment>
        {column ? (
          <div className="text-center">
            <div className="scroll-x">
              <table>
                <thead>
                  <tr>
                    {column.map((item, i) => {
                      return <th key={i}>{item.name}</th>
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((row, i) => {
                      return (
                        <tr key={i}>
                          {Object.keys(row).map((list, x) => {
                            return row[list] && typeof row[list] !== 'object' ? (
                              <td key={x}>{row[list]}</td>
                            ) : (
                              <td key={x}>-</td>
                            )
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Fragment>
            {handlingDefault && <HandlingDefault />}
            {handlingNotfound && <HandlingNotfound />}
          </Fragment>
        )}
      </Fragment>
    )
  }

  const Loading = () => {
    return (
      <div className="row">
        <div className="text-center main-loadding">
          <div className="overlay-loadding">
            <img className="icon-loadding" src="/assets/images/flipball.gif" />
          </div>

          <img className="w-40 p-3 mx-2" src={`/assets/images/SampleData.png`} alt="logo" />
          <p className="title-type">
            Please select your dataset and table to preview the sample data.
          </p>
        </div>
      </div>
    )
  }

  const HandlingNotfound = () => {
    return (
      <div className="text-center">
        <img className="w-40 p-3 mx-2" src={`/assets/images/notfound.svg`} alt="logo" />
        <p className="handling w-40 mx-auto">
          Quota exceeded: Your project exceeded quota for free query bytes scanned. For more
          information, see
          <span> https://cloud.google.com/bigquery/docs/troubleshoot-quotas</span>
        </p>
      </div>
    )
  }

  const HandlingDefault = () => {
    return (
      <div className="text-center">
        <img className="w-40 p-3 mx-2" src={`/assets/images/SampleData.png`} alt="logo" />
        <p className="handling w-40 mx-auto">
          Please select your dataset and table to preview the sample data.
        </p>
      </div>
    )
  }

  const DropdownRepeatTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownRepeatTimeSet(!openDropdownRepeatTimeSet)}
        >
          <p className="text-dropdown">
            {repeatTime ? (
              repeatTime
            ) : (
              <span className="head-sub-title m-0 p-0">Select repeat time</span>
            )}
          </p>
          <i className={`arrow-${openDropdownRepeatTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownRepeatTimeSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {repeatTimes.map((listrepeatTime, i) => {
                  return (
                    <li key={i} onClick={() => setValueRepeatTime(listrepeatTime)}>
                      {listrepeatTime}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDateSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownDateSet(!openDropdownDateSet)}
        >
          <p className="text-dropdown">
            {date ? date : <span className="head-sub-title m-0 p-0">Select date</span>}
          </p>
          <i className={`arrow-${openDropdownDateSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDateSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {dates.map((listdates, i) => {
                  return (
                    <li key={i} onClick={() => setValueDate(listdates)}>
                      {listdates}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDaySet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownDaySet(!openDropdownDaySet)}
        >
          <p className="text-dropdown">
            {day ? day.day : <span className="head-sub-title m-0 p-0">Select day of week</span>}
          </p>
          <i className={`arrow-${openDropdownDaySet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDaySet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {days.map((listdays, i) => {
                  return (
                    <li key={i} onClick={() => setValueDay(listdays, i)}>
                      {listdays}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field time-box`}
          onClick={() => UseSetOpenDropdownTimeSet(!openDropdownTimeSet)}
        >
          <p className="text-dropdown">
            {timeSchedule ? (
              timeSchedule.time
            ) : (
              <span className="head-sub-title m-0 p-0">00:00</span>
            )}
          </p>
          <i className={`arrow-${openDropdownTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownTimeSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-small">
              <ul>
                {numberTime.map((listTime, i) => {
                  return (
                    <li key={i} onClick={() => setValueTime(listTime, i)}>
                      {listTime}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownIncrementalSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownIncrementalSet(!openDropdownIncrementalSet)}
        >
          <p className="text-dropdown">
            {incremental ? (
              incremental
            ) : (
              <span className="head-sub-title m-0 p-0">Select column name</span>
            )}
          </p>
          <i className={`arrow-${openDropdownIncrementalSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownIncrementalSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {incrementals.map((listIncremental, i) => {
                  return (
                    <li key={i} onClick={() => setValueIncremental(listIncremental.name)}>
                      {listIncremental.name}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDataSet = () => {
    return (
      <DropdownBox>
        <label htmlFor="exampleInputDataset" className="title text-left small label-head">
          Dataset Name
        </label>
        <div
          className={`box_field w-400 mt-3`}
          onClick={() => UseSetOpenDropdownDataSet(!openDropdownDataSet)}
        >
          <p className="text-dropdown">
            {dataSetId ? (
              dataSetId
            ) : (
              <span className="head-sub-title m-0 p-0">Select Dataset Name</span>
            )}
          </p>
          <i className={`arrow-${openDropdownDataSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDataSet && (
          <div className="box-dropdown">
            <ul>
              {Array.isArray(dataSet) && !!dataSet.length ? (
                dataSet.map((item, i) => {
                  return (
                    <li key={i} onClick={() => setDataSetId(item.dataset_id)}>
                      {item.dataset_id}
                    </li>
                  )
                })
              ) : (
                <li>----- No data ------</li>
              )}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownTableList = () => {
    return (
      <DropdownBox>
        <label htmlFor="exampleInputTable" className="title text-left small label-head">
          Table Name
        </label>
        <div
          className={`box_field w-400 mt-3`}
          onClick={() => UseSetOpenDropdownTable(!openDropdownTable)}
        >
          <p className="text-dropdown">
            {tableId ? tableId : <span className="head-sub-title m-0 p-0">Select table name</span>}
          </p>
          <i className={`arrow-${openDropdownTable ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownTable && (
          <div className="box-dropdown">
            <ul>
              {tableList &&
                tableList.map((item, i) => {
                  return (
                    <li key={i} onClick={() => setTableId(item.table_id)}>
                      {item.table_id}
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }

  const HandlingNodate = () => {
    return (
      <div className="form-group">
        <input
          type="text"
          className="input box_field date-box disabled-bg-gray mt-0"
          placeholder="Only once"
          disabled
        />
        <p className="date-box mt-0 text-gray">
          <img className="icon-tooltip mr-1" src="/assets/icons/tooltips.png" alt="information" />
          This import cannot be set to repeat because your table does not have columns in DateTime
          format.
        </p>
      </div>
    )
  }

  return isConnect ? (
    <ConnectBigqueryBox isShowMenu={props ? props.isShowMenu : true} ref={ref}>
      <div className="section-main">
        <p className="text-left btn-back" onClick={() => props.nextStepFn('selectSource')}>
          {' '}
          <i className="arrow-left"> </i> Back
        </p>
        <h2 className="head-title text-left">Connect to Google BigQuery</h2>

        <div></div>

        <p className="head-sub-title text-left">
          Follow the steps below to start setting up BigQuery.
        </p>
        <ul className="progress-vertical vertical">
          <li data-step="1" className="active step">
            <div className="px-3">
              <p className="title md text-left mb-2">File name</p>
              <p className="text-extra-small">Name of your Google Could Storage File name.</p>
              <div className="form-group">
                <input
                  type="text"
                  className="input w-400"
                  placeholder="File name"
                  value={fileName}
                  onChange={e => setFileName(e.target.value)}
                />
              </div>
            </div>
          </li>
          <li data-step="2" className="active step">
            <div className="px-3">
              <p className="title md text-left mb-2">Enter your Project ID</p>
              <p className="title text-left small">
                Project ID <span className="text-danger">*</span>
              </p>
              <p className="text-extra-small">
                The Google Cloud project where your BigQuery database resides. Project IDs start
                With a letter and contain 6-63 lowercase letters, digits or dashes.
              </p>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="input w-400"
                    placeholder="Project ID"
                    value={projectId}
                    onChange={e => setProjectId(e.target.value)}
                    // disabled={dataSet ? true : false}
                  />
                </div>
                <div className="row">
                  <div className="col-auto my-3 py-1">
                    <button
                      type="button"
                      value="Connect"
                      className="btn-basic bg-darkblue font-white border-0"
                      onClick={() => connect()}
                    >
                      Connect
                    </button>
                  </div>
                  <div className="col-auto p-2 my-3 w70">
                    <p id="results" className={dataSet ? 'd-block -fadeIn text-dark' : 'd-none'}>
                      <img
                        className="correctValidation mx-2"
                        src={`/assets/icons/correctValidation.png`}
                      />
                      Google BigQuery Connected
                    </p>
                    <p
                      id="results"
                      className={connectPass ? 'd-none' : 'd-block -fadeIn text-danger'}
                    >
                      {errorMsg}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li data-step="3" className={dataSet ? 'active step' : 'step'}>
            <div className="px-3">
              <p className="title md text-left mb-2">Choose your data</p>
              <div className={dataSet ? 'd-block -fadeIn' : 'd-none'}>
                <div className="form-group">{dataSet && <DropdownDataSet />}</div>

                <div className="form-group">{tableList && <DropdownTableList />}</div>

                <section>
                  <p className="title text-left small m-0 mb-4">Sample Data</p>
                  {isTableLoading ? <Loading /> : <TableSample />}
                </section>
              </div>
            </div>
          </li>
          <li data-step="4" className={tableId ? 'active step' : 'step'}>
            <div className="px-3">
              <p className="title md text-left mb-2">Sync schedule</p>
              <div className={tableId ? 'd-block -fadeIn' : 'd-none'}>
                {/* Only once = /Date + /Time
              Daily = /Time + /Load incremental data by
              Weekly = /Day of week(ex Sunday) + /Time + Load incremental data by
              Monthly = On the + /Time + Load incremental data by
               */}

                <div className="row">
                  <div className="col-auto">
                    <div className="form-group">
                      <label htmlFor="" className="title text-left small label-head">
                        Repeat
                      </label>

                      {!incrementals || incrementals.length !== 0 ? (
                        <DropdownRepeatTimeSet />
                      ) : (
                        <HandlingNodate />
                      )}
                    </div>
                  </div>
                  <div className={repeatTime === 'Only once' ? 'd-block col-auto' : 'd-none'}>
                    <div className="form-group">
                      <label htmlFor="" className="title text-left small label-head">
                        Date
                      </label>
                      <Fragment>
                        <div className={`inner-segment`}>
                          <DatePicker
                            className={`box_field date-box`}
                            value={schedule}
                            onChange={e => setSchedule(e)}
                          />
                        </div>
                      </Fragment>
                    </div>
                  </div>
                  <div className={repeatTime === 'Weekly' ? 'd-block col-auto' : 'd-none'}>
                    <label className="title text-left small label-head">Day of week</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownDaySet />
                      </div>
                    </form>
                  </div>

                  <div className={repeatTime === 'Monthly' ? 'd-block col-auto' : 'd-none'}>
                    <label className="title text-left small label-head">On the</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownDateSet />
                      </div>
                    </form>
                  </div>

                  <div
                    className={
                      repeatTime === 'Only once' ||
                      repeatTime === 'Daily' ||
                      repeatTime === 'Weekly' ||
                      repeatTime === 'Monthly'
                        ? 'd-block col-auto'
                        : 'd-none'
                    }
                  >
                    <label className="title text-left small label-head">Time</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownTimeSet />
                      </div>
                    </form>
                  </div>
                </div>
                {incrementals.length > 0 ? (
                  <div className="row">
                    <div
                      className={
                        repeatTime === 'Daily' ||
                        repeatTime === 'Weekly' ||
                        repeatTime === 'Monthly'
                          ? 'd-block col-auto'
                          : 'd-none'
                      }
                    >
                      <div className="form-group">
                        <label htmlFor="" className="title text-left small label-head">
                          Load incremental data by
                        </label>

                        <DropdownIncrementalSet />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="section-footer">
        <div className="col-6 align-left">
          <a className="btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => props.nextStepFn('selectType')}>
            Previous
          </a>
        </div>
        <div className="col-6 align-right">
          <a className="btn-basic bg-whiter border-darkgreen font-darkgreen mx-2" onClick={() => history.push('/imports')}>
            Cancel
          </a>
          <a
            className={`btn-basic ${
              repeatTime === 'Only once'
                ? timeSchedule && schedule
                  ? 'bg-darkgreen font-white'
                  : 'bg-gray font-white'
                : repeatTime === 'Daily'
                ? timeSchedule && incremental
                  ? 'bg-darkgreen font-white'
                  : 'bg-gray font-white'
                : repeatTime === 'Weekly'
                ? timeSchedule && incremental && day
                  ? 'bg-darkgreen font-white'
                  : 'bg-gray font-white'
                : repeatTime === 'Monthly'
                ? timeSchedule && incremental && date
                  ? 'bg-darkgreen font-white'
                  : 'bg-gray font-white'
                : 'bg-gray font-white'
            }`}
            // onClick={() => (timeSchedule ? setStepFn() : '')}
            onClick={() =>
              repeatTime === 'Only once'
                ? timeSchedule && schedule
                  ? setStepFn()
                  : ''
                : repeatTime === 'Daily'
                ? timeSchedule && incremental
                  ? setStepFn()
                  : ''
                : repeatTime === 'Weekly'
                ? timeSchedule && incremental && day
                  ? setStepFn()
                  : ''
                : repeatTime === 'Monthly'
                ? timeSchedule && incremental && date
                  ? setStepFn()
                  : ''
                : ''
            }
          >
            Next
          </a>
        </div>
      </div>
    </ConnectBigqueryBox>
  ) : (
    <DataMappingComponent
      isType={'bigQuery'}
      objTask={objTask}
      objSchedule={objSchedule}
      option={props}
      column={column}
      rows={rows}
      setStepFn={setStep}
    />
  )
}

export default ConnectBigqueryComponent
