import React, { Fragment, useState, useEffect } from 'react'
import { LoginBox } from './style'
import { verifyOTP, sendOTP } from '../../apis/login/api'
import { useHistory } from 'react-router-dom'
import PopupComponent from '../popup/popup'

import OtpInput from 'react-otp-input'

const Login2FAComponent = props => {
  const [otp, setOtp] = useState(null)
  const [refCode, setRefCode] = useState(null)
  const [errorOtp, setErrorOtp] = useState(false)
  const [expireTimeOtp, setExpireTimeOtp] = useState(null)
  const [isLoadding, setLoading] = useState(false)
  const [apiError, setNotiApiError] = useState(false)

  // const [seconds, setSeconds] = useState(0);
  const history = useHistory()

  useEffect(() => {
    setErrorOtp(null)
  }, [otp])
  useEffect(() => {
    setExpireTimeOtp(getExpireOTPTime())
    setRefCode(props.OTPData.refId)
  }, [])

  // countdown timer
  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       clearInterval(myInterval)
  //     }
  //   }, 1000)
  //   return () => {
  //     clearInterval(myInterval);
  //   };
  // }, [seconds])

  const censoringEmail = email => {
    email = email.split('@')
    const emailName = email[0].substring(0, 3).padEnd(email[0].length, '*')
    let emailProvider = email[1].split('.')
    emailProvider[0] = emailProvider[0].replaceAll(/./g, '*')
    emailProvider = emailProvider.join('.')
    return emailName + ' ' + emailProvider
  }

  const getExpireOTPTime = () => {
    const expireTime = new Date()
    expireTime.setSeconds(expireTime.getSeconds() + 60)
    return expireTime
  }
  const resendOtp = async () => {
    setLoading(true)
    const reqSendOTP = { email: props.OTPData.email, otpId: props.OTPData.otpId }
    const { data: restSendOTP, status: statusSendOTP } = await sendOTP(reqSendOTP)
    if (statusSendOTP === 200) {
      setNotiApiError(false)
      setExpireTimeOtp(getExpireOTPTime())
      setRefCode(restSendOTP.ref_id)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } else {
      setNotiApiError(true)
      setTimeout(() => {
        setNotiApiError(false)
      }, 5000)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }
  const validateOTP = async () => {
    if (expireTimeOtp < new Date()) {
      setErrorOtp('OTPExpired')
      return
    }
    const reqVerifyOTP = { email: props.OTPData.email, otpId: props.OTPData.otpId, otp }
    const { data: res, status: statusSendOTP } = await verifyOTP(reqVerifyOTP)

    if (statusSendOTP === 200) {
      setNotiApiError(false)
      localStorage.setItem('token', res.access_token)
      window.location.href = '/home'
    } else if (statusSendOTP === 500) {
      setNotiApiError(true)
      setTimeout(() => {
        setNotiApiError(false)
      }, 5000)
    } else {
      setErrorOtp('InvalidOTP')
    }
  }

  const renderErrorMsg = () => {
    if (errorOtp) {
      if (errorOtp === 'OTPExpired')
        return (
          <label className="otp_err">
            The OTP code has expired. Please re-send the verification code to try again.
          </label>
        )
      else if (errorOtp === 'InvalidOTP')
        return <label className="otp_err">The code is incorrect</label>
    } else {
      return null
    }
  }

  return (
    <LoginBox>
      {apiError && (
        <PopupComponent
          option={{ status: 'error', msg: 'Something went wrong, please try again later.' }}
        />
      )}

      <div className="box_login">
        <p className="title">Verification Code</p>
        <p className="input_label -font-blue -font-bold">
          Check your inbox <img src="/assets/icons/sent.png" alt="sent" />
        </p>
        <p className="input_label">
          Please check your email{' '}
          <span className="-font-blue">
            {props.OTPData.email && censoringEmail(props.OTPData.email)}
          </span>{' '}
          for a six-digit code and enter it below to login
        </p>
        <div className="box_otp">
          <OtpInput
            inputStyle={`input-otp ${errorOtp ? 'input-otp-error' : ''} `}
            value={otp}
            onChange={setOtp}
            numInputs={6}
          />
          {renderErrorMsg()}
        </div>
        <p className="ref_code">Ref code ({refCode})</p>
        <br />
        <p className="input_label -center f-12">
          It may take a minute to receive your code <br />
          Haven’t received?{' '}
          {isLoadding ? (
            <div className="loader-circle fix-inline"></div>
          ) : (
            <a className="-font-blue" onClick={resendOtp}>
              Resend a new code
            </a>
          )}
        </p>
        <a
          onClick={() => validateOTP()}
          className={`w-100 border-0 btn-basic ${otp && otp.length === 6 ? 'bg-darkgreen font-white' : 'bg-gray font-white'}`}
        >
          Submit
        </a>
      </div>
    </LoginBox>
  )
}

export default Login2FAComponent
