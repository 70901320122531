import React, { Fragment, useState, useEffect, useRef } from 'react'
import SelectSourceComponent from './selectSource'
import DropdownOptions from './dropdownOptions'
import { campaignList } from '../../apis/dataActivation/api'
import DataTable from 'react-data-table-component'

import { CampaignListBox, TableBox, LoaddingBox } from './style'

const CampaignListComponent = props => {
  const [campaignListData, setCampaignListData] = useState()
  const [paginationPage, setPaginationPage] = useState(1)
  const [isTableLoading, setIsTableLoading] = useState(false)

  const columns = [
    {
      name: 'Campaign Name',
      selector: row => row.campaignName,
      sortable: true,
      width: props.isShowMenu ? 'auto' : '180px'
    },
    {
      name: 'Type',
      selector: row => row.type,
      width: '100px'
    },
    {
      name: 'Status',
      selector: row => row.status,
      width: '120px'
    },
    {
      name: 'Start at',
      selector: row => row.startAt
    },
    {
      name: 'Created at',
      selector: row => row.createAt
    },
    {
      name: 'Created by',
      selector: row => row.createBy
    },
    {
      name: 'Last Updated',
      selector: row => row.lastUpdate
    },
    {
      name: '',
      selector: row => row.options,
      width: '50px'
    }
  ]

  useEffect(async () => {
    await getCampaignList()
  }, [])

  const getCampaignList = async (page = 1, option = 'all') => {
    let obj = {
      campaign_type: option,
      page: page,
      limit: 20,
      pagination: true
    }
    let response = []
    const { data, status } = await campaignList(obj)
    if (data && data.data && data.data.campaign_lists && status === 200) {
      let tempData = data.data.campaign_lists
      let _temp = {}
      for (let i = 0; i < tempData.length; i++) {
        _temp = {
          campaignName: tempData[i].campaign_detail.campaign_name,
          type: tempData[i].campaign_detail.campaign_type,
          status: (
            <div
              className={`-status ${
                tempData[i].campaign_detail.campaign_status === 'publish'
                  ? 'running'
                  : tempData[i].campaign_detail.campaign_status === 'save_draft'
                  ? 'draft'
                  : tempData[i].campaign_detail.campaign_status === 'done'
                  ? 'ended'
                  : tempData[i].campaign_detail.campaign_status === 'fail'
                  ? 'stopped'
                  : tempData[i].campaign_detail.campaign_status === 'upcoming'
                  ? 'upcoming'
                  : tempData[i].campaign_detail.campaign_status === 'cancel'
                  ? 'paused'
                  : 'running'
              }`}
            >
              {tempData[i].campaign_detail.campaign_status === 'publish'
                ? 'Running'
                : tempData[i].campaign_detail.campaign_status === 'save_draft'
                ? 'Draft'
                : tempData[i].campaign_detail.campaign_status === 'done'
                ? 'Ended'
                : tempData[i].campaign_detail.campaign_status === 'fail'
                ? 'Failed'
                : tempData[i].campaign_detail.campaign_status === 'upcoming'
                ? 'Upcoming'
                : tempData[i].campaign_detail.campaign_status === 'cancel'
                ? 'Paused'
                : 'Running'}
            </div>
          ),
          startAt: <ConvertDate data={tempData[i].schedule_detail.schedule_start_date} />,
          createAt: <ConvertDate data={tempData[i].created_at} />,
          createBy:
            tempData[i].created_by.firstname && tempData[i].created_by.lastname ? (
              <label className="fix-name">
                {tempData[i].created_by.firstname + ' ' + tempData[i].created_by.lastname}
              </label>
            ) : (
              '-'
            ),
          lastUpdate: <ConvertDate data={tempData[i].last_update_at} />,
          options: (
            <DropdownOptions
              isShowMenu={props.isShowMenu}
              detail={tempData[i]}
              id={tempData[i].ref_id}
              status={tempData[i].campaign_detail.campaign_status}
              
            />
          )
        }

        response.push(_temp)
      }

      if (page > 1) {
        let mergeData = campaignListData
        mergeData.push(...response)

        setCampaignListData(mergeData.map(item => item))
      } else {
        setCampaignListData(response)
      }
      setIsTableLoading(true)
    } else {
      console.log('getcampaignList Error')
    }
  }

  const ConvertDate = data => {
    let date = data.data
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    return (
      <Fragment>
        {day + ' ' + month + ' ' + year} <br></br>
        <span className="span-next">{time}</span>{' '}
      </Fragment>
    )
  }
  const Loading = () => {
    return (
      <LoaddingBox>
        <div className="text-center main-loadding">
          <div className="overlay-loadding">
            <img className="icon-loadding" src="/assets/images/flipball.gif" />
          </div>
        </div>
      </LoaddingBox>
    )
  }

  const handleChangePage = async e => {
    if (e * 10 === campaignListData.length) {
      let page = paginationPage + 1
      setPaginationPage(page)
      await getCampaignList(page, 'all')
    }
  }

  return (
    <CampaignListBox isShowMenu={props.isShowMenu}>
      <div className="px-4">
        <TableBox>
          <div className="scroll-x mt-2">
            {!isTableLoading ? (
              <Loading />
            ) : (
              <DataTable
                pagination
                onChangePage={handleChangePage}
                className="-fadeIn border-radius-0 overflow-auto"
                columns={columns}
                data={campaignListData}
              />
            )}
          </div>
        </TableBox>
      </div>
    </CampaignListBox>
  )
}

export default CampaignListComponent
