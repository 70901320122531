import React, { Component, Fragment } from 'react'
import AudienceTable from '../table/audienceTable'
import { AudienceBox } from './style'
import LoadingOverlay from 'react-loading-overlay'
import { isEmpty } from '../../helper/helper'
import { verifyToken } from '../../apis/login/api'
import { withRouter } from 'react-router'

import { getCustomerList } from '../../apis/customer/api'

class AudienceComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      historyList: [],
      activityList: [],
      lastLoad: false,
      customerDetail: {},
      showDetail: false,
      isOpened: true,
      settings: {},
      role: ''
    }
  }

  componentWillMount() {
    window.addEventListener('scroll', this.loadMore)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore)
  }

  getCustomerList = async params => {
    const { historyList } = this.state
    let res = {}
    try {
      res = await getCustomerList(params)
      if (res.status === 200) {
        if (!isEmpty(res.data)) {
          historyList.push(...res.data)
          this.setState({ historyList: historyList, lastLoad: false })
        } else {
          this.setState({ lastLoad: true })
        }
      }
    } catch (error) {
      console.log('error : ', error)
    }
  }

  loadMore = async () => {
    const { historyList, lastLoad } = this.state
    if (!lastLoad) {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.scrollingElement.scrollHeight
      ) {
        let params = {
          pageSize: 25,
          lastField: !isEmpty(historyList) ? historyList[historyList.length - 1].updated_at : ''
        }

        setTimeout(async () => {
          await this.getCustomerList(params)
        }, 2000)
      }
    }
  }

  getDetail = async customer_id => {
    const { role } = this.state
    const { history } = this.props
    if (role !== 'viewer' && !isEmpty(history)) {
      history.push(`/audience/${customer_id}`)
    }
  }

  setRole = async () => {
    let role = await verifyToken()
    this.setState({ role: role.role })
  }

  componentDidMount = async () => {
    await this.setRole()
    await this.getCustomerList()
  }

  render() {
    const { isShowMenu, history } = this.props
    const { historyList, lastLoad } = this.state

    return (
      <AudienceBox isShowMenu={isShowMenu}>
        <div className="main">
          <div className="py-4 px-4">
            <div className="row">
              <div className="col-12">
                <p className="top-head-title" onClick={() => history.push('/audience')}>
                  Audience
                </p>
              </div>
            </div>
            <Fragment>
              <AudienceTable historyList={historyList} getDetailFn={this.getDetail} />
              {!lastLoad && (
                <div className="onLoadmore">
                  <LoadingOverlay active={true} spinner />
                </div>
              )}
            </Fragment>
          </div>
        </div>

        {/* <section className="main -center">
          <NotFoundComponent errorType={'404'} />
        </section> */}
      </AudienceBox>
    )
  }
}

export default withRouter(AudienceComponent)
