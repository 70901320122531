import { Component } from 'react'
import { isEmpty } from '../../helper/helper'
import { ProfileBox } from './style'
import { verifyToken, accountDetail } from '../../apis/login/api'

import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
export default class ProfileComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      customerName: 'Predictive Co., Ltd.'
    }
  }

  setRole = async () => {
    let data = await verifyToken()
    this.setState({ name: data.firstname + ' ' + data.lastname })
  }

  componentDidMount = async () => {
    await this.setRole()
    let res = await accountDetail()
    if (res) {
      this.setState({ customerName: res.account_name })
    }
  }

  render() {
    const { isShowMenu } = this.props
    const { customerName } = this.state
    return (
      <ProfileBox isShowMenu={isShowMenu}>
        <section className="main">
          <div className="profile_company">{customerName}</div>
          <div className="profile_noti">
            <NotificationsNoneOutlinedIcon />
          </div>
          <div className="profile_img">{this.state.name.charAt(0).toLocaleUpperCase()}</div>
        </section>
      </ProfileBox>
    )
  }
}
