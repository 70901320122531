import styled from 'styled-components'

const LoginBox = styled.div`
.loader-btn-disabled{
  width: 27rem;
  height: 27rem;
  margin: 4px auto;
  background-color: transparent;
  top: 0rem;
  position: absolute;
  top: 13rem;
}

  // font-family: Inter;
  display: flex;
  height: 100vh;
  section {
    width: 50%;
  }
  input {
    outline: none;
    color: #385172;
  }
  form {
    margin: auto;
  }
  .bg-white {
    background: white;
  }

  .box_login {
    margin: 15% auto;
    width: 414px;
    background-color: #fff;
    .forgot_password {
      margin-left: 305px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: right;
      color: #9daeb9;
      margin-top: 10px;
      text-decoration: underline;
      cursor: pointer;
    }
    .validate-password {
      font-size: 12px;
      margin: 0 0 0 8.5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
    .-green {
      color: #8dc360;
    }
    .-gray {
      color: #ced4de;
    }

    .title {
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
      margin-bottom: 30px;
    }
    .input_label {
      display: block;
      margin-top: 15px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #9daeb9;
      a,
      a:hover {
        text-decoration: underline !important;
      }
      img {
        width: 15px;
      }
    }
    .-font-blue {
      color: #24b0c3;
    }
    .-font-bold {
      font-weight: 500;
    }
    .ref_code {
      line-height: 1.67;
      letter-spacing: normal;
      text-align: center;
      font-size: 12px;
      margin: 30px 0px 0px 0px;
      color: #385172;
    }
    .-center {
      text-align: center;
    }
    .box_otp {
      margin-top: 40px;
      div {
        align-items: center;
        justify-content: center;
      }
    }
    .input {
      width: 414px;
      height: 50px;
      margin: 10px 0 0;
      padding: 15px 30px 14px 30px;
      border-radius: 10px;
      border: solid 1.5px #aabaca;
      background-color: #fff;
      :focus {
        box-shadow: 0 0 6px 0 rgba(36, 176, 195, 0.5);
        border: solid 1.5px #24b0c3;
      }
    }
    .input:-webkit-autofill,
    .input:-webkit-autofill:hover,
    .input:-webkit-autofill:focus,
    .input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .input-otp {
      width: 45px !important;
      height: 60px;
      margin: 0 12px 0 0;
      border-radius: 10px;
      border: solid 1.5px #aabaca;
      background-color: #fff;
      :focus {
        box-shadow: 0 0 6px 0 rgba(36, 176, 195, 0.5);
        border: solid 1.5px #24b0c3;
      }
    }
    .input-otp-error {
      border: solid 1.5px #ff2c65;
    }
    .btn {
      width: 414px;
      height: 50px;
      margin: 30px 0 0;
      padding: 14px 0 15px 5px;
      border-radius: 10px;
      color: #fff;
      border: none;
      font-size: 16px;
      font-family: Inter;
    }
    .btn_blue {
      background-color: #24b0c3;
    }
    .btn_gray {
      background-color: #9daeb9;
    }

    .label_err {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      text-align: left;
      color: #ff2c65;
    }
    .otp_err {
      margin-left: 35px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: normal;
      color: #ff2c65;
    }
    .input_err {
      border: solid 1.5px #ff2c65;
    }
    .show_password {
      position: absolute;
      margin-top: 25px;
      margin-left: -50px;
      color: #24b0c3;
      font-size: 12px;
      line-height: 1.83;
      cursor: pointer;
    }
  }

  .ico-warning {
    width: 20px;
  }
  .noti_popup_error {
    border: solid 1px #f4c229 !important;
    background-color: #fffced !important;
    color: #6f809e !important;
  }

  .noti_popup {
    position: absolute;
    width: 387px;
    height: 77px;
    padding: 12.3px 7.1px 14.5px 18.6px;
    border-radius: 10px;
    border: solid 1px #8dc360;
    background-color: #f6ffec;
    right: 100px;
    margin-top: 20px;
    display: flex;
    color: #8dc360;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    .-left {
      width: 36px;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }

    span {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }

    .close_noti_popup {
      position: absolute;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    }
    .close_noti_popup:hover {
      color: #adadad;
      cursor: pointer;
      opacity: 1;
    }
    .close_noti_popup:before,
    .close_noti_popup:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 14px;
      width: 3px;
      background-color: #333;
    }
    .close_noti_popup:before {
      transform: rotate(45deg);
    }
    .close_noti_popup:after {
      transform: rotate(-45deg);
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fix-inline {
    display: inline-block;
    margin-top: 10px !important;
    margin-left: 20px !important;
  }

  .loader-circle {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin: -5px auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export { LoginBox }
