import React, { Fragment, useState, useEffect } from 'react'
import { DataMappingBox, DropdownBox } from './style'
import DropdownComponent from '../../dropdown/dropdown'
import { isEmpty } from '../../../helper/helper'
import LoadingOverlay from 'react-loading-overlay'

import { getFieldsList, addCustomField } from '../../../apis/mapping/api'
import { createTaskBigQuery, createTaskGoogleCloud } from '../../../apis/filestatus/api'

import { useHistory } from 'react-router-dom'

const DataMappingComponent = props => {
  const { groupName, dataType, masterField } = props.option
  const { column, rows } = props
  const [listMapping, setListMapping] = useState()
  let [allSelected, setAllSelected] = useState()
  const [selectRequieColumn, setSelectRequieColumn] = useState()

  const [autoSelected, setAutoSelected] = useState()

  let [wrapObjKey, setWrapObjKey] = useState([])
  let [submitLoadding, setIsSubmitLoadding] = useState(false)

  const [pkeyValue, setPKeyValue] = useState()
  const [validatePKeyValue, setValidatePKey] = useState(false)
  const [validateFKeyValue, setValidateFKey] = useState(false)
  const [validateTKeyValue, setValidateTKey] = useState(false)
  const [fkeyValue, setFKeyValue] = useState()
  const [tkeyValue, setTKeyValue] = useState()

  const history = useHistory()
  useEffect(async () => {
    let res = await getFields()
    await autoSelect()
    setSelectRequieColumn(res)

    setFieldMapping()
  }, [])

  const autoSelect = async () => {
    let masterField = await getFields()
    if (!autoSelected) {
      setSelectRequieColumn(masterField)
    }

    let _tempSelected = []
    let _wrap = {}
    for (let i = 0; i < masterField.length; i++) {
      for (let v = 0; v < column.length; v++) {
        if (
          column[v].name
            .toLowerCase()
            .includes(masterField[i].field_name.toLowerCase().substring(0, 5))
        ) {
          _wrap = {
            data: masterField[i],
            no: v
          }
          _tempSelected.push(_wrap)
        }
      }
    }

    _tempSelected = _tempSelected.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.place === value.place && t.no === value.no)
    )

    const filteredArr = _tempSelected.reduce((acc, current) => {
      const x = acc.find(item => item.data.field_name === current.data.field_name)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    if (filteredArr) {
      setAutoSelected(filteredArr)
    } else {
      setAutoSelected(masterField)
    }
  }

  const getFields = async () => {
    const { dataType } = props.option
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'
    let res = {}

    try {
      let response = await getFieldsList(paramApi)
      if (response && response.status === 200) {
        res = response.data.filter(l => l.field_name !== 'customer_id')
      }
    } catch (error) {
      res = error.message
    }
    return res
  }

  const setFieldMapping = async () => {
    let wrapRawData = column
    let res = []
    let _temp = {}
    for (let i = 0; i < wrapRawData.length; i++) {
      _temp = {
        fieldName: wrapRawData[i].name,
        fieldMapping: {},
        key: ''
      }
      res.push(_temp)
    }
    setListMapping(res)
  }

  const checkValidKey = () => {
    let res = false
    if (dataType === 'customer') {
      if (!pkeyValue) {
        setValidatePKey(true)
      } else {
        setValidatePKey(false)
        res = true
      }
    } else {
      if (!pkeyValue) {
        setValidatePKey(true)
      } else if (!fkeyValue) {
        setValidateFKey(true)
        setValidatePKey(false)
      } else if (!tkeyValue) {
        setValidateTKey(true)
        setValidateFKey(false)
        setValidatePKey(false)
      } else {
        setValidateTKey(false)
        setValidateFKey(false)
        setValidatePKey(false)
        res = true
      }
    }
    return res
  }

  const creatTask = async () => {
    const { objTask, objSchedule, isType } = props
    let checkValid = checkValidKey()
    if (!checkValid) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      })
    } else {
      let setObj = {}
      let res = []
      let _temp = wrapObjKey.filter(l => Object.keys(l).length !== 0)
      for (let i = 0; i < _temp.length; i++) {
        let keyRules = column.filter((_, index) => index === _temp[i].selectNo)[0].name
        setObj = {
          [_temp[i].selectVal.field_name]: {
            csv_name: keyRules,
            data_type: _temp[i].selectVal.data_type
          }
        }
        res.push(setObj)
      }
      if (pkeyValue) {
        res.push(pkeyValue)
      }
      if (fkeyValue) {
        res.push(fkeyValue)
      }
      if (tkeyValue) {
        res.push(tkeyValue)
      }
      let obj = {}

      if (objSchedule && objSchedule.type === 'only once') {
        if (isType === 'gcs') {
          let wrapFileList = objTask.file_lists.map(item => item.file_name.split('/').pop())
          obj = {
            filename: objTask.filename,
            bucket_name: objTask.bucket_name,
            path_folder: objTask.path_folder,
            file_lists: wrapFileList,
            group_name: groupName,
            job_start_date: objSchedule.schedule,
            rules: arrayToObject(res)
          }
        } else {
          obj = {
            filename: objTask.filename,
            project_id: objTask.project_id,
            group_name: groupName,
            dataset_name: objTask.dataset_id,
            table_name: objTask.table_id,
            job_start_date: objSchedule.schedule,
            rules: arrayToObject(res)
          }
        }
      } else if (objSchedule && objSchedule.type === 'daily') {
        if (isType === 'gcs') {
          let wrapFileList = objTask.file_lists.map(
            item => item.file_name.split('/')[objTask.file_lists.length - 1]
          )
          obj = {
            filename: objTask.filename,
            bucket_name: objTask.bucket_name,
            path_folder: objTask.path_folder,
            file_lists: wrapFileList,
            group_name: groupName,
            schedule_detail: {
              column_timestamp: objSchedule.column_timestamp,
              type: 'daily',
              time: objSchedule.time
            },
            rules: arrayToObject(res)
          }
        } else {
          obj = {
            filename: objTask.filename,
            project_id: objTask.project_id,
            group_name: groupName,
            dataset_name: objTask.dataset_id,
            table_name: objTask.table_id,
            rules: arrayToObject(res),
            schedule_detail: {
              column_timestamp: objSchedule.column_timestamp,
              type: 'daily',
              time: objSchedule.time
            }
          }
        }
      } else {
        if (isType === 'gcs') {
          let wrapFileList = objTask.file_lists.map(
            item => item.file_name.split('/')[objTask.file_lists.length - 1]
          )
          obj = {
            filename: objTask.filename,
            bucket_name: objTask.bucket_name,
            path_folder: objTask.path_folder,
            file_lists: wrapFileList,
            group_name: groupName,
            schedule_detail: {
              type: objSchedule.type,
              time: objSchedule.time,
              day_of_type: objSchedule.day_of_type_schedule
            },
            rules: arrayToObject(res)
          }
        } else {
          obj = {
            filename: objTask.filename,
            project_id: objTask.project_id,
            group_name: groupName,
            dataset_name: objTask.dataset_id,
            table_name: objTask.table_id,
            rules: arrayToObject(res),
            schedule_detail: {
              column_timestamp: objSchedule.column_timestamp,
              type: objSchedule.type,
              time: objSchedule.time,
              day_of_type: objSchedule.day_of_type_schedule
            }
          }
        }
      }
      setIsSubmitLoadding(true)
      if (isType === 'bigQuery') {
        let { status } = await createTaskBigQuery(obj, dataType)
        window.scrollTo({
          top: 100,
          behavior: 'smooth'
        })
        if (status === 200) {
          setTimeout(() => {
            history.push('/imports')
          }, 5000)
        } else {
          setTimeout(() => {
            setIsSubmitLoadding(false)
          }, 5000)
        }
      } else if (isType === 'gcs') {
        let { status } = await createTaskGoogleCloud(obj, dataType)
        window.scrollTo({
          top: 100,
          behavior: 'smooth'
        })
        if (status === 200) {
          setTimeout(() => {
            history.push('/imports')
          }, 5000)
        } else {
          setTimeout(() => {
            setIsSubmitLoadding(false)
          }, 5000)
        }
      }
    }
  }
  const arrayToObject = arr => {
    return arr.reduce((obj, item) => {
      const key = Object.keys(item)[0]
      obj[key] = item[key]
      return obj
    }, {})
  }
  const saveCustomField = async (data_type, field_name) => {
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    let params = {
      data_type: data_type,
      field_name: field_name,
      option: paramApi
    }
    let res = {}
    try {
      res = await addCustomField(params)
      if (res && res.status === 200) {
        let updateFields = await getFields()
        setSelectRequieColumn(updateFields)
      }
    } catch (error) {
      res = { error }
    }
    return res
  }

  const setPKey = val => {
    let setObj = {}
    setObj = {
      customer_id: {
        csv_name: val.field_name,
        data_type: val.data_type
      }
    }
    setPKeyValue(setObj)
  }

  const setFKey = val => {
    let setObj = {}
    setObj = {
      event_id: {
        csv_name: val.field_name,
        data_type: val.data_type
      }
    }
    setFKeyValue(setObj)
    // wrapObjKey.push(setObj)
  }

  const setTKey = val => {
    let setObj = {}
    setObj = {
      timestamp: {
        csv_name: val.field_name,
        data_type: val.data_type
      }
    }
    setTKeyValue(setObj)
    // wrapObjKey.push(setObj)
  }

  const setInnerVal = async (val, no) => {
    let updateFieldList = await getFields()
    let setSelected = {}
    if (allSelected && val.field_name) {
      for (let i = 0; i < allSelected.length; i++) {
        if (no === allSelected[i].selectNo) {
          allSelected[i].selectVal = val
          allSelected[i].selectNo = no
          break
        } else {
          setSelected = {
            selectNo: no,
            selectVal: val
          }
        }
      }
      allSelected.push(setSelected)
    } else {
      if (val.field_name) {
        allSelected = []
        setSelected = {
          selectNo: no,
          selectVal: val
        }
        allSelected.push(setSelected)
        setAllSelected(allSelected)
      }
    }

    allSelected = Object.values(
      allSelected.reduce((acc, cur) => Object.assign(acc, { [cur.selectNo]: cur }), {})
    )

    setWrapObjKey(allSelected)
    // console.log('allSelected : ', allSelected)

    let _wrapData = []
    allSelected &&
      allSelected.map(list => {
        list.selectVal && _wrapData.push(list.selectVal)
      })

    let finalList = []
    if (masterField.length !== updateFieldList.length) {
      finalList = updateFieldList
    } else {
      finalList = masterField
    }
    let array = finalList
    let anotherOne = _wrapData
    let filteredArray = []
    filteredArray = array.filter(function (array_el) {
      return (
        anotherOne.filter(function (anotherOne_el) {
          return (
            (!isEmpty(anotherOne_el) && anotherOne_el.field_name === array_el.field_name) ||
            isEmpty(array_el.field_name)
          )
        }).length === 0
      )
    })
    setSelectRequieColumn(filteredArray)
  }

  return (
    <DataMappingBox isShowMenu={props ? props.isShowMenu : true}>
      {submitLoadding && (
        <div className="loadding-overlay">
          <LoadingOverlay active={true} spinner />
          <p>Please wait we are submiting your data.</p>
        </div>
      )}

      <div className="row-btn">
        <div className="col-12 text-left pl-5">
          <span className="title-inner ">Data Mapping |</span>{' '}
          <span className="title-inner -blue">
            {dataType} : {groupName}
          </span>
          {dataType === 'customer' ? (
            <p className="desc-inner">
              Map your source field to customer attribute. Only source field mapped to a customer
              attribute will be imported.
            </p>
          ) : dataType === 'event' ? (
            <p className="desc-inner">
              Map your source field to customer attribute. Only source field mapped to a customer
              attribute will be imported.
            </p>
          ) : (
            <p className="desc-inner">Products</p>
          )}
        </div>
      </div>
      <br />

      <div className="section-main">
        <div className="row">
          <div className="col-4 map-title ">
            <span className="-titleField">Source field</span>
            <div className="section-tooltip">
              <div className="tooltip">
                <img className="icon-tooltip" src="/assets/icons/tooltips.png" alt="information" />
                <span className="tooltiptext">
                  This is a list of all the columns from your file.
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 map-title">
            <p className="-titleField">{dataType} Attribute</p>
            <div className="section-tooltip">
              <div className="tooltip">
                <img className="icon-tooltip" src="/assets/icons/tooltips.png" alt="information" />
                <span className="tooltiptext">
                  This is our basic field. You can add a new field by selecting Create a custom
                  attribute in the drop down.
                </span>
              </div>
            </div>
          </div>
          <div className="col-4 map-title">
            <p className="-titleField">Sample Data</p>
            <div className="section-tooltip">
              <div className="tooltip">
                <img className="icon-tooltip" src="/assets/icons/tooltips.png" alt="information" />
                <span className="tooltiptext">This is a sample of the data from your file.</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-20">
          <div className="col-4">
            <div className="custom-selects">
              <DropdownComponent
                dataType={dataType}
                isKey={'PK'}
                isPrimaryValid={validatePKeyValue}
                setPrimaryField={listMapping}
                setPrimaryKeyFn={setPKey}
                // setInnerValFn={setPKey}
                primaryColumn={masterField}
                showCustom={false}
              />
              {validatePKeyValue && (
                <span className="-textvalid">Please select your customer_id</span>
              )}
            </div>
          </div>
          <div className="col-4">
            <div className="disable-field font-weight-lighter small">
              <img src="/assets/icons/door-key.png" className="icon-key" alt="predictive" />
              Customer id
            </div>
          </div>
        </div>
        {/* {console.log('primarykey : ', primarykey)} */}
        {dataType !== 'customer' ? (
          <Fragment>
            <div className="row mb-20">
              <div className="col-4">
                <div className="custom-selects">
                  <DropdownComponent
                    dataType={dataType}
                    isKey={'FK'}
                    isForeignValid={validateFKeyValue}
                    setPrimaryField={listMapping}
                    setInnerValFn={setFKey}
                    setPrimaryKeyFn={setPKey}
                    setForeignKeyFn={setFKey}
                    setThirdKeyFn={setTKey}
                    primaryColumn={masterField}
                    showCustom={false}
                  />
                  {validateFKeyValue && (
                    <span className="-textvalid">Please select your {groupName}_id</span>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="disable-field font-weight-lighter small">
                  <img src="/assets/icons/door-key.png" className="icon-key" alt="predictive" />
                  {groupName}_id
                </div>
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-4">
                <div className="custom-selects">
                  <DropdownComponent
                    dataType={dataType}
                    isKey={'TK'}
                    isThirdValid={validateTKeyValue}
                    isForeignValid={validateFKeyValue}
                    setPrimaryField={listMapping}
                    setInnerValFn={setTKey}
                    setPrimaryKeyFn={setPKey}
                    setForeignKeyFn={setFKey}
                    setThirdKeyFn={setTKey}
                    primaryColumn={masterField}
                    showCustom={false}
                  />
                  {validateTKeyValue && <span className="-textvalid">Please select timestamp</span>}
                </div>
              </div>
              <div className="col-4">
                <div className="disable-field font-weight-lighter small">
                  <img src="/assets/icons/door-key.png" className="icon-key" alt="predictive" />
                  timestamp
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment />
        )}
        {column &&
          column.map((item, i) => {
            return (
              <div className="row mb-10" key={i}>
                <div className="col-4">
                  <div className="box_field -disable_field">{item.name}</div>
                </div>
                <div className="col-4">
                  {/* {console.log('masterField : ', masterField)} */}
                  {autoSelected ? (
                    <DropdownComponent
                      dataType={dataType}
                      // isLast={setListMapping.length === i + 1 ? true : false}
                      saveCustomFieldFn={saveCustomField}
                      primaryColumn={masterField}
                      setInnerValFn={setInnerVal}
                      showCustom={true}
                      settingColumn={selectRequieColumn}
                      rowNo={i}
                      autoSelectedList={autoSelected}
                    />
                  ) : (
                    <Fragment />
                  )}
                </div>
                <div className="col-4">
                  <div className="sample-box">
                    <span>
                      {rows &&
                      rows[0] &&
                      rows[0][item.name] &&
                      typeof rows[0][item.name] !== 'object'
                        ? rows[0][item.name]
                        : '-'}
                    </span>

                    <span>
                      {rows &&
                      rows[1] &&
                      rows[1][item.name] &&
                      typeof rows[1][item.name] !== 'object'
                        ? rows[1][item.name]
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <div className="section-footer">
        <div className="col-6 align-left">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => props.setStepFn(true)}>
            Previous
          </a>
        </div>
        <div className="col-6 align-right">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2" onClick={() => history.push('/imports')}>
            Cancel
          </a>
          <a className="btn-basic bg-darkgreen font-white" onClick={() => creatTask()}>
            Start
          </a>
        </div>
      </div>
    </DataMappingBox>
  )
}

export default DataMappingComponent
