import styled from 'styled-components'

const DropdownBox = styled.div`
  .box_field {
    width: ${props => (props.isFlex ? '140px' : '180px')};
    height: 44px;
    padding: ${props => (props.isFlex ? '10px 10px 10px 10px' : '10px 15px 10px 16px')};
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    color: #aabaca;
    margin-right: 10px;
    display:flex;
    span{
        font-size: 14px;
        color:#385172;
        width: ${props => (props.isFlex ? '106px' : '140px')};
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    }
    img {
      width: 10px;
      height:7px;
      float: right;
      margin-top: 8px;
    }
    .-holder{
        color:#aabaca;
    }
 
  }
  .box-dropdown {
    position: absolute;
    min-width:${props => (props.isFlex ? '140px' : '180px')};
    max-height: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 9;
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px;
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .-bigfont{
    font-size: 18px!important;
        line-height: 1.3;
  }
  .-center{
    text-align: center;
  }
`

export { DropdownBox }
