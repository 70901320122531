import * as axios from 'axios'
import { redirectLogin } from '../../helper/helper'
const { REACT_APP_API_DATA_ACTIVATION, REACT_APP_API_CUSTOMER_VIEW_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const createCampaign = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/create`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getProvider = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/get-provider`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const campaignList = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/campaign-list`
    const response = await axios.post(
      URL,
      {
        campaign_type: obj.campaign_type, //** "all" or "publish" or "save_draft" **//
        page: obj.page,
        limit: obj.limit,
        pagination: obj.pagination
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const campaignPublish = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/publish`
    const response = await axios.post(
      URL,
      {
        ref_id: ref_id
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getSender = async configure_ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/get-sendername/${configure_ref_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const previewReachableUsers = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/segment/preview-reachable-users`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('error : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getCampaignDetail = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/get-campaign-detail/${obj.id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const editCampaign = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/edit`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('error : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const analyzeRFM = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/rfm/analyze`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('error : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getRFM = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/rfm/rfm-list`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const pullingRFM = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/rfm/pulling/${ref_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('pullingRFM error : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const previewRFM = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/rfm/preview-segment`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('error : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getReportSMS = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/report/${ref_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const downloadReportSMS = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/download-report/${ref_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const pauseCampaign = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/cancel`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const deleteCampaign = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/delete/${ref_id}`
    const response = await axios.delete(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}
const duplicateCampaign = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/duplicate`
    const response = await axios.post(
      URL,
      {
        ref_id: obj.ref_id
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const publishCampaign = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/publish`
    const response = await axios.post(URL, { ref_id: ref_id }, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const testSMS = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/campaign/send-test-sms`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const createProviderConfigure = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/provider/create-provider-configure`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getProviderConfigure = async provider_ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/provider/get-provider-configure/${provider_ref_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const removeProviderConfigure = async configure_ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/provider/delete-provider-configure/${configure_ref_id}`
    const response = await axios.delete(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

const getProviderConfigureList = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_DATA_ACTIVATION}/provider/get-provider-lists`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data.error
      }
    }
  }
  return res
}

export {
  createCampaign,
  campaignList,
  campaignPublish,
  getProvider,
  getSender,
  previewReachableUsers,
  getCampaignDetail,
  editCampaign,
  analyzeRFM,
  getRFM,
  pullingRFM,
  previewRFM,
  getReportSMS,
  deleteCampaign,
  pauseCampaign,
  duplicateCampaign,
  publishCampaign,
  downloadReportSMS,
  testSMS,
  createProviderConfigure,
  getProviderConfigure,
  removeProviderConfigure,
  getProviderConfigureList
}
