import styled from 'styled-components'

const ErrorBox = styled.div`
  .main-error {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 70%;
    padding: 40px;
    border-radius: 10px;
  }
  p {
    margin-bottom: 0px;
  }
  .-center {
    transform: translate(5%, 40%);
  }
  .-bold {
    font-weight: bold;
  }
  .-blue {
    color: #24b0c3;
  }
  .-large {
    font-size: 40px;
  }
  .-medium {
    font-size: 28px;
  }
  .-dark {
    color: #334d6e;
  }
  .-gray {
    color: rgba(51, 77, 110, 0.6);
  }
  img {
    width: 100%;
  }
`

const NotFoundBox = styled.div`
  text-align: center;
  width: 100%;
  img {
    width: 200px;
    margin-bottom: 30px;
  }
  .-center {
    transform: translate(5%, 40%);
  }
  .-bold {
    font-weight: bold;
  }
  .-blue {
    color: #24b0c3;
  }
  .-large {
    font-size: 40px;
  }
  .-medium {
    font-size: 28px;
  }
  .-dark {
    color: #334d6e;
  }
  .-gray {
    color: rgba(51, 77, 110, 0.6);
  }
`

export { ErrorBox, NotFoundBox }
