import React, { Fragment, useState, useEffect, useRef } from 'react'
import SelectSourceComponent from './selectSource'
import CampaignListComponent from './campaignList'
import SmsCampaignComponent from './sms/smsCampaign'
import { getSegmentList } from '../../apis/segmentation/api'

import { CampaignBox, CampaignListBox } from './style'
import { useHistory } from 'react-router-dom'

const CampaignComponent = props => {
  const { isCreate } = props
  const [stepSms, setStepSms] = useState(false)
  const [stepSelectSource, setStepSelectSource] = useState(false)
  const [masterSegmentList, setMasterSegmentList] = useState()

  const history = useHistory()

  useEffect(async () => {
    const { status, data } = await getSegmentList()
    if (status && status === 200) {
      setMasterSegmentList(data)
    }
  }, [])

  const createSms = () => {
    if (history.push('/campaign/create?step=smsCampaign')) {
      setStepSelectSource(false)
      setStepSms(true)
    }
  }
  return (
    <CampaignListBox isShowMenu={props.isShowMenu}>
      <div className="main">
      <div className="px-4">
        {!isCreate && (
          <div className="row py-3">
            <div className="col-6 text-left">
              <h2 className="top-head-title">Campaign</h2>
            </div>
            <div className="col-6 text-right">
              <a
                className="btn-basic bg-darkgreen font-white"
                onClick={() => history.push('/campaign/create')}
              >
                <img src="/assets/icons/create-white-2.png" className='w-px-14 mx-1'/>
                Create New
              </a>
            </div>
          </div>
        )}
      </div>
      {isCreate ? (
          <SelectSourceComponent />
        ) : (
          <CampaignListComponent masterSegmentList={masterSegmentList} />
        )}
      </div>
    </CampaignListBox>
  )
}

export default CampaignComponent
