import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_UPLOAD_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const getFieldsList = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/data-type/${params}/field`
    const response = await axios.get(URL, config)
    if (
      !isEmpty(response) &&
      !isEmpty(response.data) &&
      !isEmpty(response.status) &&
      response.status === 200
    ) {
      res = {
        status: response.status,
        data: response.data.field
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }

  // res = {
  //   status: 200,
  //   data: [
  //     {
  //       data_type: 'string',
  //       field_name: 'fistname'
  //     },
  //     {
  //       data_type: 'number',
  //       field_name: 'customerId'
  //     },
  //     {
  //       field_name: 'lastname',
  //       data_type: 'string'
  //     },
  //     {
  //       data_type: 'number',
  //       field_name: '111'
  //     }
  //   ]
  // }
  return res
}

const addCustomField = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/data-type/${params.option}/field`
    const response = await axios.post(
      URL,
      {
        data_type: params.data_type,
        field_name: params.field_name
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export { getFieldsList, addCustomField }
