import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_UPLOAD_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const getGroupList = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/group-data/${params}/group-name`
    const response = await axios.get(URL, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      let _wrap = Object.keys(response.data).map(key => [{ name: key, ...response.data[key] }])
      let item = []
      for (let i = 0; i < _wrap.length; i++) {
        item.push(..._wrap[i])
      }
      res = {
        status: response.status,
        data: item
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }

  return res
}

const creatGroup = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/group-data/${params.option}/group-name`
    const response = await axios.post(
      URL,
      {
        group_name: params.groupname
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createGroupMapping = async params => {
  let res = {}
  try {
    // let URL = `${REACT_APP_API_CDP}/group-data/${params.option}/group-name`
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/group-data/${params.option}/group-name/${params.groupName}`
    const response = await axios.post(
      URL,
      {
        fields: params.groupMapping
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export { getGroupList, creatGroup, createGroupMapping }
