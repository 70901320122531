import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_CUSTOMER_VIEW_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const segmentCreate = async objSegment => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/create`
    const response = await axios.post(URL, objSegment, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentDelete = async id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/delete/${id}`
    const response = await axios.delete(URL, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentUpdate = async (id, objSegment) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/update/${id}`
    const response = await axios.post(URL, objSegment, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentExportCSV = async (segmentId, segmentName, date) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/csv/${segmentId}`
    axios.get(URL, config).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${segmentName}_${date}.csv`) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentEditPreview = async objSegment => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/customerSegmnetList`
    const response = await axios.post(URL, objSegment, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentPreview = async objSegment => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/preview`
    const response = await axios.post(URL, objSegment, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getSegmentList = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/list`
    axios.defaults.withCredentials = true
    const response = await axios.get(URL, config)

    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const segmentDetail = async id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/get/${id}`
    const response = await axios.get(URL, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export {
  segmentCreate,
  segmentDelete,
  segmentUpdate,
  segmentPreview,
  segmentEditPreview,
  segmentExportCSV,
  getSegmentList,
  segmentDetail
}
