import React, { useState, useEffect } from 'react'
import { LoginBox } from './style'
import { useForm } from 'react-hook-form'
import PopupComponent from '../popup/popup'

import { forgotPassword } from '../../apis/login/api'

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState('tomrong@gmail.coms')
  const [checkEmail, setCheckEmail] = useState(false)
  const [sendEmail, setSendEmailSuccess] = useState(false)
  const [isLoadding, setLoading] = useState(false)
  const [apiError, setNotiApiError] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState
  } = useForm()

  const censoringEmail = email => {
    email = email.split('@')
    const emailName = email[0].substring(0, 3).padEnd(email[0].length, '*')
    let emailProvider = email[1].split('.')
    emailProvider[0] = emailProvider[0].replaceAll(/./g, '*')
    emailProvider = emailProvider.join('.')
    return emailName + ' ' + emailProvider
  }

  const onSubmit = async obj => {
    try {
      setLoading(true)
      setEmail(obj.email)
      let res = await forgotPassword(obj)
      if (res && res.status === 200) {
        setNotiApiError(false)
        setCheckEmail(false)
        setSendEmailSuccess(true)
      } else {
        setCheckEmail(true)
      }
      setLoading(false)
    } catch (error) {
      setNotiApiError(true)
      setTimeout(() => {
        setNotiApiError(false)
      }, 5000)
      console.log('error : ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setCheckEmail(false)
  }, [errors.email])

  return (
    <LoginBox>
      {apiError && (
        <PopupComponent
          option={{ status: 'error', msg: 'Something went wrong, please try again later.' }}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_login">
          <p className="title">Forgot Password ?</p>

          {!sendEmail ? (
            <span className="input_label">
              We will send you an email with instructions on how to reset your password.{' '}
            </span>
          ) : (
            <span className="input_label">
              An email with instructions on how to reset your password has been sent to{' '}
              <label className="-blue">{censoringEmail(email)}</label>
            </span>
          )}

          <br />
          <span className="input_label">Email Address</span>
          <input
            className={`input ${errors.email ? 'input_err' : ''}`}
            {...register('email', {
              required: 'Please enter your email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address'
              }
            })}
          />
          <label className="label_err">{errors.email?.message}</label>
          <label className="label_err">
            {checkEmail && 'No account found for this email address'}
          </label>
          <button className="btn btn_blue" type="submit">
            {isLoadding ? <div className="loader-circle"></div> : 'Change My Password'}
          </button>
          <p className="input_label -center">
            Know your password?
            <a className="-font-blue" onClick={() => (window.location.href = '/login')}>
              {' '}
              Log in
            </a>
          </p>
        </div>
      </form>
    </LoginBox>
  )
}

export default ForgotPasswordComponent
