import styled from 'styled-components'

const NavBox = styled.div`
  .arrow-down-no-m {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .arrow-right {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;

    margin-top: 8px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .sub-icon-menu.arrow-right:hover {
    border: solid #20adc2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 8px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .sub-icon-menu.arrow-down-no-m:hover {
    border: solid #20adc2;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-top: 8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .sub-text-menu {
    color: #c2cfe0;
    font-size: 16px;
  }
  .sub-text-menu:hover {
    color: #20adc2;
  }

  #outer-container {
    overflow: visible !important;
  }
  .bm-burger-button {
    display: none;
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  .-active {
    color: #20adc2;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    margin: 15px 7px;
    right: 11px !important;
    img {
      transform: rotate(${props => (props.isShowMenu ? '180deg' : '0deg')});
      -webkit-transition: 0.5s ease-in-out;
    }
  }
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 256px;
    transition: all 0.5s ease 0.5s;
    transform: translate3d(${props => (props.isShowMenu ? '100%;' : '-80%')}, 0px, 0px) !important;
    display: ${props => (props.isShowMenu ? 'hidden;' : 'block')}!important;
    overflow: auto;
    background: #00252f;

  /* General sidebar styles */
  .bm-menu {
    font-size: 1.15em;
    transition: all 0.5s ease 0.5s;
    overflow: visible !important;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    h1 {
      color: #c2cfe0;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .logo {
    padding: 0.8em;
    height: 60px;
    margin-bottom: 20px;
    img {
      width: 100px;
      height: 32px;
      margin: 10px 8px;
    }
  }

  // .profile {
  //   padding: ${props => (props.isShowMenu ? '0.8em' : '0px')};
  //   display: flex !important;
  //   margin: ${props => (props.isShowMenu ? 'initial' : '7px')};
  //   .profile_img {
  //     margin-left: ${props => (props.isShowMenu ? 'initial;' : 'auto')};
  //     width: 46px;
  //     height: 46px;
  //     background: #20adc2;
  //     border-radius: 25px;
  //   }
  //   .profile_name {
  //     display: ${props => (props.isShowMenu ? 'inline;' : 'none')};
  //     size: 18px;
  //     color: #20adc2;
  //     span {
  //       margin: 6px 15px;
  //       display: block;
  //     }
  //   }
  // }
  a {
    color: #c2cfe0;
    font-size: 16px;
  }
  .active {
    cursor: pointer;
    color: #20adc2;
  }

  li {
    display: block;
    cursor: pointer;
    height: 50px;
    span {
      margin-top: 9px;
    }
    .active {
      border-radius: 8px;
      background-color: #082c38;
      width: ${props => (props.isShowMenu ? '100%' : 'unset')};
      margin-left: -10px;
      padding: 0px 10px; // ${props => (props.isShowMenu ? '0px 10px;' : '0px')};
      // display: block;
    }
    a:hover {
      cursor: pointer;
      color: #20adc2;
      border-radius: 8px;
      background-color: #082c38;
      width: ${props => (props.isShowMenu ? '100%' : 'unset')};
      margin-left: -10px;
      padding: 0px 10px; //${props => (props.isShowMenu ? '0px 10px' : '0px')};
    }
    a {
      cursor: pointer;
      // padding-top: 7px;
      display: flex;
      float: ${props => (props.isShowMenu ? 'unset' : 'right')};
      padding-right: ${props => (props.isShowMenu ? 'unset' : '8px')};
    }
    padding: 7px 10px;
    list-style: none;
    text-align: ${props => (props.isShowMenu ? 'left;' : 'right')};
    svg {
      margin-top: -5px;
      margin-right: 5px;
    }
    label {
      font-size: 14px;
      cursor: pointer;
      margin-top: 9px;
      margin-left: 15px;
      display: ${props => (props.isShowMenu ? 'inline;' : 'none')};
    }
  }
  .-logout {
    margin-top: 20px;
    padding: 15px ${props => (props.isShowMenu ? '19px' : '0px')} 5px 19px !important;
    p {
      color: #c2cfe0;
      display: inline-block;
      margin-left: 14px;
      margin-top: 5px;
      font-size: 16px;
      display: ${props => (props.isShowMenu ? 'inline;' : 'none')};
    }
    li {
      display: flex;
      color: #c2cfe0;
    }
    li:hover {
      p {
        color: #20adc2;
      }
      cursor: pointer;
      color: #20adc2;
      border-radius: 8px;
      background-color: #082c38;
      width: ${props => (props.isShowMenu ? '253px' : 'unset')};

      padding: ${props => (props.isShowMenu ? '7px 10px' : '7px 10px')};
    }
  }
  .border-line {
    border-top: 1px solid #143541 !important;
  }
  .border-t {
    border-top: 1px solid #143541;
    margin-top: 35px;
  }
  .menu_icon {
    width: 20px;
    margin-right: 15px;
    margin-top: -5px;
  }
  .menu_list {
    padding: ${props => (props.isShowMenu ? '9px 0.8em 1em' : '0px 1px 2em')};
    // border-bottom: 1px solid #143541;
    img {
      width: 18px;
      margin: -1px 8px 0px 5px;
    }
  }

  .breadcrumb-box {
    color: #334d6e;
    margin-left: 24px;
    cursor: pointer;
  }

  .main-error {
    transition: margin 700ms;
    margin: ${props => (props.isShowMenu ? '90px 15px 30px 340px' : '90px 40px 35px 100px')};
  }
`

export { NavBox }
