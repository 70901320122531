import React, { Fragment, useState, useEffect } from 'react'
import { MemberBox } from './style'
import NavComponent from '../navigation/navigation'
import { useHistory, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import Modal from 'react-modal'
import DropdownOptions from './dropdownOptions'
import PopupComponent from '../popup/popup'
import { memberList, resendInviteMember, deleteMember } from '../../apis/member/api'
import ApexCharts from 'apexcharts'
import MemberComponent from './member'
import styled from 'styled-components'
import IntegrationsComponent from './integrations'
import AccountComponent from './account'
const Tab = styled.button`
  font-size: 14px;
  padding: 10px 27px;
  background: white;
  border: 0;
  outline: 0;
  color: #334d6e;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: -10px;
  border-radius: 1px;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #24b0c3;
    opacity: 1;
    color: #24b0c3;
    font-weight: 600;
  `}
`

const ButtonGroup = styled.div`
  display: flex;
`

// const types = ['account', 'users', 'integrations', 'billing', 'auditlog']
const types = ['account', 'users']
const MemberListComponent = props => {
  const history = useHistory()
  const { tab: activeTab } = useParams('account')

  const [notiSuccess, setNotiSuccess] = useState(false)
  const [listMember, setMemberList] = useState([])
  const [apiError, setNotiApiError] = useState(false)
  const [openDeletePopup, setDeletePopup] = useState(false)
  const [showInvite, setShowInvite] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [email, setEmail] = useState(null)

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      width: props.isShowMenu ? '250px' : 'auto'
    },
    {
      name: 'Email',
      selector: row => row.email,
      width: props.isShowMenu ? '340px' : 'auto'
    },
    {
      name: 'Role',
      selector: row => row.role,
      width: '100px'
    },
    {
      name: 'Last active',
      selector: row => row.lastActive
    },
    {
      name: '',
      selector: row => row.options,
      width: '50px'
    }
  ]

  const getMemberList = async () => {
    let res = {}
    let data = []
    let list = await memberList()
    if (list && list.status === 200) {
      let _tempData = {}
      for (let i = 0; i < list.data.length; i++) {
        _tempData = {
          name: list.data[i].firstname + ' ' + list.data[i].lastname,
          email: (
            <Fragment>
              {!list.data[i].verified ? (
                <Fragment>
                  <label className={`fix-email ${list.data[i].verified ? 'w100' : ''}`}>
                    {list.data[i].email}
                  </label>
                  <label className="-badge-pending">Invite Pending</label>
                </Fragment>
              ) : (
                <label className={`${list.data[i].verified ? 'w100 mg0' : ''}`}>
                  {list.data[i].email}
                </label>
              )}
            </Fragment>
          ),
          lastActive: list.data[i].last_active ? convertDate(list.data[i].last_active) : '',
          role: list.data[i].role,
          options: (
            <DropdownOptions
              isShowMenu={props.isShowMenu}
              resentInviteFn={() => resentInvite(list.data[i].email)}
              deleteMemberFn={() => removeMember(list.data[i].email)}
              editMemberFn={() => editMember(list.data[i].email)}
              openDeletePopup={openDeletePopup}
              id={list.data[i].email}
              isVerified={list.data[i].verified}
            />
          )
        }
        data.push(_tempData)
      }
      res = {
        data: data
      }
    }
    return data
  }

  const convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    let res = day + '/' + month + '/' + year + ', ' + time
    return res
  }

  const resentInvite = async email => {
    if (email) {
      const { status } = await resendInviteMember(email)
      if (status && status === 200) {
        setNotiSuccess(true)
        setTimeout(() => {
          setNotiSuccess(false)
        }, 5000)
      } else {
        setNotiApiError(true)
        setTimeout(() => {
          setNotiApiError(false)
        }, 5000)
      }
    } else {
      setNotiApiError(true)
      setTimeout(() => {
        setNotiApiError(false)
      }, 5000)
    }
  }

  const removeMember = async email => {
    if (email) {
      const { status } = await deleteMember(email)
      if (status && status === 200) {
        setDeletePopup(true)
        const res = await getMemberList()
        setMemberList(res)
      }
    } else {
      setNotiApiError(true)
      setTimeout(() => {
        setNotiApiError(false)
      }, 5000)
    }
  }

  const editMember = email => {
    setShowInvite(true)
    setIsCreate(false)
    setEmail(email)
    // history.push(`/setting/generals/${email}/edit`)
  }

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getMemberList()
    setMemberList(res)
  }, [])

  useEffect(async () => {
    window.scrollTo(0, 0)
    const res = await getMemberList()
    setMemberList(res)
  }, [showInvite])

  useEffect(async () => {
    const res = await getMemberList()
    setMemberList(res)
  }, [props.isShowMenu])

  const [active, setActive] = useState(types[0])

  const handleTab = tab => {
    setActive(tab[0])
    history.push(`/setting/generals/${tab}`)
  }

  const showInvitePage = option => {
    setShowInvite(true)
    if (option === 'create') {
      // history.push('/setting/generals/users/invite')
      setIsCreate(true)
    } else {
      setIsCreate(false)
    }
  }
  return (
    <MemberBox isShowMenu={props.isShowMenu}>
      {notiSuccess && (
        <PopupComponent option={{ status: 'success', msg: 'Invitation has been sent' }} />
      )}
      {apiError && (
        <PopupComponent
          option={{ status: 'error', msg: 'Something went wrong, please try again later.' }}
        />
      )}

      <section className="main">
        {/* <textarea maxLength={character} onChange={e => calCredit(e.target.value)} />
        {count} / {character} */}
        <div className="row top-menu m-0 pt-3 pb-0">
          <div className="col-12 text-left">
            <p className="top-head-title">Setting</p>
          </div>
          <div className="col-12 text-left px-0">
            <ButtonGroup>
              {types.map(type => (
                <Tab
                  key={type}
                  active={!activeTab || activeTab === type}
                  onClick={() => handleTab(type)}
                >
                  <span className="-capitalize">{type}</span>
                </Tab>
                // <Tab key={type} active={active === type} onClick={() => setActive(type)}>
                //   {type}
                // </Tab>
              ))}
            </ButtonGroup>
            <p />
          </div>
        </div>
        <div className="py-4 px-4">
          {activeTab === 'account' || !activeTab ? <AccountComponent /> : ''}
          {/* {activeTab === 'billing' ? <h1>{activeTab}</h1> : ''}
          {activeTab === 'auditLog' ? <h1>{activeTab}</h1> : ''} */}
          {activeTab === 'users' ? (
            !showInvite ? (
              <div className="card">
                <div className="card-header bg-white py-0 px-0 ">
                  <div className="row w-100">
                    <div className="col-6 p-2">
                      <p className=" title py-1 m-0 px-4 line-high-2 -capitalize">{activeTab}</p>
                    </div>
                    <div className="col-6 p-2 text-right top-3">
                      <a
                        className="btn-basic bg-darkgreen font-white"
                        onClick={() => showInvitePage('create')}
                      >
                        {/* onClick={() => history.push('/setting/generals/invite')} */}
                        <img src="/assets/icons/create-white.png" className="w-px-14 mx-1" />
                        Invite Member
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <DataTable
                    pagination
                    className="-fadeIn mt-0"
                    center={true}
                    columns={columns}
                    data={listMember}
                    // defaultSortDesc={true}
                  />
                </div>
              </div>
            ) : (
              <MemberComponent setShowInviteFn={setShowInvite} isCreate={isCreate} email={email} />
              // <Fragment>invite</Fragment>
            )
          ) : (
            ''
          )}
        </div>
      </section>
    </MemberBox>
  )
}

export default MemberListComponent
