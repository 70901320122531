import React, { Fragment, useState, useEffect, useRef } from 'react'
import { ConnectGoogleStorageBox, DropdownBox } from './style'
import { useHistory } from 'react-router-dom'
import DataMappingComponent from '../dataMapping/mapping'

import {
  getServiceAccount,
  connectBucketGoogle,
  previewDataGoogleStorage
} from '../../../apis/upload/api'

import 'antd/dist/antd.css'
import { DatePicker } from 'antd'

const ConnectGoogleStorageComponent = props => {
  const ref = useRef()
  const [isConnect, setStep] = useState(true)
  const [fileName, setFileName] = useState()
  const [bucketName, setBucketName] = useState()
  const [folderPath, setFolderPath] = useState()
  const [isTableLoading, setIsTableLoading] = useState(false)

  const [connectPass, setConnectPass] = useState(false)
  const [validateBucketName, setValidateBucketName] = useState(true)
  const [validateFolderPath, setValidateFolderPath] = useState(true)

  const [previewPass, setPreviewPass] = useState(false)
  const [fileData, setFileData] = useState()

  const [previewError, setPreviewError] = useState('')

  const [objSchedule, setObjSchedule] = useState()

  const [schedule, setSchedule] = useState()
  const [timeSchedule, setTimeSchedule] = useState()
  const [repeatTime, setRepeatTime] = useState()
  const [day, setDay] = useState()
  const [date, setDate] = useState()

  const [dataSetId, setDataSetId] = useState()
  const [tableId, setTableId] = useState()
  const [dataSet, setDataSet] = useState(null)
  const [tableList, setTableList] = useState()

  const [openDropdownDataSet, setOpenDropdownDataSet] = useState(false)
  const [openDropdownTable, setOpenDropdownTable] = useState(false)
  const [openDropdownTimeSet, setOpenDropdownTimeSet] = useState(false)
  const [openDropdownRepeatTimeSet, setOpenDropdownRepeatTimeSet] = useState(false)
  const [openDropdownDaySet, setOpenDropdownDaySet] = useState(false)
  const [openDropdownDateSet, setOpenDropdownDateSet] = useState(false)

  const [handlingNotfound, setHandlingNotfound] = useState(false)
  const [handlingDefault, setHandlingDefault] = useState(true)

  const [objTask, setObjTask] = useState()

  const [errorMsg, setErrorMsg] = useState()

  const [column, setColumn] = useState()
  const [rows, setRow] = useState()

  const history = useHistory()

  const [getEmailService, setEmailService] = useState()

  useEffect(async () => {
    const { data, status } = await getServiceAccount()
    if (data && status === 200) {
      setEmailService(data.data.service_account)
    } else {
      console.log('getServiceAccount Error')
    }
  }, [])

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      // callback()
    } else {
      handleCloseAll()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  const setSyncSchedule = () => {
    let obj = {}
    let now = new Date()
    now.setHours(timeSchedule.index, 0, 0)
    let utc_hours = now.getUTCHours()
    if (utc_hours < 10) {
      utc_hours = '0' + utc_hours + ':00'
    } else {
      utc_hours = utc_hours + ':00'
    }
    if (repeatTime === 'Only once') {
      let startDate = schedule._d
      startDate.setHours(timeSchedule.index, 0, 0)
      obj = {
        type: repeatTime.toLowerCase(),
        schedule: startDate
      }
    } else if (repeatTime === 'Daily') {
      obj = {
        type: repeatTime.toLowerCase(),
        time: utc_hours
      }
    } else if (repeatTime === 'Weekly') {
      obj = {
        type: repeatTime.toLowerCase(),
        time: utc_hours,
        day_of_type_schedule: day.index
      }
    } else {
      obj = {
        type: repeatTime.toLowerCase(),
        time: utc_hours,
        day_of_type_schedule: date
      }
    }
    setObjSchedule(obj)
  }

  const setStepFn = () => {
    setSyncSchedule()
    props.setStepMapFn()
    setStep(false)
  }

  const connect = async () => {
    if (!fileName) {
      setErrorMsg('Please enter File name.')
      setConnectPass(false)
    } else if (!bucketName) {
      setErrorMsg('Please enter bucket name.')
      setValidateBucketName(false)
      setConnectPass(false)
    } else {
      let obj = {
        bucket_name: bucketName,
        path_folder: folderPath
      }
      const { data, status } = await connectBucketGoogle(obj)
      if (data && status === 200) {
        setValidateBucketName(true)
        setValidateFolderPath(true)
        setConnectPass(true)
        let res = []
        let _temp = {}
        let fileList = data.data
        if (fileList.length !== 0) {
          for (let i = 0; i < fileList.length; i++) {
            _temp = {
              checked: false,
              file_name: fileList[i]
            }
            res.push(_temp)
          }
          setFileData(res)
        } else {
          setConnectPass(false)
          setErrorMsg('Error : The directory is empty.')
          setFileData([])
        }
      } else {
        setErrorMsg(data)
        setConnectPass(false)
        setValidateBucketName(false)
        setValidateFolderPath(false)
      }
    }
  }

  const handleCloseAll = () => {
    setOpenDropdownDataSet(false)
    setOpenDropdownTable(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const setValueRepeatTime = time => {
    setRepeatTime(time)
    // setValidateRepeatTime(true)
    handleCloseAll()
  }

  const setValueTime = (time, index) => {
    let obj = {
      time,
      index
    }
    setTimeSchedule(obj)
    // setValidateTimeSchedule(true)
    handleCloseAll()
  }

  const setValueDay = (day, index) => {
    let obj = {
      day,
      index
    }
    // setValidateDay(true)
    setDay(obj)
    handleCloseAll()
  }
  const setValueDate = date => {
    // setValidateValueDate(true)
    setDate(date)
    handleCloseAll()
  }

  let numberTime = []
  const repeatTimes = ['Only once', 'Daily', 'Weekly', 'Monthly']
  const days = [' Sunday', ' Monday', ' Tuesday', ' Wednesday', ' Thursday', ' Friday', ' Saturday']
  let dates = []
  for (let x = 1; x < 32; x++) {
    dates.push(x)
  }
  for (let t = 0; t < 24; t++) {
    numberTime.push((t < 10 ? '0' + t : t) + ':00')
  }
  const UseSetOpenDropdownDataSet = open => {
    setOpenDropdownDataSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownTable = open => {
    setOpenDropdownTable(open)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownRepeatTimeSet = open => {
    setOpenDropdownRepeatTimeSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownDaySet = open => {
    setOpenDropdownDaySet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownTimeSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownTimeSet = open => {
    setOpenDropdownTimeSet(open)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownDateSet = open => {
    setOpenDropdownDateSet(open)
    setOpenDropdownTimeSet(false)
    setOpenDropdownTable(false)
    setOpenDropdownDataSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const handleChecked = event => {
    let checkedState = fileData
    const updatedCheckedState = checkedState.map(item =>
      item.file_name === event.target.name
        ? { file_name: item.file_name, checked: !item.checked }
        : { file_name: item.file_name, checked: item.checked }
    )
    setFileData(updatedCheckedState)
  }

  const previewData = async () => {
    const temp = fileData
    setIsTableLoading(true)
    let getFile = temp.filter(item => item.checked)
    let obj = {
      filename: fileName, //mockup
      bucket_name: bucketName,
      path_folder: folderPath,
      file_lists: getFile
    }
    setObjTask(obj)
    const { data, status } = await previewDataGoogleStorage(obj)
    if (data && status === 200) {
      setTimeout(() => {
        setPreviewError('')
        setPreviewPass(true)
        setIsTableLoading(false)
        setColumn(data.data.metadata.table_schema.field)
        setRow(data.data.row)
      }, 1500)
    } else {
      setColumn([])
      setRow([])
      setIsTableLoading(false)
      setPreviewError(data)
      setPreviewPass(false)
      setHandlingDefault(false)
      console.log('previewDataGoogleStorage Error : ', data)
    }
  }

  const DropdownRepeatTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownRepeatTimeSet(!openDropdownRepeatTimeSet)}
        >
          <p className="text-dropdown">
            {repeatTime ? (
              repeatTime
            ) : (
              <span className="head-sub-title m-0 p-0">Select repeat time</span>
            )}
          </p>
          <i className={`arrow-${openDropdownRepeatTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownRepeatTimeSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {repeatTimes.map((listrepeatTime, i) => {
                  return (
                    <li key={i} onClick={() => setValueRepeatTime(listrepeatTime)}>
                      {listrepeatTime}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDateSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownDateSet(!openDropdownDateSet)}
        >
          <p className="text-dropdown">
            {date ? date : <span className="head-sub-title m-0 p-0">Select date</span>}
          </p>
          <i className={`arrow-${openDropdownDateSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDateSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {dates.map((listdates, i) => {
                  return (
                    <li key={i} onClick={() => setValueDate(listdates)}>
                      {listdates}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDaySet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field date-box`}
          onClick={() => UseSetOpenDropdownDaySet(!openDropdownDaySet)}
        >
          <p className="text-dropdown">
            {day ? day.day : <span className="head-sub-title m-0 p-0">Select day of week</span>}
          </p>
          <i className={`arrow-${openDropdownDaySet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDaySet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-md">
              <ul>
                {days.map((listdays, i) => {
                  return (
                    <li key={i} onClick={() => setValueDay(listdays, i)}>
                      {listdays}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field time-box`}
          onClick={() => UseSetOpenDropdownTimeSet(!openDropdownTimeSet)}
        >
          <p className="text-dropdown">
            {timeSchedule ? (
              timeSchedule.time
            ) : (
              <span className="head-sub-title m-0 p-0">00:00</span>
            )}
          </p>
          <i className={`arrow-${openDropdownTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownTimeSet && (
          <div className="bottom-height">
            <div className="box-dropdown box-dropdown-small">
              <ul>
                {numberTime.map((listTime, i) => {
                  return (
                    <li key={i} onClick={() => setValueTime(listTime, i)}>
                      {listTime}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const TableSample = () => {
    return (
      <Fragment>
        {column && column.length !== 0 ? (
          <div className="text-center">
            <div className="scroll-x">
              <table>
                <thead>
                  <tr>
                    {column &&
                      column.map((item, i) => {
                        return <th key={i}>{item.name}</th>
                      })}
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((row, i) => {
                      return (
                        <tr key={i}>
                          {Object.keys(row).map((list, x) => {
                            return row[list] && typeof row[list] !== 'object' ? (
                              <td key={x}>{row[list]}</td>
                            ) : (
                              <td key={x}>-</td>
                            )
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Fragment>
            {handlingDefault && <HandlingDefault />}
            {handlingNotfound && <HandlingNotfound />}
            {previewError && <PreviewError />}
          </Fragment>
        )}
      </Fragment>
    )
  }

  const Loading = () => {
    return (
      <div className="row">
        <div className="text-center main-loadding">
          <div className="overlay-loadding">
            <img className="icon-loadding" src="/assets/images/flipball.gif" />
          </div>

          <img className="w-50 p-3 mx-2" src={`/assets/images/SampleData.png`} alt="logo" />
          <p className="title-type">Please click “Generate preview” to see the sample data</p>
        </div>
      </div>
    )
  }

  const HandlingNotfound = () => {
    return (
      <div className="text-center">
        <img className="w-40 p-3 mx-2" src={`/assets/images/notfound.svg`} alt="logo" />
        <p className="handling w-40 mx-auto">
          Quota exceeded: Your project exceeded quota for free query bytes scanned. For more
          information, see
          <span> https://cloud.google.com/bigquery/docs/troubleshoot-quotas</span>
        </p>
      </div>
    )
  }

  const HandlingDefault = () => {
    return (
      <div className="text-center">
        <img className="w-50 p-3 mx-2" src={`/assets/images/SampleData.png`} alt="logo" />
        <p className="handling w-75 mx-auto">
          Please click “Generate preview” to see the sample data
        </p>
      </div>
    )
  }

  const PreviewError = () => {
    return (
      <div className="text-center">
        <img className="w-50 p-3 mx-2" src={`/assets/images/notfound.svg`} alt="logo" />
        <p className="handling w-75 mx-auto ">
          <p className="handling-no-match mb-0">
            Oops! <br></br>
            <span>We can’t show the sample data </span>
          </p>
          <p className="-desc">
            The file you selected contains columns with different values. <br /> Please check your
            file and select only files with matching column values.
          </p>
        </p>
      </div>
    )
  }
  return isConnect ? (
    <ConnectGoogleStorageBox isShowMenu={props ? props.isShowMenu : true} ref={ref}>
      <div className="section-main">
        <p className="text-left btn-back" onClick={() => props.nextStepFn('selectSource')}>
          {' '}
          <i className="arrow-left"> </i> Back
        </p>
        <h2 className="head-title text-left">Connect to Google Cloud Storage</h2>

        <div></div>

        <p className="head-sub-title text-left">
          Follow the steps below to start setting up Google Cloud Storage.
        </p>
        <ul className="progress-vertical vertical">
          <li data-step="1" className="active step">
            <div className="px-3">
              <p className="title md text-left mb-2">Connection Name</p>
              <div className="form-group">
                <input
                  type="text"
                  className="input w-400"
                  placeholder="Connection Name"
                  onChange={e => setFileName(e.target.value)}
                />
              </div>
            </div>
          </li>
          <li data-step="2" className="active step">
            <div className="px-3">
              <p className="title md text-left mb-2">Connect your Google Cloud Storage</p>
              <p className="text-extra-small">
                To import customer data from Google Cloud Storage, you need to give us permission to
                access to your bucket by adding <span className="-blue">{getEmailService}</span>{' '}
                with the role Storage Object Admin.
              </p>
              <p className="title text-left small">Bucket name</p>
              <p className="text-extra-small">Name of your Google Could Storage Bucket.</p>
              <div className="form-group">
                <input
                  type="text"
                  className="input w-400"
                  placeholder="Bucket name"
                  onChange={e => setBucketName(e.target.value)}
                  // disabled={connectPass ? true : false}
                />
              </div>
              <p className="title text-left small">Folder path (Optional)</p>
              <p className="text-extra-small">
                The path to the folder in your google cloud storage bucket where your data is
                stored.
              </p>
              <div className="form-group">
                <input
                  type="text"
                  className="input w-400"
                  placeholder="Folder path"
                  onChange={e => setFolderPath(e.target.value)}
                  // disabled={connectPass ? true : false}
                />
              </div>
              <div className="row">
                <div className="col-auto my-3 py-1">
                  <button
                    type="button"
                    value="Connect"
                    className={`btn-basic bg-darkblue font-white border-0`}
                    onClick={() => connect()}
                  >
                    Connect
                  </button>
                </div>
                <div className="col-auto p-2 my-3">
                  <p id="results" className={connectPass ? 'd-block -fadeIn text-dark' : 'd-none'}>
                    <img
                      className="correctValidation mx-2"
                      src={`/assets/icons/correctValidation.png`}
                    />
                    Google Cloud Storage Connected
                  </p>
                  <p
                    id="results"
                    className={connectPass ? 'd-none' : 'd-block -fadeIn text-danger'}
                  >
                    {errorMsg}
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li data-step="3" className={connectPass ? 'active step' : 'step'}>
            <div className="px-3">
              <div className="row">
                <div className="col-4">
                  <p className="title md text-left mb-2">Choose your data</p>

                  <div className={`card card-border ${connectPass ? 'd-block -fadeIn' : 'd-none'}`}>
                    <div className="card-header p-0 px-3">
                      <table>
                        <thead>
                          <tr className="bg-white">
                            <td className="w-10">
                              <img className="" src={`/assets/images/file.png`} alt="logo" />
                            </td>
                            <td className="title text-left small p-0">customer profile</td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div className="card-body p-0 px-3">
                      <table>
                        <tbody>
                          {fileData &&
                            fileData.map((filelists, i) => {
                              return (
                                <tr key={i}>
                                  <td className="w-10">
                                    <label className="container-checkbox m-0">
                                      <input
                                        type="checkbox"
                                        name={filelists.file_name}
                                        checked={filelists.checked}
                                        onChange={handleChecked}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </td>
                                  <td> {filelists.file_name} </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      <button
                        className="btn-basic bg-darkblue font-white w-100 border-0 "
                        onClick={() => previewData()}
                      >
                        Generate preview
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className={connectPass ? 'd-block -fadeIn' : 'd-none'}>
                    <p className="title md text-left mb-2">Sample Data</p>
                    <section>{isTableLoading ? <Loading /> : <TableSample />}</section>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li data-step="4" className={previewPass ? 'active step' : 'step'}>
            <div className="px-3">
              <p className="title md text-left mb-2">Sync schedule</p>
              <div className={previewPass ? 'd-block -fadeIn' : 'd-none'}>
                {/* Only once = /Date + /Time
              Daily = /Time + /Load incremental data by
              Weekly = /Day of week(ex Sunday) + /Time + Load incremental data by
              Monthly = On the + /Time + Load incremental data by
               */}

                <div className="row">
                  <div className="col-auto">
                    <div className="form-group">
                      <label htmlFor="" className="title text-left small label-head">
                        Repeat
                      </label>

                      <DropdownRepeatTimeSet />
                    </div>
                  </div>
                  <div className={repeatTime === 'Only once' ? 'd-block col-auto' : 'd-none'}>
                    <div className="form-group">
                      <label htmlFor="" className="title text-left small label-head">
                        Date
                      </label>
                      <Fragment>
                        <div className={`inner-segment`}>
                          <DatePicker
                            className={`box_field date-box`}
                            value={schedule}
                            onChange={e => setSchedule(e)}
                          />
                        </div>
                      </Fragment>
                    </div>
                  </div>
                  <div className={repeatTime === 'Weekly' ? 'd-block col-auto' : 'd-none'}>
                    <label className="title text-left small label-head">Day of week</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownDaySet />
                      </div>
                    </form>
                  </div>
                  <div className={repeatTime === 'Monthly' ? 'd-block col-auto' : 'd-none'}>
                    <label className="title text-left small label-head">On the</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownDateSet />
                      </div>
                    </form>
                  </div>

                  <div
                    className={
                      repeatTime === 'Only once' ||
                      repeatTime === 'Daily' ||
                      repeatTime === 'Weekly' ||
                      repeatTime === 'Monthly'
                        ? 'd-block col-auto'
                        : 'd-none'
                    }
                  >
                    <label className="title text-left small label-head">Time</label>
                    <form className="form-inline">
                      <div className="form-group">
                        <DropdownTimeSet />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="section-footer">
        <div className="col-6 align-left">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => props.nextStepFn('selectType')}>
            Previous
          </a>
        </div>
        <div className="col-6 align-right">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2" onClick={() => history.push('/imports')}>
            Cancel
          </a>
          <a
            className={`btn-basic 
            ${
              previewPass
                ? repeatTime === 'Only once'
                  ? timeSchedule && schedule
                    ? 'bg-darkgreen font-white'
                    : 'bg-gray font-white'
                  : repeatTime === 'Daily'
                  ? timeSchedule
                    ? 'bg-darkgreen font-white'
                    : 'bg-gray font-white'
                  : repeatTime === 'Weekly'
                  ? timeSchedule && day
                    ? 'bg-darkgreen font-white'
                    : 'bg-gray font-white'
                  : repeatTime === 'Monthly'
                  ? timeSchedule && date
                    ? 'bg-darkgreen font-white'
                    : 'bg-gray font-white'
                    ? 'bg-darkgreen font-white'
                    : 'bg-gray font-white'
                  : 'bg-gray font-white'
                : 'bg-gray font-white'
            }`}
            onClick={() =>
              previewPass
                ? repeatTime === 'Only once'
                  ? timeSchedule && schedule
                    ? setStepFn()
                    : ''
                  : repeatTime === 'Daily'
                  ? timeSchedule
                    ? setStepFn()
                    : ''
                  : repeatTime === 'Weekly'
                  ? timeSchedule && day
                    ? setStepFn()
                    : ''
                  : repeatTime === 'Monthly'
                  ? timeSchedule && date
                    ? setStepFn()
                    : ''
                  : ''
                : ''
            }
          >
            Next
          </a>
        </div>
      </div>
    </ConnectGoogleStorageBox>
  ) : (
    <DataMappingComponent
      isType={'gcs'}
      objTask={objTask}
      objSchedule={objSchedule}
      option={props}
      column={column}
      rows={rows}
      setStepFn={setStep}
    />
  )
}

export default ConnectGoogleStorageComponent
