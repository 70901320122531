import React, { Fragment, useState, useEffect, useRef } from 'react'
import { SelectSourceBox, ProgressbarBox, FooterBox } from './style'
import SmsCampaignComponent from './sms/smsCampaign'

import { useHistory, useParams } from 'react-router-dom'

const SelectSourceComponent = props => {
  const [selectSource, setSelectSource] = useState()
  const [dataType, setDataType] = useState()
  const [step, setStep] = useState(1)
  const [pass, setPass] = useState(false)
  const [isSelectType, setIsSelectType] = useState(false)
  const { isCreate } = props
  const { id: ref_id } = useParams()
  const history = useHistory()
  useEffect(() => {
    if (ref_id) {
      setStep(3)
    }
  }, [])

  const nextStep = () => {
    if (step === 1) {
      if (dataType) {
        history.push('/campaign/create?step=typeSource')
        setStep(2)
        setPass(false)
      }
    } else if (step === 2) {
      if (selectSource) {
        history.push('/campaign/create?step=smsCampaign')
        setStep(3)
        // setPass(true)
      }
    }
  }
  const ProgressbarType = step => {
    return (
      <ProgressbarBox>
        <div className="box">
          <div className="section-progress">
            <ul className="progressbar">
              <li className="active">
                <span>Select type</span>
              </li>
              <li className="">
                <span>Select channel</span>
              </li>
              <li className="">
                <span>Campaign</span>
              </li>
            </ul>
          </div>
        </div>
      </ProgressbarBox>
    )
  }
  const ProgressbarSource = step => {
    return (
      <ProgressbarBox>
        <div className="box">
          <div className="section-progress">
            <ul className="progressbar">
              <li className="step-pass">
                <span>Select type</span>
              </li>
              <li className="active">
                <span>Select channel</span>
              </li>
              <li className="">
                <span>Campaign</span>
              </li>
            </ul>
          </div>
        </div>
      </ProgressbarBox>
    )
  }

  const selectDataType = type => {
    setDataType(type)
    setPass(true)
  }

  const selectSourceType = type => {
    setSelectSource(type)
    setPass(true)
  }

  const ListTypeCampaign = () => {
    return (
      <Fragment>
        <p className="title my-4">Select a Campaign Type</p>
        <div className="section-body">
          <div
            className={`list-type-box ${dataType === 'one_time' ? 'list-type-box-active' : ''}`}
            onClick={() => selectDataType('one_time')}
          >
            <div className="section-tooltip">
              <div className="tooltip">
                <img className="icon-tooltip" src="/assets/icons/tooltips.png" alt="information" />
              </div>
            </div>
            <div className="list-icon mb-3">
              <img src="/assets/icons/onetime.png" alt="predictive" />
            </div>
            <p className="title-type">One-time</p>
            <p className="desc-type">Send the campaign only once at a specified date and time</p>
          </div>
          <div
            className={`list-type-box ${dataType === 'recurring' ? 'list-type-box-active' : ''}`}
            onClick={() => selectDataType('recurring')}
          >
            <div className="section-tooltip">
              <div className="tooltip">
                <img className="icon-tooltip" src="/assets/icons/tooltips.png" alt="information" />
              </div>
            </div>
            <div className="list-icon mb-3">
              <img src="/assets/icons/recurring.png" alt="predictive" />
            </div>
            <p className="title-type">Recurring</p>
            <p className="desc-type">To automatically send recurring as per set schedule</p>
          </div>
        </div>
      </Fragment>
    )
  }
  const TypeSourceCampaign = () => {
    return (
      <Fragment>
        <p className="title mt-4 mb-3">Select data source</p>
        <p className="sub-title mb-5">
          Select channel to send a{' '}
          <span> “{dataType === 'one_time' ? 'One-time' : 'Recurring'}” </span>campaign
        </p>
        <div className="section-select-source">
          <div
            className={`box-select-source ${
              selectSource === 'SMS' ? 'box-select-source-active' : ''
            }`}
            onClick={() => selectSourceType('SMS')}
          >
            <div className="box-image-source">
              <img src="/assets/icons/sms.png" />
            </div>
            <div className="box-name-source">
              <p>SMS</p>
              <label>Send SMS to customers from selected segments</label>
            </div>
          </div>
          <div className="box-select-source -disable">
            <div className="box-image-source">
              <img src="/assets/icons/gmail.png" />
            </div>
            <div className="box-name-source">
              <p>Email</p>
              <label>Send Email to customers from selected segments</label>
            </div>
          </div>
          <div className="box-select-source -disable">
            <div className="box-image-source">
              <img src="/assets/icons/fb.png" />
            </div>
            <div className="box-name-source">
              <p>Facebook</p>
              <label>Send Facebook to customers from selected segments</label>
            </div>
          </div>
        </div>
        <div className="section-select-source ">
          <div className="box-select-source -disable">
            <div className="box-image-source">
              <img src="/assets/icons/gga.png" />
            </div>
            <div className="box-name-source">
              <p>Google Ads</p>
              <label>Send Google Ads to customers from selected segments</label>
            </div>
          </div>
          <div className="box-select-source -disable">
            <div className="box-image-source">
              <img src="/assets/icons/line.png" />
            </div>
            <div className="box-name-source">
              <p>Line</p>
              <label>Send Line to customers from selected segments</label>
            </div>
          </div>
          <div className="box-select-source -disable">
            <div className="box-image-source">
              <img src="/assets/icons/ap.png" />
            </div>
            <div className="box-name-source">
              <p>App Push</p>
              <label>Send App Push to customers from selected segments</label>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
  const FooterCampaign = () => {
    return (
      <FooterBox>
        <div className="section-footer">
          <div className="col-6 align-left"></div>
          <div className="col-6 align-right">
            <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2" onClick={() => history.push('/campaign')}>
              Cancel
            </a>
            <a
              onClick={() => nextStep()}
              className={`btn-basic ${pass ? 'bg-darkgreen font-white' : 'bg-gray font-white'}`}
            >
              Next
            </a>
          </div>
        </div>
      </FooterBox>
    )
  }
  return (
    <SelectSourceBox isShowMenu={props.isShowMenu}>
      {step === 1 ? (
        <div>
          <div className="breadcrumb-box">
            <p className="navigation-bar mt-3">
              Campaign<span> / Create new</span>
            </p>
          </div>
          <ProgressbarType />
          <div className="section-main">
            <ListTypeCampaign />
          </div>
          <FooterCampaign />
        </div>
      ) : step === 2 ? (
        <div>
          <div className="breadcrumb-box">
            <p className="navigation-bar mt-3">
              Campaign<span> / Create new</span>
            </p>
          </div>
          <ProgressbarSource />
          <div className="section-main">
            <TypeSourceCampaign />
          </div>
          <FooterCampaign />
        </div>
      ) : (
        <SmsCampaignComponent
          setStepFn={setStep}
          dataType={dataType}
          masterSegment={props.masterSegmentList}
        />
      )}
    </SelectSourceBox>
  )
}

export default SelectSourceComponent
