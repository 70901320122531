import React, { Fragment, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PopupBox } from './style'
const PopupComponent = props => {
  const [openPopup, setOpenPopup] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setOpenPopup(false)
    }, 5000)
  }, [])

  return (
    <PopupBox>
      {openPopup && (
        <div className={`noti_popup ${props.option.status === 'error' ? 'noti_popup_error' : ''}`}>
          <div className="close_noti_popup" onClick={() => setOpenPopup(false)}></div>
          <div className="-left">
            {props.option.status === 'error' ? (
              <img className="ico-warning" src="/assets/icons/warning.png" alt="warning" />
            ) : (
              <div className={`checkmark checkmark-green`}></div>
            )}
          </div>
          <div className="-right">
            {props.option.status === 'error' ? (
              <Fragment>
                <b>Sorry</b>
                <br />
                <span>{props.option.msg}</span>
              </Fragment>
            ) : (
              <Fragment>
                <b>Successfully</b>
                <br />
                <span>{props.option.msg}</span>
              </Fragment>
            )}
          </div>
        </div>
      )}
    </PopupBox>
  )
}

export default PopupComponent
