import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { RFMBox, DropdownBox, LoaddingBox } from './style'
import { getFieldsList } from '../../apis/mapping/api'
import { analyzeRFM, getRFM, pullingRFM } from '../../apis/dataActivation/api'
import TreemapComponent from './treemap'
import Modal from 'react-modal'
const CreateRmfComponent = props => {
  const ref = useRef()

  const [fieldsList, setFieldsList] = useState(null)

  const [recency, setRecency] = useState(null)

  const [frequency, setFrequency] = useState(null)

  const [monetary, setMonetary] = useState(null)

  const [period, setPeriod] = useState(null)
  const [periodValue, setPeriodValue] = useState(null)

  const [refID, setRefID] = useState()
  const [rfmStatus, setRFMStatus] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [resourceRFM, setResourceRFM] = useState(null)

  const [openDropdownRecency, setOpenDropdownRecency] = useState(false)
  const [openDropdownFrequency, setOpenDropdownFrequency] = useState(false)
  const [openDropdownMonetary, setOpenDropdownMonetary] = useState(false)
  const [openDropdownPeriod, setOpenDropdownPeriod] = useState(false)
  const [openPopupFalse, setOpenPopupFalse] = useState(false)

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      borderRadius: '10px',
      display: 'inline-table',
      padding: '20px',
      border: 'solid 1px #aabaca !important'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  const handleCloseAll = () => {
    setOpenDropdownRecency(false)
    setOpenDropdownFrequency(false)
    setOpenDropdownMonetary(false)
    setOpenDropdownPeriod(false)
  }

  const history = useHistory()

  useEffect(async () => {
    const { status, data } = await getFieldsList('event')
    if (data && status === 200) {
      let wrap = data.filter(item => item.data_type === 'number' || item.data_type === 'date')
      setFieldsList(wrap)
    }
  }, [])

  useEffect(async () => {
    if (refID) {
      setIsLoading(true)
      const interval = setInterval(async () => {
        let res = await loopPulling(refID)
        if (
          (res && res.status && res.status === 'created') ||
          res.status === 'RFM Features Extraction 1: In Progress' ||
          res.status === 'RFM Features Extraction 2: In Progress'
        ) {
          setRFMStatus(res.status)
        } else if (res && res.status && res.status === 'success') {
          setRFMStatus(res.status)
          setResourceRFM(res)
          clearInterval(interval)
          setIsLoading(false)
        } else if (res && res.status && res.status === 'failed') {
          setOpenPopupFalse(true)
          setResourceRFM(null)
          setRFMStatus(res.status)
          clearInterval(interval)
          setIsLoading(false)
        }
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [refID])

  const ModalMessageError = () => {
    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupFalse}
        onRequestClose={() => setOpenPopupFalse(false)}
        style={customStyles}
      >
        <div className="close px-2" onClick={() => setOpenPopupFalse(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        <div className="body-popup">
          <div className="px-5 text-center">
            <img src="/assets/icons/fail.png" className="px-5 w-50" />

            <p className="f-16 font-weight-bold font-darkpink mt-3">RFM Error Occurs</p>
            <p className="sub-title-12 text-secondary">
              RFM cannot analyze your customer data.<br></br>
              Please recheck your data source and its setting.
            </p>
          </div>

          <div className="footer-popup text-center mb-3">
            <a
              onClick={() => setOpenPopupFalse(false)}
              className=" btn-basic  bg-darkpink font-white"
            >
              Close
            </a>
          </div>
        </div>
      </Modal>
    )
  }

  const loopPulling = async ref_id => {
    let res = {}
    let { data, status } = await pulling(ref_id)
    if (data && status === 200) {
      res = data.data
    }

    return res
  }

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      // callback()
    } else {
      setOpenDropdownRecency(false)
      setOpenDropdownFrequency(false)
      setOpenDropdownMonetary(false)
      setOpenDropdownPeriod(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  const setRecencyValue = Recency => {
    setRecency(Recency)
    handleCloseAll()
  }
  const setFrequencyValue = Frequency => {
    setFrequency(Frequency)
    handleCloseAll()
  }

  const setMonetaryValue = Monetary => {
    setMonetary(Monetary)
    handleCloseAll()
  }

  // const setPeriodValue = () => {
  //   // setPeriod(period)
  //   handleCloseAll()
  // }

  const periodData = (data, periodValue) => {
    setPeriod(data)
    setPeriodValue(periodValue)
    handleCloseAll()
  }

  const UseSetOpenDropdownsetRecencyset = open => {
    setOpenDropdownRecency(open)
    setOpenDropdownFrequency(false)
    setOpenDropdownMonetary(false)
    setOpenDropdownPeriod(false)
  }
  const UseSetOpenDropdownsetFrequencyset = open => {
    setOpenDropdownFrequency(open)
    setOpenDropdownRecency(false)
    setOpenDropdownMonetary(false)
    setOpenDropdownPeriod(false)
  }
  const UseSetOpenDropdownsetMonetaryset = open => {
    setOpenDropdownMonetary(open)
    setOpenDropdownRecency(false)
    setOpenDropdownFrequency(false)
    setOpenDropdownPeriod(false)
  }
  const UseSetOpenDropdownsetPeriodset = open => {
    setOpenDropdownPeriod(open)
    setOpenDropdownRecency(false)
    setOpenDropdownFrequency(false)
    setOpenDropdownMonetary(false)
  }

  const DropdownRecency = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  time-box`}
          onClick={() => UseSetOpenDropdownsetRecencyset(!openDropdownRecency)}
        >
          <p className="text-dropdown">
            {recency ? (
              recency
            ) : (
              <span className="head-sub-title m-0 p-0">Select Recency event</span>
            )}
          </p>
          <i className={`arrow-${openDropdownRecency ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownRecency && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {fieldsList &&
                  fieldsList.map((list, i) => {
                    return (
                      <li key={i} onClick={() => setRecencyValue(list.field_name)}>
                        {list.field_name}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }
  const DropdownFrequency = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  time-box`}
          onClick={() => UseSetOpenDropdownsetFrequencyset(!openDropdownFrequency)}
        >
          <p className="text-dropdown">
            {frequency ? (
              frequency
            ) : (
              <span className="head-sub-title m-0 p-0">Select Frequency event</span>
            )}
          </p>
          <i className={`arrow-${openDropdownFrequency ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownFrequency && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {fieldsList &&
                  fieldsList.map((list, i) => {
                    return (
                      <li key={i} onClick={() => setFrequencyValue(list.field_name)}>
                        {list.field_name}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }
  const DropdownMonetary = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  time-box`}
          onClick={() => UseSetOpenDropdownsetMonetaryset(!openDropdownMonetary)}
        >
          <p className="text-dropdown">
            {monetary ? (
              monetary
            ) : (
              <span className="head-sub-title m-0 p-0">Select Monetary event</span>
            )}
          </p>
          <i className={`arrow-${openDropdownMonetary ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownMonetary && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {fieldsList &&
                  fieldsList.map((list, i) => {
                    return (
                      <li key={i} onClick={() => setMonetaryValue(list.field_name)}>
                        {list.field_name}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const Loading = () => {
    return (
      <LoaddingBox>
        <div className="text-center main-loadding card">
          <div className="overlay-loadding">
            <img className="icon-loadding" src="/assets/images/flipball.gif" />
          </div>
        </div>
      </LoaddingBox>
    )
  }

  const periodList = [
    { data: 'Last 30 days', periodValue: 30 },
    { data: 'Last 60 days', periodValue: 60 },
    { data: 'Last 90 days', periodValue: 90 },
    { data: 'Last 180 days', periodValue: 180 },
    { data: 'Last 360 days', periodValue: 360 }
  ]

  const DropdownPeriod = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  time-box`}
          onClick={() => UseSetOpenDropdownsetPeriodset(!openDropdownPeriod)}
        >
          <p className="text-dropdown">
            {period ? period : <span className="head-sub-title m-0 p-0">Select Period</span>}
          </p>
          <i className={`arrow-${openDropdownPeriod ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownPeriod && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {periodList.map((list, i) => {
                  return (
                    <li key={i} onClick={() => periodData(list.data, list.periodValue)}>
                      {list.data}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const analyzeData = async () => {
    let obj = {
      recency_event: recency,
      frequency_event: frequency,
      monetary_event: monetary,
      period: periodValue
    }
    const { data, status } = await analyzeRFM(obj)
    if (data && status === 200) {
      setRefID(data.ref_id)
    } else {
      setOpenPopupFalse(true)
      setResourceRFM(null)
    }
  }

  const pulling = async id => {
    let res = {}
    if (id) {
      const { data, status } = await pullingRFM(id)
      if (data && status === 200) {
        res = {
          data,
          status
        }
      } else {
        res = {
          data: 'nodata',
          status: 400
        }
      }
    }
    return res
  }

  return (
    <RFMBox isShowMenu={props.isShowMenu} ref={ref}>
      <div className="main py-3 px-4">
        <div className="row">
          <div className="col-6 text-left">
            <p className="top-head-title">RFM</p>
          </div>
          {/* <div className="col-6 text-right">
            <button className="btn bg-white border-0" onClick={() => history.push('/rfm')}>
              Cancel
            </button>

            <button className="btn -active-btn">Save</button>
          </div> */}
        </div>

        <Fragment>
          {/* <div className="card mb-4">
            <div className="card-body py-5">
              <p className="title-16 m-0">RFM Name</p>
              <div className="row">
                <div className="col-5">
                  <input type="text" className="input " placeholder="Enter your RFM name" />
                </div>
              </div>
            </div>
          </div> */}
          {isLoading ? (
            <Loading />
          ) : (
            <Fragment>
              <div className="card mb-4">
                <div className="bg-white card-header">
                  <p className="title-16 my-1">Criteria</p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-auto mb-3">
                      <p className="title-16">Recency event</p>
                      <DropdownRecency />
                    </div>
                    <div className="col-auto mb-3">
                      <p className="title-16">Frequency event</p>
                      <DropdownFrequency />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto mb-3">
                      <p className="title-16">Monetary event</p>
                      <DropdownMonetary />
                    </div>
                    <div className="col-auto mb-3">
                      <p className="title-16">Period</p>
                      <DropdownPeriod />
                    </div>
                    <div className="col-12 mb-3">
                      <a
                        className={`btn-basic ${
                          recency || frequency || monetary || period
                            ? 'bg-darkblue font-white'
                            : 'bg-gray font-white'
                        }`}
                        onClick={() =>
                          recency || frequency || monetary || period ? analyzeData() : ''
                        }
                      >
                        Analyze
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {resourceRFM && (
                <div className="card mb-4">
                  <div className="card-header">
                    <p className="title-16 font-weight-normal my-1">
                      Total Users Analyzed : <span className="font-weight-bold">{resourceRFM.total_customer}</span>
                    </p>
                  </div>

                  <div className="card-body">
                    <TreemapComponent data={resourceRFM} />
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      </div>
      <ModalMessageError />
    </RFMBox>
  )
}

export default CreateRmfComponent
