import styled from 'styled-components'

const ConnectBigqueryBox = styled.div`
  :root {
    --blue: #385172;
    --light: #aabaca;
    --green: #24b0c3;
  }
  .ant-picker-suffix {
    align-self: center;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 0;
    pointer-events: none;
}
  .input:-webkit-autofill,
  .input:-webkit-autofill:hover,
  .input:-webkit-autofill:focus,
  .input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .input::placeholder{
    color:#aabaca;
  }
  .form-control:disabled,
  .form-control[readonly] {
    cursor: no-drop;
    background-color: #aabaca;
  }
  .disabled-bg-gray{
    background-color: #eef1f5 !important;
  }
  .ant-picker-suffix,.ant-picker-clear{
    margin-top: 2px!important;
  }
  .ant-picker-input{
    svg{
      margin-right:0px!important;
    }
  }
.text-gray{
  color: #aabaca;
  font-size: 12px;
}
  .ball-loadding {
    text-align: center;
    img {
      width: 80px;
      margin-left: -50px;
      margin-top: 160px;
      left: 50%;
    }
  }

    .main-loadding {
      
      width: 100%;
      height: 100%;
      .icon-loadding {
       
        z-index: 9;
        width: 80px;
      }
      .overlay-loadding {
        width: 100%;
        display: table;
        background: rgba(255, 255, 255, 0.8);
        z-index: 999;
        opacity: 1;
        padding: 11%;
        position: absolute;
        text-align:center;
      }
    }
  .input {
    outline: none;
    color: #385172;
    width: 414px;
    height: 50px;
    margin: 10px 0 0;
    padding: 15px 30px 14px 30px;
    border-radius: 10px;
    border: solid 1.5px #aabaca;
    background-color: #fff;
}
.input:focus {
  box-shadow: 0 0 6px 0 rgb(36 176 195 / 50%);
  border: solid 1.5px #24b0c3;
}
  .btn-back {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #385172;
    cursor: pointer;
    .arrow-left {
      border: solid #385172;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 5px;
      margin-left: -12px;
      position: absolute;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
  .label-head {
    margin: 10px 0 !important;
  }
}
.w70{
  width:70%;
}

.ant-picker-cell-selected{
  .ant-picker-cell-inner{
    background:#24b0c3;
  }
}
.ant-picker{
  border: solid 1px #aabaca;
  border-radius: 8px;
}
.label-head{
  margin: 10px 0 !important;
}
  .scroll-x {
    overflow-x: auto;
    border-radius: 0em;
  //  box-shadow: 4px -2px 6px 4px #f2f2f4;
  }
  .w-400 {
    width: 400px !important;
  }
  .head-title {
    font-size: 18px;
    color: #385172;
  }
  .head-sub-title {
    font-size: 14px;
    color: #aabaca;
    font-weight: normal;
  }

  li {
    height: 100% !important;
  }
  .correctValidation {
    width: 16px;
    height: 16px;
  }
  .btn-royal-blue {
    background-color: #385172;
    color: #fff;
    font-size: 14px;
  }
  .w-30 {
    width: 30% !important;
  }
  .w-40 {
    width: 40% !important;
  }
  .mt--14 {
    margin-top: -14px !important;
  }
  .section-main {
    font-family: 'Inter';
    height: 100%;
    width: 100%;
  }
  .section-main .title {
    font-family: 'Inter';
  } 

.handling{
  font-size: 14px;
  color: #6f809e;
  
  span{
    color: #24b0c3;
  }
}



  .title-type {
    font-size: 14px;
    color: #aabaca;
  }
  .sub-title {
    font-size: 14px;
    color: #aabaca;
  }
  .title.md {
    font-size: 16px;
    color: #385172;
    font-weight: 500;
  }
  .title.small {
    font-size: 14px;
    color: #385172;
    font-weight: 500;
  }

  .text-extra-small {
    font-size: 12px;
    color: #aabaca;
    font-weight: normal;
  }

  .box-progress {
    padding: 0px 18px;
    line-height: 2;
  }
  .progress-vertical {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #aabaca;
    display: table;
    table-layout: fixed;
    width: 100%;
    font-size: 0.8em;

    li.step {
      display: table-cell;
      text-align: center;
      position: relative;
    }
    li.step.active:before {
      background: #24b0c3;
    }
    li.step.active:after {
      background: #24b0c3;
    }
    li.step:before {
      position: relative;
      content: attr(data-step);
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 auto 2px;
      line-height: 30px;
      background: #aabaca;
      border-radius: 100%;
      text-align: center;
      color: #ffffff;
      z-index: 1;
    }
    li.step:after {
      position: absolute;
      width: 100%;
      height: 2px;
      display: block;
      top: 11px;
      left: -50%;
      content: '';
      background: #aabaca;
      margin-left: 2px;
      z-index: 0;
    }
    li.step:first-child:after {
      display: none;
    }
  }

  .progress-vertical.vertical {
    li.step {
      display: block;
      min-height: 44px;
      margin-left: 30px;
      text-align: left;
    }
    li.step:first-child:after {
      display: block;
    }

    li.step:last-child:after {
      display: none;
    }

    li.step.active:after {
      background: #aabaca;
    }

    li.step:after {
      width: 2px;
      height: 100%;
      display: block;
      left: -10px;
      top: 10;
      margin-left: 0;
    }
    li.step:before {
      float: left;
      display: inline-block;
      margin-left: -34px;
      position: absolute;
    }
  }
  table {
    border-collapse: collapse;
         width: 100%;
         color: #334D6E;
         font-family: Inter;
         font-size: 13px;
   
    thead {
      tr {
        background-color: #f1f5f9;
      }
    }
    tr {
      height: 50px;
      background-color: #fff;
    }
    th {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
      padding: 10px;
    }
    td {
      padding: 10px 0px 10px 10px;
      border: unset;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }
    tr:nth-child(even) {
      background: #fafcfe;
    }
    tbody {
      tr:hover {
        background: #efeff7 !important;
      }
    }
  }

  .section-footer {
    padding: 20px 20px 35px 20px;
    display: flex;
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .btn-blue {
      background-color: #24b0c3;
      color: #fff;
    }
    .btn {
      width: 120px;
      height: 44px;
      margin: 0 0 0 10px;
      border-radius: 8px;
      border: solid 1.5px #24b0c3;
      color: #24b0c3;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .arrow-right {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 4px;
      margin-left: 16px;
      position: absolute;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .arrow-left {
      border: solid #24b0c3;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 3px;
      margin-left: -17px;
      position: absolute;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 100%;
    }
  }
  .-fadeIn {
    animation: fadeIn 1s;
  }

  .time-box{
    width: 130px !important;
  }
  .date-box{
    width: 234px !important;
    padding: 10px;
  }
  .title-small {
    font-size: 14px !important;
    color: #385172 !important;
    font-weight: 500 !important;
}
.form-group{
  .-invalid{
    border-color:#ff2c65;
  }
}
.mg-0{
  margin:0px;
}
.box_field{
  width: 234px;
  height: 44px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #aabaca;
  color: #385172;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 12px 20px;
  cursor : pointer;
  // padding-right:40px;
}
`

const DropdownBox = styled.div` 
.box-dropdown {
    position: absolute;
    min-width:${props => (props.isFlex ? '400px' : '400px')};
    max-height: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 9;
    
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px;
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .box_field{
    width: 234px;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 20px;
    cursor : pointer;
    padding-right:40px;s
  }
  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: -28px;
  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    margin: -28px;
  }
.text-dropdown{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-dropdown-small {
  width: 119px !important;
  min-width: 80% !important;

  li{
    width: 90px !important;
    min-width: 80% !important;
  }
}
.bottom-height{
  margin-bottom: 200px;
  position: absolute;
  height: 300px;
}
.box-dropdown-md {
  width: 232px !important;
  min-width: 90% !important;
}
  
`
export { ConnectBigqueryBox, DropdownBox }
