import React, { Fragment, useState, useEffect } from 'react'
import { IntegrationsBox } from './style'
import ConfigIntegrationsComponent from './configIntegrations'
import { getProviderConfigureList } from '../../apis/dataActivation/api'

const IntegrationsComponent = props => {
  const [showConfigure, setShowConfigure] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [providerConfigure, setProviderConfigure] = useState()

  const showConfigurePage = option => {
    setShowConfigure(true)
    if (option === 'configure') {
      setIsCreate(true)
    } else {
      setIsCreate(false)
    }
  }

  useEffect(async () => {
    const { status, data } = await getProviderConfigureList()
    if (data && data.data && data.data[0] && status && status === 200) {
      setProviderConfigure(data.data[0])
    }
  }, [showConfigure])

  useEffect(async () => {
    const { status, data } = await getProviderConfigureList()
    if (data && data.data && data.data[0] && status && status === 200) {
      setProviderConfigure(data.data[0])
    }
  }, [])

  const createConfig = () => {
    setShowConfigure(true)
    setIsCreate(false)
  }
  return (
    <IntegrationsBox>
      {!showConfigure ? (
        <div className="card">
          <div className="card-header bg-white py-0 px-0 ">
            <div className="row">
              <div className="col-12 py-2">
                <p className=" title py-1 m-0 px-3 line-high-2 -capitalize">SMS Service Providers</p>
              </div>
            </div>
          </div>
          <div className="card-body p-0 text-center">
         
            <div className="section-body my-3">
              <div className="list-type-box">
                <div
                  className={`badge-green ${
                    providerConfigure && providerConfigure.status_configure.connected
                      ? ''
                      : 'd-none'
                  }`}
                >
                  Connected{' '}
                  <img src="/assets/icons/accept-mini.png" className="icon-mini" alt="predictive" />
                </div>
                <div className="mb-3">
                  <img src="/assets/icons/THAIBULKSMSicon.png" alt="predictive" />
                </div>
                <p className="title-type f-20 font-darkblue font-w-600">ThaibulkSMS</p>
                <p className="f-14 font-gray font-weight-normal mb-25">
                  Connect with ThaiBulkSMS to start sending SMS through our platform.
                </p>
                <button
                  onClick={() => showConfigurePage('configure')}
                  className={`btn-connect ${
                    providerConfigure && providerConfigure.status_configure.connected
                      ? 'btn-configure-white'
                      : 'btn-configure-green'
                  }`}
                >
                  {providerConfigure && providerConfigure.status_configure.connected
                    ? 'View Configuration'
                    : 'Configure'}
                </button>
              </div>
              <div className="list-type-box">
                <div className="mb-3">
                  <img src="/assets/icons/SMSMKTicon.png" alt="predictive" />
                </div>
                <p className="title-type f-20 font-darkblue font-w-600">SMSMKT</p>
                <p className="f-14 font-weight-normal mb-25">
                  helps you to send SMS to your customers easier with connecting to our platform.
                </p>

                <button className="btn-connect btn-configure btn-configure-gray ">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ConfigIntegrationsComponent
          setShowConfigureFn={setShowConfigure}
          providerConfigure={providerConfigure}
        />
      )}
    </IntegrationsBox>
  )
}
export default IntegrationsComponent
