import React, { Component, Fragment } from 'react'
import { ImportTypeBox } from './style'
import LoadingOverlay from 'react-loading-overlay'
import { isEmpty } from '../../helper/helper'
import { uploadFileList, deleteTaskBigQuery } from '../../apis/filestatus/api'
import { verifyToken } from '../../apis/login/api'
import { withRouter } from 'react-router'
import ScheduleDropdownOptions from './scheduleDropdownOptions'

class ImportListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataType: '',
      isLoadding: false,
      step: 'start',
      resTable: {
        resColumns: [],
        resRows: [],
        resFileInfo: ''
      },
      openModel: false,
      groupName: '',
      groupIsPass: false,
      groupList: [],
      fieldlist: [],
      refID: '',
      fileInfo: {},
      historyList: [],
      historyStatus: true,
      uploadError: false,
      lastLoad: false,
      role: ''
    }
  }

  convertTime = date => {
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    return time
  }

  convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()

    let res = day + ' ' + month + ' ' + year

    return res
  }

  getFileList = async params => {
    const { historyList, isLoadmore } = this.state
    let res = {}
    try {
      res = await uploadFileList(params)
      if (res.status === 200) {
        if (!isEmpty(res.data)) {
          historyList.push(...res.data)
          this.setState({
            historyList: historyList,
            historyStatus: true,
            isLoadmore: false,
            lastLoad: false
          })
        } else {
          this.setState({ lastLoad: true })
        }
      }
    } catch (error) {
      this.setState({ historyStatus: true, isLoadmore: false })
    }
  }

  loadMore = async () => {
    const { historyList, lastLoad } = this.state
    if (!lastLoad) {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.scrollingElement.scrollHeight
      ) {
        let params = {
          pageSize: 25,
          lastField: !isEmpty(historyList) ? historyList[historyList.length - 1].uploaded_at : ''
        }

        await this.getFileList(params)
      }
    }
  }

  setRole = async () => {
    let role = await verifyToken()
    this.setState({ role: role.role })
  }

  deleteSchedule = async id => {
    let { status } = await deleteTaskBigQuery(id)
    if (status && status === 200) {
      window.location.reload()
    } else {
      console.log('deleteSchedule')
    }
  }

  componentWillMount = () => {
    window.addEventListener('scroll', this.loadMore)
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.loadMore)
  }

  componentDidMount = async () => {
    await this.setRole()
    await this.getFileList()
  }
  render() {
    const { isShowMenu, history } = this.props
    const { isLoadmore, lastLoad, historyList, role, openDeletePopup } = this.state
    return (
      <ImportTypeBox isShowMenu={isShowMenu}>
        <section className="main">
          <div className="py-3 px-4">
            <div className="row">
              <div className="col-6 text-left">
                <p className="top-head-title">Data Management</p>
              </div>
              <div className="col-6 text-right">
                {role !== 'viewer' && (
                  <a
                    className="btn-basic bg-darkgreen font-white"
                    onClick={() => history.push('/imports/create?step=select-type')}
                  >
                    <img src="/assets/icons/create-white-2.png" className="w-px-14 mx-1" />
                    {`Create New`}
                  </a>
                )}
              </div>
            </div>

            <div className="section-history p-0">
              <div className="scroll-x">
                <table>
                  <thead>
                    <tr>
                      <th className="pl-4 w-px-147">Name</th>
                      <th className="w-px-80">Source</th>
                      <th className="w-10">Data Type</th>
                      <th className="w-5">Status</th>
                      <th>Row Added</th>
                      <th>Row Skipped</th>
                      <th>Created by</th>
                      <th>Last Updated</th>

                      <th className="-centered"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="line-h-3 pl-4 -ellipsis">
                            {' '}
                            {item.sourceType && item.sourceType === 'BigQuery' ? (
                              <img className="icon-type" src="/assets/images/upload-bigquery.png" />
                            ) : item.sourceType === 'GoogleCloudStorage' ? (
                              <img className="icon-type" src="/assets/images/upload-cloud.png" />
                            ) : !item.sourceType ? (
                              <img className="icon-type" src="/assets/images/upload-file.png" />
                            ) : (
                              ''
                            )}
                            <span>{item.filename}</span>
                          </td>
                          <td className="line-height-1">{item.group_name}</td>
                          <td className="line-height-1">
                            {item.type === 'profile'
                              ? 'Customer'
                              : item.type === 'event'
                              ? 'Event'
                              : 'Product'}{' '}
                          </td>
                          <td className="">
                            <div
                              className={`-status ${
                                item.status === 'inprogress'
                                  ? '-warning'
                                  : item.status === 'complete'
                                  ? '-success'
                                  : item.status === 'canceled'
                                  ? '-cancle'
                                  : item.status === 'error'
                                  ? '-fail'
                                  : item.status === 'waiting'
                                  ? '-waiting'
                                  : ''
                              }`}
                            >
                              {item.status === 'inprogress'
                                ? 'In Progress'
                                : item.status === 'complete'
                                ? 'Completed'
                                : item.status === 'canceled'
                                ? 'Canceled'
                                : item.status === 'error'
                                ? 'Failed'
                                : item.status === 'waiting'
                                ? 'Waiting'
                                : ''}
                            </div>
                          </td>

                          <td className="line-height-1">{item['success'] ? item.success : '-'}</td>
                          <td className="line-height-1">{item['error'] ? item.error : '-'}</td>
                          <td className="line-height-1">
                            {item.created_by &&
                              item.created_by.firstname &&
                              item.created_by.firstname}{' '}
                            {item.created_by &&
                              item.created_by.lastname &&
                              item.created_by.lastname}
                          </td>
                          <td className="line-height-1">
                            {' '}
                            {item.last_update_at ? this.convertDate(item.last_update_at) : '-'}{' '}
                            <br></br>
                            <span className="span-next">
                              {item.last_update_at ? this.convertTime(item.last_update_at) : ''}
                            </span>
                          </td>
                          <td>
                            {((item.sourceType === 'BigQuery' ||
                              item.sourceType === 'GoogleCloudStorage') &&
                              item.status !== 'canceled' &&
                              item.status !== 'error' &&
                              item.hasOwnProperty('job_start_date') &&
                              item.status === 'waiting') ||
                            (item.hasOwnProperty('schedule_detail') &&
                              item.schedule_detail.active) ? (
                              <ScheduleDropdownOptions
                                editObj={
                                  item.hasOwnProperty('schedule_detail') ? item.schedule_detail : {}
                                }
                                optionEdit={item.hasOwnProperty('job_start_date') ? true : false}
                                openDeletePopup={openDeletePopup}
                                deleteScheduleFn={() => this.deleteSchedule(item.ref_id)}
                                isShowMenu={isShowMenu}
                                id={item.ref_id}
                              />
                            ) : (
                              <Fragment />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              {!isLoadmore && !lastLoad && historyList ? (
                <div className="onLoadmore">
                  <LoadingOverlay active={true} spinner />
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </section>
      </ImportTypeBox>
    )
  }
}

export default withRouter(ImportListComponent)
