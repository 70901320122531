import React, { useState, useEffect } from 'react'
import { LoginBox } from './style'
import { changePassword } from '../../apis/login/api'
import PopupComponent from '../popup/popup'

import { useLocation, useHistory } from 'react-router-dom'

const ResetPasswordComponent = () => {
  const [shown, setShown] = useState(false)
  const [shownCF, setShownCF] = useState(false)
  const [password, setPassword] = useState()
  const [passwordError, setShowErrorPassword] = useState(false)
  const [passwordCFError, setShowErrorCFPassword] = useState({
    status: false,
    msg: 'Please enter confirm password'
  })

  const [CFPassword, setConfirmPassword] = useState()

  const [passwordLength, setLengthPassword] = useState(false)
  const [passwordPattern, setPatternPassword] = useState(false)

  const [apiError, setNotiApiError] = useState(false)

  let location = useLocation()
  useEffect(() => {
    validatePassword()
  }, [password])

  const validatePassword = () => {
    if (password && password.length >= 8) {
      setLengthPassword(true)
      setShowErrorPassword(false)
    } else {
      setLengthPassword(false)
    }

    if (/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)) {
      setPatternPassword(true)
      setShowErrorPassword(false)
    } else {
      setPatternPassword(false)
    }
  }

  const btnChangePassword = async () => {
    let objUser = {}
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    const email = params.get('email')
    if (!password) {
      setShowErrorPassword(true)
    } else {
      setShowErrorPassword(false)
    }

    if (!CFPassword) {
      setShowErrorCFPassword({ status: true, msg: 'Please enter confirm password' })
    } else {
      setShowErrorCFPassword({ status: false })
      if (CFPassword !== password) {
        setShowErrorCFPassword({ status: true, msg: 'Your password don’t match' })
      } else {
        if (passwordLength && passwordPattern) {
          objUser = {
            email: email,
            password: password,
            token: token
          }
          let res = await changePassword(objUser)
          if (res && res.status === 200) {
            window.location.href = '/login/?reset=t'
          } else {
            setNotiApiError(true)
            setTimeout(() => {
              setNotiApiError(false)
            }, 5000)
          }
        }
      }
    }
  }

  return (
    <LoginBox>
      <div className="col-6">xxx</div>
      <div className="col-6 bg-white">
        {apiError && (
          <PopupComponent
            option={{ status: 'error', msg: 'Something went wrong, please try again later.' }}
          />
        )}
        <div className="box_login">
          <p className="title">
            {location.pathname.includes('invite') ? 'Create Your Password' : 'Reset Your Password'}{' '}
          </p>

          <br />
          <span className="input_label">New Password</span>

          <input
            className={`input  `}
            type={shown ? 'text' : 'password'}
            onChange={e => setPassword(e.target.value)}
          />
          <span className="show_password" onClick={() => setShown(!shown)}>
            {shown ? 'Hide' : 'Show'}
          </span>
          {passwordError ? <label className="label_err">Please enter password</label> : ''}
          <br />
          <br />
          <div className={`validate-password ${passwordLength ? '-green' : '-gray'} `}>
            <div className={`checkmark ${passwordLength ? 'checkmark-green' : ''}`}></div>
            Contains at least 8 characters
          </div>

          <div className={`validate-password ${passwordPattern ? '-green' : '-gray'}`}>
            <div className={`checkmark ${passwordPattern ? 'checkmark-green' : ''}`}></div>
            Contains both lower (a-z) and upper case letters (A-Z)
          </div>
          <div className={`validate-password ${passwordPattern ? '-green' : '-gray'}`}>
            <div className={`checkmark ${passwordPattern ? 'checkmark-green' : ''}`}></div>Contains
            at least one number (0-9) or a symbol
          </div>

          <span className="input_label">Confirm New Password</span>
          <input
            className={`input ${passwordCFError.status ? 'input_err' : ''}`}
            type={shownCF ? 'text' : 'password'}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <span className="show_password" onClick={() => setShownCF(!shownCF)}>
            {shownCF ? 'Hide' : 'Show'}
          </span>
          {passwordCFError.status ? <label className="label_err">{passwordCFError.msg}</label> : ''}

          <button className="btn btn_blue" onClick={() => btnChangePassword()}>
            Change My Password
          </button>
          <p className="input_label -center">
            Know your password?{' '}
            <a className="-font-blue" onClick={() => (window.location.href = '/login')}>
              Log in
            </a>
          </p>
        </div>
      </div>
    </LoginBox>
  )
}

export default ResetPasswordComponent
