import React, { Fragment, useState, useEffect } from 'react'
import { SelectSourceBox } from './style'
import { inviteMember, getDataUser, editMember, deleteMember } from '../../apis/member/api'
import { useHistory } from 'react-router-dom'
import PopupComponent from '../popup/popup'

import Modal from 'react-modal'
import { useParams } from 'react-router-dom'

const SelectSourceComponent = props => {
  const history = useHistory()

  const [source, setSource] = useState()
  // useEffect(() => {
  //   console.log('source : ', source)
  // }, [source])

  return (
    <SelectSourceBox isShowMenu={props ? props.isShowMenu : true}>
      <div className="section-main">
        <h2 className="title-source">Select data source</h2>
        <br />
        <div className="section-select-source">
          <div
            className={`box-select-source ${source === 'uploadfile' ? '-active' : ''}`}
            onClick={() => setSource('uploadfile')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-file.png" />
            </div>
            <div className="box-name-source">
              <p>File Upload</p>
              <label>Import your customer data from CSV file</label>
            </div>
          </div>
          <div
            className={`box-select-source ${source === 'bigquery' ? '-active' : ''}`}
            onClick={() => setSource('bigquery')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-bigquery.png" />
            </div>
            <div className="box-name-source">
              <p>Google BigQuery</p>
              <label>Import your customer data from Google BiqQuery</label>
            </div>
          </div>
          <div
            className={`box-select-source ${source === 'cloud' ? '-active' : ''}`}
            onClick={() => setSource('cloud')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-cloud.png" />
            </div>
            <div className="box-name-source">
              <p>Google Cloud Storage</p>
              <label>Import your customer data from Google Cloud Storage</label>
            </div>
          </div>
        </div>
        <div className="section-select-source">
          <div
            className={`box-select-source -disable `} //${source === 'ga' ? '-active' : ''}
            // onClick={() => setSource('ga')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-ga.png" />
            </div>
            <div className="box-name-source">
              <p>Google Analytics</p>
              <label>Import your customer data from Google Analytics</label>
            </div>
          </div>
          <div
            className={`box-select-source -disable `} //${source === 's3' ? '-active' : ''}
            // onClick={() => setSource('s3')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-s3.png" />
            </div>
            <div className="box-name-source">
              <p>Amazon S3</p>
              <label>Import your customer data from Amazon S3</label>
            </div>
          </div>
          <div
            className={`box-select-source -disable `} //${source === 'amazon' ? '-active' : ''}
            // onClick={() => setSource('amazon')}
          >
            <div className="box-image-source">
              <img src="/assets/images/upload-amazon.png" />
            </div>
            <div className="box-name-source">
              <p>Amazon Redshift</p>
              <label>Import your customer data from Amazon Redshift</label>
            </div>
          </div>
        </div>

        <br />
      </div>
      <div className="section-footer">
        <div className="col-6 align-left">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => props.nextStepFn('groupping')}>
            Previous
          </a>
        </div>
        <div className="col-6 align-right">
          <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2" onClick={() => history.push('/imports')}>
            Cancel
          </a>
          <a
            className={`btn-basic ${source ? 'bg-darkgreen font-white' : 'bg-gray font-white'}`}
            onClick={() => (source ? props.nextStepFn(source) : '')}
          >
            Next
          </a>
        </div>
      </div>
    </SelectSourceBox>
  )
}

export default SelectSourceComponent
