import { Component } from 'react'
import DropdownComponent from '../dropdown/dropdown'
import { isEmpty } from '../../helper/helper'
import { MappingBox } from './style'
import { getFieldsList, addCustomField } from '../../apis/mapping/api'
import { createGroupMapping } from '../../apis/group/api'
import { validateRawData } from '../../apis/upload/api'
import LoadingOverlay from 'react-loading-overlay'
import { Fragment } from 'react'

export default class MappingComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      renderSelect: '',
      isChecked: true,
      selectedColumn: [],
      resTableState: {
        resColumns: [],
        resRows: []
      },
      selectRequieColumn: [],
      allSelected: [],
      isFinished: false,
      isPrimaryValidate: false,
      isCloseDropdown: false,
      isLoadValidate: false,
      autoSelected: [],
      primaryColumn: [],
      showSelectTable: [],
      setListMapping: [],
      validateText: '',
      isValidated: true
    }
  }

  setAutoSelected = async () => {
    const { autoSelected } = this.state
    let masterField = await this.getFieldsList()
    masterField = masterField.filter(l => l.field_name !== 'customer_id')
    if (isEmpty(autoSelected)) {
      this.setState({ selectRequieColumn: masterField })
    }

    const {
      resTable: { resColumns }
    } = this.props
    let _tempSelected = []
    let _wrap = {}
    for (let i = 0; i < masterField.length; i++) {
      for (let v = 0; v < resColumns.length; v++) {
        if (
          resColumns[v].title
            .toLowerCase()
            .includes(masterField[i].field_name.toLowerCase().substring(0, 5))
        ) {
          _wrap = {
            data: masterField[i],
            no: v
          }
          _tempSelected.push(_wrap)
        }
      }
    }
    // _tempSelected = _tempSelected.filter(
    //   (value, index, self) =>
    //     index === self.findIndex(t => t.place === value.place && t.no === value.no)
    // )

    const filteredArr = _tempSelected.reduce((acc, current) => {
      const x = acc.find(item => item.data.field_name === current.data.field_name)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    if (!isEmpty(filteredArr)) {
      this.setState({ autoSelected: filteredArr })
    } else {
      this.setState({ autoSelected: masterField })
    }
  }

  componentDidMount = async () => {
    await this.setFieldMapping()
    await this.setAutoSelected()
  }

  setFieldMapping = async () => {
    const { rawData } = this.props
    const {
      resTable: { resRows }
    } = this.props
    let sampleRow1 = !isEmpty(rawData[1]) ? rawData[1] : []
    let sampleRow2 = !isEmpty(rawData[2]) ? rawData[2] : []
    let wrapRawData = rawData[0].filter(l => !isEmpty(l))
    let res = []
    let _temp = {}
    for (let i = 0; i < wrapRawData.length; i++) {
      for (let x = 0; x < resRows.length; x++) {
        _temp = {
          fieldName: wrapRawData[i],
          sampleData: [sampleRow1[i], sampleRow2[i]],
          fieldMapping: {},
          key: ''
        }
      }
      res.push(_temp)
    }
    this.setState({ setListMapping: res })
  }

  renderDropdown = () => {
    const { masterField } = this.props
    const { open, requieColumn } = this.state
    this.setState({ open: !open })

    let showSelect = open && (
      <div className="box-dropdown">
        <ul>
          {masterField.map((item, i) => {
            return <li key={i}>{item.field_name}</li>
          })}
        </ul>
        <div className="box-dropdown-footer">
          <img className="icon-create" src="assets/icons/create.png" alt="predictive" /> Create a
          new attribute..
        </div>
      </div>
    )

    this.setState({ renderSelect: showSelect })
  }

  setInnerVal = async (val, no) => {
    let { allSelected, setListMapping } = this.state
    const { masterField } = this.props
    let updateFieldList = await this.getFieldsList()
    updateFieldList = updateFieldList.filter(l => l.field_name !== 'customer_id')
    let setSelected = {}
    for (let i = 0; i < setListMapping.length; i++) {
      if (i === no) {
        setListMapping[i].fieldMapping = !isEmpty(val.field_name) ? val : {}
      }
    }

    if (isEmpty(val.field_name)) {
      allSelected = allSelected.filter(l => l.selectNo !== no)
    }

    this.setState({ setListMapping })
    if (!isEmpty(allSelected) && !isEmpty(val.field_name)) {
      for (let i = 0; i < allSelected.length; i++) {
        if (allSelected[i].selectNo !== no) {
          setSelected = {
            selectNo: no,
            selectVal: val
          }
        } else {
          allSelected[i].selectVal = val
          allSelected[i].selectNo = no
        }
      }

      allSelected.push(setSelected)

      this.setState({ allSelected: allSelected })
    } else {
      if (!isEmpty(val.field_name)) {
        setSelected = {
          selectNo: no,
          selectVal: val
        }
        allSelected.push(setSelected)
        this.setState({ allSelected: allSelected })
      }
    }
    let _wrapData = []
    allSelected.map((list, i) => {
      _wrapData.push(list.selectVal)
    })
    let finalList = []
    if (masterField.length !== updateFieldList.length) {
      finalList = updateFieldList
    } else {
      finalList = masterField
    }
    let array = finalList
    let anotherOne = _wrapData
    let filteredArray = []
    filteredArray = array.filter(function (array_el) {
      return (
        anotherOne.filter(function (anotherOne_el) {
          return (
            (!isEmpty(anotherOne_el) && anotherOne_el.field_name === array_el.field_name) ||
            isEmpty(array_el.field_name)
          )
        }).length === 0
      )
    })
    this.setState({
      selectRequieColumn: filteredArray,
      setListMapping,
      isFinished: false,
      isValidated: true,
      isPrimaryValidate: false
    })
  }

  gotoReview = async () => {
    const { groupName, nextStepFn, dataType } = this.props
    const { setListMapping, isFinished } = this.state
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    if (isFinished) {
      let _temp = {}
      let wrap = []
      for (let i = 0; i < setListMapping.length; i++) {
        _temp = {
          [setListMapping[i].fieldName]: !isEmpty(setListMapping[i].fieldMapping.field_name)
            ? setListMapping[i].fieldMapping.field_name
            : ''
        }
        wrap.push(_temp)
      }

      let res = {}
      try {
        let obj = {
          option: paramApi,
          groupName: groupName,
          groupMapping: wrap
        }
        res = await createGroupMapping(obj)

        nextStepFn('review')
      } catch (error) {
        res = { error }
      }
    }
  }

  closeDD = () => {
    this.setState({ isCloseDropdown: true })
  }

  dataExists(obj1, obj2) {
    return obj1.some(function (el) {
      return el.field_name === obj2.fieldName
    })
  }

  setPrimaryKey = primarykey => {
    let { setListMapping } = this.state

    for (let i = 0; i < setListMapping.length; i++) {
      if (isEmpty(setListMapping[i].key)) {
        // first
        if (setListMapping[i].fieldName === primarykey.field_name) {
          setListMapping[i].key = 'PK'
        }
      } else {
        // second
        if (setListMapping[i].key === 'PK') {
          setListMapping[i].key = ''
          if (setListMapping[i].fieldName === primarykey.field_name) {
            setListMapping[i].key = 'PK'
          }
        }
      }
    }
    this.setState({
      primarykey,
      isPrimaryValidate: false,
      isFinished: false,
      isPrimaryValid: false,
      isValidated: true
    })
  }

  setForeignKey = foreignKey => {
    let { setListMapping } = this.state
    for (let i = 0; i < setListMapping.length; i++) {
      if (isEmpty(setListMapping[i].key)) {
        // first
        if (setListMapping[i].fieldName === foreignKey.field_name) {
          setListMapping[i].key = 'FK'
        }
      } else {
        // second
        if (setListMapping[i].key === 'FK') {
          setListMapping[i].key = ''
          if (setListMapping[i].fieldName === foreignKey.field_name) {
            setListMapping[i].key = 'FK'
          }
        }
      }
    }
    this.setState({
      foreignKey,
      isPrimaryValidate: false,
      isFinished: false,
      isForeignValid: false,
      isValidated: true
    })
  }

  setThirdKey = thirdKey => {
    let { setListMapping } = this.state
    for (let i = 0; i < setListMapping.length; i++) {
      if (isEmpty(setListMapping[i].key)) {
        // first
        if (setListMapping[i].fieldName === thirdKey.field_name) {
          setListMapping[i].key = 'TK'
        }
      } else {
        // second
        if (setListMapping[i].key === 'TK') {
          setListMapping[i].key = ''
          if (setListMapping[i].fieldName === thirdKey.field_name) {
            setListMapping[i].key = 'TK'
          }
        }
      }
    }
    this.setState({
      thirdKey,
      isPrimaryValidate: false,
      isFinished: false,
      isThirdValid: false,
      isValidated: true
    })
  }

  validateMapping = async () => {
    const { refID, setReviewDetailFn, dataType } = this.props
    const { setListMapping, primarykey, foreignKey, thirdKey, isFinished, isValidated } = this.state
    let wrapDataType =
      dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'catalog'

    let validateText = {}

    let _temp = {}
    let _tempWrap = {}
    let resRules = {}

    if (!isValidated) {
      return
    }

    if (!isFinished) {
      if (dataType === 'customer') {
        if (!isEmpty(primarykey)) {
          let primary = {
            customer_id: {
              csv_name: primarykey.field_name,
              data_type: primarykey.data_type
            }
          }
          resRules = Object.assign(_temp, primary)
        } else {
          window.scrollTo({
            top: 200,
            behavior: 'smooth'
          })
          this.setState({ isPrimaryValid: true })
          return
        }
      } else {
        /// Event , Product
        if (!isEmpty(primarykey) && !isEmpty(foreignKey) && !isEmpty(thirdKey)) {
          let primary = {
            customer_id: {
              csv_name: primarykey.field_name,
              data_type: primarykey.data_type
            }
          }
          resRules = Object.assign(_temp, primary)
          let foreign = {
            event_id: {
              csv_name: foreignKey.field_name,
              data_type: foreignKey.data_type
            }
          }
          resRules = Object.assign(_temp, foreign)
          let thirdK = {
            timestamp: {
              csv_name: thirdKey.field_name,
              data_type: thirdKey.data_type
            }
          }
          resRules = Object.assign(_temp, thirdK)
        } else {
          window.scrollTo({
            top: 200,
            behavior: 'smooth'
          })

          if (!isEmpty(primarykey)) {
            this.setState({ isPrimaryValid: false })
          } else {
            this.setState({ isPrimaryValid: true })
          }

          if (!isEmpty(foreignKey)) {
            this.setState({ isForeignValid: false })
          } else {
            this.setState({ isForeignValid: true })
          }

          if (!isEmpty(thirdKey)) {
            this.setState({ isThirdValid: false })
          } else {
            this.setState({ isThirdValid: true })
          }
          return
        }
      }

      for (let i = 0; i < setListMapping.length; i++) {
        if (!isEmpty(setListMapping[i].fieldMapping)) {
          _tempWrap = {
            [setListMapping[i].fieldMapping.field_name]: {
              csv_name: setListMapping[i].fieldName,
              data_type: setListMapping[i].fieldMapping.data_type
            }
          }

          resRules = Object.assign(_temp, _tempWrap)
        }
      }

      let res = {}
      try {
        let obj = {
          ref_id: refID,
          rules: resRules,
          wrapDataType
        }
        res = await validateRawData(obj)

        if (res.status === 200) {
          if (dataType === 'event') {
            if (res.data.error !== 0) {
              validateText = `ERRORS | ${res.data.error} errors found`
              this.setState({
                isValidated: false,
                isFinished: false,
                isPrimaryValidate: res.data.error > 0 ? true : false,
                isLoadValidate: true,
                validateText
              })
            } else {
              validateText = `Validatation completed - ${res.data.error} errors found`
              setReviewDetailFn(res)
              this.setState({
                isValidated: false,
                isFinished: true,
                isPrimaryValidate: res.data.error > 0 ? true : false,
                isLoadValidate: true,
                validateText
              })
            }
          } else {
            validateText = `Validatation completed - ${res.data.error} errors found`

            setReviewDetailFn(res)
            this.setState({
              isValidated: false,
              isFinished: true,
              isPrimaryValidate: res.data.error > 0 ? true : false,
              isLoadValidate: true,
              validateText
            })
          }
          window.scrollTo({
            top: 100,
            behavior: 'smooth'
          })
          setTimeout(() => {
            this.setState({ isValidated: false, isLoadValidate: false })
          }, 5000)
        } else {
          validateText = `server is disconnect`

          this.setState({ isFinished: false, isPrimaryValidate: true, validateText })
        }
      } catch (error) {
        validateText = `server is disconnect`

        this.setState({ isFinished: false, isPrimaryValidate: true, validateText })
      }
    } else {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      })
      this.setState({ isPrimaryValidate: false, isPrimaryValid: true })
    }
  }

  getFieldsList = async () => {
    const { dataType } = this.props
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'
    let res = {}

    try {
      let response = await getFieldsList(paramApi)
      if (!isEmpty(response) && response.status === 200) {
        res = response.data
      }
    } catch (error) {
      res = error.message
    }
    return res
  }

  saveCustomField = async (data_type, field_name) => {
    const { dataType } = this.props
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    let params = {
      data_type: data_type,
      field_name: field_name,
      option: paramApi
    }
    let res = {}
    try {
      res = await addCustomField(params)
    } catch (error) {
      res = { error }
    }
    if (!isEmpty(res) && res.status === 200) {
      const getFieldsList = await this.getFieldsList()
      this.setState({
        selectRequieColumn: getFieldsList.filter(l => l.field_name !== 'customer_id')
      })
    }
    return res
  }
  render() {
    const { nextStepFn, masterField, dataType, groupName } = this.props
    const {
      selectRequieColumn,
      validateText,
      setReview,
      isThirdValid,
      isValidated,
      isForeignValid,
      isPrimaryValid,
      isLoadValidate,
      isFinished,
      isCloseDropdown,
      autoSelected,
      setListMapping,
      isPrimaryValidate
    } = this.state
    return (
      <MappingBox>
        {isLoadValidate && (
          <div className="loadding-overlay">
            <LoadingOverlay active={true} spinner />
            <p>Please wait we are validating your data.</p>
          </div>
        )}
        <div className="row-btn">
          <div className="col-12 text-left">
            <span className="title-inner head-map ">Data Mapping|</span>
            <span className="title-inner -blue">
              {' '}
              {dataType} : {groupName}
            </span>
            {dataType === 'customer' ? (
              <p className="desc-inner font-weight-lighter">
                Map your source field to customer attribute. Only source field mapped to a customer
                attribute will be imported.
              </p>
            ) : dataType === 'event' ? (
              <p className="desc-inner">
                Map your source field to event attribute. Only source field mapped to an event
                attribute will be imported.
              </p>
            ) : (
              <p className="desc-inner">Products</p>
            )}
          </div>
        </div>
        <div className="section-main">
          <section className="" onClick={() => this.closeDD()}>
            <br />
            <div className="row">
              {/* <div className="col-1"></div> */}
              <div className="col-4">
                <span className="title-map -titleField">Source field</span>
                <div className="section-tooltip">
                  <div className="tooltip">
                    <img
                      className="icon-tooltip"
                      src="/assets/icons/tooltips.png"
                      alt="information"
                    />
                    <span className="tooltiptext">
                      This is a list of all the columns from your file.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <p className="title-map -titleField">{dataType} Attribute</p>
                <div className="section-tooltip">
                  <div className="tooltip">
                    <img
                      className="icon-tooltip"
                      src="/assets/icons/tooltips.png"
                      alt="information"
                    />
                    <span className="tooltiptext">
                      This is our basic field. You can add a new field by selecting Create a custom
                      attribute in the drop down.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <p className="title-map -titleField">Sample Data</p>
                <div className="section-tooltip">
                  <div className="tooltip">
                    <img
                      className="icon-tooltip"
                      src="/assets/icons/tooltips.png"
                      alt="information"
                    />
                    <span className="tooltiptext">
                      This is a sample of the data from your file.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                <div className="custom-selects">
                  <DropdownComponent
                    dataType={dataType}
                    isKey={'PK'}
                    isPrimaryValid={isPrimaryValid}
                    setPrimaryField={setListMapping}
                    setPrimaryKeyFn={this.setPrimaryKey}
                    primaryColumn={masterField}
                    showCustom={false}
                  />
                  {isPrimaryValid && (
                    <span className="-textvalid">Please select your customer_id</span>
                  )}
                </div>
              </div>
              <div className="col-4">
                <div className="custom-selects">
                  <div className="box_field no-cursor -border  ">
                    <img src="/assets/icons/key.png" className="icon-key" alt="predictive" />
                    customer_id
                  </div>
                </div>
              </div>
            </div>

            {dataType !== 'customer' ? (
              <Fragment>
                <div className="row">
                  <div className="col-4">
                    <div className="custom-selects">
                      <DropdownComponent
                        dataType={dataType}
                        isKey={'FK'}
                        isForeignValid={isForeignValid}
                        setPrimaryField={setListMapping}
                        setPrimaryKeyFn={this.setPrimaryKey}
                        setForeignKeyFn={this.setForeignKey}
                        setThirdKeyFn={this.setThirdKey}
                        primaryColumn={masterField}
                        showCustom={false}
                      />
                      {isForeignValid && (
                        <span className="-textvalid">Please select your {groupName}_id</span>
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="custom-selects">
                      <div className="box_field no-cursor -border  ">
                        <img src="/assets/icons/key.png" className="icon-key" alt="predictive" />
                        {groupName}_id
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="custom-selects">
                      <DropdownComponent
                        dataType={dataType}
                        isKey={'TK'}
                        isThirdValid={isThirdValid}
                        isForeignValid={isForeignValid}
                        setPrimaryField={setListMapping}
                        setPrimaryKeyFn={this.setPrimaryKey}
                        setForeignKeyFn={this.setForeignKey}
                        setThirdKeyFn={this.setThirdKey}
                        primaryColumn={masterField}
                        showCustom={false}
                      />
                      {isThirdValid && <span className="-textvalid">Please select timestamp</span>}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="custom-selects">
                      <div className="box_field no-cursor -border  ">
                        <img src="/assets/icons/key.png" className="icon-key" alt="predictive" />
                        timestamp
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment />
            )}

            <br />
            {setListMapping.map((item, i) => {
              return (
                !isEmpty(item) && (
                  <div key={i} className="row">
                    {/* <div className="col-1">
                    <label className="container-checkbox">
                      <input type="checkbox" className="checkedbox" onChange={e => this.selectField(e, i)} name={item.nameCheckbox} value={item.nameCheckbox} checked={item.isCheck} />
                      <span className="checkmark"></span>
                    </label>
                  </div> */}
                    <div className="col-4">
                      <div className="custom-selects">
                        <div className="box_field">{item.fieldName}</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="custom-selects">
                        {!isEmpty(autoSelected) ? (
                          <DropdownComponent
                            dataType={dataType}
                            isLast={setListMapping.length === i + 1 ? true : false}
                            saveCustomFieldFn={this.saveCustomField}
                            primaryColumn={masterField}
                            setInnerValFn={this.setInnerVal}
                            showCustom={true}
                            settingColumn={selectRequieColumn}
                            rowNo={i}
                            closeDDFn={isCloseDropdown}
                            autoSelectedList={autoSelected}
                          />
                        ) : (
                          <LoadingOverlay active={true} spinner />
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="sample-box">
                        {item.sampleData.map((item, i) => (
                          <span key={i}>{!isEmpty(item) ? item : '-'}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                )
              )
            })}
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                <a
                  onClick={() => this.validateMapping()}
                  className={`w-100 btn-basic ${
                    !isValidated ? 'bg-gray font-white' : 'bg-darkblue font-white'
                  }`}
                >
                  Validate Mapping
                </a>
              </div>
            </div>
            {/* <span className="warning-text">Please validate before Next Step </span> */}
            <br />
            <br />
            {isFinished && !isPrimaryValidate ? (
              <div className="box-validate-success">
                {' '}
                <img src="/assets/icons/collect-circle.png" alt="predictive" /> Validation succeeded
              </div>
            ) : (
              <Fragment />
            )}
            {isPrimaryValidate ? (
              dataType !== 'event' ? (
                <div className="box-validate">
                  <img src="/assets/icons/warning-react.png" alt="predictive" /> {validateText}
                </div>
              ) : (
                <div className="box-validate-red">
                  <img src="/assets/icons/warning-red.png" alt="predictive" /> {validateText}
                  <p>
                    Due to errors in the file, no rows were uploaded. Please review your file and
                    re-upload after fixing the file.
                  </p>
                </div>
              )
            ) : (
              <Fragment />
            )}

            {/* <p className="title-gray">Sample Rows</p>
          <span>When you are finished choosing your column mappings, review the sample data below.</span>

          {!isEmpty(showSelectTable) ? (
            <div className="section-table">
              {showSelectTable.map((items, i) => (
                <div className="box-show-mapping" key={i}>
                  <div className="box-column">{items.columns}</div>
                  {items.rows.map((item, x) => {
                    return (
                      <div className="box-rows" key={x}>
                        {item.value}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          ) : (
            <Fragment />
          )} */}
          </section>
        </div>

        <div className="col-12 text-right section-footer">
          <div className="col-6 align-left">
            <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => nextStepFn('renderTable')}>
              Previous
            </a>
          </div>
          <div className="col-6 align-right">
            <a
              className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              onClick={() => (window.location.href = '/imports')}
            >
              Cancel
            </a>
            <a
              className={`btn-basic ${!isFinished ? 'bg-gray font-white' : 'bg-darkgreen font-white'}`}
              onClick={() => this.gotoReview()}
            >
              <span>Next</span>
            </a>
          </div>
        </div>
      </MappingBox>
    )
  }
}
