import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_CUSTOMER_VIEW_SERVICE } = process.env
let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const getCustomerProfile = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/customer/profile/${params.cutomer_id}`
    const response = await axios.get(URL, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      let _wrap = Object.keys(response.data).map(key => [{ name: key, data: response.data[key] }])
      let item = []
      for (let i = 0; i < _wrap.length; i++) {
        item.push(..._wrap[i])
      }
      res = {
        status: response.status,
        data: item
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }

  return res
}

const getCustomerList = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/customer/list`
    const response = await axios.post(
      URL,
      {
        pagination: {
          pageSize: isEmpty(params) ? 25 : params.pageSize,
          lastField: isEmpty(params) ? '' : params.lastField
        }
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getEventList = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/customer/events/${params.cutomer_id}` //${params.cutomer_id}
    const response = await axios.post(
      URL,
      {
        pagination: {
          pageSize: isEmpty(params) ? 25 : params.pageSize,
          lastField: isEmpty(params) ? '' : params.lastField
        }
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data //last
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createRFMSegment = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_CUSTOMER_VIEW_SERVICE}/segment/rfm/create`
    const response = await axios.post(URL, obj, config)
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export { getCustomerList, getCustomerProfile, getEventList, createRFMSegment }
