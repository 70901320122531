import React, { Fragment, useState, useEffect, useRef } from 'react'
import {
  SmsCampaignBox,
  ProgressbarBox,
  CheckmarkBox,
  DropdownBox,
  Tab,
  ButtonGroup
} from './style'
import { getSegmentList } from '../../../apis/segmentation/api'
import {
  createCampaign,
  getProvider,
  getSender,
  previewReachableUsers,
  getCampaignDetail,
  editCampaign,
  getReportSMS,
  deleteCampaign,
  duplicateCampaign,
  downloadReportSMS,
  testSMS
} from '../../../apis/dataActivation/api'
import { CSVLink } from 'react-csv'
import { useHistory, useParams } from 'react-router-dom'
import useOutsideClick from '../useOutsideClick'
import Modal from 'react-modal'
import moment from 'moment'
import 'antd/dist/antd.css'
import { DatePicker } from 'antd'
// import styled from 'styled-components'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
const SmsCampaignComponent = props => {
  const history = useHistory()
  const [count, setCount] = useState(0)
  const [character, setCharacter] = useState(161)
  const [credit, setCredit] = useState(0)
  const [method, setMedthod] = useState(0)

  const [masterSegmentList, setMasterSegmentList] = useState(null)
  const [editDetail, setEditDetail] = useState(null)
  const [segmentList, setSegmentList] = useState(null)
  const [providerList, setProviderList] = useState(null)
  const [providerId, setProviderId] = useState()
  const [senderList, setSenderList] = useState(null)
  const [schedule, setSchedule] = useState('onetime')
  const [showSchedule, setShowSchedule] = useState(false)
  const [openDropdownProvider, setOpenDropdownProvider] = useState(false)
  const [openDropdownSender, setOpenDropdownSender] = useState(false)
  const [isLoaddingReachable, setIsLoaddingReachable] = useState(false)

  const [checkExclude, setCheckExclude] = useState(false)

  const [countDropdown, setCountDropdown] = useState([])
  const [countDropdownExclude, setCountDropdownExclude] = useState([])
  const [textMessage, setTextMessage] = useState()
  const [campaignName, setCampaignName] = useState('')

  const [valueProvider, setProvider] = useState(null)
  const [valueSender, setSender] = useState()
  const [campaignType, setCampaignType] = useState()

  const [dateSchedule, setDateSchedule] = useState(null)
  const [hours, setHours] = useState(0)
  const [mins, setMins] = useState(0)

  const [reachableUsers, setReachableUsers] = useState(0)

  const [isUpdate, setIsUpdate] = useState(false)
  const [campaignStatus, setCampaignStatus] = useState()

  const [getReport, setGetReport] = useState()
  const [columnReport, setColumnReport] = useState([])
  const [rowReport, setRowReport] = useState([])

  const [testNumber, setTestNumber] = useState()
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupTestSMS, setOpenPopupTestSMS] = useState(false)
  const [passTestSMS, setPassTestSMS] = useState(false)
  const [showExcludePopup, setShowExcludePopup] = useState(false)
  const [openPopupSorry, setOpenPopupSorry] = useState(false)
  const [openPopupExitNow, setOpenPopupExitNow] = useState(false)
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [openPopupTestCampaign, setOpenPopupTestCampaign] = useState(false)

  const [openPopupError, setOpenPopupError] = useState(false)
  const [openPopupReachable, setOpenPopupReachable] = useState(false)

  //////// Validate //////////
  const [inValidateCampaignName, setInValidateCampaignName] = useState(false)
  const [inValidateSchedule, setInValidateSchedule] = useState(false)
  const [inValidateReachable, setInValidateReachable] = useState(false)
  const [inValidateTestCampaign, setInValidateTestCampaign] = useState(false)

  //////// Validate //////////

  const scheduleRef = useRef(null)
  const messageRef = useRef(null)

  const types = ['Campaign', 'Report']

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: 'auto',
      height: 'auto',
      border: '2px solid #0698BE',
      borderRadius: '10px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  const { id: ref_id } = useParams()
  const [active, setActive] = useState(
    history.location.pathname.includes('/report') ? types[1] : types[0]
  )

  useEffect(async () => {
    if (editDetail) {
      if (history.location.pathname.includes('/report')) {
        await activeTab('Report')
      }

      let wrap = []
      wrap.push(
        ...editDetail.segment_target.include_segment,
        editDetail.segment_target.exclude_segment
      )

      let masterObj = segmentList ? segmentList : props.masterSegment
      let filterReturn = filterByReferenceID(masterObj, wrap)
      setSegmentList(filterReturn.map(item => item))
      for (let i = 0; i < editDetail.segment_target.include_segment.length; i++) {
        let temp = {
          value: editDetail.segment_target.include_segment[i].segment_name,
          id: editDetail.segment_target.include_segment[i].ref_id,
          isOpen: false
        }
        countDropdown.push(temp)
        setCountDropdown(countDropdown.map(item => item))
      }
      if (editDetail.segment_target.exclude_segment.length >= 1) {
        setCheckExclude(true)
        setShowExcludePopup(true)

        for (let i = 0; i < editDetail.segment_target.exclude_segment.length; i++) {
          let temp = {
            value: editDetail.segment_target.exclude_segment[i].segment_name,
            id: editDetail.segment_target.exclude_segment[i].ref_id,
            isOpen: false
          }
          countDropdownExclude.push(temp)
          setCountDropdownExclude(countDropdownExclude.map(item => item))
        }
      } else {
        setCheckExclude(false)
      }

      let _filerInclude = countDropdown.map(item => item.id)
      let _filerExclude = countDropdownExclude.map(item => item.id)

      let obj = {
        include_segment: _filerInclude.filter(item => item !== ''),
        exclude_segment: _filerExclude.filter(item => item !== '')
      }
      setIsLoaddingReachable(true)
      let { data, status } = await previewReachableUsers(obj)
      if (data && status === 200) {
        setReachableUsers(data)
        setIsLoaddingReachable(false)
      } else {
        setIsLoaddingReachable(false)
      }
    }
  }, [editDetail])

  useEffect(async () => {
    const { status, data } = await getSegmentList()
    if (status && status === 200) {
      setSegmentList(data)
      setMasterSegmentList(data)
    }
    if (!ref_id) {
      addDropdownSegment()
    } else {
      await getDetail(ref_id)
    }

    await getProviderList()
  }, [])

  const activeTab = async type => {
    setActive(type)
    if (ref_id) {
      history.push(`/campaign/${type === 'Report' ? 'report' : 'edit'}/${ref_id}`)
      if (type === 'Report') {
        await getReportDetail(ref_id)
      }
    }
  }

  const getReportDetail = async ref_id => {
    const { status, data } = await getReportSMS(ref_id)
    if (status && status === 200) {
      setGetReport(data.data)
      await downloadReport(ref_id)
    } else {
      setGetReport()
    }
  }

  const downloadReport = async ref_id => {
    const { status, data } = await downloadReportSMS(ref_id)
    if (data && data.data && status === 200) {
      setColumnReport(data.data.columns_report)
      setRowReport(data.data.smsTransactionList)
    }
  }

  const ConvertDateTimeUTC = data => {
    let date = data

    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'numeric' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    let hour = new Date(date).getHours()
    let min = new Date(date).getMinutes()
    day = day > 9 ? day : '0' + day
    month = month > 9 ? month : '0' + month
    let dateTime = year + '-' + month + '-' + day + 'T' + time + ':00.000Z'
    return { dateTime, hour, min }
  }
  const getDetail = async id => {
    let obj = {
      id: id
    }
    setIsUpdate(true)
    const { status, data } = await getCampaignDetail(obj)
    if (data && status === 200) {
      setEditDetail(data.data)
      setCampaignStatus(data.data.campaign_detail.campaign_status)
      let schedule = data.data.schedule_detail.schedule_type === 'send_later' ? 'daily' : 'onetime'
      setCampaignName(data.data.campaign_detail.campaign_name)
      setCampaignType(data.data.campaign_detail.campaign_type)
      calCredit(data.data.sms_detail.message)
      setSchedule(schedule)
      if (schedule === 'daily') {
        setShowSchedule(true)
        let { dateTime, hour, min } = ConvertDateTimeUTC(
          data.data.schedule_detail.schedule_start_date
        )
        setDateSchedule(moment(data.data.schedule_detail.schedule_start_date))
        setHours(hour)
        setMins(min)
      }
      if (Object.keys(data.data.service_provider).length !== 0) {
        setValueProvider(data.data.service_provider)
        setValueSender(data.data.sms_detail.sender_name)
      }
    }
  }

  const getProviderList = async () => {
    const { status, data } = await getProvider()
    if (status && status === 200) {
      setProviderList(data.data)
    }
  }

  const getSenderList = async id => {
    const { status, data } = await getSender(id)
    let res = []
    if (data && status && status === 200) {
      res = data.data
    }
    return res
  }

  const handleChange = e => {
    setSchedule(e.target.value)
    if (e.target.value === 'daily') {
      setShowSchedule(true)
    } else {
      setShowSchedule(false)
    }
  }

  const CheckmarkBoxCampaign = () => {
    return (
      <CheckmarkBox>
        <div className="row-option" id="parent-node">
          <label className="container">
            <input
              onChange={handleChange}
              id="preview1"
              type="radio"
              name="radio"
              value="onetime"
              checked={schedule === 'onetime'}
              disabled={
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ||
                campaignStatus === 'upcoming'
                  ? true
                  : false
              }
            />
            <span
              className={`  ${
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ||
                campaignStatus === 'upcoming'
                  ? 'checkmark-dis'
                  : 'checkmark'
              }`}
            ></span>
            <span>Send Now</span>
          </label>
          <label className="container">
            <input
              onChange={handleChange}
              id="preview2"
              type="radio"
              name="radio"
              value="daily"
              checked={schedule === 'daily'}
              disabled={
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ||
                campaignStatus === 'upcoming'
                  ? true
                  : false
              }
            />
            <span
              className={`   ${
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ||
                campaignStatus === 'upcoming'
                  ? 'checkmark-dis'
                  : 'checkmark'
              }`}
            ></span>
            <span>Send Later</span>
          </label>
        </div>
      </CheckmarkBox>
    )
  }
  const ProgressbarCampaign = () => {
    return (
      <ProgressbarBox>
        <div className="box">
          <div className="section-progress">
            <ul className="progressbar">
              <li
                className={` ${
                  campaignStatus === 'running' ||
                  campaignStatus === 'done' ||
                  campaignStatus === 'fail' ||
                  campaignStatus === 'upcoming'
                    ? 'step-pass-dis'
                    : 'step-pass'
                }`}
              >
                <span>Select type</span>
              </li>
              <li
                className={` ${
                  campaignStatus === 'running' ||
                  campaignStatus === 'done' ||
                  campaignStatus === 'fail' ||
                  campaignStatus === 'upcoming'
                    ? 'step-pass-dis'
                    : 'step-pass'
                }`}
              >
                <span>Select channel</span>
              </li>
              <li
                className={` ${
                  campaignStatus === 'running' ||
                  campaignStatus === 'done' ||
                  campaignStatus === 'fail' ||
                  campaignStatus === 'upcoming'
                    ? 'active-dis'
                    : 'active'
                }`}
              >
                <span>Campaign</span>
              </li>
            </ul>
          </div>
        </div>
      </ProgressbarBox>
    )
  }

  const callTestSMS = async () => {
    if (testNumber) {
      let temp = new Array()
      temp = testNumber.split(',')
      let obj = {
        sms_detail: {
          sender_name: valueSender,
          message: textMessage
        },
        service_provider: {
          configure_ref_id: providerId
        },
        test_sms_number: temp
      }
      // setOpenPopupTestSMS(true)
      const { data, status } = await testSMS(obj)
      if (data && status === 200) {
        setOpenPopupTestSMS(true)
        setPassTestSMS(true)
      } else {
        // setOpenPopupTestCampaign(false)
        setPassTestSMS(false)
        setOpenPopupTestSMS(true)
      }
    } else {
      console.log('no validate')
    }
  }

  const testPhoneNumber = val => {
    var regex = /^[0-9,]+$/.test(val)
    if (regex) {
      setTestNumber(val)
    }

    if (!val) {
      setTestNumber()
    }
  }

  const calCredit = val => {
    let res = /^[a-zA-Z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~/\s/g]+$/.test(val)
    if (val) {
      if (val.length === 0) {
        setCredit(0)
        setCharacter(161)
      } else {
        if (res) {
          if (val.length <= 160) {
            setCredit(1)
            setMedthod(0)
            setCharacter(161)
          } else if (val.length > 160 && val.length < 306) {
            setCharacter(307)
            setMedthod(0)
            setCredit(2)
          } else if (val.length > 306 && val.length <= 459) {
            setCharacter(459)
            setCredit(3)
            setMedthod(1)
          } else {
            setCharacter(459)
            setCredit(3)
            setMedthod(1)
          }
        } else {
          if (val.length <= 70) {
            setCredit(1)
            setMedthod(0)
            setCharacter(71)
          } else if (val.length > 70 && val.length < 135) {
            setCharacter(135)
            setMedthod(0)
            setCredit(2)
          } else if (val.length > 134 && val.length <= 201) {
            setCharacter(201)
            setCredit(3)
            setMedthod(1)
          } else {
            setCharacter(201)
            setCredit(3)
            setMedthod(1)
          }
        }
      }
      setTextMessage(val)
      setCount(val.length === 0 ? 0 : val.length)
    } else {
      setCount(0)
      setTextMessage('')
    }
  }

  const addDropdownSegment = () => {
    let temp = {
      value: '',
      id: '',
      isOpen: false
    }
    countDropdown.push(temp)
    setCountDropdown(countDropdown.map(item => item))
  }

  const addDropdownExcludeSegment = () => {
    let temp = {
      value: '',
      id: '',
      isOpen: false
    }
    countDropdownExclude.push(temp)
    setCountDropdownExclude(countDropdownExclude.map(item => item))
  }

  const setOpenDropdown = async (option, val) => {
    for (let i = 0; i < countDropdown.length; i++) {
      if (option === 'open') {
        if (i === val.no) {
          countDropdown[i].isOpen = !countDropdown[i].isOpen
        } else {
          countDropdown[i].isOpen = false
        }
      } else if (option === 'remove') {
        setIsLoaddingReachable(true)
        if (i === val.no) {
          countDropdown.splice(val.no, 1)

          let filterReturn = filterByReference(masterSegmentList, countDropdown)
          setSegmentList(filterReturn.map(item => item))

          let _filerInclude = countDropdown.map(item => item.id)
          let _filerExclude = countDropdownExclude.map(item => item.id)

          let obj = {
            include_segment: _filerInclude.filter(item => item !== ''),
            exclude_segment: checkExclude ? _filerExclude.filter(item => item !== '') : []
          }
          let { data, status } = await previewReachableUsers(obj)
          if (data && status === 200) {
            setReachableUsers(data)
            setIsLoaddingReachable(false)
          } else {
            setIsLoaddingReachable(false)
          }
        }
      }
    }
    setCountDropdown(countDropdown.map(item => item))
  }

  const setOpenDropdownExclude = async (option, val) => {
    for (let i = 0; i < countDropdownExclude.length; i++) {
      if (option === 'open') {
        if (i === val.no) {
          countDropdownExclude[i].isOpen = !countDropdownExclude[i].isOpen
        } else {
          countDropdownExclude[i].isOpen = false
        }
      } else if (option === 'remove') {
        setIsLoaddingReachable(true)
        if (i === val.no) {
          countDropdownExclude.splice(val.no, 1)
          let filterReturn = filterByReference(masterSegmentList, countDropdownExclude)
          setSegmentList(filterReturn.map(item => item))
          let _filerInclude = countDropdown.map(item => item.id)
          let _filerExclude = countDropdownExclude.map(item => item.id)
          let obj = {
            include_segment: _filerInclude.filter(item => item !== ''),
            exclude_segment: _filerExclude.filter(item => item !== '')
          }
          let { data, status } = await previewReachableUsers(obj)
          if (data && status === 200) {
            setReachableUsers(data)
            setIsLoaddingReachable(false)
          } else {
            setIsLoaddingReachable(false)
          }
        }
      }
    }
    setCountDropdownExclude(countDropdownExclude.map(item => item))
  }

  const Loading = () => {
    return (
      <div className="text-center main-loadding">
        <div className="overlay-loadding">
          <img className="icon-loadding" src="/assets/images/flipball.gif" />
        </div>
      </div>
    )
  }

  const filterByReference = (arr1, arr2) => {
    let res = []
    res = arr1.filter(el => {
      return !arr2.find(element => {
        return element.id === el.id
      })
    })
    return res
  }

  const filterByReferenceID = (arr1, arr2) => {
    let res = []
    res = arr1.filter(el => {
      return !arr2.find(element => {
        return element.ref_id === el.id
      })
    })
    return res
  }

  const setValueDropdown = async (val, no) => {
    setIsLoaddingReachable(true)
    for (let i = 0; i < countDropdown.length; i++) {
      if (i === no) {
        countDropdown[i].value = val.segment_name
        countDropdown[i].id = val.id
        countDropdown[i].isOpen = false
      } else {
        countDropdown[i].isOpen = false
      }
    }
    setCountDropdown(countDropdown.map(item => item))

    ////// dup segment
    let _filterSegment = segmentList.filter(l => l.id !== val.id)
    setSegmentList(_filterSegment.map(item => item))

    let _filerInclude = countDropdown.map(item => item.id)
    let _filerExclude = countDropdownExclude.map(item => item.id)

    let obj = {
      include_segment: _filerInclude.filter(item => item !== ''),
      exclude_segment: checkExclude ? _filerExclude.filter(item => item !== '') : []
    }
    let { data, status } = await previewReachableUsers(obj)
    if (data && status === 200) {
      setReachableUsers(data)
      setIsLoaddingReachable(false)
    } else {
      setIsLoaddingReachable(false)
    }
  }

  const setValueDropdownExclude = async (val, no) => {
    setIsLoaddingReachable(true)
    for (let i = 0; i < countDropdownExclude.length; i++) {
      if (i === no) {
        countDropdownExclude[i].value = val.segment_name
        countDropdownExclude[i].id = val.id
        countDropdownExclude[i].isOpen = false
      } else {
        countDropdownExclude[i].isOpen = false
      }
    }
    setCountDropdownExclude(countDropdownExclude.map(item => item))
    if (segmentList) {
      let _filterSegment = segmentList.filter(l => l.id !== val.id)
      setSegmentList(_filterSegment.map(item => item))
    }

    let _filerInclude = countDropdown.map(item => item.id)
    let _filerExclude = countDropdownExclude.map(item => item.id)
    let obj = {
      include_segment: _filerInclude.filter(item => item !== ''),
      exclude_segment: _filerExclude.filter(item => item !== '')
    }
    let { data, status } = await previewReachableUsers(obj)
    if (data && status === 200) {
      setReachableUsers(data)
      setIsLoaddingReachable(false)
    } else {
      setIsLoaddingReachable(false)
    }
  }
  const numberWithCommas = x => {
    let str = x.toLocaleString('en-US')
    return str
  }

  const DropdownSegment = data => {
    const refer = useRef()
    useOutsideClick(refer, callback => {
      for (let i = 0; i < countDropdown.length; i++) {
        countDropdown[i].isOpen = false
      }
      setCountDropdown(countDropdown.map(item => item))

      for (let i = 0; i < countDropdownExclude.length; i++) {
        countDropdownExclude[i].isOpen = false
      }
      setCountDropdownExclude(countDropdownExclude.map(item => item))

      setOpenDropdownProvider(false)
      setOpenDropdownSender(false)
    })
    return (
      <DropdownBox>
        <div
          ref={refer}
          className={`box_field time-box ${
            campaignStatus === 'running' ||
            campaignStatus === 'done' ||
            campaignStatus === 'fail' ||
            campaignStatus === 'upcoming'
              ? 'input-disabled'
              : ''
          }`}
          onClick={() =>
            campaignStatus !== 'running' &&
            campaignStatus !== 'done' &&
            campaignStatus !== 'fail' &&
            campaignStatus !== 'upcoming' &&
            setOpenDropdown('open', data)
          }
        >
          <p className="text-dropdown">
            {countDropdown[data.no].value ? (
              countDropdown[data.no].value
            ) : (
              <span className="head-sub-title m-0 p-0">Select Segment</span>
            )}
          </p>
          <i className={`arrow-${countDropdown[data.no].isOpen ? 'up' : 'down'}`}></i>
        </div>
        {countDropdown[data.no].isOpen && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {segmentList.map((list, i) => {
                  return (
                    <li key={i} onClick={() => setValueDropdown(list, data.no)}>
                      {list.segment_name}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
        {data.no !== 0 && (
          <span
            onClick={() =>
              campaignStatus !== 'running' &&
              campaignStatus !== 'done' &&
              campaignStatus !== 'fail' &&
              campaignStatus !== 'upcoming' &&
              setOpenDropdown('remove', data)
            }
            className={`btn-close ${
              campaignStatus === 'running' ||
              campaignStatus === 'done' ||
              campaignStatus === 'fail' ||
              campaignStatus === 'upcoming'
                ? 'icon-no-drop'
                : ''
            }`}
          ></span>
        )}
      </DropdownBox>
    )
  }

  const DropdownExcludeSegment = data => {
    return (
      <DropdownBox>
        <div
          className={`box_field time-box ${
            campaignStatus === 'running' ||
            campaignStatus === 'done' ||
            campaignStatus === 'fail' ||
            campaignStatus === 'upcoming'
              ? 'input-disabled'
              : ''
          }`}
          onClick={() =>
            campaignStatus !== 'running' &&
            campaignStatus !== 'done' &&
            campaignStatus !== 'fail' &&
            campaignStatus !== 'upcoming' &&
            setOpenDropdownExclude('open', data)
          }
        >
          <p className="text-dropdown">
            {countDropdownExclude[data.no].value ? (
              countDropdownExclude[data.no].value
            ) : (
              <span className="head-sub-title m-0 p-0">Select Segment</span>
            )}
          </p>
          <i className={`arrow-${countDropdownExclude[data.no].isOpen ? 'up' : 'down'}`}></i>
        </div>
        {countDropdownExclude[data.no].isOpen && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {segmentList.map((list, i) => {
                  return (
                    <li key={i} onClick={() => setValueDropdownExclude(list, data.no)}>
                      {list.segment_name}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
        {data.no !== 0 && (
          <span
            onClick={() =>
              campaignStatus !== 'running' &&
              campaignStatus !== 'done' &&
              campaignStatus !== 'fail' &&
              campaignStatus !== 'upcoming' &&
              setOpenDropdownExclude('remove', data)
            }
            className="btn-close"
          ></span>
        )}
      </DropdownBox>
    )
  }

  const setValueProvider = async data => {
    setProvider(data)
    setOpenDropdownProvider(false)
    setProviderId(data.configure_ref_id)
    let res = await getSenderList(data.configure_ref_id)
    if (res && res.sender_name) {
      setSenderList(res.sender_name)
    }
  }

  const setValueSender = data => {
    setSender(data)
    setOpenDropdownSender(false)
  }

  const DropdownProvider = () => {
    return (
      <DropdownBox>
        <div
          className={`provider box_field time-box ${
            campaignStatus === 'running' ||
            campaignStatus === 'done' ||
            campaignStatus === 'fail' ||
            campaignStatus === 'upcoming'
              ? 'input-disabled'
              : ''
          }`}
          onClick={() =>
            campaignStatus !== 'running' &&
            campaignStatus !== 'done' &&
            campaignStatus !== 'fail' &&
            campaignStatus !== 'upcoming' &&
            setOpenDropdownProvider(!openDropdownProvider)
          }
        >
          <p className="text-dropdown">
            {valueProvider ? (
              valueProvider.provider_name
            ) : (
              <span className="head-sub-title m-0 p-0">Select SMS service provider</span>
            )}
          </p>
          <i className={`arrow-${openDropdownProvider ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownProvider && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {providerList &&
                  providerList.map((list, i) => {
                    return (
                      <li key={i} onClick={() => setValueProvider(list)}>
                        {list.provider_name}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownSender = () => {
    return (
      <DropdownBox>
        <div
          className={`provider box_field time-box ${
            campaignStatus === 'running' ||
            campaignStatus === 'done' ||
            campaignStatus === 'fail' ||
            campaignStatus === 'upcoming' ||
            !senderList
              ? 'input-disabled'
              : ''
          }`}
          onClick={() =>
            campaignStatus !== 'running' &&
            campaignStatus !== 'done' &&
            campaignStatus !== 'fail' &&
            campaignStatus !== 'upcoming' &&
            (senderList ? setOpenDropdownSender(!openDropdownSender) : '')
          }
        >
          <p className="text-dropdown">
            {valueSender ? (
              valueSender
            ) : (
              <span className="head-sub-title m-0 p-0">Select sender name</span>
            )}
          </p>
          <i className={`arrow-${openDropdownSender ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownSender && (
          <div className="bottom-height">
            <div className="box-dropdown">
              <ul>
                {senderList &&
                  senderList.map((list, i) => {
                    return (
                      <li key={i} onClick={() => setValueSender(list)}>
                        {list}
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }

  const validateSaveDraft = () => {
    let isValidSaveDraft = false
    if (!campaignName) {
      window.scrollTo({
        top: 150,
        behavior: 'smooth'
      })
      isValidSaveDraft = false
      setInValidateCampaignName(true)
      if (showSchedule) {
        if (!dateSchedule) {
          scheduleRef.current.scrollIntoView({ behavior: 'smooth' })
          setInValidateSchedule(true)
          isValidSaveDraft = false
        } else {
          setInValidateSchedule(false)
          isValidSaveDraft = true
        }
      }
    } else {
      setInValidateCampaignName(false)
      isValidSaveDraft = false
      if (showSchedule) {
        // showSchedule : true = 'send later'
        if (!dateSchedule) {
          scheduleRef.current.scrollIntoView({ behavior: 'smooth' })
          setInValidateSchedule(true)
          isValidSaveDraft = false
        } else {
          setInValidateSchedule(false)
          isValidSaveDraft = true
        }
      } else {
        // 'send now'
        setInValidateSchedule(false)
        isValidSaveDraft = true
      }
    }

    return isValidSaveDraft
  }

  const validatePublish = () => {
    let isValidSaveDraft = false
    if (validateSaveDraft()) {
      if (reachableUsers < 1) {
        window.scrollTo({
          top: 150,
          behavior: 'smooth'
        })
        setInValidateReachable(true)
        setOpenPopupConfirm(false)
        setOpenPopupReachable(true)
      } else if (!providerId || !valueSender || !textMessage) {
        messageRef.current.scrollIntoView({ behavior: 'smooth' })
        setInValidateTestCampaign(true)
        setOpenPopupConfirm(false)
        setInValidateReachable(false)
      } else {
        setInValidateTestCampaign(false)
        setInValidateReachable(false)
        isValidSaveDraft = true
      }
    } else {
      setInValidateTestCampaign(false)
      setOpenPopupConfirm(false)
      isValidSaveDraft = false
    }

    return isValidSaveDraft
  }

  const saveCampaign = async option => {
    var localMoment = moment()
    let serverTime = ''
    let isValid = false
    if (option === 'save_draft') {
      if (validateSaveDraft()) {
        isValid = validateSaveDraft()
      }
    } else {
      if (validatePublish()) {
        isValid = validatePublish()
      }
    }

    if (isValid) {
      if (showSchedule && dateSchedule) {
        let day = new Date(dateSchedule._d).getDate()
        let month = new Date(dateSchedule._d).getMonth()
        let year = new Date(dateSchedule._d).getFullYear()
        day = day > 9 ? day : '0' + day
        month = month + 1 > 9 ? month + 1 : '0' + (month + 1)
        let _date = year + '-' + month + '-' + day + ' ' + hours + ':' + mins + ':00 GMT+0700'

        let dateTime = new Date(_date)
        let utc_year = dateTime.getUTCFullYear()
        let utc_month = dateTime.getUTCMonth()
        let utc_date = dateTime.getUTCDate()
        let utc_hours = dateTime.getUTCHours()
        let utc_min = dateTime.getUTCMinutes()
        utc_date = utc_date > 9 ? utc_date : '0' + utc_date
        utc_month = utc_month + 1 > 9 ? utc_month + 1 : '0' + (utc_month + 1)
        utc_hours = utc_hours > 9 ? utc_hours : '0' + utc_hours
        utc_min = utc_min > 9 ? utc_min : '0' + utc_min
        serverTime =
          utc_year + '-' + utc_month + '-' + utc_date + 'T' + utc_hours + ':' + utc_min + ':00.000Z'
      }
      let wrap = countDropdown.filter(item => item.id !== '')
      let _wrap = []
      if (wrap.length !== 0) {
        _wrap = wrap.map(item => (item = { ref_id: item.id }))
      }
      let wrapExclude = countDropdownExclude.filter(item => item.id !== '')
      let _wrapExclude = {}
      if (wrapExclude.length !== 0) {
        _wrapExclude = wrapExclude.map(item => (item = { ref_id: item.id }))
      }

      let type = props.dataType ? props.dataType : campaignType
      let obj = {
        campaign_detail: {
          campaign_name: campaignName,
          campaign_type: type, //campaignType === 'one_time' ? 'one_time' : 'recurring', //** "onetime" or "recurring" **//
          campaign_status: option // "save_draft" or "publish"
        },
        segment_target: {
          include_segment: _wrap,
          exclude_segment: checkExclude ? _wrapExclude : []
        },
        schedule_detail: {
          schedule_type: showSchedule ? 'send_later' : 'send_now', //** "send_now" or "send_later" **//
          schedule_start_date: showSchedule ? serverTime : localMoment.format()
        },
        sms_detail: {
          sender_name: valueSender,
          message: textMessage
        },
        service_provider: {
          configure_ref_id: providerId
        },
        customer_list: reachableUsers !== 0 ? reachableUsers.customer_list : []
      }
      if (!isUpdate) {
        const { data, status } = await createCampaign(obj)
        if (data && status === 200) {
          history.push('/campaign')
        } else {
          setOpenPopupError(true)
        }
      } else {
        obj['ref_id'] = ref_id
        const { data, status } = await editCampaign(obj)
        if (data && status === 200) {
          if (campaignStatus === 'upcoming') {
            window.location.reload()
          } else {
            history.push('/campaign')
          }
        } else {
          setOpenPopupError(true)
        }
      }
    }
  }

  const setValueHH = val => {
    if (val > 23) {
      setHours(23)
    } else {
      setHours(val)
    }
  }

  const setValueMM = val => {
    if (val > 59) {
      setMins(59)
    } else {
      setMins(val)
    }
  }

  const handleChecked = async event => {
    setCheckExclude(!checkExclude)
    setShowExcludePopup(!checkExclude)
    if (event.target.checked) {
      if (countDropdownExclude.length === 0) {
        addDropdownExcludeSegment()
      }
      let _filerInclude = countDropdown.map(item => item.id)
      let _filerExclude = countDropdownExclude.map(item => item.id)

      let obj = {
        include_segment: _filerInclude.filter(item => item !== ''),
        exclude_segment: _filerExclude.filter(item => item !== '')
      }
      setIsLoaddingReachable(true)
      let { data, status } = await previewReachableUsers(obj)
      if (data && status === 200) {
        setReachableUsers(data)
        setIsLoaddingReachable(false)
      } else {
        setIsLoaddingReachable(false)
      }
    } else {
      let _filerInclude = countDropdown.map(item => item.id)
      let obj = {
        include_segment: _filerInclude.filter(item => item !== ''),
        exclude_segment: []
      }
      setIsLoaddingReachable(true)
      let { data, status } = await previewReachableUsers(obj)
      if (data && status === 200) {
        setReachableUsers(data)
        setIsLoaddingReachable(false)
      } else {
        setIsLoaddingReachable(false)
      }
    }
  }

  const ConvertDateTime = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    return day + ' ' + month + ' ' + year + ', ' + time
  }

  const ConvertNormalDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    return day + ' ' + month + ' ' + year
  }

  const stopCampaign = async () => {
    let { data, status } = await deleteCampaign(editDetail.ref_id)
    if (status && status === 200) {
      history.push('/campaign')
    } else {
      console.log('deleteCampaign')
    }
  }
  const duplicateCampaignFn = async () => {
    const { detail } = props
    let obj = {
      ref_id: editDetail.ref_id
    }

    const { data, status } = await duplicateCampaign(obj)
    if (status && status === 200) {
      history.push('/campaign')
    } else {
      console.log('duplicateCampaign Error')
    }
  }
  return (
    <SmsCampaignBox isShowMenu={props.isShowMenu}>
      <div className=" pr-0 pt-0">
        <div className="row top-menu m-0 pb-0">
          <div className="col-6 text-left">
            <p className="title">
              <i
                className="arrow-left"
                onClick={() => (!ref_id ? props.setStepFn(2) : history.push('/campaign'))}
              ></i>
              SMS Campaign
            </p>
          </div>
          <div className="col-6 text-right">
            {campaignStatus ? (
              <Fragment>
                {campaignStatus === 'save_draft' ||
                campaignStatus === 'publish' ||
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ||
                campaignStatus === 'upcoming' ||
                campaignStatus === 'cancel' ? (
                  <a
                    className="btn-basic bg-whiter font-darkgreen border-0 shadow-0"
                    onClick={() => history.push('/campaign')}
                  >
                    Cancel
                  </a>
                ) : (
                  ''
                )}
                {campaignStatus === 'save_draft' || campaignStatus === 'cancel' ? (
                  <a
                    className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-1"
                    onClick={() => saveCampaign('save_draft')}
                  >
                    Save Draft
                  </a>
                ) : (
                  ''
                )}
                {campaignStatus === 'save_draft' || campaignStatus === 'cancel' ? (
                  <a
                    className="btn-basic bg-darkgreen font-white mx-1"
                    onClick={() => setOpenPopupConfirm(true)}
                  >
                    Publish
                  </a>
                ) : (
                  ''
                )}

                {campaignStatus === 'upcoming' ? (
                  <a
                    className="btn-basic bg-darkgreen font-white mx-1"
                    onClick={() => (campaignStatus !== 'publish' ? saveCampaign('save_draft') : '')}
                  >
                    <img src="/assets/icons/paused-w.png" className="w-px-14" /> Pause Campaign
                  </a>
                ) : (
                  ''
                )}

                {campaignStatus === 'publish' ||
                campaignStatus === 'running' ||
                campaignStatus === 'done' ||
                campaignStatus === 'fail' ? (
                  <a
                    className="btn-basic bg-darkgreen font-white mx-1"
                    onClick={() => duplicateCampaignFn()}
                  >
                    <img src="/assets/icons/dup-w.png" className="w-px-14" /> Duplicate
                  </a>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              <Fragment>
                <a
                  className="btn-basic bg-whiter font-darkgreen border-0 shadow-0"
                  onClick={() => setOpenPopupExitNow(true)}
                >
                  Cancel
                </a>
                <a
                  className="btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
                  onClick={() => saveCampaign('save_draft')}
                >
                  Save Draft
                </a>
                <a
                  className="btn-basic bg-darkgreen font-white"
                  onClick={() => setOpenPopupConfirm(true)}
                >
                  Publish
                </a>
              </Fragment>
            )}
          </div>
          <ButtonGroup>
            {types.map(type => (
              <Tab key={type} active={active === type} onClick={() => activeTab(type)}>
                {type}
              </Tab>
            ))}
          </ButtonGroup>
          <p />
        </div>
        <div className="px-4">
          {active === 'Campaign' ? (
            <Fragment>
              <ProgressbarCampaign />
              <div className="tab-content">
                <div className="active">
                  <div className="row">
                    <div className="col-8">
                      <div className="card mb-4">
                        <div className="card-body">
                          <p className="title-16 m-0">Campaign Name</p>
                          <input
                            type="text"
                            className={`input w-400 ${
                              inValidateCampaignName ? '-input-invalid' : ''
                            }`}
                            placeholder="Enter your campaign name"
                            value={campaignName}
                            onChange={e => setCampaignName(e.target.value)}
                            disabled={
                              campaignStatus === 'running' ||
                              campaignStatus === 'done' ||
                              campaignStatus === 'fail' ||
                              campaignStatus === 'upcoming'
                                ? true
                                : false
                            }
                          />
                          {inValidateCampaignName && (
                            <p className="font-darkpink f-14">Campaign name is required</p>
                          )}
                          <p className="title-16 mt-4">Campaign Type</p>
                          <p className="sub-title-14 mt-2 text-black fw-0">
                            {campaignType
                              ? campaignType === 'one_time'
                                ? 'One-time'
                                : 'Recurring'
                              : props.dataType === 'one_time'
                              ? 'One-time'
                              : 'Recurring'}
                          </p>
                        </div>
                      </div>

                      <div className={`card mb-4 ${inValidateReachable ? '-input-invalid' : ''}`}>
                        <div className="card-header">
                          <p className="title-16 font-weight-bold my-1">Target</p>
                        </div>
                        <div className="card-body">
                          <p className="title-16">Select Segment</p>
                          {inValidateReachable && (
                            <Fragment>
                              <div className="d-flex">
                                <img className="icon-mini" src="/assets/icons/information.png" />
                                <p className="font-darkpink f-14">
                                  Reachable users of segment you select need to be at least 1 user.
                                  <br /> Please re-select segment(s)
                                </p>
                              </div>
                            </Fragment>
                          )}
                          {countDropdown &&
                            countDropdown.map((item, i) => {
                              return (
                                <div className="mt-2" key={i}>
                                  {i !== 0 && <p className="title-16 ml-190px">And</p>}
                                  <DropdownSegment data={item} no={i} />
                                </div>
                              )
                            })}

                          <div className="mt-2">
                            <span
                              onClick={() =>
                                campaignStatus !== 'running' &&
                                campaignStatus !== 'done' &&
                                campaignStatus !== 'fail' &&
                                campaignStatus !== 'upcoming' &&
                                addDropdownSegment()
                              }
                              className={`sub-title-14 ${
                                campaignStatus === 'running' ||
                                campaignStatus === 'done' ||
                                campaignStatus === 'fail' ||
                                campaignStatus === 'upcoming'
                                  ? 'text-disabled'
                                  : '-blue '
                              }`}
                            >
                              {' '}
                              + Add Segment
                            </span>
                          </div>

                          <br />
                          <div className="exclude">
                            <label className="container-checkbox m-0">
                              <input
                                type="checkbox"
                                checked={checkExclude}
                                onChange={handleChecked}
                                disabled={
                                  campaignStatus === 'running' ||
                                  campaignStatus === 'done' ||
                                  campaignStatus === 'fail' ||
                                  campaignStatus === 'upcoming'
                                    ? true
                                    : false
                                }
                              />
                              <span
                                className={`checkmark ${
                                  campaignStatus === 'running' ||
                                  campaignStatus === 'done' ||
                                  campaignStatus === 'fail' ||
                                  campaignStatus === 'upcoming'
                                    ? 'bgcheckmark-dis checkmark-dis'
                                    : ''
                                }`}
                              ></span>
                              <label className="text-exclude">Exclude Segment</label>
                            </label>
                          </div>
                          {showExcludePopup &&
                            countDropdownExclude &&
                            countDropdownExclude.map((item, i) => {
                              return (
                                <div className="mt-2" key={i}>
                                  {i !== 0 && <p className="title-16 ml-190px">And</p>}
                                  <DropdownExcludeSegment data={item} no={i} />
                                </div>
                              )
                            })}
                          {showExcludePopup && (
                            <div className="mt-2">
                              <span
                                onClick={() =>
                                  campaignStatus !== 'running' &&
                                  campaignStatus !== 'done' &&
                                  campaignStatus !== 'fail' &&
                                  campaignStatus !== 'upcoming' &&
                                  addDropdownExcludeSegment()
                                }
                                className={`sub-title-14 ${
                                  campaignStatus === 'running' ||
                                  campaignStatus === 'done' ||
                                  campaignStatus === 'fail' ||
                                  campaignStatus === 'upcoming'
                                    ? 'text-disabled'
                                    : '-blue '
                                }`}
                              >
                                {' '}
                                + Add Segment
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="card mb-4" ref={scheduleRef}>
                        <div className="card-header">
                          <p className="title-16 font-weight-bold my-1">Schedule</p>
                        </div>
                        <div className="card-body">
                          <p className="sub-title-14 dark-gray f-b">Delivery time</p>
                          <div className="mt-2">
                            <CheckmarkBoxCampaign />
                          </div>
                          {showSchedule && (
                            <div className="mt-3 d-flex">
                              <div className="form-group  mr-3">
                                <span className="mb-2 d-block sub-title-14 dark-gray f-b">
                                  Date
                                </span>
                                <div className={`inner-segment`}>
                                  <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    className={`box_field  ${
                                      campaignStatus === 'running' ||
                                      campaignStatus === 'done' ||
                                      campaignStatus === 'fail' ||
                                      campaignStatus === 'upcoming'
                                        ? 'date-box-dis'
                                        : 'date-box'
                                    } ${inValidateSchedule ? ' -input-invalid' : ''}`}
                                    // showTime
                                    onChange={date => {
                                      setDateSchedule(date)
                                    }}
                                    disabled={
                                      campaignStatus === 'running' ||
                                      campaignStatus === 'done' ||
                                      campaignStatus === 'fail' ||
                                      campaignStatus === 'upcoming'
                                        ? true
                                        : false
                                    }
                                    value={dateSchedule}
                                    disabledDate={current => {
                                      return (
                                        moment().add(-1, 'days') >= current ||
                                        moment().add(1, 'month') <= current
                                      )
                                    }}
                                    // onOk={onOk}
                                  />
                                </div>
                                {inValidateSchedule && (
                                  <p className="font-darkpink f-14">Please select date and time</p>
                                )}
                              </div>
                              <div className="form-group">
                                <span className="mb-2 d-block sub-title-14 dark-gray f-b">
                                  Time
                                </span>
                                <div className={`inner-segment`}>
                                  <input
                                    type="number"
                                    className={`input-mini ${
                                      inValidateSchedule ? '-input-invalid' : ''
                                    }`}
                                    min="0"
                                    max="23"
                                    maxLength="2"
                                    value={hours}
                                    disabled={
                                      campaignStatus === 'running' ||
                                      campaignStatus === 'done' ||
                                      campaignStatus === 'fail' ||
                                      campaignStatus === 'upcoming'
                                        ? true
                                        : false
                                    }
                                    onChange={e => setValueHH(e.target.value)}
                                    placeholder="HH"
                                  />{' '}
                                  :{' '}
                                  <input
                                    type="number"
                                    className={`input-mini ${
                                      inValidateSchedule ? '-input-invalid' : ''
                                    }`}
                                    min="0"
                                    max="59"
                                    maxLength="2"
                                    value={mins}
                                    disabled={
                                      campaignStatus === 'running' ||
                                      campaignStatus === 'done' ||
                                      campaignStatus === 'fail' ||
                                      campaignStatus === 'upcoming'
                                        ? true
                                        : false
                                    }
                                    onChange={e => setValueMM(e.target.value)}
                                    placeholder="mm"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className={`card mb-4 ${inValidateTestCampaign ? '-input-invalid' : ''}`}
                      >
                        <div className="card-header">
                          <p className="title-16 font-weight-bold my-1">Message</p>
                        </div>
                        <div className="card-body" ref={messageRef}>
                          {inValidateTestCampaign && (
                            <div className="d-flex">
                              <img className="icon-mini" src="/assets/icons/information.png" />
                              <p className="font-darkpink f-14">
                                SMS service provider, Sender name and Message are required
                              </p>
                            </div>
                          )}
                          <p className="sub-title-14">SMS Service Provider</p>
                          <DropdownProvider />

                          <p className="sub-title-14 mt-4">Sender Name</p>
                          <DropdownSender />

                          <div className="mt-2">
                            <p className="sub-title-14">Message</p>
                            <p className="sub-title-12 text-secondary">
                              Character Limit
                              <img
                                className="icon-tooltip"
                                src="/assets/icons/tooltips.png"
                                alt="information"
                              />
                            </p>
                            <textarea
                              className={`form-control ${
                                campaignStatus === 'running' ||
                                campaignStatus === 'done' ||
                                campaignStatus === 'fail' ||
                                campaignStatus === 'upcoming'
                                  ? 'input-disabled'
                                  : ''
                              }`}
                              maxLength={character}
                              value={textMessage}
                              onChange={e => calCredit(e.target.value)}
                              rows="6"
                              disabled={
                                campaignStatus === 'running' ||
                                campaignStatus === 'done' ||
                                campaignStatus === 'fail' ||
                                campaignStatus === 'upcoming'
                                  ? true
                                  : false
                              }
                            ></textarea>
                            <div className="row mt-3">
                              <div className="col-6">
                                <p className="sub-title-12 ">
                                  <span>
                                    {count}/{method === 0 ? character - 1 : character}
                                  </span>{' '}
                                  Characters
                                </p>
                                <p className="sub-title-12  ">
                                  Count as <b> {credit} </b>Credit for <b>1</b> User
                                </p>
                              </div>
                              <div className={`col-6 text-right`}>
                                <a
                                  className={`btn-basic bg-darkblue font-white ${
                                    campaignStatus === 'running' ||
                                    campaignStatus === 'done' ||
                                    campaignStatus === 'fail' ||
                                    campaignStatus === 'upcoming' ||
                                    !providerId ||
                                    !valueSender ||
                                    !textMessage
                                      ? '-btn-disable'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    campaignStatus !== 'running' &&
                                    campaignStatus !== 'done' &&
                                    campaignStatus !== 'fail' &&
                                    campaignStatus !== 'upcoming' &&
                                    providerId &&
                                    valueSender &&
                                    textMessage &&
                                    setOpenPopupTestCampaign(true)
                                  }
                                >
                                  Test Campaign
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card mb-4">
                        <div className="card-header">
                          <p className="title-16 font-weight-bold my-1">Reachable User (SMS)</p>
                        </div>
                        {isLoaddingReachable && <Loading />}

                        <div className="card-body text-center">
                          <p
                            className={` ${
                              campaignStatus === 'running' ||
                              campaignStatus === 'done' ||
                              campaignStatus === 'fail' ||
                              campaignStatus === 'upcoming'
                                ? 'big-title m-3 p-3 font-softgray'
                                : 'big-title m-3 p-3'
                            }`}
                          >
                            {reachableUsers !== 0
                              ? numberWithCommas(reachableUsers.customer_list_count)
                              : 0}
                          </p>
                          <p className="f-14 text-dark">Target users from segment you select.</p>
                          <p className="f-14 font-softgray align-left indent-left">
                            <img src="/assets/icons/tooltips.png" className=" w-px-14 mr-2" />
                            This number is excluded duplicated and invalid data.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : active === 'Report' ? (
            // <ReportComponent />
            <div className="tab-content mt-5">
              <div className="active">
                <div className="row">
                  <div className="col-12">
                    {getReport ? (
                      <div className="card mb-4">
                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              <p className="f-16 font-darkblue font-weight-bold m-0 py-2">
                                Overview
                              </p>
                            </div>
                            <div className="col-6 text-right">
                              <CSVLink
                                filename={`Report_SMS_${getReport.campaign_name}_${getReport.campaign_type}_${getReport.schedule_start_date}.csv`}
                                data={rowReport}
                                headers={columnReport}
                              >
                                <a className="btn-basic bg-darkblue font-white w-50">
                                  <img src="/assets/icons/load-w.png" className="w-px-14 mx-1" />{' '}
                                  Download Full Report (.CSV)
                                </a>
                              </CSVLink>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-5">
                              <p className="f-16 font-darkblue font-w-600">Campaign Info</p>
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      Campaign Name
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {getReport.campaign_name ? getReport.campaign_name : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      Type
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {getReport.campaign_type
                                        ? getReport.campaign_type === 'one_time'
                                          ? 'One time'
                                          : 'Recurring'
                                        : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      Start at
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {getReport.schedule_start_date
                                        ? ConvertDateTime(getReport.schedule_start_date)
                                        : ''}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      Credit Used
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {' '}
                                      {getReport.total_credit ? getReport.total_credit : 0}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-4">
                              <p className="f-16 font-darkblue font-w-600">Performance</p>
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      #Total Users
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {' '}
                                      {getReport.total_user ? getReport.total_user : 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      #Delivered
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {' '}
                                      {getReport.total_success ? getReport.total_success : 0}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th
                                      scope="row"
                                      className="border-0 font-darkblue f-14 w-35 font-w-100 py-2"
                                    >
                                      #Failure
                                    </th>
                                    <td className="border-0 font-darkblue w-10">:</td>
                                    <td className="border-0 font-darkgreen f-14 font-w-600">
                                      {' '}
                                      {getReport.total_fail ? getReport.total_fail : 0}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-2 text-center">
                              <CircularProgressbar
                                value={
                                  getReport.percentage_success ? getReport.percentage_success : 0
                                }
                                text={
                                  getReport.percentage_success
                                    ? getReport.percentage_success + '%'
                                    : 0 + '%'
                                }
                              />
                              <p className="f-14 font-darkblue font-w-100 m-0 mt-3">
                                Delivered Success
                              </p>
                              <p className="f-14 font-darkgreen font-w-600 m-0">
                                {' '}
                                {getReport.total_success ? getReport.total_success : 0}
                                {getReport.total_success
                                  ? getReport.total_success > 1
                                    ? ' Users'
                                    : ' User'
                                  : ' User'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="card mb-4 index-card">
                        <div className="card-body">
                          <div className="text-center">
                            <img
                              className="img-300 p-3 mx-2"
                              src={`/assets/images/report-2.png`}
                              alt="logo"
                            />
                            <p className="f-20 font-darkblue font-w-500 w-40 mx-auto my-0">
                              Your report is not available for now.
                            </p>
                            <p className="f-14 font-gray font-w-100">
                              Report will be automatically created after the campaign ended.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupSorry}
        onRequestClose={() => setOpenPopupSorry(false)}
        style={customStyles}
      >
        <div className="body-popup ">
          <div className="text-center">
            <img src="/assets/icons/ErrorWarning.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Sorry</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              This scheduled campaign is running now. <br></br>You cannot edit details in this
              campaign.
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPopupSorry(false)}
                className="btn-basic bg-darkgreen font-white"
              >
                OK
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupExitNow}
        onRequestClose={() => setOpenPopupExitNow(false)}
        style={customStyles}
      >
        <div className="close" onClick={() => setOpenPopupExitNow(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        <div className="body-popup">
          <div className="text-center">
            <p className="f-20 font-w-600 font-darkblue my-2">Exit Now?</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              Your campaign change will be lost if you exit now. <br></br>Would you like to save
              your progress as a draft?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => history.push('/campaign')}
                className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              >
                Leave without saving
              </a>
              <a
                onClick={() => (campaignStatus !== 'publish' ? saveCampaign('save_draft') : '')}
                className="btn-basic bg-darkgreen font-white"
              >
                Save Draft
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupConfirm}
        onRequestClose={() => setOpenPopupConfirm(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/Paper_Plane.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Confirm to Publish Now?</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              This campaign is set to send on{' '}
              <span className="font-darkblue font-weight-bold">
                {showSchedule && dateSchedule && dateSchedule._d
                  ? ConvertNormalDate(dateSchedule._d) +
                    ', ' +
                    (hours < 10 ? '0' + hours : hours) +
                    ':' +
                    (mins < 10 ? '0' + mins : mins)
                  : '“Send Now”'}{' '}
              </span>
              <br></br>
              Do you confirm to publish this campaign?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPopupConfirm(false)}
                className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              >
                Cancel
              </a>
              <a
                onClick={() => saveCampaign('publish')}
                className="btn-basic bg-darkgreen font-white"
              >
                Confirm
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/bin.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Delete Campaign?</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-2">
              This campaign will be deleted from the list.
              <br /> Do you confirm to delete this campaign?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPopup(false)}
                className=" btn-basic bg-whiter border-darkpink font-darkpink mx-2"
              >
                Cancel
              </a>
              <a onClick={() => stopCampaign()} className=" btn-basic  bg-darkpink font-white">
                Delete campaign
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupTestCampaign}
        onRequestClose={() => setOpenPopupTestCampaign(false)}
        style={customStyles}
      >
        {' '}
        <div className="close" onClick={() => setOpenPopupTestCampaign(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        <div className="body-popup">
          <div className=" text-left px-3">
            <p className="f-20 font-w-600 font-darkblue">Test Campaign</p>
            <p className="f-12 font-weight-normal font-gray my-3">
              Please add phone number you would like to test. If there are multple phone numbers,
              <br></br>
              please use “,” (comma) to seperate the numbers.
            </p>
            <p className="f-12 font-weight-normal font-gray my-3">
              • Testing SMS campaign is optional and not a required step.<br></br>• Testing SMS
              campaign will be deducted from your SMS credits.
            </p>
            <p className="f-14 font-w-600 font-darkblue my-2">Phone Number (s)</p>
            <textarea
              className="form-control"
              value={testNumber ? testNumber : ''}
              rows="3"
              onChange={e => testPhoneNumber(e.target.value)}
            ></textarea>
            <div className="footer-popup text-right my-2">
              <a
                onClick={() => testNumber && callTestSMS()}
                className={`btn-basic bg-darkblue font-white ${!testNumber ? '-btn-disable' : ''}`}
              >
                Send Test Campaign
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupTestSMS}
        onRequestClose={() => setOpenPopupTestSMS(false)}
        style={customStyles}
      >
        <div className="body-popup ">
          <div className="text-center">
            <img
              src={`/assets/icons/${passTestSMS ? 'Success' : 'fail'}.png`}
              className="w-px-65"
            />
            <p
              className={`f-20 font-w-600 ${passTestSMS ? 'font-darkblue' : 'font-darkpink'} my-4`}
            >
              {passTestSMS ? 'Test Campaign (SMS) has sent' : 'Test Campaign Failed '}
            </p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              {passTestSMS ? (
                <Fragment>
                  The test campaign was successfully sent. <br />
                  Please check your device(s) to review the campaign.
                </Fragment>
              ) : (
                <Fragment>
                  The test campaign was failed to sent. <br />
                  Please contact your selected SMS service provider.
                </Fragment>
              )}
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => {
                  setOpenPopupTestSMS(false)
                  setOpenPopupTestCampaign(passTestSMS ? false : true)
                }}
                className={`btn-basic ${
                  passTestSMS ? 'bg-darkblue font-white' : 'bg-darkpink font-white'
                }`}
              >
                OK
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupError}
        onRequestClose={() => setOpenPopupError(false)}
        style={customStyles}
      >
        <div className="body-popup ">
          <div className="text-center">
            <img src={`/assets/icons/fail.png`} className="w-px-65" />
            <p className={`f-20 font-w-600 font-darkpink my-4`}>Error</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              <Fragment>
                mockup <br />
                error
              </Fragment>
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => {
                  setOpenPopupError(false)
                }}
                className={` btn-basic  bg-darkpink font-white`}
              >
                OK
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupReachable}
        onRequestClose={() => setOpenPopupReachable(false)}
        style={customStyles}
      >
        <div className="body-popup ">
          <div className="text-center pt-10">
            <img src={`/assets/icons/ErrorWarning.png`} className="w-px-65" />
            <p className={`f-20 font-w-600 font-darkblue my-4`}>No user to send campaign.</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              <Fragment>
                Reachable users of segment you select need to be <br />
                at least 1 user. Please re-select target in order to send a campaign.
              </Fragment>
            </p>
            <div className="footer-popup text-center my-4">
              <button
                onClick={() => {
                  setOpenPopupReachable(false)
                }}
                className={`btn-basic bg-gray font-white`}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </SmsCampaignBox>
  )
}

export default SmsCampaignComponent
