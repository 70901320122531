import styled from 'styled-components'

const Tab = styled.button`
  font-size: 14px;
  padding: 10px 27px;
  background: white;
  border: 0;
  outline: 0;
  color: #334d6e;
  padding-left: 14px;
  padding-right: 14px;
  ${({ active }) =>
    active &&
    `
border-bottom: 3px solid #24b0c3;
opacity: 1;
color: #24b0c3;
`}
`

const ButtonGroup = styled.div`
  display: flex;
`

const CheckmarkBox = styled.div`
  .row-option {
    display: flex;
    margin: 0px;
    width: 400px;
    .container {
      width: 120px;
      margin: 7px 26px;
      span {
        font-size: 14px;
        color: #385172;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      height: 25px;
      width: 25px;
      cursor: pointer;
      border: solid 1px #919fb6;
      border-radius: 50%;
      margin-left: -40px;
      :hover {
        border: solid 1px #385172;
      }
    }
    .container input:checked ~ .checkmark {
      background-color: #fff;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 3px;
      left: 3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: #385172;
    }
  }

  .row-option {
    display: flex;
    margin: 0px;
    width: 400px;
    .container {
      width: 120px;
      margin: 7px 26px;
      span {
        font-size: 14px;
        color: #385172;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark-dis {
      position: absolute;
      height: 25px;
      width: 25px;
      cursor: no-drop;
      border: solid 1px #aabaca;
      border-radius: 50%;
      margin-left: -40px;
      :hover {
        border: solid 1px #aabaca;
      }
    }
    .container input:checked ~ .checkmark-dis {
      background-color: #fff;
    }

    .checkmark-dis:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark-dis:after {
      display: block;
    }

    .checkmark-dis:after {
      top: 3px;
      left: 3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: #aabaca;
    }
  }
`
const SmsCampaignBox = styled.div`

input:disabled {
  cursor: no-drop;
  background-color: #fafafa;
  color:#334d6e;
  border-color: solid 1px #aabaca !important;
}

.input-disabled {
  cursor: no-drop !important;
  background-color: #fafafa !important;
  color:#334d6e !important;
  border-color: solid 1px #aabaca !important;
}
.text-disabled {
  cursor: no-drop !important;
  color:#334d6e !important;
}
.icon-no-drop {
  cursor: no-drop !important;
}

.-input-invalid {
  border:1px solid #ff2c65 !important;
}
.align-left{
  text-align:left
}
.-btn-disable{
  cursor:no-drop!important;
  color:#ffffff!important;
  background-color: #aabaca!important;
}

.icon-mini{
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 5px;
}
 
.h-45{
  height:45px;
}
.indent-left{
  text-indent: -21px;
  margin-left: 15px;
}
.bg-blue{
  border:unset;
  background-color: #24b0c3;
}
.index-card {
  border-radius: 6px;
  border: 0;
  box-shadow: 4px 1px 10px 2px rgb(0 0 0 / 10%);
  min-height: 628px;
  padding: 10%;
}
.img-300 {
  width: 300px;
}
.head-handling {
  font-size: 16px;
  color: #334d6e;
}
.handling {
  font-size: 14px;
  color: #6f809e;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke-width: 5px;
  stroke: #24b0c3;
  stroke-linecap: unset;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke:#e4ebf2;
  stroke-width: 5px;
}
.CircularProgressbar-text {
  fill: #24b0c3!important;
}

.CircularProgressbar {
  width: 100%;
  margin: 0px;
}
.ml-190px{
  margin-left:190px;
}
.-blue{
  color:#24b0c3!important;
  font-weight:400!important;
}
.text-exclude{
  margin-left: 20px;
  color: #334d6e;
  font-size: 14px;
  margin-top:1px;
}

.exclude{
/* Create a custom checkbox */
  .checkmark {
    position: absolute;
    margin-left: 20px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border:1px solid #aabaca;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  .container-checkbox:hover input ~ .checkmark {
    border:1px solid #334d6e;
    background-color: #334d6e;
  }

  /* When the checkbox is checked, add a blue background */
  .container-checkbox input:checked ~ .checkmark {
    background-color: #334d6e;
    border:1px solid #334d6e;
    border-radius: 3px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .bgcheckmark-dis {
    background-color: #fafafa !important;
    border:1px solid #aabaca !important;
    cursor: no-drop !important;
  
  }
  .container-checkbox:hover input ~ .checkmark-dis {
    border:1px solid #aabaca !important;
    background-color: #fafafa !important;
    cursor: no-drop !important;
  }
}
.btn-condition {
  background-color: #385172;
  color: #fff !important;
  border: 0 !important;
  line-height: 2.3 !important;
}
.card{
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05)!important;
}
.card-header{
  background-color:#fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.tab-content{
  .active{
    cursor:unset!important;
  }
}
.f-14{
  font-size: 14px;
}
.overlay-loadding {
  width: 100%;
  display: table;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
  opacity: 1;
  padding: 30% 11%;
  position: absolute;
  text-align:center;
  .icon-loadding{
    width:50px;
  }
}
.-sticky{
  position: sticky;
  top: 8rem;
}

.-fixed{
  position: fixed;
  top: 16rem;
}

.input{
  padding: 12px 25px!important;
}

 

.input::placeholder{
  color: #aabaca;
}

.input-disabled{
  border: solid 1px #aabaca !important;
  background-color: #fafafa !important;
  cursor: no-drop;
}
.big-title{
  font-size: 60px;
  color: #24b0c3;
}
.title-16{
  font-size: 16px;
  font-weight: 600;
  color: #344d6f;
}
.fw-0{
  font-weight:unset!important;
}
.sub-title-14{
  font-size: 14px;
  color:#334d6e;
  font-weight: 500;
}
.sub-title-12{
  font-size: 12px;
  color:#334d6e;
  span{
    font-weight: 600;
  }
}
.-blue {
  cursor: pointer;
}

.date-box{
  border-radius: 6px;
  border: solid 1px #aabaca;
  background-color: #fff;
  width: 234px;
  height: 44px;
}
.date-box-dis {
  border-radius: 6px;
  border: solid 1px #aabaca;
  background-color: #fafafa;
  width: 234px;
  height: 44px;
}

.input-mini{
  width: 68px;
  height: 44px;
  text-align: center;
  border-radius: 6px;
  border: solid 1px #aabaca;
  background-color: #fff;
  padding: 11.1px 3.1px 13.1px 15.1px;
  color:#334d6e;
  :focus-visible{
    outline:none;
  }
  :place-holder{
    color:#aabaca;
  }
}

.dark-gray{
  color:#385172;
}
.f-b{
  font-weight:500;
}

}
.input {
  outline: none;
  color: #385172;
  width: 414px;
  height: 50px;
  margin: 10px 0 0;
  padding: 15px 30px 14px 30px;
  border-radius: 10px;
  border: solid 1.5px #aabaca;
  background-color: #fff;
}
.input:focus {
  box-shadow: 0 0 6px 0 rgb(36 176 195 / 50%);
  border: solid 1.5px #24b0c3;
}
.card{
  border-radius: 8px;
  border: 0;
  box-shadow: 2px -2px 10px 3px rgb(0 0 0 / 9%);
}
.-active{
  cursor: pointer;
  color: #20adc2;
  border-radius: 0;
  width: auto;
  margin-left: -10px;
  padding: 7px 10px;
  display: block;
  background-color: #ffffff00;
  border-bottom: 2px solid;
  a{
    color: #20adc2;
  }
  img{
    width: 15px;
  }
}

li {
  a:hover {
    cursor: pointer;
    color: #20adc2 !important;
    border-radius: 0;
    width: max-content !important;
    margin-left: -10px;
    padding: 7px 10px;
    display: block;
    background-color: #082c3800 !important;

  }
  
  a {
    cursor: pointer;
    color: #344d6f;
    border-radius: 0;
    width: auto;
    margin-left: -10px;
    padding: 7px 10px;
    display: block;
 
    
  }

}
.arrow-left {
  border: solid #385172;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0px;
  margin-top: 9px;
  margin-left: -12px;
  position: absolute;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  width: 10px;
  height: 10px;
}
.title {
  color: #334d6e;
  font-size: 18px;
  font-weight: 600;

}
.top-menu{ 
    padding: 15px 20px 10px 20px;
    color: #6a707e;
    width: 100%;
    background-color: #fff;
    text-align: right;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    border-top: 1px solid #eef1f5;
    z-index: 99;
}
.btn {
  min-width: 120px;
  width:auto;
  height: 44px;
  margin: 0 0 0 10px;
  border-radius: 8px;
  border: solid 1.5px #24b0c3;
  color: #24b0c3;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.btn-red{
  background-color: #ff2c65;
  border: solid 1.5px #ff2c65;
  color:white;
}

.btn-disable{
  border: solid 1px #aabaca;
  background-color: #fff;
  color:#aabaca;
  cursor:no-drop!important;
}

.-active-btn {
  background: #aabaca !important;
  color: #fff !important;
  cursor: no-drop !important;
  border: 1px solid #aabaca !important;
}
  .box {
    line-height: 30px;
    border-radius: 100%;
    text-align: center;
  }

  .-status {
    display: inline-block;
    padding: 5px 20px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    width: 100%;
}

.running{
  background-color: #d4f5eb;
  color: #28cc97;
}
.span-next{
  font-size: x-small;
  color: #aabaca;
}
.title-type {
  color: #334d6e;
  font-size: 20px;
  font-weight: 600;
}
.sub-title{
  font-size: 16px;
  color: #385172;
  font-weight: normal;
  span{
    font-weight: 600;
    color: #24b0c3; 
  }
}
.desc-type {
  font-size: 14px;
  color: #6a707e;
  margin: auto 15px;
}
  .navigation-bar {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    color: #1e334e;
    span {
      color: #9daeb9;
    }
  }
  .breadcrumb-box {
    color: #334d6e;
    margin-left: 24px;
    cursor: pointer;
  }
  .section-main {
    width: 100%;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 0px;
    padding: 40px 56px;
    text-align: center;
    border: 2px solid #efeff7;
    border-left: 0;
    border-right: 0;
    .title {
      font-family: 'Inter';
      color: #334d6e;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    .section-body {
      display: flex;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .list-type-box {
      cursor: pointer;
      border-radius: 10px;
      background-color: #ffffff;
      width: 300px;
      height: 300px;
      margin: 20px;
      border: 1px solid #aabaca;
      padding: 2px 20px;
    }
    .section-tooltip {
      text-align: right;

      .icon-tooltip {
        width: 12px;
        margin-top: -2px;
        margin-right: 8px;
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .list-type-box {
      .list-icon {
        height: 90px;
      }
      img {
        margin-top: 20px;
        width: 60px;
      }
    }
    .list-type-box:hover {
      border: 3px solid #39BCDE;
      padding: 0px 18px;
  }
  }
  .tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 400px;
      background-color: #00252f;
      color: #fff;
      border-radius: 0px;
      padding: 5px 0;
      position: absolute;
      opacity: 1;
      z-index: 1;
      bottom: 24px;
      margin-left: -132px;
      padding: 15px;
      font-family: inter;
    }
    .tooltiptext::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 55px;
      border-width: 8px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
.section-select-source {
  display: flex;
  margin: 20px 0px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.box-select-source:hover {
  padding: 19px;
  border: solid 2px #24b0c3;
}

.box-select-source {
  cursor: pointer;
  width: 320px;
  height: 110px;
  margin: 0 20px 20px 0; 
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #aabaca;
  background-color: #fff;
  display: flex;
  .box-image-source {
    cursor: pointer;
    img {
      width: 43px;
      margin-top: 14px;
      margin-left: 4px;
    }
  }
  .box-name-source {
    padding: 5px 20px;
    text-align: left;
    cursor: pointer;
    p {
      font-size: 16px;
      color: #334d6e;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 5px;
    }
    label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #6f809e;
    cursor: pointer;
    }
  }
}
`
const TableBox = styled.div`
  .scroll-x {
    overflow-x: auto;
    border-radius: 0em;
    // box-shadow: 4px -2px 6px 4px #f2f2f4;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    color: #334d6e;
    font-family: Inter;
    font-size: 13px;

    thead {
      tr {
        background-color: #f1f5f9;
      }
    }
    tr {
      height: 60px;
      background-color: #fff;
    }
    th {
      padding: 10px 10px;
      background-color: #edf1f4;
      border-bottom: 1px solid #dfe0eb;
      text-align: left;
      font-weight: normal;
      font-size: 14px;
      vertical-align: middle;
      border: 0px;
    }
    td {
      padding: 10px;
      border: unset;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
      vertical-align: middle;
    }
    tr:nth-child(even) {
      background: #fafcfe;
    }
    tbody {
      tr:hover {
        background: #efeff7 !important;
      }
    }
  }
  .w-5 {
    width: 5%;
  }
  .icon-option {
    margin-top: 11px;
    cursor: pointer;
    width: 25px;
    height: 7px;
    background: url(/assets/icons/option.png) no-repeat;
    display: inline-block;
    background-size: 17px 5px;
  }
  .icon-option:hover {
    background: url(/assets/icons/option-active.png) no-repeat;
    background-size: 17px 5px;
  }
`

const ProgressbarBox = styled.div`
  .section-progress {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 60px;
    li {
      padding: unset;
    }
    .progressbar {
      padding: unset;
    }
    .progressbar li {
      list-style-type: none;
      width: 205px;
      float: left;
      font-size: 14px;
      position: relative;
      text-align: center;
      color: #c2cfe0;
      display: flex;
      span {
        padding: 0px 0px 0px 10px;
        line-height: 1.2;
        font-weight: 500;
        font-size: 16px;
        margin-top: 0px;
      }
    }
    .progressbar li:before {
      background-image: url('/assets/icons/unselect.png');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 20px;
      height: 20px;
      content: '';
      color: transparent;
      line-height: 35px;
      display: inline-table;
      margin: 0px;
      border-radius: 50%;
      background-color: #c2cfe0;
    }
    .progressbar li:after {
      width: 50px;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #c2cfe0;
      top: 10px;
      left: -60px;
    }
    .progressbar li:first-child:after {
      content: none;
    }
    .progressbar li.active {
      color: #334d6e;
    }

    .progressbar li.step-pass {
      color: #334d6e;
    }
    .progressbar li.active:before {
      background-color: transparent;
      background-image: url(/assets/icons/active_trans.png);
      z-index: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: inline-table;
    }
    .progressbar li.active-dis:before {
      background-color: transparent;
      background-image: url(/assets/icons/active_gray2.png);
      z-index: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      display: inline-table;
    }
    .progressbar li.active + li:after {
      background-color: rgba(194, 207, 224, 0.58);
    }
  }

  .progressbar li.step-pass:before {
    background-color: #20adc2;
    background-image: url(/assets/icons/check.png);
    background-size: 12px 12px;
    z-index: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #20adc2;
    color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;

    .progressbar li.step-pass + li:after {
      background-color: #24b0c3;
    }
  }
  .progressbar li.step-pass-dis:before {
    background-color: #aabaca;
    background-image: url(/assets/icons/check.png);
    background-size: 12px 12px;
    z-index: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #aabaca;
    color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-table;

    .progressbar li.step-pass-dis + li:after {
      background-color: #aabaca;
    }
  }
`

const FooterBox = styled.div`
  .section-footer {
    padding: 20px 20px 35px 20px;
    display: flex;
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .btn {
      width: 120px;
      height: 44px;
      margin: 0 0 0 10px;
      border-radius: 8px;
      border: solid 1.5px #24b0c3;
      color: #24b0c3;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .-active-btn {
      background: #aabaca !important;
      color: #fff !important;
      cursor: no-drop !important;
      border: 1px solid #aabaca !important;
    }
  }
`

const DropdownBox = styled.div` 
display: flex;
.box-dropdown {
  margin-top:44px;
    position: absolute;
    min-width: 414px;
    max-height: 260px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 8;
    
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px; 
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .box_field{
    width: 414px;
    height: 44px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 25px;
    cursor : pointer;
    padding-right:40px;s
  }
  .btn-close {
    float: right;
    cursor: pointer;
    right: 32px;
    top: 36px;
    width: 31px;
    height: 18px;
    opacity: 0.3;
    display: inline-block;
  }
  .btn-close:before,
  .btn-close:after {
    position: absolute;
    margin-left: 20px;
    margin-top: 17px;
    content: ' ';
    height: 11px;
    width: 2px;
    border-radius: 3px;
    background-color: #333;
  }
  .btn-close:before {
    transform: rotate(45deg);
  }
  .btn-close:after {
    transform: rotate(-45deg);
  }
  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: -28px;
  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    margin: -28px;
  }
.text-dropdown{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.head-sub-title{
  color: #aabaca;
}
.box-dropdown-small {
  width: 119px !important;
  min-width: 80% !important;

  li{
    width: 90px !important;
    min-width: 80% !important;
  }
}
.bottom-height{
  margin-bottom: 200px;
  position: absolute;
  height: 300px;
}
.box-dropdown-md {
  width: 232px !important;
  min-width: 90% !important;
}
  
`
export {
  Tab,
  ButtonGroup,
  SmsCampaignBox,
  TableBox,
  ProgressbarBox,
  FooterBox,
  CheckmarkBox,
  DropdownBox
}
