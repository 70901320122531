import styled from 'styled-components'

const RFMBox = styled.div`
.main {
  padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
  color: #6a707e;
  width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
  margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
  transition: margin 700ms;
}
.-btn-blue-popup {
  font-size: 13px;
  width: 110px;
  height: 40px;
  border-radius: 6px;
  background-color: #24b0c3;
  color: #fff;
  border: unset;
  margin-left: 6px;
  font-weight: 600;
}
  #chart {
    position: relative;
  }
  .custom_popup {
    padding: 15px;
    width: 220px;
    height: auto;
    color:#fff;
    min-height: 200px;
    // position: absolute;
    opacity: 0.95;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #082c38;
    border-radius:4px;
    p{
      font-size: 15px;
      font-weight: bold;
      line-height: 1.27;
      color:#fff;
      margin: 0px;
    }
    label{
      font-size: 11px;
      line-height: 1.45;
      color: #c2cfe0;
    }
    hr{
      border-top: 1px solid #818da7;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    .-right{
      float: right;
      margin-bottom: 0px;
    }
    .-left{
      float:left;
      margin-bottom: 0px;
    }
    .btn-create-static{
      height: 38px;
      width:100%;
      border-radius:5px;
      border: solid 1px #fff;
      background-color: #082c38;
      font-size: 13px;
    }
  }
  .clear-both{
    clear:both
  }
  .fix-popup {
    transition: unset !important;
  }

.btn-Analyze{
  background-color: #385172;
    border: 0px solid !important;
    color: #fff !important;
    font-weight: 100 !important;
  
}
  .btn-blue {
    margin-left: auto;
    width: 160px;
    height: 50px;
    padding: 12.5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    img {
      width: 16px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }

  
  .handling{
    font-size: 14px;
    color: #6f809e;
    
    span{
      color: #334d6e;
    }
  }

  .head-handling{
    font-size: 16px;
    color: #334d6e;

  }


  .main-loadding {
      
    width: 100%;
    height: 100%;
    .icon-loadding {
     
      z-index: 9;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      z-index: 999;
      opacity: 1;
      padding: 11%;
      position: absolute;
      text-align:center;
    }
  }

  .index-card{
    border-radius: 6px;
    border: 0;
    box-shadow: 4px 1px 10px 2px rgb(0 0 0 / 10%);
    min-height: 628px;
    padding: 20%;
  }
  .img-300{
    width: 300px;
  }
  .btn-condition {
    background-color: #385172;
    color: #fff !important;
    border: 0 !important;
    line-height: 2.3 !important;
  }
  .-sticky{
    position: sticky;
    top: 8rem;
  }
  
  .-fixed{
    position: fixed;
    top: 16rem;
  }
  
  .input{
    padding: 12px 25px!important;
  }
  
  .input::placeholder{
    color: #aabaca;
  }
  
  .input-disabled{
    border: solid 1px #eef1f5 !important;
    background-color: #fafafa !important;
    cursor: no-drop;
  }
  .big-title{
    font-size: 60px;
    color: #24b0c3;
  }
  .title-16{
    font-size: 16px;
    font-weight: 600;
    color: #344d6f;
  }
  .sub-title-14{
    font-size: 14px;
  }
  .sub-title-12{
    font-size: 12px;
  }
  .-blue {
    cursor: pointer;
  }
  
  
  
  }
  .input {
    outline: none;
    color: #385172;
    width: 414px;
    height: 50px;
    margin: 10px 0 0;
    padding: 15px 30px 14px 30px;
    border-radius: 10px;
    border: solid 1.5px #aabaca;
    background-color: #fff;
  }
  .input:focus {
    box-shadow: 0 0 6px 0 rgb(36 176 195 / 50%);
    border: solid 1.5px #24b0c3;
  }
  .card{
    border-radius: 10px;
    border: 0;
    box-shadow: 2px -2px 10px 3px rgb(0 0 0 / 9%);
  }
  .card-header:first-child {
    border-radius: 10px 10px 0px 0px;
}
  .-active{
    cursor: pointer;
    color: #20adc2;
    border-radius: 0;
    width: auto;
    margin-left: -10px;
    padding: 7px 10px;
    display: block;
    background-color: #ffffff00;
    border-bottom: 2px solid;
    a{
      color: #20adc2;
    }
    img{
      width: 15px;
    }
  }
  
  li {
    a:hover {
      cursor: pointer;
      color: #20adc2 !important;
      border-radius: 0;
      width: max-content !important;
      margin-left: -10px;
      padding: 7px 10px;
      display: block;
      background-color: #082c3800 !important;
  
    }
    
    a {
      cursor: pointer;
      color: #344d6f;
      border-radius: 0;
      width: auto;
      margin-left: -10px;
      padding: 7px 10px;
      display: block;
   
      
    }
  
  }
  .arrow-left {
    border: solid #385172;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0px;
    margin-top: 9px;
    margin-left: -12px;
    position: absolute;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 10px;
    height: 10px;
  }
  .title {
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
  
  }
  .top-menu{
      padding: 15px 20px 10px 20px;
      color: #6a707e;
      width: 100%;
      background-color: #fff;
      text-align: right;
      box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
      position: sticky;
      top: 0;
      border-top: 1px solid #eef1f5;
      z-index: 999999;
  }
  .btn {
    width: 120px;
    height: 44px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    border: solid 1.5px #24b0c3;
    color: #24b0c3;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  
  
  .-active-btn {
    background: #aabaca !important;
    color: #fff !important;
    cursor: no-drop !important;
    border: 1px solid #aabaca !important;
  }
    .box {
      line-height: 30px;
      border-radius: 100%;
      text-align: center;
    }
  
    .-status {
      display: inline-block;
      padding: 5px 20px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 10px;
      width: 100%;
  }
  
  .running{
    background-color: #d4f5eb;
    color: #28cc97;
  }
  .span-next{
    font-size: x-small;
    color: #aabaca;
  }
  .title-type {
    color: #334d6e;
    font-size: 20px;
    font-weight: 600;
  }
  .sub-title{
    font-size: 16px;
    color: #385172;
    font-weight: normal;
    span{
      font-weight: 600;
      color: #24b0c3; 
    }
  }
  .desc-type {
    font-size: 14px;
    color: #6a707e;
    margin: auto 15px;
  }
    .navigation-bar {
      font-family: Inter;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      color: #1e334e;
      span {
        color: #9daeb9;
      }
    }
    .breadcrumb-box {
      color: #334d6e;
      margin-left: 24px;
      cursor: pointer;
    }
    .section-main {
      width: 100%;
      background-color: #fff;
      margin: 20px auto;
      border-radius: 0px;
      padding: 40px 56px;
      text-align: center;
      border: 2px solid #efeff7;
      border-left: 0;
      border-right: 0;
      .title {
        font-family: 'Inter';
        color: #334d6e;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .section-body {
        display: flex;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      .list-type-box {
        cursor: pointer;
        border-radius: 10px;
        background-color: #ffffff;
        width: 300px;
        height: 300px;
        margin: 20px;
        border: 1px solid #aabaca;
        padding: 2px 20px;
      }
      .section-tooltip {
        text-align: right;
  
        .icon-tooltip {
          width: 12px;
          margin-top: -2px;
          margin-right: 8px;
          position: relative;
          display: inline-block;
          cursor: pointer;
          margin-left: 5px;
        }
      }
  
      .list-type-box {
        .list-icon {
          height: 90px;
        }
        img {
          margin-top: 20px;
          width: 60px;
        }
      }
      .list-type-box:hover {
        border: 3px solid #39BCDE;
        padding: 0px 18px;
    }
    }
    .tooltip {
      position: relative;
      display: inline-block;
  
      .tooltiptext {
        visibility: hidden;
        width: 400px;
        background-color: #00252f;
        color: #fff;
        border-radius: 0px;
        padding: 5px 0;
        position: absolute;
        opacity: 1;
        z-index: 1;
        bottom: 24px;
        margin-left: -132px;
        padding: 15px;
        font-family: inter;
      }
      .tooltiptext::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 55px;
        border-width: 8px;
        border-style: solid;
        border-color: black transparent transparent transparent;
      }
    }
  }
  .section-select-source {
    display: flex;
    margin: 20px 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .box-select-source:hover {
    padding: 19px;
    border: solid 2px #24b0c3;
  }
  .box-select-source {
    cursor: pointer;
    width: 320px;
    height: 110px;
    margin: 0 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px #aabaca;
    background-color: #fff;
    display: flex;
    .box-image-source {
      cursor: pointer;
      img {
        width: 43px;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .box-name-source {
      padding: 5px 20px;
      text-align: left;
      cursor: pointer;
      p {
        font-size: 16px;
        color: #334d6e;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 5px;
      }
      label {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
      cursor: pointer;
      }
    }
  }
  .-left{
    text-align:left;
  }
  .apexcharts-data-labels{
    text{
       tspan:last-child{
        font-size: 13px;
        font-weight:100;
       }
    }
  }
`

const DropdownBox = styled.div` 
.box-dropdown {
    position: absolute; 
    min-width: 414px;
    max-height: 260px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 8;
    
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px; 
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .box_field{
    width: 414px;
    height: 44px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 25px;
    cursor : pointer;
    padding-right:40px;s
  }
  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: -28px;
  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    margin: -28px;
  }
.text-dropdown{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.head-sub-title{
  color: #aabaca;
  font-weight: 400;
  font-size: 14px;
}
.box-dropdown-small {
  width: 119px !important;
  min-width: 80% !important;

  li{
    width: 90px !important;
    min-width: 80% !important;
  }
}
.bottom-height{
  margin-bottom: 200px;
  position: absolute;
  height: 300px;
}
.box-dropdown-md {
  width: 232px !important;
  min-width: 90% !important;
}
  
`
const LoaddingBox = styled.div`
  .main-loadding {
    width: 100%;
    height: 100%;
  }
  .icon-loadding {
    z-index: 9;
    width: 80px;
  }
  .overlay-loadding {
    width: 100%;
    display: table;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
    opacity: 1;
    padding: 10%;
    position: relative;
    text-align: center;
    height: 100%;
  }
`
export { RFMBox, DropdownBox, LoaddingBox }
