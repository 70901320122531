import React, { Fragment, useState, useEffect, useRef } from 'react'
import { FacebookBox } from './style'
import { useHistory, useParams } from 'react-router-dom'
const FacebookCampaignComponent = props => {
  const history = useHistory()

  return (
    <FacebookBox isShowMenu={props.isShowMenu}>
      <div className="main">
        <div className="px-4">
          <div className="row py-3">
            <div className="col-6 text-left">
              <h2 className="top-head-title">Facebook Ads</h2>
            </div>
            <div className="col-6 text-right">
              <a
                className="btn-basic bg-darkgreen font-white"
                onClick={() => history.push('/campaign/create')}
              >
                <img src="/assets/icons/create-white-2.png" className="w-px-14 mx-1" />
                Export New
              </a>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <img className="w-px-300 m-5" src={`/assets/images/SampleData.png`} alt="logo" />
                <p className='sub-title-18 font-darkblue'>You do not have any exported segments to Facebook Ad Account.</p>
                <p className="sub-title-14 font-gray w-40 mx-auto">
                Export your first segment by clicking the <span className='font-darkblue font-w-500'>“Export New”</span> button.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FacebookBox>
  )
}

export default FacebookCampaignComponent
