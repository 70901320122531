import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { RFMBox } from './style'



const RFMComponent = props => {
  const history = useHistory()
  const HandlingDefault = () => {
    return (
      <div className="text-center">
        <img className="img-300 p-3 mx-2" src={`/assets/images/SampleData.png`} alt="logo" />
        <p className="head-handling w-40 mx-auto">
          You have not created any RFM yet</p>
          <p className='handling'>Create your first RFM by clicking the <span>“Create New”</span> button.</p>
        
      </div>
    )
  }

 
  return (
    <RFMBox isShowMenu={props.isShowMenu}>
      <div className="main">
        <div className="row py-3 px-4">
          <div className="col-6 text-left">
            <p className="top-head-title">RFM</p>
          </div>
          <div className="col-6 text-right">
            <a className="btn-blue font-weight-lighter" onClick={() => history.push('/rfm/create')}>
              <img src="/assets/icons/create-white-2.png" />
              Create New
            </a>
          </div>
        </div>
        <div className="px-4">
          <div className='card index-card'>
          <HandlingDefault />

          </div>

        </div>
        

      
      </div>
    </RFMBox>
  )
}

export default RFMComponent
