import { PureComponent } from 'react'

import { Pie, Bar } from 'react-chartjs-2'
import { DashboardBox } from './style'

export default class DashboardComponent extends PureComponent {
  state = {
    step: 'start'
  }

  nextStep = step => {
    this.setState({ step: step })
  }
  render() {
    const { isShowMenu } = this.props
    const { step } = this.state

    const dataTopSale = {
      labels: ['product1', 'product2', 'product3', 'product4', 'product5', 'product6'],
      datasets: [
        {
          label: 'ยอดขาย',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]
    }
    const dataTopGender = {
      labels: ['Male', 'Female'],
      datasets: [
        {
          label: 'กลุ่มลูกค้า',
          data: [47, 96],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]
    }

    const dataTopAge = {
      labels: ['15-20', '21-25', '26-30', '30+'],
      datasets: [
        {
          label: 'กลุ่มอายุ',
          data: [45, 87, 104, 9],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }
      ]
    }

    return (
      <DashboardBox isShowMenu={isShowMenu}>
        {step === 'start' ? (
          <div className="section-main-landding">
            <div className="col-6">
              <img src="/assets/images/landding.png" alt="predictive" />
            </div>
            <div className="col-6">
              <div className="center-verticle">
                <p className="title">Start building</p>
                <p className="title">your dashboard</p>
                <p className="desc">
                  Before we can create any charts, we’ll first need to get some data in here!
                </p>
                <a className="btn-blue" onClick={() => this.nextStep('dashboard')}>
                  {`+ ADD DATA`}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="main">
            <div className="piechart">
              <div className="col-6">
                <Bar data={dataTopSale} />
              </div>
              <div className="col-6">
                <Pie data={dataTopGender} />
              </div>
              <div className="col-6">
                <Bar data={dataTopAge} />
              </div>
            </div>
          </div>
        )}
      </DashboardBox>
    )
  }
}
