import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'

import { isEmpty } from '../../helper/helper'
import { BasicDropdownBox } from './style'

export default class BasicDropdownComponent extends PureComponent {
  state = {
    open: false,
    selectedValue: ''
  }

  renderDropdown = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  setValueSelect = data => {
    const { setGroupFn } = this.props
    this.setState({ selectedValue: data, open: false })
    setGroupFn(data)
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode.contains(event.target)) {
      this.setState({
        open: false
      })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  controlPopup = () => {
    const { openModalFn } = this.props
    this.setState({ open: false })
    openModalFn()
  }

  render() {
    const { groupList, groupName, dataType } = this.props
    const { open } = this.state
    return (
      <BasicDropdownBox>
        <div className={`box_field `} onClick={() => this.renderDropdown()}>
          <span>
            {isEmpty(groupName)
              ? `Select ${dataType === 'customer' ? 'Customers Group' : 'an event'}`
              : groupName}
          </span>
          <img className="arrow_select" alt="predictive" src="/assets/icons/ChevronDown.png" />
        </div>
        {open && (
          <div className="box-dropdown">
            <ul>
              {!isEmpty(groupList) &&
                groupList.map((groupItem, i) => {
                  return (
                    <li key={i} onClick={() => this.setValueSelect(groupItem.name)}>
                      {groupItem.name}
                    </li>
                  )
                })}
            </ul>
            <div onClick={() => this.controlPopup()} className="box-dropdown-footer">
              <img className="icon-create" src="/assets/icons/create.png" alt="predictive" /> Create
              a new {dataType === 'customer' ? 'Customer Group' : dataType}
            </div>
          </div>
        )}
      </BasicDropdownBox>
    )
  }
}
