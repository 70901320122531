import React, { Fragment, useState, useEffect } from 'react'
import { PercentBox, AccountBox } from './style'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { accountDetail } from '../../apis/login/api'

const AccountComponent = props => {
  const [accDetail, setAccountDetail] = useState()

  useEffect(async () => {
    let detail = await accountDetail()
    if (detail) {
      setAccountDetail(detail)
    }
  }, [])

  const PercentBar = percent => {
    return (
      <PercentBox percent={percent.percent}>
        <div className="percent_bar">
          <div className="percent" />
        </div>
      </PercentBox>
    )
  }

  const ConvertNormalDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    return day + ' ' + month + ' ' + year
  }
  return (
    <AccountBox>
      <div className="card">
        <div className="card-header bg-white py-2 px-0 ">
          <div className="row">
            <div className="col-6">
              <p className=" title py-1 m-0 px-3 line-high-2 -capitalize">Your account details</p>
            </div>
          </div>
        </div>
        <div className="card-body py-3 pl-4">
          <div className="row">
            <div className="col-2">
              <p>Account name</p>
            </div>
            <div className="col-10">
              <p className="text-black font-w-600">{accDetail && accDetail.account_name}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <p>Account ID</p>
            </div>
            <div className="col-10">
              <p className="text-black font-w-600">{accDetail && accDetail.account_id}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="card mt-20">
        <div className="card-header bg-white py-2 px-0 ">
          <div className="row">
            <div className="col-6">
              <p className=" title py-1 m-0 px-3 line-high-2 -capitalize">Current plan & usage</p>
            </div>
          </div>
        </div>
        <div className="card-body py-3 pl-4">
          <div className="row">
            <div className="col-2">
              <p>Current Plan</p>
            </div>
            <div className="col-10">
              <p className="text-black font-w-600">{accDetail && accDetail.current_plan}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <p>Subscription</p>
            </div>
            <div className="col-10">
              <p className="text-black">
                Your subscription ends on{' '}
                <span className="font-w-600">
                  {accDetail && ConvertNormalDate(accDetail.subscription_exp)}
                </span>
              </p>
            </div>
          </div>
          <br />
          <p className="text-black mb-0 f-12">
            Data Storage Usage (Max {accDetail && accDetail.data_capacity}GB)
          </p>
          <p className="text-black f-bold mb-0">
            {accDetail && accDetail.data_usage} GB ({accDetail && accDetail.percent_data_usage}%
            used)
          </p>
          <PercentBar percent={accDetail && accDetail.percent_data_usage} />
          <br />
          
          
        </div>
      </div>
    </AccountBox>
  )
}
export default AccountComponent
