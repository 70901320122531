import styled from 'styled-components'

const AudienceBox = styled.div`
  .main {
    padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
    color: #6a707e;
    width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    transition: margin 700ms;
    .top-section {
      width: 100%;
      display: flex;
    }
  }
  table {
    // box-shadow: 4px -2px 6px 4px #f2f2f4;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0px;
    border-radius: 15px;
    width: 100%;
    margin-top: 0px;
    text-align: center;
    border: solid 1px #ebebeb;
  }
  .d-flex {
    display: flex;
  }
  .box-search {
    border: solid 1px #aabaca;
    input {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #acbac8;
    }
  }
  .main-table {
    margin: ${props => (props.isShowMenu ? ' 30px 5px 30px 310px' : ' 40px 5px 35px 70px')};
    color: #6a707e;
    transition: margin 700ms;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 43px;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 43px;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 43px;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 43px;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
      height: 0px;
    }
    100% {
      opacity: 1;
      height: 43px;
    }
  }
  .-fadeIn {
    animation: fadeIn 0.5s;
  }
  .tab-section {
    padding: 12px 30px 10px ${props => (props.isShowMenu ? '45px' : '90px')};
    span {
      margin-right: 40px;
      cursor: pointer;
    }
    span:hover {
      color: #0698be;
    }
    .active {
      color: #0698be;
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 0px;
    span {
      color: #1e334e;
    }
    a {
      color: #1e334e;
      font-size: 12px;
      cursor: pointer;

      font-weight: 100;
    }
  }
  .box-task {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
    padding: 30px 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    .icon-arrow {
      width: 16px;
      float: right;
      margin-top: 11px;
      cursor: pointer;
    }
  }
  .box-activity {
    padding: 30px 45px;
    .ReactCollapse--collapse {
      background-color: #f2f5f8;
      margin-left: -45px;
      margin-right: -45px;
    }
    .ReactCollapse--content {
      margin-top: -1px;
      padding: 20px 20px 20px 80px;
      .row {
        margin-bottom: 5px;
      }
      .-title {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #344d6f;
        float: unset;
      }
      .-time {
        float: unset;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #aabaca;
        margin-left: -30px;
      }
    }
  }
  .customer-img {
    width: 114px;
    margin: 40px 0px 20px 0px;
  }

  .item {
    display: flow-root;
    .content {
      display: none;
    }
    .-show {
      display: block;
    }
  }
  .-right {
    text-align: right;
  }
  .-center {
    text-align: center;
  }
  .font-dark {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #344d6f;
  }
  .title-col {
    font-size: 16px;
  }
  .font-blue {
    color: #0698be;
  }
  .font-gray {
    color: #90a0b7;
  }
  .sub-menu {
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #9daeb9 !important;
  }

  .box-data-table {
    .font-b {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1e334e;
    }
    .title {
      font-size: 22px;
      color: #000;
      img {
        width: 32px;
        margin-right: 10px;
      }
    }

    table {
      border: unset;
      thead {
        tr {
          background-color: #f1f5f9;
        }
      }
      tr {
        height: 60px;
        background-color: #fff;
      }
      th {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #385172;
      }
      td {
        padding: 10px 0px 10px 35px;
        border: unset;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #6f809e;
      }
      tr:nth-child(even) {
        background: #fafcfe;
      }
      tbody {
        tr:hover {
          background: #efeff7 !important;
        }
      }
    }
  }
  .ReactCollapse--collapse {
    transition: height 500ms ease 0s;
  }

  .ReactCollapse--content {
    margin-top: 30px;
    p {
      color: #344d6f;
      border-bottom: 1px solid #f1f5f7;
      padding-bottom: 7px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    span {
      float: right;
      color: #919fb7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 250px;
      text-align: right;
    }
  }

  .Ellipse-25 {
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 10px;
    padding: 16px 0;
    background-color: #aabaca;
    border-radius: 50%;
    span {
      font-size: 20px;
      color: #fff;
    }
  }
  .onLoadmore {
    height: 100px;
  }
  ._loading_overlay_spinner {
    margin: 30px auto 10px auto;
  }

  .row-title {
    padding-top: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f5f7;
    img {
      width: 20px;
      margin-top: -2px;
      margin-right: 10px;
    }
    .-title {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #344d6f;
    }
    .-time {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #aabaca;
    }
    .-icon {
      cursor: pointer;
      font-weight: 600;
      color: #919fb7;
      font-size: 28px;
      line-height: 0.3;
    }
  }

  .main-loadding {
    .icon-loadding {
      position: absolute;
      z-index: 9;
      left: ${props => (props.isShowMenu ? '57%' : 'unset')};
      top: 20%;
      margin: 0px auto;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;
      height: 100vw;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      position: fixed;
      z-index: 999;
      opacity: 1;
      margin-top: ${props => (props.isShowMenu ? '-80px' : '-90px')};
      text-align: center;
    }
  }
`

export { AudienceBox }
