import React, { PureComponent, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { isEmpty } from '../../helper/helper'
import { DropdownBox } from './style'
export default class DropdownComponent extends PureComponent {
  state = {
    open: false,
    openInner: false,
    showCreateField: false,
    selectRequieColumn: [],
    selectedValue: {},
    autoSelected: [],
    customDatatype: '',
    customField: '',
    settingColumnState: [],
    customFieldisPrimaryValid: true,
    customDatatypeisPrimaryValid: true,
    errMsg: ''
  }

  renderDropdown = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  renderInnerDropdown = () => {
    const { openInner } = this.state
    this.setState({ openInner: !openInner })
  }

  createField = () => {
    const { showCreateField } = this.state
    this.setState({
      showCreateField: !showCreateField,
      customFieldisPrimaryValid: true,
      customDatatype: '',
      customField: ''
    })
  }

  setValueSelect = async (val, option, no) => {
    const { setInnerValFn, setPrimaryKeyFn, setForeignKeyFn, setThirdKeyFn } = this.props
    if (option === 'PK' && !isEmpty(setPrimaryKeyFn)) {
      setPrimaryKeyFn(val)
    } else if (option === 'FK' && !isEmpty(setForeignKeyFn)) {
      setForeignKeyFn(val)
    } else if (option === 'TK' && !isEmpty(setThirdKeyFn)) {
      setThirdKeyFn(val)
    }

    if (!isEmpty(val.field_name)) {
      this.renderDropdown()
      this.setState({ selectedValue: val })
    } else {
      this.setState({
        selectedValue: {},
        open: false
      })
    }
    setTimeout(function () {
      if (!isEmpty(setInnerValFn)) {
        if (option !== 'PK') {
          setInnerValFn(val, no)
        }
      }
    }, 100)
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode.contains(event.target)) {
      this.setState({
        open: false
      })
    }
  }

  setValueCustom = val => {
    this.setState({ customDatatype: val, openInner: false })
  }

  setAutoSelected = (autoSelectedList, rowNo) => {
    for (let i = 0; i < autoSelectedList.length; i++) {
      if (!isEmpty(autoSelectedList[i].data)) {
        if (rowNo === autoSelectedList[i].no) {
          this.setValueSelect(autoSelectedList[i].data, 'nokey', autoSelectedList[i].no)
          this.setState({ open: false })
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
    const { autoSelectedList, showCustom, rowNo } = this.props
    if (showCustom && !isEmpty(autoSelectedList)) {
      this.setAutoSelected(autoSelectedList, rowNo)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  checkDuplicateCustomField = obj => {
    const { primaryColumn } = this.props
    let x
    for (x in primaryColumn) {
      if (primaryColumn[x].field_name === obj.field_name) {
        return true
      }
    }

    return false
  }

  saveCustomField = async () => {
    const { customField, customDatatype } = this.state
    const { rowNo, saveCustomFieldFn } = this.props
    let obj = {
      field_name: customField,
      data_type: customDatatype
    }

    if (isEmpty(customField)) {
      this.setState({ customFieldisPrimaryValid: false, errMsg: 'Please enter attribute name.' })
    } else if (isEmpty(customDatatype)) {
      this.setState({ customFieldisPrimaryValid: true, customDatatypeisPrimaryValid: false })
    } else {
      if (!this.checkDuplicateCustomField(obj)) {
        let res = await saveCustomFieldFn(customDatatype, customField, rowNo)
        if (!isEmpty(res) && res.status === 200) {
          this.setValueSelect(obj, 'nokey', rowNo)
          this.setState({
            customDatatypeisPrimaryValid: true,
            showCreateField: false,
            open: false,
            customFieldisPrimaryValid: true
          })
        }
      } else {
        this.setState({
          customFieldisPrimaryValid: false,
          errMsg: 'The name entered already exists. Please enter a unique name.'
        })
      }
    }
  }

  handleChange = ({ target }) => {
    this.setState({
      customField: target.value
    })
  }

  render() {
    const {
      showCustom,
      settingColumn,
      dataType,
      rowNo,
      setPrimaryField,
      isPrimaryValid,
      isForeignValid,
      isThirdValid,
      isLast,
      isKey
    } = this.props
    const {
      open,
      errMsg,
      customDatatypeisPrimaryValid,
      openInner,
      showCreateField,
      selectedValue,
      closeAll,
      customDatatype,
      customFieldisPrimaryValid
    } = this.state
    let primaryField = []
    if (!isEmpty(setPrimaryField)) {
      let thumb = {}

      for (let i = 0; i < setPrimaryField.length; i++) {
        thumb = {
          field_name: setPrimaryField[i].fieldName,
          key: setPrimaryField[i].key,
          data_type: 'string'
        }
        primaryField.push(thumb)
      }
    }
    let showValid = ''

    if (!isEmpty(isKey)) {
      if (isKey === 'PK') {
        if (isPrimaryValid) {
          showValid = '-validatePK'
        }
      } else if (isKey === 'FK') {
        if (isForeignValid) {
          showValid = '-validateFK'
        }
      } else {
        if (isThirdValid) {
          showValid = '-validateTK'
        }
      }
    }

    return (
      <DropdownBox showCustom={showCustom}>
        <div
          className={`box_field ${showValid} ${
            !showCustom ? 'active_field' : isEmpty(selectedValue) ? '-inactive' : ''
          } `}
          onClick={() => this.renderDropdown()}
        >
          {!isEmpty(selectedValue) && !isEmpty(selectedValue.field_name) && (
            <img
              className="iconDataType"
              src={`/assets/icons/${selectedValue.data_type}.png`}
              alt="predictive"
            />
          )}

          {isEmpty(selectedValue) && (
            <img
              className="iconDataType"
              src={`/assets/icons/circle-plus${showValid ? '-red' : ''}.png`}
              alt="predictive"
            />
          )}
          {isEmpty(selectedValue)
            ? ` Select ${
                dataType === 'customer' ? 'Customer' : dataType === 'event' ? 'Event' : 'Product'
              } Attribute...`
            : selectedValue.field_name}

          <img className="arrow_select" alt="predictive" src="/assets/icons/down-arrows.png" />
        </div>
        {!closeAll && open && (
          <div className={`box-dropdown ${isLast ? '-lastitem' : ''}`}>
            {!showCreateField ? (
              showCustom ? (
                <Fragment>
                  <ul>
                    <li
                      className="default-select"
                      onClick={() =>
                        this.setValueSelect({ field_name: null, data_type: null }, 'nokey', rowNo)
                      }
                    >
                      - - Select None - -
                    </li>
                    {!isEmpty(settingColumn) &&
                      settingColumn.map((item, i) => {
                        return (
                          <li key={i} onClick={() => this.setValueSelect(item, 'nokey', rowNo)}>
                            {item.field_name}
                          </li>
                        )
                      })}
                  </ul>
                  <div className="box-dropdown-footer" onClick={() => this.createField()}>
                    <img className="icon-create" src="/assets/icons/create.png" alt="predictive" />{' '}
                    Create a new attribute..
                  </div>
                </Fragment>
              ) : (
                <ul>
                  {!isEmpty(primaryField) &&
                    primaryField.map((item, i) => {
                      return (
                        <li
                          className={`${!isEmpty(item.key) ? '-isKey' : ''}  `}
                          key={i}
                          onClick={() =>
                            isEmpty(item.key) ? this.setValueSelect(item, isKey) : ''
                          }
                        >
                          {/* this.setValueSelect(item, !isEmpty(setForeignKeyFn) ? 'fKey' : 'pKey') : '') */}
                          {item.field_name}
                        </li>
                      )
                    })}
                </ul>
              )
            ) : (
              <div className="box-create-field">
                <p className="title-popup">Create a new customer group</p>
                <input
                  className={`input_field ${!customFieldisPrimaryValid ? '-validated' : ''}`}
                  value={this.state.customField}
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Enter a name .."
                />
                {!customFieldisPrimaryValid && (
                  <div className="section-msg">
                    <p className="-red">{errMsg}</p>
                  </div>
                )}
                <div
                  className={`box_field ${!customDatatypeisPrimaryValid ? '-validated' : ''} `}
                  onClick={() => this.renderInnerDropdown()}
                >
                  {isEmpty(customDatatype) ? 'Select data type' : customDatatype}
                  <img
                    className="arrow_select"
                    alt="predictive"
                    src="/assets/icons/ChevronDown.png"
                  />
                </div>
                {!customDatatypeisPrimaryValid && (
                  <div className="section-msg">
                    <p className="-red">Please select data type.</p>
                  </div>
                )}

                {openInner && (
                  <div className="box-inner-dropdown">
                    <ul>
                      <li onClick={() => this.setValueCustom('string')}>String</li>
                      <li onClick={() => this.setValueCustom('number')}>Number</li>
                      <li onClick={() => this.setValueCustom('date')}>Date</li>
                      <li onClick={() => this.setValueCustom('datetime')}>Datetime</li>
                    </ul>
                  </div>
                )}

                <div className="box-inner-footer">
                  <a className="btn-basic bg-gray font-white" onClick={() => this.createField()}>
                    Cancel
                  </a>
                  <a className="btn-basic bg-darkgreen font-white" onClick={() => this.saveCustomField()}>
                    Save
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </DropdownBox>
    )
  }
}
