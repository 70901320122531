import styled from 'styled-components'

const DataMappingBox = styled.div`
  .section-main {
    width: 100%;
    height: auto;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 10px;
    padding: 50px;
    text-align: left;
    box-shadow: 0px 0px 3px #888888;
    border-radius: unset;
    .section-tooltip {
      text-align: right;
      .icon-tooltip {
        width: 11px;
        margin-top: -4px;
        margin-right: 7px;
        position: relative;
        display: inline-block;
      }
    }
  }
  .loadding-overlay {
    background: white;
    margin-top: -190px;
    color: #334d6e;
    opacity: 0.9;
    position: absolute;
    width: 82%;
    height: 80vw;
    z-index: 99999;
    text-align: center;
    ._loading_overlay_wrapper {
      margin-top: 30%;
    }
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .title-inner {
    text-transform: capitalize;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #385172;
  }
  .desc-inner {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #848484;
  }
  .-blue {
    color: #24b0c3;
  }

  .map-title {
    text-align: left;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1e334e;
  }
  .-titleField {
    text-transform: capitalize;
    font-weight: 600;
    margin-left: 7px;
  }
  .disable-field {
    width: 330px;
    height: 40px;
    padding: 9px 16px;
    border-radius: 8px;
    border: solid 1px #d0dbe5;
    background-color: #f9f9fb;
    text-align: left;
    
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #aabaca;
    img {
      width: 12px;
      margin-right: 10px;
    }
  }
  .box_field {
    width: 330px;
    height: 40px;
    padding: 12px 18px 12px 20px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #385172;
    .icon-key {
      margin-right: 10px;
      width: 26px;
    }
    .iconDataType {
      width: 12px;
      margin-top: -1px;
    }
    .arrow_select {
      margin-top: 4px;
      margin-right: 0px;
      float: right;
    }
  }
  .box-dropdown {
    width: 330px;
  }
  .-inactive {
    cursor: pointer;
  }
  .-disable_field {
    border: solid 1px #d0dbe5;
    background-color: #f9f9fb;
    color: #aabaca;
  }
  .box-dropdown-footer {
    text-align: left;
  }
  .sample-box {
    padding-top: 7px;
    span {
      margin-top: 10px;
      background: #f9f9fb;
      color: #aabaca;
      font-size: 12px;
      text-align: center;
      margin-right: 10px;
      border-radius: 5px;
      padding: 10px 16px;
      border: solid 1px #d0dbe5;
    }
  }

  .section-footer {
    padding: 20px 20px 35px 20px;
    display: flex;
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .btn-blue {
      background-color: #24b0c3;
      color: #fff;
    }
    .btn {
      width: 120px;
      height: 44px;
      margin: 0 0 0 10px;
      border-radius: 8px;
      border: solid 1.5px #24b0c3;
      color: #24b0c3;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .arrow-right {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 4px;
      margin-left: 16px;
      position: absolute;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .arrow-left {
      border: solid #24b0c3;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 3px;
      margin-left: -17px;
      position: absolute;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
  .box-create-field {
    .box_field {
      width: 100%;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #dfe0eb;
      padding: 14px 0px 0px 20px;
      height: 50px;
      margin-top: 10px;
      font-size: 14px;
      .arrow_select {
        width: 28px;
        margin-top: -1px;
      }
    }
    .btn-next {
      cursor: pointer;
      background-color: #24b0c3;
      width: 170px;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      height: 40px;
      color: white;
      padding: 9px;
      :hover {
        color: white;
      }
    }

    .btn-back {
      cursor: pointer;
      background-color: #eeeeee !important;
      width: 170px;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      height: 40px;
      color: #334d6e;
      padding: 9px;
      :hover {
        color: #334d6e;
      }
      span {
        font-weight: 600;
      }
    }
  }
  .-textvalid {
    display: contents;
    color: #e86580;
    font-family: Inter;
    font-size: 12px;
    position: absolute;
    line-height: normal;
    letter-spacing: 0.12px;
    margin-top: 5px;
  }
`
const DropdownBox = styled.div` 
.box-dropdown {
    position: absolute;
    min-width:${props => (props.isFlex ? '140px' : '180px')};
    max-height: 260px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 9;
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px;
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .box_field{
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 20px;
    cursor : pointer;
    span{
      padding: 0px 16px;
    }
 
  }

  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }

  
`
export { DataMappingBox, DropdownBox }
