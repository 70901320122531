import styled from 'styled-components'

const ProfileBox = styled.div`
  .main {
    padding: 15px 20px 10px ${props => (props.isShowMenu ? '20px' : '80px')};
    color: #6a707e;
    width: auto; //${props => (props.isShowMenu ? '79%;' : '100%')};
    transition: width 0.5s;
    background-color: #fff;
    text-align: right;
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    height: 58px;
    svg {
      transform: translateY(-45%);
    }
  }
  .profile_company {
    position: absolute;
  }
  .profile_img {
    width: 30px;
    height: 30px;
    background: #334d6e;
    border-radius: 25px;
    display: inline-block;
    color: #fff;
    padding: 4px 9px;
  }
  .profile_noti {
    position: absolute;
    right: 58px;
    top: 19px;
    svg {
      margin-top: 11px;
    }
  }

  .profile_name {
    display: inline-block;
    line-height: 0.7;
    color: #707683;
    font-size: 16px;
    transform: translateY(-70%);
    margin-left: 10px;
    margin-right: 30px;
  }
`

export { ProfileBox }
