import styled from 'styled-components'

const MemberBox = styled.div`
  .main {
    padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
    color: #6a707e;
    width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    transition: margin 700ms;
  }
  .line-high-2 {
    line-height: 2;
  }
  .card-header {
    border-radius: 10px 10px 0px 0px !important;
  }

  .card {
    border-radius: 10px 10px 10px 10px !important;
  }
  .top-menu {
    height: 113px;
    padding: 15px 20px 10px 20px;
    color: #6a707e;
    width: 100%;
    background-color: #fff;
    text-align: right;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-top: 1px solid #eef1f5;
    z-index: 9;
  }
  .card {
    border-radius: 10px px;
    border: 0px solid;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 5%);
  }
  .title {
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
  }

  .top_breadcrumb {
    font-size: 12px;
    cursor: pointer;
    span {
      color: #212529 !important;
    }
    .-gray {
      color: #9daeb9 !important;
    }
  }

  .top-title {
    display: flex;
    cursor: pointer;
    margin-top: 20px;
    .title {
      margin-bottom: 0px;
      font-weight: 100;
      color: #1e334e;
      font-size: 24px;
      width: 100%;
    }

    .btn-blue {
      font-size: 14px !important;
      font-weight: 400;
      float: right;
      width: 160px;
      height: 50px;
      padding: 12.5px 0;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      img {
        width: 17px;
        margin-right: 5px;
        margin-top: -2px;
      }
      :hover {
        background-color: #1a90a0;
      }
    }
  }
  .btn-blue {
    font-size: 14px !important;
    font-weight: 400;
    float: right;
    width: 160px;
    height: 50px;
    padding: 12.5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px;
    }
    :hover {
      background-color: #1a90a0;
    }
  }
  .w100 {
    width: 100% !important;
  }

  .noti_popup_error {
    border: solid 1px #f4c229 !important;
    background-color: #fffced !important;
    color: #6f809e !important;
  }
  .-mini {
    width: 240px !important;
  }

  .noti_popup {
    position: absolute;
    width: 387px;
    max-height: 77px;
    padding: 12.3px 7.1px 14.5px 18.6px;
    border-radius: 10px;
    border: solid 1px #8dc360;
    background-color: #f6ffec;
    right: 100px;
    margin-top: 20px;
    display: flex;
    z-index: 999;
    color: #8dc360;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    .-left {
      width: 36px;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }

    span {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }

    .ico-warning {
      width: 20px;
    }

    .close_noti_popup {
      position: absolute;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    }
    .close_noti_popup:hover {
      color: #adadad;
      cursor: pointer;
      opacity: 1;
    }
    .close_noti_popup:before,
    .close_noti_popup:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 14px;
      width: 3px;
      background-color: #333;
    }
    .close_noti_popup:before {
      transform: rotate(45deg);
    }
    .close_noti_popup:after {
      transform: rotate(-45deg);
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
  }

  .rdt_Table {
    background-color: #edf1f4;
    border: unset !important;
    border-radius: unset !important;
    margin-top: 25px;
    max-height: 655px;
    // height: 40vw;
    overflow: hidden;
    .rdt_TableHeadRow {
      border: unset;
      border-radius: unset;
      min-height: 50px;
    }
    .rdt_TableRow {
      border: unset;
      cursor: default !important;
    }
    .rdt_TableRow:nth-child(even) {
      background: #fafcfe;
    }
  }
  .rdt_Table {
    background-color: unset;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-top: 0px;
    // height: 655px;
    // overflow: scroll;
  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: #f1f5f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ebebeb;
    .rdt_TableCol {
      font-weight: bold;
      font-size: 16px;
      color: #1e334e;
      padding: 0px 15px;
      padding-left: 40px;
    }
  }
  .fix-email {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    margin-bottom: -4px;
    line-height: 1;
  }
  .mg0 {
    margin: 0px;
  }

  .rdt_TableCell {
    font-size: 12px;
    color: #6f809e;
    font-weight: normal;
    font-stretch: normal;
    padding-left: 40px;
    div {
      text-overflow: ellipsis !important;
      overflow: inherit !important;
      white-space: nowrap !important;
    }
    .-badge-pending {
      padding: 4px 7px;
      border-radius: 4px;
      background-color: #ffcc48;
      color: #fff;
      margin-left: 6px;
      margin-top: 9px;
    }
    div:first-child {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }
  }
  .rdt_TableRow {
    border-bottom-color: #ebebeb;
    // padding-right: 10px;
    height: 50px;
    font-size: 16px;
  }
  .rdt_TableRow:hover {
    cursor: pointer;
    // background-color: #fafcfe;
  }
  .rdt_TableRow:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rdt_TableCol_Sortable {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #385172;
    // margin-left: 30px;
    span {
      font-size: 9px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .rdt_TableCol_Sortable:hover {
    color: unset; //#404040;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .-fadeIn {
    animation: fadeIn 0.5s;
  }
  .section-inner {
    margin: 24px 10px 30px 0px;
    padding: 23px 36.3px 47px 30px;
    border-radius: 14px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    .-left {
      margin-right: 40px;
    }
  }
  .input {
    outline: none;
    color: #385172;
    width: 414px;
    height: 50px;
    margin: 10px 0 0;
    padding: 15px 30px 14px 30px;
    border-radius: 10px;
    border: solid 1.5px #aabaca;
    background-color: #fff;
    :focus {
      box-shadow: 0 0 6px 0 rgba(36, 176, 195, 0.5);
      border: solid 1.5px #24b0c3;
    }
  }
  .input_label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #385172;
    a,
    a:hover {
      text-decoration: underline !important;
    }
  }
  .row-option {
    .-radio-active {
      border: solid 1px #385172 !important;
    }
    .container {
      cursor: pointer;
      width: 293px;
      margin: 29px 20px 20px 0px;
      padding: 20px;
      border-radius: 16px;
      border: solid 1px #ced4de;
      span {
        color: #385172;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      margin-top: 32px;
      margin-left: 7px;
      position: absolute;
      height: 21px;
      width: 21px;
      cursor: pointer;
      border: solid 1px #919fb6;
      border-radius: 50%;
      :hover {
        border: solid 1px #385172;
      }
    }
    .container input:checked ~ .checkmark {
      background-color: #fff;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #385172;
    }
  }
  .box-role {
    margin-left: 50px;
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }
    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e !important;
    }
  }

  .section-footer {
    padding-right: 10px;
    padding-bottom: 40px;
    text-align: right;
    font-size: 15px;
  }
  .btn-connected {
    width: 130px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: 0;
    background-color: #eaf5f2;
    font-size: 14px;
    color: #28cc97;
    font-weight: 500;
  }
  .btn-remove {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #ff2c65;
    background-color: #fff;
    color: #ff2c65;
  }

  .btn-cancel {
    width: 160px;
    height: 44px;
    margin: 0 9px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #24b0c3;
    background-color: #fff;
    color: #24b0c3;
  }

  .btn-blue {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    background-color: #24b0c3;
    color: #fff;
    border: none;
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px 0px;
      padding: 10px 0px;
    }
  }
  .label_err {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #ff2c65;
    display: block;
  }
  .input_err {
    border: solid 1.5px #ff2c65;
  }
  .input_disable {
    background-color: #f5f5fa;
    border-color: #aabaca;
    color: #aabaca;
  }

  .box_field {
    font-weight: 500;
    cursor: pointer;
    margin: auto;
    background: unset;
    height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: -40px;
    .icon-option {
      margin-top: 11px;
      cursor: pointer;
      width: 25px;
      height: 7px;
      background: url(/assets/icons/option.png) no-repeat;
      display: inline-block;
      background-size: 17px 5px;
    }
    .icon-option:hover {
      background: url(/assets/icons/option-active.png) no-repeat;
      background-size: 17px 5px;
    }
  }

  .rdt_Table {
    overflow: hidden;
  }
  .main-loadding {
    width: 100%;
    height: 100%;
    .icon-loadding {
      z-index: 9;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      z-index: 999;
      opacity: 1;
      padding: 11%;
      position: absolute;
      text-align: center;
      height: 100%;
    }
  }
`
const IntegrationsBox = styled.div`
  .card-header {
    border-radius: 10px 10px 0px 0px !important;
  }

  .badge-green {
    width: 90px;
    height: 24px;
    padding: 5px 10.8px 5px 10.8px;
    border-radius: 20px;
    background-color: #eaf5f2;
    color: #28cc97;
    font-size: 10px;
    font-weight: 500;
    margin-left: auto;
    margin-right: -15px;
    margin-top: -14px;
    margin-bottom: -10px;
    .icon-mini {
      width: 10px;
      margin: 0px;
      margin-top: -2px;
      margin-left: 1px;
    }
  }

  .card {
    border-radius: 10px !important;
  }
  .arrow-left {
    border: solid #385172;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0px;
    margin-top: 13px;
    margin-left: -12px;
    position: absolute;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 10px;
    height: 10px;
  }

  .btn-configure {
    width: 130px;
    height: 40px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-family: Inter;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0;
  }

  .btn-connect {
    width: 190px;
    height: 40px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-family: Inter;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0;
    border: 0;
  }
  .connect-in-active {
    color: #fff;
    background-color: #aabaca;
    cursor: no-drop;
  }
  .connect-active {
    color: #fff;
    background-color: #385172;
  }

  .btn-configure-green {
    border: solid 1.5px #24b0c3;
    color: #fff;
    background: #24b0c3;
  }

  .btn-configure-white {
    border: solid 1.5px #24b0c3;
    color: #24b0c3;
    background: #fff;
  }

  .btn-configure-gray {
    border: 0;
    color: #9daeb9;
    background-color: #eef1f5;
  }

  .title {
    font-family: 'Inter';
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .section-body {
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .list-type-box {
    cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 20px;
    border: 1px solid #aabaca;
    padding: 20px;
    width: 340px;
    height: 270px;
  }
  .section-tooltip {
    text-align: right;

    .icon-tooltip {
      width: 12px;
      margin-top: -2px;
      margin-right: 8px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .list-type-box {
    .list-icon {
      height: 90px;
    }
    img {
      margin-top: 15px;
      width: 200px;
    }
  }
  .list-type-box:hover,
  .list-type-box-active {
    border: 3px solid #39bcde;
    padding: 18px;
  }
  .tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 400px;
      background-color: #00252f;
      color: #fff;
      border-radius: 0px;
      padding: 5px 0;
      position: absolute;
      opacity: 1;
      z-index: 1;
      bottom: 24px;
      margin-left: -132px;
      padding: 15px;
      font-family: inter;
    }
    .tooltiptext::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 55px;
      border-width: 8px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  .section-select-source {
    display: flex;
    margin: 20px 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .box-select-source:hover {
    padding: 19px;
    border: solid 2px #24b0c3;
  }
  .box-select-source-active {
    border: solid 2px #24b0c3 !important;
    padding: 19px !important;
  }
  .box-select-source {
    cursor: pointer;
    width: 320px;
    height: 110px;
    margin: 0 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px #aabaca;
    background-color: #fff;
    display: flex;
    .box-image-source {
      cursor: pointer;
      img {
        width: 43px;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .box-name-source {
      padding: 5px 20px;
      text-align: left;
      cursor: pointer;
      p {
        font-size: 16px;
        color: #334d6e;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 5px;
      }
      label {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #6f809e;
        cursor: pointer;
      }
    }
  }
  .line-high-2 {
    line-height: 2;
  }
  .top-menu {
    padding: 15px 20px 10px 20px;
    color: #6a707e;
    width: 100%;
    background-color: #fff;
    text-align: right;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-top: 1px solid #eef1f5;
    z-index: 9;
  }
  .card {
    border-radius: 10px px;
    border: 0px solid;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 5%);
  }
  .title {
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
  }

  .top_breadcrumb {
    font-size: 12px;
    cursor: pointer;
    span {
      color: #212529 !important;
    }
    .-gray {
      color: #9daeb9 !important;
    }
  }

  .top-title {
    display: flex;
    cursor: pointer;
    margin-top: 20px;
    .title {
      margin-bottom: 0px;
      font-weight: 100;
      color: #1e334e;
      font-size: 24px;
      width: 100%;
    }

    .btn-blue {
      font-size: 14px !important;
      font-weight: 400;
      float: right;
      width: 160px;
      height: 50px;
      padding: 12.5px 0;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      img {
        width: 17px;
        margin-right: 5px;
        margin-top: -2px;
      }
      :hover {
        background-color: #1a90a0;
      }
    }
  }
  .btn-blue {
    font-size: 14px !important;
    font-weight: 400;
    float: right;
    width: 160px;
    height: 50px;
    padding: 12.5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px;
    }
    :hover {
      background-color: #1a90a0;
    }
  }
  .w100 {
    width: 100% !important;
  }

  .noti_popup_error {
    border: solid 1px #f4c229 !important;
    background-color: #fffced !important;
    color: #6f809e !important;
  }
  .-mini {
    width: 240px !important;
  }

  .noti_popup {
    position: absolute;
    width: 387px;
    max-height: 77px;
    padding: 12.3px 7.1px 14.5px 18.6px;
    border-radius: 10px;
    border: solid 1px #8dc360;
    background-color: #f6ffec;
    right: 100px;
    margin-top: 20px;
    display: flex;
    z-index: 999;
    color: #8dc360;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    .-left {
      width: 36px;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }

    span {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }

    .ico-warning {
      width: 20px;
    }

    .close_noti_popup {
      position: absolute;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    }
    .close_noti_popup:hover {
      color: #adadad;
      cursor: pointer;
      opacity: 1;
    }
    .close_noti_popup:before,
    .close_noti_popup:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 14px;
      width: 3px;
      background-color: #333;
    }
    .close_noti_popup:before {
      transform: rotate(45deg);
    }
    .close_noti_popup:after {
      transform: rotate(-45deg);
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
  }

  .rdt_Table {
    background-color: #edf1f4;
    border: unset !important;
    border-radius: unset !important;
    margin-top: 25px;
    max-height: 655px;
    height: 100vw;
    overflow: scroll;
    .rdt_TableHeadRow {
      border: unset;
      border-radius: unset;
      height: 50px;
    }
    .rdt_TableRow {
      border: unset;
      cursor: auto;
    }
    .rdt_TableRow:nth-child(even) {
      background: #fafcfe;
    }
  }
  .rdt_Table {
    background-color: unset;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-top: 0px;
    // height: 655px;
    // overflow: scroll;
  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: #f1f5f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ebebeb;
    .rdt_TableCol {
      font-weight: bold;
      font-size: 16px;
      color: #1e334e;
      padding: 0px 15px;
      padding-left: 40px;
    }
  }
  .fix-email {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    margin-bottom: -4px;
    line-height: 1;
  }
  .mg0 {
    margin: 0px;
  }
  .fix-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${props => (props.isShowMenu ? ' 245px' : '280px')};
    margin-bottom: -4px;
  }
  .rdt_TableCell {
    font-size: 12px;
    color: #6f809e;
    font-weight: normal;
    font-stretch: normal;
    padding-left: 40px;
    .-badge-pending {
      padding: 4px 7px;
      border-radius: 4px;
      background-color: #ffcc48;
      color: #fff;
      margin-left: 6px;
      margin-top: 9px;
    }
    div:first-child {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }
  }
  .rdt_TableRow {
    border-bottom-color: #ebebeb;
    // padding-right: 10px;
    height: 50px;
    font-size: 16px;
  }
  .rdt_TableRow:hover {
    cursor: pointer;
    // background-color: #fafcfe;
  }
  .rdt_TableRow:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rdt_TableCol_Sortable {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #385172;
    // margin-left: 30px;
    span {
      font-size: 9px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .rdt_TableCol_Sortable:hover {
    color: unset; //#404040;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .-fadeIn {
    animation: fadeIn 0.5s;
  }
  .section-inner {
    margin: 24px 10px 30px 0px;
    padding: 23px 36.3px 47px 30px;
    border-radius: 14px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    .-left {
      margin-right: 40px;
    }
  }
  .input {
    font-size: 14px;
    font-weight: 500;
    outline: none;
    color: #385172;
    width: 414px;
    height: 44px;
    margin: 10px 0 0;
    padding: 15px 30px 14px 30px;
    border-radius: 6px;
    border: solid 1px #aabaca;
    background-color: #fff;
    :focus {
      box-shadow: 0 0 6px 0 rgba(36, 176, 195, 0.5);
      border: solid 1.5px #24b0c3;
    }
  }
  .input_label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #385172;
    a,
    a:hover {
      text-decoration: underline !important;
    }
  }
  .row-option {
    .-radio-active {
      border: solid 1px #385172 !important;
    }
    .container {
      cursor: pointer;
      width: 293px;
      margin: 29px 20px 20px 0px;
      padding: 20px;
      border-radius: 16px;
      border: solid 1px #ced4de;
      span {
        color: #385172;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      margin-top: 32px;
      margin-left: 7px;
      position: absolute;
      height: 21px;
      width: 21px;
      cursor: pointer;
      border: solid 1px #919fb6;
      border-radius: 50%;
      :hover {
        border: solid 1px #385172;
      }
    }
    .container input:checked ~ .checkmark {
      background-color: #fff;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #385172;
    }
  }
  .box-role {
    margin-left: 50px;
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }
    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e !important;
    }
  }

  .section-footer {
    padding-right: 10px;
    padding-bottom: 40px;
    text-align: right;
    font-size: 15px;
  }

  .btn-remove {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #ff2c65;
    background-color: #fff;
    color: #ff2c65;
    img {
      width: 12px;
      margin-right: 5px;
    }
  }

  .btn-cancel {
    width: 160px;
    height: 44px;
    margin: 0 9px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #24b0c3;
    background-color: #fff;
    color: #24b0c3;
  }
  .btn-connected {
    width: 117px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: 0;
    background-color: #eaf5f2;
    font-size: 14px;
    color: #28cc97;
    font-weight: 500;
  }
  .btn-remove-noborder {
    width: 117px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: 0;
    background-color: #fff;
    font-size: 14px;
    color: #ff2c65;
    font-weight: 500;
    img {
      width: 15px;
      margin-right: 3px;
    }
  }
  .btn-blue {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    background-color: #24b0c3;
    color: #fff;
    border: none;
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px 0px;
      padding: 10px 0px;
    }
  }
  .label_err {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #ff2c65;
    display: block;
  }
  .input_err {
    border: solid 1.5px #ff2c65;
  }
  .input_disable {
    background-color: #f5f5fa;
    border-color: #aabaca;
    color: #aabaca;
  }

  .box_field {
    font-weight: 500;
    cursor: pointer;
    margin: auto;
    background: unset;
    height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: -40px;
    .icon-option {
      margin-top: 11px;
      cursor: pointer;
      width: 25px;
      height: 7px;
      background: url(/assets/icons/option.png) no-repeat;
      display: inline-block;
      background-size: 17px 5px;
    }
    .icon-option:hover {
      background: url(/assets/icons/option-active.png) no-repeat;
      background-size: 17px 5px;
    }
  }

  .rdt_Table {
    overflow: auto;
  }
  .main-loadding {
    width: 100%;
    height: 100%;
    .icon-loadding {
      z-index: 9;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      z-index: 999;
      opacity: 1;
      padding: 11%;
      position: absolute;
      text-align: center;
      height: 100%;
    }
  }
`

const PercentBox = styled.div`
  .percent_bar {
    width: 414px;
    height: 10px;
    margin: 9px 0 0;
    border-radius: 9px;
    background-color: #eceff2;
    .percent {
      position: inherit;
      width: ${props => (props.percent ? props.percent : '0')}%;
      height: 10px;
      border-radius: 9px;
      background-color: #00b8d9;
    }
  }
`

const AccountBox = styled.div`
  font-size: 14px;
  .card-header {
    border-radius: 10px 10px 0px 0px !important;
  }

  .card {
    border-radius: 10px !important;
  }
  .arrow-left {
    border: solid #385172;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0px;
    margin-top: 13px;
    margin-left: -12px;
    position: absolute;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 10px;
    height: 10px;
  }

  .btn-configure {
    width: 130px;
    height: 40px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-family: Inter;

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0;
  }

  .btn-connect {
    width: 117px;
    height: 40px;
    margin: 0 0 0 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-family: Inter;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0;
    border: 0;
  }
  .connect-active {
    color: #fff;
    background-color: #aabaca;
  }
  .connect-in-active {
    color: #fff;
    background-color: #385172;
  }

  .btn-configure-green {
    border: solid 1.5px #24b0c3;
    color: #24b0c3;
  }

  .btn-configure-gray {
    border: 0;
    color: #9daeb9;
    background-color: #eef1f5;
  }

  .title {
    font-family: 'Inter';
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .section-body {
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .list-type-box {
    cursor: pointer;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 20px;
    border: 1px solid #aabaca;
    padding: 2px 20px;
    width: 340px;
    height: 270px;
  }
  .section-tooltip {
    text-align: right;

    .icon-tooltip {
      width: 12px;
      margin-top: -2px;
      margin-right: 8px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .list-type-box {
    .list-icon {
      height: 90px;
    }
    img {
      margin-top: 20px;
      width: 160px;
      height: 29px;
    }
  }
  .list-type-box:hover,
  .list-type-box-active {
    border: 3px solid #39bcde;
    padding: 0px 18px;
  }
  .tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 400px;
      background-color: #00252f;
      color: #fff;
      border-radius: 0px;
      padding: 5px 0;
      position: absolute;
      opacity: 1;
      z-index: 1;
      bottom: 24px;
      margin-left: -132px;
      padding: 15px;
      font-family: inter;
    }
    .tooltiptext::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 55px;
      border-width: 8px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  .section-select-source {
    display: flex;
    margin: 20px 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .box-select-source:hover {
    padding: 19px;
    border: solid 2px #24b0c3;
  }
  .box-select-source-active {
    border: solid 2px #24b0c3 !important;
    padding: 19px !important;
  }
  .box-select-source {
    cursor: pointer;
    width: 320px;
    height: 110px;
    margin: 0 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
    border: solid 1px #aabaca;
    background-color: #fff;
    display: flex;
    .box-image-source {
      cursor: pointer;
      img {
        width: 43px;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .box-name-source {
      padding: 5px 20px;
      text-align: left;
      cursor: pointer;
      p {
        font-size: 16px;
        color: #334d6e;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 5px;
      }
      label {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #6f809e;
        cursor: pointer;
      }
    }
  }
  .line-high-2 {
    line-height: 2;
  }
  .top-menu {
    padding: 15px 20px 10px 20px;
    color: #6a707e;
    width: 100%;
    background-color: #fff;
    text-align: right;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-top: 1px solid #eef1f5;
    z-index: 9;
  }
  .card {
    border-radius: 10px px;
    border: 0px solid;
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 5%);
  }
  .title {
    color: #334d6e;
    font-size: 18px;
    font-weight: 600;
  }

  .top_breadcrumb {
    font-size: 12px;
    cursor: pointer;
    span {
      color: #212529 !important;
    }
    .-gray {
      color: #9daeb9 !important;
    }
  }

  .top-title {
    display: flex;
    cursor: pointer;
    margin-top: 20px;
    .title {
      margin-bottom: 0px;
      font-weight: 100;
      color: #1e334e;
      font-size: 24px;
      width: 100%;
    }

    .btn-blue {
      font-size: 14px !important;
      font-weight: 400;
      float: right;
      width: 160px;
      height: 50px;
      padding: 12.5px 0;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      display: inline-block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      img {
        width: 17px;
        margin-right: 5px;
        margin-top: -2px;
      }
      :hover {
        background-color: #1a90a0;
      }
    }
  }
  .btn-blue {
    font-size: 14px !important;
    font-weight: 400;
    float: right;
    width: 160px;
    height: 50px;
    padding: 12.5px 0;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px;
    }
    :hover {
      background-color: #1a90a0;
    }
  }
  .w100 {
    width: 100% !important;
  }

  .noti_popup_error {
    border: solid 1px #f4c229 !important;
    background-color: #fffced !important;
    color: #6f809e !important;
  }
  .-mini {
    width: 240px !important;
  }

  .noti_popup {
    position: absolute;
    width: 387px;
    max-height: 77px;
    padding: 12.3px 7.1px 14.5px 18.6px;
    border-radius: 10px;
    border: solid 1px #8dc360;
    background-color: #f6ffec;
    right: 100px;
    margin-top: 20px;
    display: flex;
    z-index: 999;
    color: #8dc360;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    .-left {
      width: 36px;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }

    span {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }

    .ico-warning {
      width: 20px;
    }

    .close_noti_popup {
      position: absolute;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    }
    .close_noti_popup:hover {
      color: #adadad;
      cursor: pointer;
      opacity: 1;
    }
    .close_noti_popup:before,
    .close_noti_popup:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 14px;
      width: 3px;
      background-color: #333;
    }
    .close_noti_popup:before {
      transform: rotate(45deg);
    }
    .close_noti_popup:after {
      transform: rotate(-45deg);
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
  }

  .rdt_Table {
    background-color: #edf1f4;
    border: unset !important;
    border-radius: unset !important;
    margin-top: 25px;
    max-height: 655px;
    height: 100vw;
    overflow: scroll;
    .rdt_TableHeadRow {
      border: unset;
      border-radius: unset;
      height: 50px;
    }
    .rdt_TableRow {
      border: unset;
      cursor: auto;
    }
    .rdt_TableRow:nth-child(even) {
      background: #fafcfe;
    }
  }
  .rdt_Table {
    background-color: unset;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    margin-top: 0px;
    // height: 655px;
    // overflow: scroll;
  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: #f1f5f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ebebeb;
    .rdt_TableCol {
      font-weight: bold;
      font-size: 16px;
      color: #1e334e;
      padding: 0px 15px;
      padding-left: 40px;
    }
  }
  .fix-email {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    margin-bottom: -4px;
    line-height: 1;
  }
  .mg0 {
    margin: 0px;
  }
  .fix-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: ${props => (props.isShowMenu ? ' 245px' : '280px')};
    margin-bottom: -4px;
  }
  .rdt_TableCell {
    font-size: 12px;
    color: #6f809e;
    font-weight: normal;
    font-stretch: normal;
    padding-left: 40px;
    .-badge-pending {
      padding: 4px 7px;
      border-radius: 4px;
      background-color: #ffcc48;
      color: #fff;
      margin-left: 6px;
      margin-top: 9px;
    }
    div:first-child {
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }
  }
  .rdt_TableRow {
    border-bottom-color: #ebebeb;
    // padding-right: 10px;
    height: 50px;
    font-size: 16px;
  }
  .rdt_TableRow:hover {
    cursor: pointer;
    // background-color: #fafcfe;
  }
  .rdt_TableRow:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rdt_TableCol_Sortable {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #385172;
    // margin-left: 30px;
    span {
      font-size: 9px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .rdt_TableCol_Sortable:hover {
    color: unset; //#404040;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .-fadeIn {
    animation: fadeIn 0.5s;
  }
  .section-inner {
    margin: 24px 10px 30px 0px;
    padding: 23px 36.3px 47px 30px;
    border-radius: 14px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    .-left {
      margin-right: 40px;
    }
  }
  .input {
    outline: none;
    color: #385172;
    width: 414px;
    height: 50px;
    margin: 10px 0 0;
    padding: 15px 30px 14px 30px;
    border-radius: 10px;
    border: solid 1.5px #aabaca;
    background-color: #fff;
    :focus {
      box-shadow: 0 0 6px 0 rgba(36, 176, 195, 0.5);
      border: solid 1.5px #24b0c3;
    }
  }
  .input_label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #385172;
    a,
    a:hover {
      text-decoration: underline !important;
    }
  }
  .row-option {
    .-radio-active {
      border: solid 1px #385172 !important;
    }
    .container {
      cursor: pointer;
      width: 293px;
      margin: 29px 20px 20px 0px;
      padding: 20px;
      border-radius: 16px;
      border: solid 1px #ced4de;
      span {
        color: #385172;
      }
    }
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      margin-top: 32px;
      margin-left: 7px;
      position: absolute;
      height: 21px;
      width: 21px;
      cursor: pointer;
      border: solid 1px #919fb6;
      border-radius: 50%;
      :hover {
        border: solid 1px #385172;
      }
    }
    .container input:checked ~ .checkmark {
      background-color: #fff;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #385172;
    }
  }
  .box-role {
    margin-left: 50px;
    p {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }
    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e !important;
    }
  }

  .section-footer {
    padding-right: 10px;
    padding-bottom: 40px;
    text-align: right;
    font-size: 15px;
  }

  .btn-remove {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #ff2c65;
    background-color: #fff;
    color: #ff2c65;
    img {
      width: 12px;
      margin-right: 5px;
    }
  }

  .btn-cancel {
    width: 160px;
    height: 44px;
    margin: 0 9px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: solid 1px #24b0c3;
    background-color: #fff;
    color: #24b0c3;
  }
  .btn-connected {
    width: 117px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: 0;
    background-color: #eaf5f2;
    font-size: 14px;
    color: #28cc97;
    font-weight: 500;
  }
  .btn-remove-noborder {
    width: 117px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    border: 0;
    background-color: #fff;
    font-size: 14px;
    color: #ff2c65;
    font-weight: 500;
  }
  .btn-blue {
    width: 160px;
    height: 44px;
    margin: 0 10px 0 0;
    padding: 5px 0;
    border-radius: 10px;
    background-color: #24b0c3;
    color: #fff;
    border: none;
    img {
      width: 17px;
      margin-right: 5px;
      margin-top: -2px 0px;
      padding: 10px 0px;
    }
  }
  .label_err {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left;
    color: #ff2c65;
    display: block;
  }
  .input_err {
    border: solid 1.5px #ff2c65;
  }
  .input_disable {
    background-color: #f5f5fa;
    border-color: #aabaca;
    color: #aabaca;
  }

  .box_field {
    font-weight: 500;
    cursor: pointer;
    margin: auto;
    background: unset;
    height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: -40px;
    .icon-option {
      margin-top: 11px;
      cursor: pointer;
      width: 25px;
      height: 7px;
      background: url(/assets/icons/option.png) no-repeat;
      display: inline-block;
      background-size: 17px 5px;
    }
    .icon-option:hover {
      background: url(/assets/icons/option-active.png) no-repeat;
      background-size: 17px 5px;
    }
  }

  .rdt_Table {
    overflow: auto;
  }
  .main-loadding {
    width: 100%;
    height: 100%;
    .icon-loadding {
      z-index: 9;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      z-index: 999;
      opacity: 1;
      padding: 11%;
      position: absolute;
      text-align: center;
      height: 100%;
    }
  }
`
const DropdownBox = styled.div` 
.box-dropdown {
    position: absolute; 
    min-width: 414px;
    max-height: 260px;
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    margin: 0px 10.4px 0 0;
    padding: ${props => (props.isFlex ? '9px 8px 11px 8px' : '9px 0px 14px 12px')};
    z-index: 8;
    
    ul {
      padding: 0px 11px 0px 0px;
      overflow: auto;
      max-height: 240px;
      margin: 0px;
      height: auto;
    }
    li{
        padding: 10px 11px 7px 10px;
        height: 40px; 
        font-size: 13px;
        min-width:${props => (props.isFlex ? '111px' : '140px')};
        color: #385172;
        text-align:left;
    }
    li:hover{
          border-radius: 8px;
        background-color: #f5f5fa;
      }
    }
  }
  .box_field{
    border-radius: 6px;
    border: solid 1px #aabaca;
    color: #385172;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 12px 30px;
    cursor: pointer;
    padding-right: 40px;
    width: 414px;
    height: 44px;
  }
  .arrow-down {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 4px; 
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: -28px;
  }
  .arrow-up {
    border: solid #aabaca;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    margin-top: 6px; 
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    margin: -28px;
  }
.text-dropdown{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
}
.head-sub-title{
  color: #aabaca;
}
.box-dropdown-small {
  width: 119px !important;
  min-width: 80% !important;

  li{
    width: 90px !important;
    min-width: 80% !important;
  }
}
.bottom-height{
  margin-bottom: 200px;
  position: absolute;
  height: 300px;
}
.box-dropdown-md {
  width: 232px !important;
  min-width: 90% !important;
}
  
`
const FacebookBox = styled.div`
.card{
  border-radius: 8px;
  border: 0;
  box-shadow: 2px -2px 10px 3px rgb(0 0 0 / 9%);
  min-height: 560px;
  padding: 10%;
}

`
export { MemberBox, IntegrationsBox, AccountBox, PercentBox, DropdownBox ,FacebookBox}
