import styled from 'styled-components'

const SegmentBox = styled.div`
  .btn-150 {
    padding: 11px !important;
    width: 150px !important;
    height: 44px !important;
    border-radius: 10px !important;
    font-size: 14px !important;
  }

  .main {
    padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
    color: #6a707e;
    width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
    margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
    transition: margin 700ms;
    .title {
      font-weight: 500;
      color: #1e334e;
      font-size: 24px;
      img {
        width: 22px !important;
        margin-right: 3px;
      }
    }
    .box-option {
      display: none;
      ul {
        padding: unset;
      }
    }
    .-show {
      display: block;
    }
    .top-title {
      display: flex;
      cursor: pointer;
      .title {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1e334e;
        margin-bottom: 0px;
        font-weight: 100;
      }
      .-gray {
        color: #aabaca;
      }
      .btn-blue {
        margin-left: auto;
        width: 160px;
        height: 50px;
        padding: 12.5px 0;
        border-radius: 8px;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        img {
          width: 16px;
          margin-right: 5px;
          margin-top: -2px;
        }
        :hover {
          background-color: #1a90a0;
        }
      }
    }
    .rdt_Table {
      background-color: #edf1f4;

      margin-top: 25px;
      max-height: 655px;
      height: 40vw;
      overflow: auto;
      .rdt_TableHeadRow {
        border: unset;
        border-radius: unset;
        height: 50px;
        min-height: 50px;
      }
      .rdt_TableRow {
        border: unset;
        cursor: auto;
      }
      .rdt_TableRow:nth-child(even) {
        background: #fafcfe;
      }
    }
    .rdt_Table {
      background-color: unset;
      // box-shadow: 12px -4px 6px 4px #f2f2f4;
      // border-radius: 8px;
      margin-top: 10px;
      // height: 655px;
      // overflow: scroll;
    }
    .rdt_TableHeader {
      display: none;
    }
    .rdt_TableHeadRow {
      background-color: #edf1f4 !important;
      border-bottom: 1px solid #ebebeb;
      padding-right: 10px;

      .rdt_TableCol {
        font-weight: bold;
        font-size: 16px;
        color: #1e334e;
        padding: 0px 15px;
      }
    }
    .rdt_TableCell {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
      padding-left: 25px;
    }
  }
  .rdt_TableRow {
    border-bottom-color: #ebebeb;
    padding-right: 10px;
    height: 50px;
    font-size: 16px;
  }
  .rdt_TableRow:hover {
    cursor: pointer;
    // background-color: #fafcfe;
  }
  .rdt_TableRow:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .rdt_TableCol_Sortable {
    color: #385172;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding-left: 10px;
    span {
      font-size: 9px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .rdt_TableCol_Sortable:hover {
    color: unset; //#404040;
  }
  .icon-option {
    cursor: pointer;
    cursor: pointer;
    width: 25px;
    height: 7px;
    background: url(/assets/icons/option.png) no-repeat;
    display: inline-block;
    background-size: 17px 5px;
  }
  .icon-option:hover {
    background: url(/assets/icons/option-active.png) no-repeat;
    cursor: pointer;
    cursor: pointer;
    width: 25px;
    height: 7px;
    display: inline-block;
    background-size: 17px 5px;
  }

  .-icon-active {
    cursor: pointer;
    cursor: pointer;
    width: 25px;
    height: 7px;
    background: url(/assets/icons/option-active.png) no-repeat;
    display: inline-block;
    background-size: 17px 5px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fadeIn {
    animation: fadeIn 0.5s;
  }
  .ball-loadding {
    text-align: center;
    img {
      width: 80px;
      margin-left: -50px;
      margin-top: 160px;
    }
  }

  .main-loadding {
    // text-align: center;
    .icon-loadding {
      position: absolute;
      z-index: 9;
      left: 44%;
      top: 20%;
      margin: 0px auto;
      width: 80px;
    }
    .overlay-loadding {
      width: ${props => (props.isShowMenu ? '81%' : '96%')};

      height: 100vw;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      position: fixed;
      z-index: 999;
      opacity: 1;
      margin-left: -40px;
      margin-top: ${props => (props.isShowMenu ? '-80px' : '-90px')};
    }
  }
`

const BuilderBox = styled.div`
  .main-section {
    margin: ${props => (props.isShowMenu ? '30px 15px 30px 340px' : '40px 40px 35px 100px')};
    transition: margin 700ms;

    display: flex;
    margin-top: 30px;
    .col-8,
    col-4 {
      padding-left: unset !important;
    }
 
  }
  .top-title {
    display: flex;
      cursor: pointer;
      .title {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #1e334e;
        margin-bottom: 0px;
        font-weight: 100;
      }
  }

  .title {
    font-size: 17px;
    font-weight: bold;
    color: #1e334e;
    img {
      width: 22px !important;
      margin-right: 3px;
    }
  }
  .main-loadding { 
    .icon-loadding {
      position: absolute;
      z-index: 9;
      left: ${props => (props.isShowMenu ? '57%' : 'unset')};
      top: 20%;
      margin: 0px auto;
      width: 80px;
    }
    .overlay-loadding {
      width: 100%;//${props => (props.isShowMenu ? '81%' : '96%')};
      height: 100vw;
      display: table;
      background: rgba(255, 255, 255, 0.8);
      position: fixed;
      z-index: 999;
      opacity: 1;
      margin-top: ${props => (props.isShowMenu ? '-80px' : '-90px')};
      text-align: center;
    }
  }
  .-red-bd{
    border:1px solid #ff2c65!important;
  }
  .rdt_Table {
    background-color: #edf1f4;
    border: unset!important;
    border-radius: unset!important;
    margin-top: 25px;
    max-height: 655px;
    overflow: auto;
    height:auto!important;
    .rdt_TableHeadRow{
      border:unset;
      border-radius:unset;
      height:50px;
      padding:unset;
    }
    .rdt_TableRow{
      border:unset;
      padding:unset!important;
    }
    .rdt_TableRow:nth-child(even) {background: #fafcfe}
    .rdt_TableCol{
      font-size:15px!important;
      padding: 0px 15px!important;
    }
  }
  .title {
    .-gray { 
      color: #aabaca;
    }
    .-blue {
      color: #24b0c3;
      text-transform: capitalize;
    }
    // img {
    //   width: 15px !important;
    //   margin-left: 6px;
    // }
  }
  .sub-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #344d6f;
    .-red {
      color: #ff2c65;
      font-size: inherit;
    }
  }
  

  .box-segment {
    padding: 25px 25px;
    border-radius: 14px;
    margin-bottom: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    .title{
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #344d6f;
    }
    .segment-name {
      height: 44px;
      padding: 11px 32px 10px 22px;
      border-radius: 8px;
      border: solid 1px #aabaca;
      background-color: #fff;
      width: 50%;
      margin-bottom: 35px;
      color:#385172;
      outline:none;
      font-size: 14px;
      font-weight: 400;
    }
    .segment-name::placeholder {
      color: #aabaca;
      opacity: 1; /* Firefox */
    }
    .segment-name:hover , .segment-name:focus{
      border: solid 1px #24B0C3;
    }
    .row-option {
      display:flex;
      margin: 0px;
      width: 400px;
      .container{
        width: 110px;
        margin: 7px 26px;
        span{
          color:#385172;
          font-size: 14px;
      font-weight: 400;
        }
      }
       .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
        .checkmark {
        position: absolute;
        height: 25px;
        width: 25px;
        cursor: pointer;
        border: solid 1px #919fb6;
        border-radius: 50%;
        margin-left: -40px;
        :hover{
          border: solid 1px #385172;
        }
      } 
        .container input:checked ~ .checkmark {
        background-color: #fff;
      }
      
       .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
       .container input:checked ~ .checkmark:after {
        display: block;
      }
      
       .container .checkmark:after {
        top: 3px;
            left: 3px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: #385172;
      }
    }
  }
  .create-segment {
    padding: 25px 25px;
    border-radius: 14px;
    margin-bottom: 15px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease 0.5s;
    background-color: #fff;
    .title {
      font-size: 16px;
      font-weight:600;
      color:#344d6f!important;
    }
    .-blue {
      cursor: pointer;
    }
    .nice-dates{
      width:168px;
    }
    .btn-close {
      float: right;
      cursor: pointer;
      right: 32px;
      top: 36px;
      width: 31px;
      height: 18px;
      opacity: 0.3;
      display: inline-block;
    }
    .btn-close:before,
    .btn-close:after {
      position: absolute;
      content: ' ';
      height: 16px;
      width: 2px;
      right: 38px;
      border-radius: 3px;
      background-color: #333;
    }
    .btn-close:before {
      transform: rotate(45deg);
    }
    .btn-close:after {
      transform: rotate(-45deg);
    }
    .inner-segment {
      display: flex;
      .btn-close {
        float: unset;
      }
      .btn-close:before,
      .btn-close:after {
        right: unset;
        margin-left: 25px;
        margin-top: 13px;
      }
      .-fix-space{
        margin-left:11px!important;
      }
      .icon-carlendar {
        width: 20px;
        margin-left: -35px;
        margin-top: -3px;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity:0;
      height: 0px;
    }
    100% {
      opacity:1;
      height: 43px;
    }
    }
  
    @-moz-keyframes fadeIn {
        0% {
          opacity:0;
        height: 0px;
      }
      100% {
        opacity:1;
        height: 43px;
      }
    }
  
    @-webkit-keyframes fadeIn {
          0% {
          opacity:0;
        height: 0px;
      }
      100% {
        opacity:1;
        height: 43px;
      }
    }
  
    @-o-keyframes fadeIn {
        0% {
          opacity:0;
        height: 0px;
      }
      100% {
        opacity:1;
        height: 43px;
      }
    }
  
    @-ms-keyframes fadeIn {
        0% {
          opacity:0;
        height: 0px;
      }
      100% {
        opacity:1;
        height: 43px;
      }
    }

    .-fadeIn{
      animation: fadeIn 0.5s;
    }
  
    .inner-event-condition{
      margin-top: 15px;
     
      .-times{
        color:#aabaca;
        margin-top:10px;
        font-weight: 500;
        font-size: 16px;
        margin-left:10px;
      }
      .container-checkbox {
        display: block;
        position: relative;
        padding-left: 32px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 14px;
        color:#385172;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .container-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 21px;
        width: 21px;
        border-radius:6px;
        background-color: #fff;
        border: 1px solid #919fb6;
      }
      
      /* On mouse-over, add a grey background color */
      .container-checkbox:hover input ~ .checkmark {
        background-color: #fff;
      }
      
      /* When the checkbox is checked, add a blue background */
      .container-checkbox input:checked ~ .checkmark {
        background-color: #385172;
        border: 1px solid #385172;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .container-checkbox input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .container-checkbox .checkmark:after {
        left: 6px;
        top: 2px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }


  .btn-condition {
    cursor: pointer;
    width: 180px;
    height: 50px;
    padding: 11px 18px 10px 17px;
    border-radius: 8px;
    background-color: #385172;
    color: #fff;
    font-weight:100!important;
    font-size:14px!important;
    img {
      width: 15px;
      margin-top: -3px;
    }
    :hover{
      background-color:#1e334e;
    }
  }
  .box-summary {
    border-radius: 14px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px 25px;
    text-align: center;
    .overlay-loadding {
      width: ${props => (props.isShowMenu ? '92%' : '93%')};
      height: ${props => (props.isShowMenu ? '435px' : '460px')};
      display: table;
      background: rgba(255,255,255,.8);
      position: absolute;
      z-index: 999;
      opacity: 1;
      margin: 0;
      margin-top:-20px;
      margin-left: -25px;
      .icon-loadding{
        position: absolute;
        z-index: 9;
        left: 38.5%;
        top: 38%;
        margin: 0px auto;
        width: 80px;
      }
    }
    .-res_total{
      color: #344d6f;
      font-size: 20px;
      font-weight: 600;
    }
    .-total{
      color: #919fb6;
      font-size: 15px;
      font-weight: 100;
  }
    }
  }
  .CircularProgressbar .CircularProgressbar-path {
    stroke-width: 5px;
    stroke: #24b0c3;
    stroke-linecap: unset;
  }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke:#e4ebf2;
    stroke-width: 5px;
  }
  .CircularProgressbar-text {
    fill: #24b0c3!important;
  }
  
  .CircularProgressbar {
    width: 75%;
    margin: 30px;
  }
  .inner-result {
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    position: sticky;
    color: #24b0c3;
    padding-top: 55px;
    background-color: #fff;
    width: 170px;
    height: 170px;
    border-radius: 50%;
  }
  .input-basic {
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    padding: 11px 11px 10px 11px;
    outline: none;
    width: 170px;
    height: 44px;
    font-size: 13px;
    color:#385172;
    ::placeholder {
      color: #aabaca;
      opacity: 1; /* Firefox */
    }
  }
  .input-basic:hover , .input-basic:focus{
    border: solid 1px #24B0C3;
  }
  .-isFlex {
    width: 120px;
  }

  .input-datepicker {
    border-radius: 8px;
    border: solid 1px #aabaca;
    background-color: #fff;
    height: 44px;
    padding: 6px 13px;
    color: #385172;
    outline: none;
    width:170px;
    ::placeholder {
      color: #aabaca;
      opacity: 1; /* Firefox */
    }
  }

  .nice-dates-day {
    height: 41px !important;
    color: #385172;
  }
  .nice-dates-popover {
    transform: unset;
    padding: 15px;
    box-shadow: none;
    margin: 2px 0;
    border: 1px solid #c2cfe0;
    height: 360px;
    border-radius: 10px;
    max-width: 360px;
    transition: none;
    -webkit-transition: opacity ease-out 0.2s 0.1s;
    -moz-transition: opacity ease-out 0.2s 0.1s;
    -o-transition: opacity ease-out 0.2s 0.1s;
    -ms-transition: opacity ease-out 0.2s 0.1s;
    transition: opacity ease-out 0.2s 0.1s;
  }
  .nice-dates-navigation_current {
    color: #385172;
  }
  ..nice-dates-week-header {
    margin-bottom: 10px;
  }
  .nice-dates-week-header_day {
    color: #aabaca;
  }
  .nice-dates-day_month {
    top: 1px;
  }
  .nice-dates-day:before,
  .nice-dates-day:after {
    width: 40px;
    margin-left: 4px;
    background-color: #24b0c3;
    border: none;
    color: #fff;
  }
  .nice-dates-day:hover {
    span {
      color: #fff;
    }
  }
  .-outside {
    color: #aabaca !important;
  }
  .btn-refresh {
    cursor: pointer;
    margin-top: -14px;
    margin-right: -18px;
    float:right;
    padding: 10px 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border: 1px solid #fff;
    img {
      width: 20px !important;
    }
    :hover{
      border: 1px solid #aabaca;
    }
  }
  .bd-top{
    border-top:2px solid #E1E6ED;
    padding-top:40px;
    margin-top:60px;
    display: block;
    .title{
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #1e334e;
    }
  }

 
  .box-preview{
    .overlay-loadding {
      width: ${props => (props.isShowMenu ? '77%' : '94%')};
      height: 870px;
      display: table;
      background: rgba(255,255,255,.8);
      position: absolute;
      z-index: 999;
      opacity: 1;
      margin-left: -20px;
      margin-top: -20px;
      .icon-loadding{
        position: absolute;
        z-index: 9;
        left: 45%;
        top: 35%;
        margin: 0px auto;
        width: 80px;
      }
    }
    .-nodata{
      width:450px;
      margin: 40px auto;
      display: block;
    }
    .txt-no-data{
      text-align:center;
      color:#919fb6;
      font-size: 14px;
    }
 
    .box-footer{
      text-align:right;
      margin-top: 20px;
      .-btn{ 
        margin-left: 10px;
        width: 160px;
        height: 50px;
        padding: 12.5px 0;
        border-radius: 8px;
        cursor:pointer;
        text-align: center;
        display: inline-block;
      }
      .-btn-gray{
        background-color:  #eee;
        color:#6f809e;
        padding: 13px 36px;
        :hover{
          background-color: #dbdbdb;
        }
      }
      .-btn-cancel{
        padding: 13px 36px;
        border-radius: 8px;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #24b0c3;
        background-color: #fff;
        width: 120px;
        color: #24b0c3;
      }
      .-btn-blue{
        width: 120px;
        background-color: #24b0c3;
    border: solid 1px #24b0c3;
        color:#fff;
        padding: 13px 45px;
        :hover{
          background-color: #1a90a0;
        }
      }
    }

  }

  .-tab{
    padding:15px;
    color:#919fb6;
    cursor:pointer;
    border-bottom: 1.5px solid #e1e6ed;
    :hover{
      color:#6f809e;
    }
  }

  .-tab-active{
    color: #385172;
    border-bottom: 3px solid #24b0c3;
  }

  .btn-export{ 
    padding: 16px 37px;
    width: 155px;
    height: 56px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  cursor:pointer;
  margin-right: 10px;
      float: right;
      margin-top: -20px;
    color:#385172;
    border: 1px solid #fff;
    :hover{
      border: 1px solid #1a90a0;
    }
    a{
      color:#385172!important;
    }
  img{
    width: 19px;
    margin-right: 10px; 
    margin-top: -3px;
  }
  }

  .rdt_Table {
    background-color: #edf1f4;
    border: unset !important;
    border-radius: unset !important;
    margin-top: 25px;
    max-height: 655px;
    height: 100vw;
    overflow: auto;
    .rdt_TableHeadRow {
      
      border: unset;
      border-radius: unset;
      height: 50px;
      min-height: unset;
    }
    .rdt_TableRow {
      border: unset;
      cursor: auto;
    }
    .rdt_TableRow:nth-child(even) {
      background: #fafcfe;
    }
  }
  .rdt_Table {
    margin-top: 25px;

  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    background-color: #edf1f4;
    border-bottom: 1px solid #ebebeb;
    padding-right: 10px;

    .rdt_TableCol {
      font-size: 14px!important;
      font-weight:400;
      color: #334d6e;
      padding: 0px 15px;
    }
  }
  .rdt_TableCell {
    font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6f809e;
  
  }
  .rdt_TableBody {
  }
  .rdt_TableRow {
    border-bottom-color: #ebebeb;
    padding-right: 10px;
    height: 50px;
    font-size: 16px;
    min-height:unset;
  }
  .rdt_TableRow:hover {
    cursor: pointer;
    // background-color: #fafcfe;
  }
 
  .rdt_TableCol_Sortable {
    color: unset; //#404040;
    span {
      font-size: 9px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .rdt_TableCol_Sortable:hover {
    color: unset; //#404040;
  }

  .ant-picker-cell-selected{
    .ant-picker-cell-inner{
      background:#24b0c3;
    }
  }
  .ant-picker{
    border: solid 1px #aabaca;
    border-radius: 8px;
  }
  .-mt-3{
    margin-top:-3px;
  }
`

const ConditionBox = styled.div`
  .box-condition {
    margin-bottom: 20px;
    text-align: center;
    .inner-condition {
      border-radius: 20px;
      border: solid 1px #aabaca;
      background-color: #fff;
      width: 169px;
      height: 40px;
      margin: auto;
      padding: 2px;
      .active-condition {
        margin: 0 0 0 1px;
        border-radius: 20px;
        background-color: #24b0c3;
        color: #fff;
        padding: 5px;

        position: absolute;
        width: 80px;
        height: 34px;
        margin-left: ${props => (props.isLeft ? '0px' : '83px')};
        transition: all 0.2s ease-in-out;
      }
      .-active {
        color: #fff;
        :hover {
          color: #fff;
        }
      }

      label {
        cursor: pointer;
        position: relative;
        margin-top: 5px;
        width: 81px;
        height: 34px;
        color: #919fb6;
        :hover {
          color: #24b0c3;
        }
      }
    }
  }
`

export { SegmentBox, BuilderBox, ConditionBox }
