import React, { Fragment, useState, useEffect, useRef } from 'react'
import { IntegrationsBox, DropdownBox } from './style'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import useOutsideClick from './useOutsideClick'
import {
  getProviderConfigure,
  removeProviderConfigure,
  createProviderConfigure
} from '../../apis/dataActivation/api'
import { testConnectProvider } from '../../apis/provider/api'

const ConfigIntegrationsComponent = props => {
  const ref = useRef()
  const [showConfigure, setShowConfigure] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [openPopupRemove, setOpenPopupRemove] = useState(false)
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false)
  const [openPopupFalse, setOpenPopupFalse] = useState(false)
  const [connect, setConnect] = useState(false)
  const [packages, setPackages] = useState(null)
  const [detailConfigure, setDetailConfigure] = useState()
  const [openDropdownPackages, setOpenDropdownPackages] = useState(false)

  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [apiSenderName, setApiSenderName] = useState('')

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      borderRadius: '10px',
      display: 'inline-table',
      padding: '0px',
      border: 'solid 1px #aabaca !important'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  const handleCloseAll = () => {
    setOpenDropdownPackages(false)
  }
  const handleClick = e => {
    setOpenDropdownPackages(true)
  }
  const setPackagesValue = packages => {
    setPackages(packages)
    handleCloseAll()
  }
  const setOpenDropdownsetPackagesset = open => {
    setOpenDropdownPackages(open)
  }

  useEffect(async () => {
    const { providerConfigure } = props
    if (providerConfigure && providerConfigure.status_configure.connected) {
      await getServiceDetail(providerConfigure.ref_id)
    }
  }, [])

  const getServiceDetail = async ref_id => {
    const { data, status } = await getProviderConfigure(ref_id)
    if (data && data.data && data.data[0] && data.data[0].provider_configure && status === 200) {
      for (let i = 0; i < data.data[0].provider_configure.length; i++) {
        if (data.data[0].provider_configure[i].field_id_name === 'api_key') {
          setApiKey(data.data[0].provider_configure[i].field_value)
        }
        if (data.data[0].provider_configure[i].field_id_name === 'api_secret') {
          setApiSecret(data.data[0].provider_configure[i].field_value)
        }
        if (data.data[0].provider_configure[i].field_id_name === 'package_type') {
          setPackagesValue(data.data[0].provider_configure[i].field_value)
        }
        if (data.data[0].provider_configure[i].field_id_name === 'sender_name') {
          setApiSenderName(data.data[0].provider_configure[i].field_value)
        }
      }
      setDetailConfigure(data.data[0])
    } else {
      console.log('getDetail error : ', data)
    }
  }

  const createConnect = async () => {
    let obj = {
      provider_ref_id: providerConfigure.ref_id,
      provider_configure: [
        {
          field_id_name: 'api_key',
          field_value: apiKey
        },
        {
          field_id_name: 'api_secret',
          field_value: apiSecret
        },
        {
          field_id_name: 'sender_name',
          field_value: apiSenderName
        },
        {
          field_id_name: 'package_type',
          field_value: packages
        }
      ]
    }
    const { data, status } = await createProviderConfigure(obj)
    if (data && status === 200) {
      setOpenPopupFalse(false)
      setOpenPopupSuccess(true)
    } else {
      setOpenPopupSuccess(false)
      setOpenPopupFalse(true)
    }
  }

  const removeConfigure = async () => {
    const { data, status } = await removeProviderConfigure(
      props.providerConfigure.status_configure.configure_ref_id
    )
    if (data && status && status === 200) {
      props.setShowConfigureFn(false)
    }
  }

  const ModalConfigIntegrationsSuccess = () => {
    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupSuccess}
        onRequestClose={() => setOpenPopupSuccess(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="px-5 text-center">
            <img src="/assets/icons/Success.png" className="px-5 w-50 pt-3" />

            <p className="f-16 font-weight-bold font-darkblue mt-3">Connected</p>
            <p className="sub-title-12 text-secondary">
              Your integration is connected successfully
            </p>
          </div>

          <div className="footer-popup text-center mb-3">
            <a
              onClick={() => props.setShowConfigureFn(false)}
              className="btn-basic bg-darkblue font-white w-50 "
            >
              OK
            </a>
          </div>
        </div>
      </Modal>
    )
  }
  const ModalConfigIntegrationsFalse = () => {
    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupFalse}
        onRequestClose={() => setOpenPopupFalse(false)}
        style={customStyles}
      >
        <div className="close p-2" onClick={() => setOpenPopupFalse(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        <div className="body-popup">
          <div className="px-5 text-center">
            <img src="/assets/icons/fail.png" className="px-5 w-50 pt-3" />

            <p className="f-16 font-weight-bold font-darkpink mt-3">Connect Fail</p>
            <p className="sub-title-12 text-secondary">
              Your integration is connected fail. Please check your<br></br>
              API information
            </p>
          </div>

          <div className="footer-popup text-center mb-3">
            <a
              onClick={() => setOpenPopupFalse(false)}
              className=" btn-basic  bg-darkpink font-white"
            >
              Close
            </a>
          </div>
        </div>
      </Modal>
    )
  }

  const DropdownPackages = () => {
    const refer = useRef()
    useOutsideClick(refer, callback => {
      setOpenDropdownsetPackagesset(false)
    })
    return (
      <DropdownBox>
        <div
          ref={refer}
          className={`box_field  time-box m-0 mt-2 ${
            providerConfigure && providerConfigure.status_configure.connected ? '-disable' : ''
          }`}
          onClick={() =>
            providerConfigure &&
            !providerConfigure.status_configure.connected &&
            setOpenDropdownsetPackagesset(!openDropdownPackages)
          }
        >
          <p className="text-dropdown">{packages ? packages : ''}</p>
          <i className={`arrow-${openDropdownPackages ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownPackages && (
          <div className="bottom-height">
            <div className="box-dropdown ml-0">
              <ul>
                {dataPackages.map((listpackage, i) => {
                  return (
                    <li key={i} onClick={() => setPackagesValue(listpackage)}>
                      {listpackage}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </DropdownBox>
    )
  }
  const dataPackages = [' standard', ' corporate']
  const { providerConfigure } = props
  return (
    <IntegrationsBox>
      <div className="card" ref={ref}>
        <div className="card-header bg-white py-2 px-0 ">
          <div className="row">
            <div className="col-12 py-2">
              <p className=" title py-1 m-0 px-5 line-high-2">
                {' '}
                <i className="arrow-left" onClick={() => props.setShowConfigureFn(false)}></i>{' '}
                ThaibulkSMS{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="card-body py-2 pl-4">
          <div className="w-100 my-3 f-14 font-darkblue font-weight-normal">
            Connect with Thaibulk.
            <span className="font-darkgreen font-w-600 -pointer"> Learn more</span>
          </div>
          <div className="form-horizontal">
            <div className="row ">
              <label className="control-label col-2 font-w-600 f-16 font-darkblue line-h-4">
                API Key
              </label>
              <div className="col-5">
                <input
                  value={apiKey}
                  type="input"
                  className={`input ${
                    providerConfigure && providerConfigure.status_configure.connected
                      ? '-disable'
                      : ''
                  }`}
                  onChange={e => setApiKey(e.target.value)}
                  disabled={
                    providerConfigure && providerConfigure.status_configure.connected ? true : false
                  }
                />
                {/* <input type="" className="input -disable" id="" placeholder="" disabled/> */}
              </div>
            </div>
            <div className="row  ">
              <label className="control-label col-2 font-w-600 f-16 font-darkblue line-h-4">
                API Secret
              </label>
              <div className="col-5">
                <input
                  value={apiSecret}
                  type="input"
                  className={`input ${
                    providerConfigure && providerConfigure.status_configure.connected
                      ? '-disable'
                      : ''
                  }`}
                  onChange={e => setApiSecret(e.target.value)}
                  disabled={
                    providerConfigure && providerConfigure.status_configure.connected ? true : false
                  }
                />
              </div>
            </div>
            <div className="row ">
              <label className="control-label col-2 font-w-600 f-16 font-darkblue line-h-4">
                Package
              </label>
              <div className="col-5">
                <DropdownPackages />
              </div>
            </div>
            <div className="row form-group ">
              <label className="control-label col-2 font-w-600 f-16 font-darkblue line-h-4">
                Sender Name
              </label>
              <div className="col-5">
                <input
                  value={apiSenderName}
                  type="input"
                  className={`input ${
                    providerConfigure && providerConfigure.status_configure.connected
                      ? '-disable'
                      : ''
                  }`}
                  onChange={e => setApiSenderName(e.target.value)}
                  disabled={
                    providerConfigure && providerConfigure.status_configure.connected ? true : false
                  }
                />
              </div>
            </div>
            <div className="row form-group ">
              <label className="control-label col-2 font-w-600 f-16 font-darkblue line-h-4">
                {''}
              </label>
              <div className="col-8 p-1">
                <p className="f-12 pl-3 font-weight-normal font-gray">
                  Note: Enter approved sender name(s) from the selected SMS service provider. Use
                  (,) to seperate the sender names. The sender name need to be matched 100% . Please
                  be aware of sensitive case.{' '}
                </p>
                {providerConfigure && providerConfigure.status_configure.connected ? (
                  <Fragment>
                    <button className="btn-connected cursor-default">Connected</button>
                    <button
                      className="btn-basic bg-whiter font-darkpink border-0 shadow-0"
                      onClick={() => setOpenPopupRemove(true)}
                    >
                      <img
                        src="/assets/icons/bin-red.png"
                        alt="predictive"
                        className="w-px-14 mx-1"
                      />
                      Remove
                    </button>
                  </Fragment>
                ) : (
                  <a
                    className={`btn-basic ${
                      apiKey || apiSecret || apiSenderName
                        ? 'bg-darkgreen font-white'
                        : 'bg-gray font-white'
                    }`}
                    onClick={() =>
                      apiKey || apiSecret || apiSenderName ? createConnect(false) : ''
                    }
                  >
                    Connect
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupRemove}
        onRequestClose={() => setOpenPopupRemove(false)}
        style={customStyles}
      >
        <div className="body-popup pt-10 ">
          <div className="px-5 text-center">
            <img src="/assets/icons/bin.png" className="px-5 w-50 pt-3" />

            <p className="f-20 font-w-600 text-black mt-3">Remove Configuration?</p>
            <p className="sub-title-12 text-secondary">
              All running and upcoming campaigns will be failed to send. Do you confirm to remove
              this configuration?
            </p>
          </div>

          <div className="footer-popup text-center mb-25">
            <button
              onClick={() => setOpenPopupRemove(false)}
              className=" btn-sm-config bg-white border-darkpink mb-3 font-darkpink"
            >
              Cancel
            </button>
            <button
              onClick={() => removeConfigure(providerConfigure.ref_id)}
              className="btn-sm-config bg-darkpink mb-3"
            >
              Remove
            </button>
          </div>
        </div>
      </Modal>
      <ModalConfigIntegrationsSuccess />
      <ModalConfigIntegrationsFalse />
    </IntegrationsBox>
  )
}
export default ConfigIntegrationsComponent
