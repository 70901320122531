import styled from 'styled-components'

const PopupBox = styled.div`
  .noti_popup {
    position: absolute;
    width: 387px;
    max-height: 77px;
    padding: 12.3px 7.1px 14.5px 18.6px;
    border-radius: 10px;
    border: solid 1px #8dc360;
    background-color: #f6ffec;
    right: 100px;
    margin-top: 20px;
    display: flex;
    z-index: 999;
    color: #8dc360;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    .-left {
      width: 36px;
    }
    b {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #385172;
    }

    span {
      font-family: Inter;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: left;
      color: #6f809e;
    }

    .ico-warning {
      width: 20px;
    }

    .close_noti_popup {
      position: absolute;
      right: 6px;
      width: 24px;
      height: 24px;
      opacity: 0.3;
    }
    .close_noti_popup:hover {
      color: #adadad;
      cursor: pointer;
      opacity: 1;
    }
    .close_noti_popup:before,
    .close_noti_popup:after {
      position: absolute;
      left: 10px;
      content: ' ';
      height: 14px;
      width: 3px;
      background-color: #333;
    }
    .close_noti_popup:before {
      transform: rotate(45deg);
    }
    .close_noti_popup:after {
      transform: rotate(-45deg);
    }
    .checkmark {
      display: inline-block;
      transform: rotate(45deg);
      height: 15px;
      width: 9px;
      margin-right: 8px;
      border-bottom: 4px solid #ced4de;
      border-right: 4px solid #ced4de;
    }
    .checkmark-green {
      border-bottom: 4px solid #78b13f;
      border-right: 4px solid #78b13f;
    }
  }
`

export { PopupBox }
