import React, { Fragment, useState, useEffect } from 'react'
import { LoginBox } from './style'
import NavComponent from '../navigation/navigation'
import { useForm } from 'react-hook-form'
import { connectLogin, sendOTP } from '../../apis/login/api'
import Login2FAComponent from './login2FA'
import ForgotPasswordComponent from './forgot'
import PopupComponent from '../popup/popup'
import { useHistory, useLocation } from 'react-router-dom'

const LoginComponent = () => {
  const [isLogin, setLogin] = useState(false)
  const [loginSucces, setLoginSucces] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [shown, setShown] = useState(false)
  const [gotoForgot, setForgot] = useState(false)
  const [resetNoti, setResetNoti] = useState(false)
  const [OTPData, setOTPData] = useState({})
  const [isLoadding, setLoading] = useState(false)
  const [apiError, setNotiApiError] = useState(false)

  const history = useHistory()
  let location = useLocation()
  useEffect(() => {
    history.push('/login')
    localStorage.removeItem('token')
    const params = new URLSearchParams(location.search)
    const reset = params.get('reset')
    if (reset) {
      setResetNoti(true)
      setTimeout(() => {
        setResetNoti(false)
      }, 5000)
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = async obj => {
    setLoading(true)
    try {
      const { data: resLogin, status: statusLogin } = await connectLogin(obj)
      console.log('resLogin : ', resLogin)
      if (statusLogin !== 200) {
        // setNotiApiError(true)
        // setTimeout(() => {
        //   setNotiApiError(false)
        // }, 5000)
        throw 'Login fail'
      }
      const reqSendOTP = { email: obj.email, otpId: resLogin.otp_id }
      const { data: restSendOTP, status: statusSendOTP } = await sendOTP(reqSendOTP)
      if (statusSendOTP !== 200) {
        setNotiApiError(true)
        setTimeout(() => {
          setNotiApiError(false)
        }, 5000)
        throw 'Send OTP Fail'
      }
      setNotiApiError(false)
      setOTPData({ email: obj.email, otpId: resLogin.otp_id, refId: restSendOTP.ref_id })
      setLoginSucces(true)
      setLoading(false)
    } catch (error) {
      setLoginError(true)
      setLoginSucces(false)
      setLoading(false)
      // setNotiApiError(true)
      // setTimeout(() => {
      //   setNotiApiError(false)
      // }, 5000)
    }
  }

  const forgotPage = () => {
    setForgot(true)
    history.push('/login/forgot')
  }
  return !isLogin ? (
    <LoginBox>
      <section>xxx</section>
      <section className="bg-white">
        {resetNoti && (
          <PopupComponent
            option={{ status: 'success', msg: 'Your password was set successfully.' }}
          />
        )}
        {apiError && (
          <PopupComponent
            option={{ status: 'error', msg: 'Something went wrong, please try again later.' }}
          />
        )}

        {!loginSucces ? (
          !gotoForgot ? (
            <form className="bg-white" onSubmit={handleSubmit(onSubmit)}>
              <div className="box_login">
                <p className="title">Login</p>
                <label className="label_err">
                  {loginError ? 'The email address or password is incorrect' : ''}
                </label>
                <span className="input_label">Email Address</span>
                <input
                  className={`input ${errors.email || loginError ? 'input_err' : ''}`}
                  {...register('email', {
                    required: 'Please enter your email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter a valid email address'
                    }
                  })}
                />
                <label className="label_err" >{errors.email?.message}</label>
                <span className="input_label">Password</span>
                <input
                  className={`input ${errors.password || loginError ? 'input_err' : ''}`}
                  type={shown ? 'text' : 'password'}
                  {...register('password', { required: 'Please enter your password' })}
                />
                <span className="show_password" onClick={() => setShown(!shown)}>
                  {shown ? 'Hide' : 'Show'}
                </span>
                <label className="label_err">{errors.password?.message}</label>
                <span onClick={() => forgotPage()} className="forgot_password">
                  Forgot Password ?
                </span>
                <button className="btn-basic bg-darkgreen font-white w-100 border-0 mt-3" type="submit">
                  {isLoadding ? <div className="loader-circle"></div> : 'Login'}
                  </button>
                  {isLoadding ? <div className="loader-btn-disabled"></div> : ''}
                
              </div>
            </form>
          ) : (
            <ForgotPasswordComponent />
          )
        ) : (
          <Login2FAComponent OTPData={OTPData} setLoginFn={setLogin} />
        )}
      </section>
    </LoginBox>
  ) : (
    <NavComponent setLoginFn={setLogin} />
  )
}

export default LoginComponent
