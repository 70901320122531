import styled from 'styled-components'

const DropdownBox = styled.div`
  .-inactive {
    color: rgba(112, 118, 131, 0.65);
    background-color: #f4f5f5;
  }
  li {
    cursor: pointer;
  }

  .-validate,
  .-validatePK,
  .-validateFK,
  .-validateTK {
    border: solid 1px #e86580 !important;
    color: #e86580 !important;
  }
  .-isKey {
    display: none;
    // background: #f4f5f5;
    // cursor: no-drop;
    // font-weight: 700;
  }
  // .-isKey:hover {
  //   background: #f4f5f5 !important;
  //   border: none !important;
  //   font-weight: 700 !important;
  // }
  .-lastitem {
    position: relative !important;
  }
  .box-dropdown {
    width: 300px;
    max-height: ${props => (props.showCustom ? 'auto' : '260px')};
    box-shadow: 0px 14px 24px 0px #3333331f;
    box-shadow: 0px 4px 4px 0px #3333330;
    position: absolute;
    z-index: 999;
    background-color: white;
    border: 1px solid #0698be;
    border-radius: 5px;
    // animation: fadeIn 0.5s;
    // -webkit-animation: fadeIn 0.5s;
    // -moz-animation: fadeIn 0.5s;
    // -o-animation: fadeIn 0.5s;
    // -ms-animation: fadeIn 0.5s;
    ul {
      padding: 0px;
      overflow: auto;
      max-height: 252px;
      height: auto;
      margin: 0px;
      li {
        padding: 10px 25px;
        font-size: 14px;
        color: #707683;
        text-align: left;
      }
      li:hover {
        border-left: 3px solid #0698be;
        background: #eff9f8;
        font-weight: 700;
      }
    }

    .default-select {
      color: #707683;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
    }

    .box-dropdown-footer {
      cursor: pointer;
      border-top: 1px solid #cedae4;
      font-size: 13px;
      color: #334d6e;
      padding: 12px 24px;
      .icon-create {
        width: 20px;
        margin-right: 5px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .arrow_select {
    width: 11px;
    margin-right: 15px;
    margin-top: 8px;
  }
  .iconDataType {
    width: 20px;
    margin-right: 10px;
  }

  .box-create-field {
    padding: 30px 15px;
    // height: 270px;
    display: grid;
    .box_field {
      color: #0698be !important;
      width: 268px;
    }
    .title-popup {
      font-family: inter;
      font-size: 13px;
      font-weight: bold;
    }
    .box-inner-footer {
      margin-top: 15px;
      float: right;
      display: flex;
      a {
        margin-left: 7px;
        width: 120px;
      }
    }
    .box_field {
      margin-bottom: 8px !important;
    }

    .input_field {
      width: 100%;
      align-items: center;
      padding: 0px 0px 0px 20px;
      border: 1px solid #dfe0eb;
      box-sizing: border-box;
      border-radius: 5px;
      height: 50px;
      margin-bottom: 10px;
      outline: none;
    }

    .-validated {
      border: 1px solid #ff2c65;
      color: #ff2c65 !important;
    }

    .box-inner-dropdown {
      width: 95%;
      height: auto;
      position: absolute;
      box-shadow: 0px 14px 24px 0px #3333331f;
      box-shadow: 0px 4px 4px 0px #3333330;
      z-index: 999;
      background-color: white;
      border: 1px solid #0698be;
      border-radius: 5px;

      ul {
        padding: 0px;
        overflow: auto;
        height: auto;
        margin: 0px;
        li {
          padding-left: 25px;
          font-size: 14px;
          color: #707683;
          text-align: left;
        }
        li:hover {
          border-left: 3px solid #0698be;
          background: #eff9f8;
          font-weight: 700;
        }
      }
    }
  }
  .section-msg {
    margin-top: -5px;
    text-align: left;
  }
`

const BasicDropdownBox = styled.div`
  .box_field {
    font-weight: 500;
    font-family: inter;
    cursor: pointer;
    width: 300px;
    margin: auto;
    background: white;
    height: 45px;
    border: 2px solid #24b0c3 !important;
    border-radius: 5px;
    margin-top: 10px;
    text-align: left;
    span {
      margin-top: 5px;
      margin-left: 30px;
      display: -webkit-inline-box;
      font-size: 14px !important;
      font-family: inter;
      font-weight: 700;
      color: #334d6e !important;
    }
    img {
      width: 26px !important;
      margin-right: 7px;
      float: right;
      margin-top: 10px !important;
    }
  }
  .box-dropdown {
    width: 300px;
    margin-left: 75px;
    position: absolute;
    z-index: 999;
    box-shadow: 0px 14px 24px 0px #3333331f;
    box-shadow: 0px 4px 4px 0px #3333330;
    background-color: white;
    border: 1px solid #e8e8e8 !important;
    border-radius: 5px;
    ul {
      padding: 0px;
      overflow: auto;
      max-height: 160px;
      margin: 0px;
      li {
        padding: 10px 25px;
        font-size: 14px;
        color: #707683;
        text-align: left;
      }
      li:hover {
        border-left: 3px solid #0698be;
        background: #eff9f8;
        font-weight: 700;
      }
    }
    .box-dropdown-footer {
      font-family: inter;
      cursor: pointer;
      border-top: 1px solid #cedae4;
      font-size: 14px;
      color: #334d6e;
      padding: 11px 24px;
      height: 49px;
      text-align: left;
      .icon-create {
        width: 20px;
        margin-right: 5px;
      }
      img {
        margin-top: 5px;
      }
    }
  }
`

const OptionDropdownBox = styled.div`
  .box_field {
    font-weight: 500;
    font-family: inter;
    cursor: pointer;
    margin: auto;
    background: unset;
    height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    span {
      margin-top: 10px;
      margin-left: 30px;
      display: -webkit-inline-box;
      font-size: 14px !important;
      font-family: inter;
      font-weight: 700;
      color: #334d6e !important;
    }
    img {
      width: 26px !important;
      margin-right: 7px;
      float: right;
      margin-top: 10px !important;
    }
  }

  .box-dropdown {
    position: absolute;
    z-index: 999;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin-left: -135px;
    margin-top: -25px;
    padding: 10px;
    width: 150px;
    height: auto;
    display: block;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        font-size: 14px;
        color: #344d6f;
        height: 34px;
        margin: 2px;
        text-align: left;
        img {
          width: 14px;
          margin-right: 6px;
        }
      }
      li:hover {
        border-radius: 8px;
        height: 34px;
        background: #f5f5fa;
      }
    }
    .-del {
      color: #ff2c65;
    }
  }
`
const FullOptionDropdownBox = styled.div`
  .box_field {
    font-weight: 500;
    font-family: inter;
    cursor: pointer;
    margin: auto;
    background: unset;
    height: 45px;
    border-radius: 5px;
    margin-top: 20px;
    span {
      margin-top: 10px;
      margin-left: 30px;
      display: -webkit-inline-box;
      font-size: 14px !important;
      font-family: inter;
      font-weight: 700;
      color: #334d6e !important;
    }
    img {
      width: 26px !important;
      margin-right: 7px;
      float: right;
      margin-top: 10px !important;
    }
  }

  .box-dropdown {
    position: absolute;
    z-index: 999;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #c2cfe0;
    background-color: #fff;
    margin-left: -150px;
    margin-top: -25px;
    padding: 10px;
    width: auto;
    height: auto;
    display: block;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        font-size: 14px;
        color: #344d6f;
        height: 34px;
        margin: 2px;
        text-align: left;
        img {
          width: 14px;
          margin-right: 6px;
        }
      }
      li:hover {
        border-radius: 8px;
        height: 34px;
        background: #f5f5fa;
      }
    }
    .-del {
      color: #ff2c65;
    }
  }
`
export { DropdownBox, BasicDropdownBox, OptionDropdownBox, FullOptionDropdownBox }
