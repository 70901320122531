import styled from 'styled-components'

const AudienceTableBox = styled.div`
  .main {
    padding-top: 10px 0px !important;
    color: #6a707e;
    width: 100% !important;
    transition: width 0.5s;
  }
  .container {
    margin: unset;
    padding: 0px;
    width: 100% !important;
    max-width: 100% !important;
    #search-field {
      border-radius: 5px;
      border: 1px solid #cfdce5;
      margin-left: 10px;
    }
    #page-menu {
      border-radius: 5px;
      border-color: #cfdce5;
      margin-left: 10px;
    }
  }

  .data-table-extensions {
    padding: 0.7rem 0px;
  }
  .data-table-extensions-filter {
    background: #fff;
    padding: 6px;
    width: 270px;
    label {
      margin: 1px 0px 0px 19px;
    }
  }
  .filter-text {
    width: 200px;
    font-size: 13px;
    border-bottom: none !important;
  }
  .filter-text:hover {
    border-bottom-color: #fff !important;
  }
  .data-table-extensions > .data-table-extensions-filter > .icon {
    background-image: url(/assets/icons/icon-search.png);
    background-size: 19px;
  }
  .rdt_Table {
    border: 1px solid #dfe0eb;
  }
  .rdt_TableCol {
    background: #f2f5f9;
  }
  .rdt_TableRow:hover {
    background: rgba(218, 245, 247, 0.56);
    outline-width: 0px;
  }
  #pagination-last-page,
  #pagination-first-page {
    display: none;
  }

  .rdt_TableCol_Sortable {
    div {
      font-weight: bold;
    }
  }

  header {
    justify-content: unset !important;
    background: unset;
    padding: 40px 0px 35px 0px;
  }
  .rdt_TableHeader {
    background: unset;
    padding-left: 0px;
  }

  .box-data-table {
    background-color: #fff;
    border-radius: 5px;
  }

  .section-filter {
    cursor: pointer;
    margin-left: 15px;
    color: rgba(51, 77, 110, 0.7) !important;
    span {
      color: #334d6e;
      font-size: 16px;
      font-weight: 200;
    }
    a {
      font-size: 13px;
    }
  }

  table {
    // box-shadow: 4px -2px 6px 4px #f2f2f4;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0px;
    border-radius: 15px;
    width: 100%;
    // margin-top: 25px;
    text-align: center;
    border: solid 1px #ebebeb;
    thead {
      background-color: #edf1f4;
      border: 0;
      height: 50px;
      th {
        font-size: 13px !important;
        font-weight: normal !important;
      }
      tr {
        height: 50px !important;
        background-color: #edf1f4 !important;
      }
    }
    th {
      color: #404040;
      font-weight: bold;
      padding: 10px 0px 10px 25px !important;
    }
    th:first-child {
      // border-top-left-radius: 15px;
      display: table-cell;
    }
    th:last-child {
      // border-top-right-radius: 15px;
      display: table-cell;
    }
    tr {
      border: 1px solid #ebebeb;
    }
    tbody {
      tr {
        height: 50px !important;
      }
      td {
        font-size: 13px !important;
        padding: 10px 0px 10px 25px !important;
      }
      tr:hover {
        cursor: pointer;
        background: #efeff7;
      }
    }

    td {
      padding: 18px 35px;
      color: #848484;
      border-top: 1px solid #ebebeb;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .-left {
    text-align: left;
    padding: 18px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 280px;
  }
  .box-top {
    display: flex;
    margin-top: 30px;
  }

  .box-search {
    background-color: #fff;
    height: 50px;
    border-radius: 8px;
    width: 315px;
    border: solid 1px #ebebeb;
    #search {
      height: 40px;
      width: 255px;
      border-radius: unset;
      border: none;
      outline: none;
      margin-top: 5px;
    }
    img {
      width: 24px;
      margin: -5px 10px 0px 20px;
    }
  }

  .box-filter {
    p {
      padding: 13px 30px;
      color: #404040;
    }
  }

  #gototop {
    position: fixed;
    cursor: pointer;
    bottom: 10px;
    left: 95%;
    max-width: 46px;
    width: 100%;
    padding: 0.5px;
    border-radius: 4px;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export { AudienceTableBox }
