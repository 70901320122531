import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import { useHistory, useLocation } from 'react-router-dom'
import { FullOptionDropdownBox } from '../dropdown/style'
import useOutsideClick from './useOutsideClick'
import {
  editCampaign,
  deleteCampaign,
  campaignPublish,
  duplicateCampaign,
  pauseCampaign
} from '../../apis/dataActivation/api'

const DropdownOptions = props => {
  const ref = useRef()
  const history = useHistory()
  let location = useLocation()
  const [openPopup, setOpenPopup] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [openPopupPublishFailed, setOpenPublishFailed] = useState(false)
  const [openPopupTimeError, setOpenTimeError] = useState(false)
  const [timeDate, setTimeDate] = useState(false)

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      border: '2px solid #0698BE',
      borderRadius: '10px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  useOutsideClick(ref, () => {
    setOpenOptions(false)
  })

  const ConvertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
    return day + ' ' + month + ' ' + year + ', ' + time
  }

  const deleteCampaignFn = async () => {
    const { detail } = props
    let { data, status } = await deleteCampaign(detail.ref_id)
    if (status && status === 200) {
      window.location.reload()
    } else {
      console.log('deleteCampaign')
    }
  }

  const duplicateCampaignFn = async () => {
    const { detail } = props
    let obj = {
      ref_id: detail.ref_id
    }

    const { data, status } = await duplicateCampaign(obj)
    if ((data, status === 200)) {
      window.location.reload()
    } else {
      console.log('duplicateCampaign Error')
    }
  }
  const stopCampaign = async () => {
    const { detail } = props
    let obj = {
      ref_id: detail.ref_id
    }
    let { data, status } = await pauseCampaign(obj)
    if (data && status === 200) {
      window.location.reload()
    } else {
      console.log('stopCampaign')
    }
  }

  const saveCampaign = async () => {
    const { detail } = props
    if (
      detail &&
      detail.schedule_detail &&
      detail.schedule_detail.schedule_start_date &&
      campaignTimeOut(detail.schedule_detail.schedule_start_date)
    ) {
      // console.log('case timeout')
      setOpenPopupConfirm(false)
      setOpenTimeError(true)
    } else {
      const { data, status } = await campaignPublish(detail.ref_id)
      if (data && status === 200) {
        // console.log('case happy')
        window.location.reload()
      } else {
        // console.log('case in time un complete')
        setOpenPopupConfirm(false)
        setOpenPublishFailed(true)
      }
    }
  }

  const publishNow = async () => {
    const { detail } = props
    const { data, status } = await campaignPublish(detail.ref_id)
    if (data && status === 200) {
      window.location.reload()
    } else {
      setOpenTimeError(false)
      setOpenPublishFailed(true)
    }
  }

  const campaignTimeOut = date => {
    let res = false
    if (ConvertDate(date) < ConvertDate(new Date())) {
      res = true
    }
    // console.log('campaignTimeOut : ', res)
    return res
  }

  return (
    <FullOptionDropdownBox>
      <div className={`box_field `} ref={ref}>
        <div className={`icon-option`} onClick={() => setOpenOptions(!openOptions)} />
      </div>
      {openOptions && (
        <div className="box-dropdown">
          <ul>
            {props.status === 'upcoming' ? (
              <li className=" border-bottom" onClick={() => stopCampaign()}>
                <img src="/assets/icons/paused-d.png" />
                Pause Campaign
              </li>
            ) : props.status === 'save_draft' || props.status === 'cancel' ? (
              <li className=" border-bottom" onClick={() => setOpenPopupConfirm(true)}>
                <img src="/assets/icons/check-small.png" /> Publish Campaign
              </li>
            ) : (
              ''
            )}

            <li onClick={() => history.push(`/campaign/edit/${props.id}`)}>
              <img src="/assets/icons/edit_trans.png" /> Edit
            </li>
            <li onClick={() => history.push(`/campaign/report/${props.id}`)}>
              <img src="/assets/icons/report.png" /> View Report
            </li>
            <li onClick={() => duplicateCampaignFn()}>
              <img src="/assets/icons/dup.png" /> Duplicate
            </li>
            <li className="-del" onClick={() => setOpenPopup(true)}>
              <img src="/assets/icons/bin-red.png" /> Delete
            </li>
          </ul>
        </div>
      )}

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupConfirm}
        onRequestClose={() => setOpenPopupConfirm(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/Paper_Plane.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Confirm to Publish Now?</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              This campaign is set to send on{' '}
              <span className="font-darkblue font-weight-bold">
                {' '}
                {props.detail.schedule_detail.schedule_type !== 'send_later'
                  ? '"Send Now"'
                  : ConvertDate(props.detail.schedule_detail.schedule_start_date)}
              </span>
              <br></br>
              Do you confirm to publish this campaign?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPopupConfirm(false)}
                className="btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              >
                Cancel
              </a>
              <a onClick={() => saveCampaign()} className="btn-basic bg-darkgreen font-white">
                Confirm
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/bin.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Delete Campaign?</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              This campaign will be deleted from the list. Do you confirm to delete this campaign?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPopup(false)}
                className=" btn-basic bg-whiter border-darkpink font-darkpink mx-2"
              >
                Cancel
              </a>
              <a onClick={() => deleteCampaignFn()} className=" btn-basic  bg-darkpink font-white">
                Yes, remove
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupPublishFailed}
        onRequestClose={() => setOpenPublishFailed(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/ErrorWarning.png" className="w-px-65" />
            <p className="f-20 font-w-600 font-darkblue my-4">Sorry</p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              Your campaign information is not ready. Please fill-in required information in order
              to publish the campaign
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => setOpenPublishFailed(false)}
                className=" btn-basic bg-whiter border-darkblue font-darkblue  mx-2"
              >
                Cancel
              </a>
              <a
                onClick={() => history.push(`/campaign/edit/${props.id}`)}
                className="btn-basic bg-darkblue font-white"
              >
                Edit Now
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupTimeError}
        onRequestClose={() => setOpenTimeError(false)}
        style={customStyles}
      >
              <div className="close px-2" onClick={() => setOpenTimeError(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        <div className="body-popup">
          <div className="text-center">
            <img src="/assets/icons/TimeWatch.png" className="w-px-65" />
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              Campaign delivery time need to be between <br></br>
              <b className="font-darkblue"> 30 mins and 30 days later </b> from when you create
              them.
            </p>
            <p className="f-12 font-weight-normal font-gray my-4 px-5">
              Do you want to edit your new delivery time?
            </p>
            <div className="footer-popup text-center my-4">
              <a
                onClick={() => publishNow()}
                className=" btn-basic bg-whiter border-darkgreen font-darkgreen  mx-2"
              >
                Publish Now
              </a>
              <a
                onClick={() => history.push(`/campaign/edit/${props.id}`)}
                className="btn-basic bg-darkgreen font-white"
              >
                Edit Now
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </FullOptionDropdownBox>
  )
}

export default DropdownOptions
