import React, { Fragment, useState, useEffect } from 'react'
import { MemberBox } from './style'
import { useForm } from 'react-hook-form'
import { inviteMember, getDataUser, editMember, deleteMember } from '../../apis/member/api'
import { useHistory } from 'react-router-dom'
import { verifyToken } from '../../apis/login/api'
import PopupComponent from '../popup/popup'

import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'



const Tab = styled.button`
  font-size: 16px;
  padding: 10px 27px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #24b0c3;
    opacity: 1;
    color: #24b0c3;
    font-weight: 600;
  `}
`

const ButtonGroup = styled.div`
  display: flex;
`

const types = ['Account', 'Users', 'SMS', 'Billing', 'Audit Log']
const MemberComponent = props => {
  const { id: userEmail } = useParams()
  const history = useHistory()
  const [messageError, setMessageError] = useState('Something went wrong, please try again later.')
  const [apiError, setNotiApiError] = useState(false)
  const [isLoadding, setLoading] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [role, setRole] = useState('admin')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      border: '2px solid #0698BE',
      borderRadius: '10px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm()

  useEffect(async () => {
    let checkedRole = await verifyToken()
    if (checkedRole.role !== 'admin') {
      history.push('/dashboard')
    }
    const initUserData = async () => {
      setLoading(true)
      const { data } = await getDataUser(props.email)
      setValue('email', data?.email, { shouldValidate: false })
      setValue('firstname', data?.firstname, { shouldValidate: false })
      setValue('lastname', data?.lastname, { shouldValidate: false })
      setValue('role', data?.role, { shouldValidate: false })
      setRole(data?.role)
      setLoading(false)
    }
    if (!props.isCreate) {
      initUserData()
    }
  }, [])

  const removeMember = async email => {
    setLoading(true)
    const { status } = await deleteMember(props.email)
    if (status === 200) {
      setLoading(false)
      history.push('/setting/generals')
    } else {
      setLoading(false)
      setOpenPopup(false)
      setMessageError('Something went wrong, please try again later.')
      setNotiApiError(true)
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTimeout(() => {
        setNotiApiError(false)
      }, 10000)
    }
  }

  const onSubmit = async obj => {
    setLoading(true)
    try {
      const { status } = props.isCreate ? await inviteMember(obj) : await editMember(obj)
      if (status === 200) {
        setLoading(false)
        props.setShowInviteFn(false)
        // history.push('/setting/generals')
      } else if (status === 400) {
        setLoading(false)
        if (props.isCreate) {
          setMessageError('This Email is exist.')
        } else {
          setMessageError('Something went wrong, please try again later.')
        }
        setNotiApiError(true)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
          setNotiApiError(false)
        }, 10000)
      }
    } catch (error) {
      console.log('connectLogin error : ', error)
      setLoading(false)
    }
  }

  return (
    <MemberBox isShowMenu={props ? props.isShowMenu : true}>
      {apiError && <PopupComponent option={{ status: 'error', msg: messageError }} />}

      <div className="card">
        {isLoadding && (
          <div className="text-center main-loadding">
            <div className="overlay-loadding">
              <img className="icon-loadding" src="/assets/images/flipball.gif" />
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card-header bg-white py-2 px-0">
            <div className="row">
              <div className="col-4 py-1">
                <p className=" title py-1 m-0 px-3">
                  {props.isCreate ? 'Invite new member' : 'Edit member'}
                </p>
              </div>
              <div className="col-8 py-1">
                <div className=" text-right">
                  {!props.isCreate && (
                    <a className="btn-basic bg-whiter border-darkpink font-darkpink mx-1" type="button" onClick={() => setOpenPopup(true)}>
                      Remove
                    </a>
                  )}

                  <a className="btn-basic bg-whiter border-darkgreen font-darkgreen  mx-1" onClick={() => props.setShowInviteFn(false)}>
                    {/* history.push('/setting/generals') */}
                    Cancel
                  </a>
                  <a className="btn-basic bg-darkgreen font-white mx-1" type="submit">
                    {props.isCreate ? 'Send Invite' : 'Save'}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="p-3 -fadeIn row">
              <div className="col-auto">
                <span className="input_label">First name</span>
                <input
                  className={`input ${errors.firstname ? 'input_err' : ''}`}
                  {...register('firstname', { required: 'First name is required' })}
                />
                <label className="label_err">{errors.firstname?.message}</label>
              </div>
              <div className="col-5">
                <span className="input_label ">Last name</span>
                <input
                  className={`input ml-2 ${errors.lastname ? 'input_err' : ''}`}
                  {...register('lastname', { required: 'Last name is required' })}
                />
                <label className="label_err">{errors.lastname?.message}</label>
              </div>
              <div className="col-5">
                <span className="input_label">Email</span>
                <input
                  disabled={props.isCreate ? false : true}
                  className={`input ${!props.isCreate ? 'input_disable' : ''} ${
                    errors.email ? 'input_err' : ''
                  }`}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter a valid email address '
                    }
                  })}
                />
                <label className="label_err">{errors.email?.message}</label>
              </div>
              <div className="col-12">
                <hr />
                <span className="input_label">Choose Role</span>
                <div className="row-option">
                  <label className={`container ${role === 'admin' ? '-radio-active' : ''}`}>
                    <input
                      {...register('role')}
                      id="preview1"
                      type="radio"
                      name="role"
                      value="admin"
                      checked={role === 'admin' ? true : false}
                      onClick={() => setRole('admin')}
                    />

                    <span className="checkmark"></span>
                    <div className="box-role">
                      <p>Administrator</p>
                      <span>Full access for creating, editing, saving and team management</span>
                    </div>
                  </label>
                  <label className={`container ${role === 'editor' ? '-radio-active' : ''}`}>
                    <input
                      {...register('role')}
                      id="preview2"
                      type="radio"
                      value="editor"
                      name="role"
                      checked={role === 'editor' ? true : false}
                      onClick={() => setRole('editor')}
                    />
                    <span className="checkmark"></span>
                    <div className="box-role">
                      <p>Editor</p>
                      <span>Full access for creating, editing, saving and exporting of data</span>
                    </div>
                  </label>
                  <label className={`container ${role === 'viewer' ? '-radio-active' : ''}`}>
                    <input
                      {...register('role')}
                      id="preview3"
                      type="radio"
                      value="viewer"
                      name="role"
                      checked={role === 'viewer' ? true : false}
                      onClick={() => setRole('viewer')}
                    />
                    <span className="checkmark"></span>
                    <div className="box-role">
                      <p>Viewer</p>
                      <span>Read-only access. No editing, saving, exporting of data</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <b className="title-popup font-darkblue">Remove member ?</b>
          <p className="desc-popup">
            {getValues('email')} will lose all access to this workspace Are you sure you want to
            remove this user?
          </p>
          <div className="footer-popup">
            <a onClick={() => setOpenPopup(false)} className="btn-basic bg-whiter border-darkpink font-darkpink mx-2">
              Cancel
            </a>
            <a className="btn-basic  bg-darkpink font-white" onClick={removeMember}>
              Yes, remove
            </a>
          </div>
        </div>
      </Modal>
    </MemberBox>
  )
}

export default MemberComponent
