import { Fragment, PureComponent } from 'react'
import { ReviewBox } from './style'
import { uploadFileStatus } from '../../apis/filestatus/api'
import { withRouter } from 'react-router'

class ReviewComponent extends PureComponent {
  state = {
    uploadding: false,
    uploadFail: false
  }
  uploadStatus = async () => {
    const { history } = this.props
    let res = {}

    this.setState({ uploadFail: false })
    try {
      let obj = {
        ref_id: this.props.refID
      }
      this.setState({ uploadding: true })
      window.scrollTo({
        top: 200,
        behavior: 'smooth'
      })
      res = await uploadFileStatus(obj)
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({ uploadding: false, uploadFail: true })
          history.push('/imports')
        }, 5000)
      } else {
        setTimeout(() => {
          this.setState({ uploadFail: true, uploadding: false })
        }, 5000)
      }
    } catch (error) {
      this.setState({ uploadFail: true, uploadding: false })
    }
  }

  render() {
    const { reviewDetail, groupName, dataType, fileInfo, nextStepFn, isShowMenu } = this.props
    const { uploadding, uploadFail } = this.state

    return (
      <ReviewBox isShowMenu={isShowMenu}>
        <div className="section-main">
          <p className="title-review ">Review Data</p>

          <div className="row justify-content-md-center px-3">
            <div className="col col-4">
              {' '}
              <div className="card datatype ml-auto mr-0">
                <div className="card-body">
                  <table>
                    <tr>
                      <td className="vertical-top"> 
                        <img
                          className="icon -fix-icon mr-3"
                          // src={`/assets/icons/${dataType}-circle.png`}
                          src="/assets/icons/user.png"
                          alt="predictive"
                        />
                      </td>
                      <td>
                        <p className="text-left head-map">Data Type</p>
                        <p className="-desc">
                          Data Type : <span className="-upper">{dataType}</span>
                        </p>
                        <p className="-desc">
                          {dataType === 'customer'
                            ? 'Customer Group'
                            : dataType === 'event'
                            ? 'Event Name'
                            : 'Product'}{' '}
                          : <span>{groupName}</span>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="card-footer bg-transparent p-0 border-0 text-left">
                  <div className="box-status">
                    <img
                      className="correct-icon"
                      src="/assets/icons/correctValidation.png"
                      alt="predictive"
                    />
                    <span> Data Type Selected</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-4">
              {' '}
              <div className="card datatype mx-auto">
                <div className="card-body">
                  <table>
                    <tr>
                      <td className="vertical-top">
                        <img
                          className="icon -fix-icon mr-3"
                          src="/assets/icons/upload-file.png"
                          alt="predictive"
                        />
                      </td>
                      <td>
                        <p className="text-left head-map">File Upload</p>
                        <p className="-desc">
                          File name : <span>{fileInfo.file.name}</span>
                        </p>
                        <p className="-desc">
                          File size :{' '}
                          <span>
                            {fileInfo.file.size} {fileInfo.file.size >= 1000 ? 'mb' : 'byte'}
                          </span>
                        </p>
                        <p className="-desc">
                          Columns : <span>{fileInfo.columns}</span>
                        </p>
                        <p className="-desc">
                          Rows : <span>{fileInfo.rows}</span>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="card-footer bg-transparent p-0 border-0 text-left">
                  <div className="box-status">
                    <img
                      className="correct-icon"
                      src="/assets/icons/correctValidation.png"
                      alt="predictive"
                    />
                    <span> Data Connected</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-4">
              <div className="card datatype mr-auto">
                <div className="card-body">
                  <table>
                    <tr>
                      <td className="vertical-top">
                        <img
                          className="icon -fix-icon mr-3"
                          src="/assets/icons/db.png"
                          alt="predictive"
                        />
                      </td>
                      <td>
                        <p className="text-left head-map">Data Mapping</p>
                        <p className="-desc">
                          Column Mapped : <span>{Object.keys(reviewDetail.data.rules).length}</span>
                        </p>
                        <p className="-desc">
                          Number of valid rows : <span>{reviewDetail.data.success}</span>
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="card-footer bg-transparent p-0 border-0 text-left">
                  <div className="box-status">
                    <img
                      className="correct-icon"
                      src="/assets/icons/correctValidation.png"
                      alt="predictive"
                    />
                    <span> Data Mapped</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {uploadding ? (
            <div className="loadding">
              <img src="/assets/images/loadding.gif" alt="predictive" />
              <p className="-blue">Your import has started.</p>
              <p className="-gray-light">
                It will take a few minutes to complete this import. <br />
                You can view the results in import history.
              </p>
            </div>
          ) : (
            <Fragment />
          )}

          {uploadFail ? (
            <div className="loadding-fail">
              <img src="/assets/images/file.png" alt="predictive" />
              <p className="-red">Upload Failed</p>
              <p className="-gray-light">
                Something went wrong. <br />
                Please try again in a few seconds.
              </p>

              <a className={`btn-next`} onClick={() => this.uploadStatus()}>
                <span>Try again</span>
              </a>
            </div>
          ) : (
            <Fragment />
          )}
        </div>

        <div className="col-12 text-right section-footer">
          <div className="col-6 align-left">
            <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => nextStepFn('mapping')}>
              Previous
            </a>
          </div>
          <div className="col-6 align-right">
            <a
              className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              onClick={() => (window.location.href = '/imports')}
            >
              Cancel
            </a>
            <a className={`btn-basic bg-darkgreen font-white`} onClick={() => this.uploadStatus()}>
              <span>Save</span>
            </a>
          </div>
        </div>
      </ReviewBox>
    )
  }
}

export default withRouter(ReviewComponent)
