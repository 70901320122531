import React, { Fragment } from 'react'
import styled from 'styled-components'

const ClearButton = styled.a`
  cursor: pointer;
  margin-left: 15px;
  color: rgba(51, 77, 110, 0.7) !important;
  span {
    color: #334d6e;
    font-size: 16px;
    font-weight: 200;
  }
  a {
    font-size: 13px;
  }
`

const BoxInput = styled.div`
  background-color: #fff;
  height: 50px;
  #search {
    height: 40px;
    width: 200px;
    border-radius: unset;
    border: none;
    outline: none;
    margin-top: 5px;
  }
  img {
    width: 24px;
    margin: 0px 10px 0px 20px;
  }
  .btn-clear {
    width: 25px;
    height: 25px;
    background-color: #90a0b7;
    display: -webkit-inline-box;
    border-radius: 15px;
    span {
      margin-left: 8px;
      color: #fff;
    }
  }
`

const FilterComponent = ({ filterText, onFilter, onClear, searchTotal }) => (
  <Fragment>
    <BoxInput>
      <img src="/assets/icons/icon-search.png" alt="predictive" />
      <input id="search" type="text" placeholder="Search name, id,  …" value={filterText} onChange={onFilter} />
      <img onClick={onClear} src="/assets/icons/icon-close.png" alt="predictive" />
    </BoxInput>
    <ClearButton>
      <span>{searchTotal + ` MATCHING CUSTOMERS  `}</span>| <a onClick={onClear}>Clear all</a>
    </ClearButton>

    {/* <div className="-right">
      <a className="btn-blue" href="/import">
        + Create New
      </a>
    </div> */}
  </Fragment>
)

export default FilterComponent
