import * as axios from 'axios'
import { isEmpty, redirectLogin } from '../../helper/helper'
const { REACT_APP_API_UPLOAD_SERVICE } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const uploadFileStatus = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/file-status/update`
    const response = await axios.post(
      URL,
      {
        ref_id: params.ref_id,
        status: 'inprogress'
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const uploadFileList = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/file-status/list`
    const response = await axios.post(
      URL,
      {
        pagination: {
          pageSize: isEmpty(params) ? 25 : params.pageSize,
          lastField: isEmpty(params) ? '' : params.lastField
        }
      },
      config
    )
    if (!isEmpty(response) && !isEmpty(response.status) && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const connectBigquery = async project_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/bigquery/auth-project`
    const response = await axios.post(
      URL,
      {
        project_id: project_id
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.response.data
      }
    }
  }
  return res
}

const tableListBigQuery = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/bigquery/get-tableslist/${params.project_id}/${params.dataset_id}`
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const sampleDataBigQuery = async params => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/bigquery/get-datas`
    const response = await axios.post(
      URL,
      {
        project_id: params.project_id,
        dataset_id: params.dataset_id,
        table_id: params.table_id,
        limit_row: 10
      },
      config
    )
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createTaskBigQuery = async (obj, data_type) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/task-bigquery-customer-${
      data_type === 'customer' ? 'profile' : 'profile'
    }/`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const deleteTaskBigQuery = async ref_id => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/schedule/${ref_id}`
    const response = await axios.delete(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    console.log('deleteTaskBigQuery : ', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const editTaskBigQuery = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/schedule/`
    const response = await axios.patch(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const createTaskGoogleCloud = async (obj, data_type) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_UPLOAD_SERVICE}/upload/task-gcs-customer-${
      data_type === 'customer' ? 'profile' : 'profile'
    }/`
    const response = await axios.post(URL, obj, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

export {
  uploadFileStatus,
  uploadFileList,
  connectBigquery,
  tableListBigQuery,
  sampleDataBigQuery,
  createTaskBigQuery,
  deleteTaskBigQuery,
  editTaskBigQuery,
  createTaskGoogleCloud
}
