import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { OptionDropdownBox } from './style'
import { withRouter } from 'react-router'

class OptionDropdownComponent extends PureComponent {
  state = {
    open: false,
    selectedValue: ''
  }

  renderDropdown = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  setValueSelect = data => {
    const { setGroupFn } = this.props
    this.setState({ selectedValue: data, open: false })
    setGroupFn(data)
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode.contains(event.target)) {
      this.setState({
        open: false
      })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  controlPopup = () => {
    const { openModalFn } = this.props
    this.setState({ open: false })
    openModalFn()
  }

  render() {
    const { id, segmentOptionFn, history } = this.props
    const { open } = this.state
    return (
      <OptionDropdownBox>
        <div className={`box_field `} onClick={() => this.renderDropdown()}>
          <div className={`icon-option ${open ? '-icon-active' : ''}`} />
        </div>
        {open && (
          <div className="box-dropdown">
            <ul>
              <li onClick={() => history.push(`/segmentation/${id}/edit`)}>
                <img src="/assets/icons/edit_trans.png" /> Edit
              </li>
              {/* <li> onClick={() => segmentOptionFn(id, 'update')}
                <img src="/assets/icons/group_doc.png" />
                Duplicate segment
              </li>
              <li>
                <img src="/assets/icons/export_trans.png" />
                Export
              </li> */}
              <li className="-del" onClick={() => segmentOptionFn(id, 'delete')}>
                <img src="/assets/icons/bin-red.png" /> Delete
              </li>
            </ul>
          </div>
        )}
      </OptionDropdownBox>
    )
  }
}
export default withRouter(OptionDropdownComponent)
