import React, { Fragment, PureComponent } from 'react'
import ReactDOM from 'react-dom'

import { isEmpty } from '../../helper/helper'
import { DropdownBox } from './style'

export default class DropdownV2Component extends PureComponent {
  state = {
    open: false,
    selectedValue: ''
  }

  renderDropdown = () => {
    const { open } = this.state

    this.setState({ open: !open })
  }

  setValueSelect = (no, data, dropdownType, setValueConditionFn) => {
    let obj = {
      select_field: data.field_name,
      value_type: data.data_type
    }
    localStorage.setItem('su', 'Y') //su = segment update
    setValueConditionFn(no, obj, dropdownType)
    this.setState({ selectedValue: data.field_name, open: false })
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode.contains(event.target)) {
      this.setState({
        open: false
      })
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  render() {
    const { open } = this.state
    const { fieldList, no, setValueConditionFn, dropdownType, isFlex } = this.props
    const showData = fieldList
    let isSelectValue = !isEmpty(this.props.selectedValue) ? this.props.selectedValue : ''
    if (dropdownType === 'operation') {
      let _warp = fieldList.filter(l => l.data_type === isSelectValue)
      isSelectValue = !isEmpty(_warp) && !isEmpty(_warp[0]) ? _warp[0].field_name : ''
    }
    return (
      <DropdownBox isFlex={isFlex}>
        <div
          className={`box_field ${
            dropdownType === 'operation' || dropdownType === 'eventCondition' ? '-center' : ''
          }`}
          onClick={() => this.renderDropdown()}
        >
          {/* <span>{isSelectValue}</span> */}
          {/* {!isEmpty(selectedValue) ? <span>{selectedValue}</span> : !isEmpty(operationList) ? <span className="-holder">Select condition</span> : <span className="-holder">Select an attribute</span>} */}
          {!isEmpty(isSelectValue) ? (
            <span
              className={
                dropdownType === 'operation' || dropdownType === 'eventCondition' ? '-bigfont' : ''
              }
            >
              {isSelectValue}
            </span>
          ) : // ) : !isEmpty(isSelectValue) ? (
          //   <span
          //     className={
          //       dropdownType === 'operation' || dropdownType === 'eventCondition' ? '-bigfont' : ''
          //     }
          //   >
          //     {isSelectValue}
          //   </span>
          dropdownType === 'group' ? (
            <Fragment>
              <span className="-holder">Select event type</span>
              <img className="arrow_select" alt="predictive" src="/assets/icons/down-arrow.png" />
            </Fragment>
          ) : dropdownType === 'field' ? (
            <Fragment>
              <span className="-holder">Select an attribute</span>
              <img className="arrow_select" alt="predictive" src="/assets/icons/down-arrow.png" />
            </Fragment>
          ) : (
            <Fragment>
              <span className="-holder">Select condition</span>{' '}
              <img className="arrow_select" alt="predictive" src="/assets/icons/down-arrow.png" />
            </Fragment>
          )}

          {!isEmpty(isSelectValue) ? (
            <img className="arrow_select" alt="predictive" src="/assets/icons/down-arrow.png" />
          ) : (
            <Fragment />
          )}
        </div>
        {open && (
          <div className="box-dropdown">
            <ul>
              {!isEmpty(showData) &&
                showData.map((item, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() =>
                        this.setValueSelect(no, item, dropdownType, setValueConditionFn)
                      }
                    >
                      {item.field_name}
                    </li>
                  )
                })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }
}
