import React, { Component, Fragment } from 'react'
import { CSVReader } from 'react-papaparse'
import { ImportTypeBox } from './style'
import { ProgressbarBox } from './style'
import TableComponent from './table'
import MappingComponent from './mapping'
import ReviewComponent from './review'
import LoadingOverlay from 'react-loading-overlay'
import Modal from 'react-modal'
import { isEmpty } from '../../helper/helper'
import BasicDropdownComponent from '../dropdown/basicDropdown'
import { getGroupList, creatGroup } from '../../apis/group/api'
import { getFieldsList } from '../../apis/mapping/api'
import { uploadRawData } from '../../apis/upload/api'
import { uploadFileList } from '../../apis/filestatus/api'
import SelectSourceComponent from './selectSource'
import ConnectBigQueryComponent from './bigquery/connectBigquery'
import ConnectGoogleStorageComponent from './googleStorage/connectGoogleStorage'
import DataMappingComponent from './dataMapping/mapping'
import { verifyToken } from '../../apis/login/api'

import { withRouter } from 'react-router'

class ImportComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataType: '',
      isLoadding: false,
      step: 'selectType',
      stepMap: false,
      resTable: {
        resColumns: [],
        resRows: [],
        resFileInfo: ''
      },
      openModel: false,
      groupName: '',
      groupIsPass: false,
      groupList: [],
      fieldlist: [],
      refID: '',
      fileInfo: {},
      historyList: [],
      historyStatus: true,
      uploadError: false,
      lastLoad: false
    }
  }

  nextStep = async stepOption => {
    const { history } = this.props
    const { dataType, groupName } = this.state
    this.setState({ uploadError: false })
    if (!isEmpty(dataType) || stepOption === 'selectType') {
      history.push('/imports/create?step=select-type')
      this.setState({ step: stepOption })
    }
    if (stepOption === 'groupping') {
      if (dataType === 'customer') {
        history.push('/imports/create?step=select-group')
      } else {
        history.push('/imports/create?step=select-event')
      }
      if (!isEmpty(groupName)) {
        this.clearGroupName()
      }
      this.setState({ isLoadding: true })
      let grouplist = await this.getGroupList()
      let fieldlist = await this.getFieldsList()
      if (!isEmpty(grouplist) && !isEmpty(grouplist.data) && grouplist.status === 200) {
        this.setState({ groupList: grouplist.data, fieldlist: fieldlist, isLoadding: false })
      } else {
        this.setState({ isLoadding: false })
      }
    } else if (stepOption === 'start') {
      this.setState({ step: stepOption, historyList: [] })
      await this.getFileList()
    } else if (stepOption === 'uploadfile' || stepOption === 'renderTable') {
      history.push('/imports/create?step=fileupload')
    } else if (stepOption === 'mapping') {
      history.push('/imports/create?step=mapping')
    } else if (stepOption === 'selectType') {
      this.setState({ dataType: '' })
      history.push('/imports/create?step=select-type')
    } else if (stepOption === 'selectSource') {
      history.push('/imports/create?step=sources')
    } else if (stepOption === 'bigquery') {
      history.push('/imports/create?step=connectBigquery')
    } else {
      // review
      history.push('/imports/create?step=review')
    }
  }
  setRole = async () => {
    const { history } = this.props
    let role = await verifyToken()
    if (role.role === 'viewer') {
      history.push('/imports')
    }
  }
  componentDidMount = async () => {
    await this.setRole()
  }

  clearGroupName = () => {
    this.setState({ groupName: '' })
  }

  selectType = type => {
    this.setState({ dataType: type })
    // if (type === 'customer' || type === 'event') {
    //   setTimeout(() => {
    //     this.nextStep('groupping')
    //   }, 100)
    // } else {
    //   this.nextStep('uploadfile')
    // }
  }

  uploadAndRender = stepOption => {
    this.setState({ step: stepOption })
  }

  renderTable = async (data, fileInfo) => {
    this.setState({ isLoadding: true })
    let rawData = data.map(item => item.data)
    let columns = []
    for (let i = 0; i < data[0].data.length; i++) {
      let title = !isEmpty(data[0].data[i]) ? data[0].data[i] : '-'
      columns.push({ title: title, value: data[0].data[i] })
    }
    let rows = []
    let rowsRawUpload = []
    let _tempRawUpload = []
    let _temp = []
    let limit = data.length //> 11 ? 11 : data.length
    for (let l = 1; l < limit; l++) {
      let filtered = data[l].data
      let _tempRows = []
      for (let x = 0; x < filtered.length; x++) {
        let title =
          !isEmpty(columns[x]) && !isEmpty(columns[x].title) ? columns[x].title : 'no data'
        _temp = { title: title, value: filtered[x] } //columns[x].title
        _tempRows.push(_temp)
      }
      /////// for upload file
      for (let x = 0; x < filtered.length; x++) {
        _tempRawUpload = { ..._tempRawUpload, [columns[x].title]: filtered[x] }
      }
      rowsRawUpload.push(_tempRawUpload)
      /////// for upload file
      rows.push(_tempRows)
    }

    let res = await this.postRawData(rowsRawUpload, fileInfo)
    if (res.status === 200) {
      this.setState({
        resTable: {
          resColumns: columns,
          resRows: rows,
          resFileInfo: fileInfo
        },
        rawData: rawData
      })

      setTimeout(
        function () {
          this.nextStep('renderTable')
          this.setState({ isLoadding: false })
        }.bind(this),
        3000
      )
    } else {
      /// more then 50MB
      this.setState({ isLoadding: false, uploadError: true })
    }
  }

  handleOnDrop = async (data, file) => {
    let fileSize = file.size / 1024 / 1024
    if (fileSize <= 10) {
      this.renderTable(data, file)
    } else {
      this.setState({ uploadError: true })
    }
  }

  postRawData = async (data, file) => {
    const { dataType } = this.state
    let wrapDataType =
      dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'catalog'
    const { groupName } = this.state
    let obj = {
      filename: file.name,
      group_name: groupName,
      data,
      wrapDataType
    }
    let fileInfo = {
      columns: Object.keys(data[0]).length,
      rows: data.length,
      file
    }

    let size = encodeURI(JSON.stringify(obj)).split(/%..|./).length - 1

    let kiloBytes = size / 1024
    let megaBytes = kiloBytes / 1024
    let res = {}
    let resUploadRawData = {}

    if (megaBytes < 50) {
      resUploadRawData = await uploadRawData(obj)
      if (!isEmpty(resUploadRawData) && resUploadRawData.status === 200) {
        this.setState({ refID: resUploadRawData.data.ref_id, fileInfo: fileInfo })
        res = {
          status: 200
        }
      }
    } else {
      /// more than 50MB
      res = {
        status: 500
      }
    }
    return res
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log('handleOnError : ', err)
  }

  openModal = () => {
    this.setState({ openModel: true, groupName: '' })
  }
  closeModal = () => {
    this.setState({ openModel: false, groupName: '', groupIsPass: false })
  }

  checkDupplicateGroup = nameGroup => {
    const { groupList } = this.state

    let res = !isEmpty(groupList.filter(l => l.name === nameGroup)) ? true : false
    return res
  }

  getGroupList = async () => {
    const { dataType } = this.state
    let pastType = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'
    let res = {}
    try {
      res = await getGroupList(pastType)
    } catch (error) {
      res = { error }
    }
    // res = {}
    return res
  }

  creatGroup = async groupname => {
    const { dataType } = this.state
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    let params = {
      groupname: groupname,
      option: paramApi
    }
    let res = {}
    try {
      res = await creatGroup(params)
    } catch (error) {
      res = { error }
    }
    return res
  }

  getFieldsList = async () => {
    const { dataType } = this.state
    let paramApi = dataType === 'customer' ? 'profile' : dataType === 'event' ? 'event' : 'product'

    let res = {}
    try {
      let response = await getFieldsList(paramApi)
      if (!isEmpty(response) && response.status === 200) {
        res = response.data
      }
    } catch (error) {
      res = error.message
    }
    return res
  }

  saveGroup = async () => {
    const { groupName } = this.state

    if (!isEmpty(groupName)) {
      let checkGroup = this.checkDupplicateGroup(groupName)
      if (!checkGroup) {
        this.setState({ isLoadding: true })
        let resCreatGroup = await this.creatGroup(groupName)
        if (
          !isEmpty(resCreatGroup) &&
          !isEmpty(resCreatGroup.status) &&
          resCreatGroup.status === 200
        ) {
          let grouplist = await this.getGroupList()

          if (grouplist) {
            setTimeout(
              function () {
                this.setState({
                  groupList: grouplist.data,
                  groupName: groupName,
                  groupIsPass: true,
                  isLoadding: false,
                  openModel: false
                })
              }.bind(this),
              2000
            )
          } else {
            this.setState({
              isLoadding: false
            })
          }
        } else {
          this.setState({ groupIsPass: false, isLoadding: false })
        }
      } else {
        this.setState({ groupIsPass: true })
      }
    } else {
      this.setState({ groupIsPass: true })
    }
  }

  setGroup = groupName => {
    this.setState({ groupName: groupName })
  }

  handleChange = ({ target }) => {
    this.setState({
      groupName: target.value
    })
  }

  downloadSample = () => {
    window.location.href = '/assets/sample_csv/sample_customer.csv'
  }

  setReviewDetail = res => {
    this.setState({ reviewDetail: res })
  }

  getFileList = async params => {
    const { historyList, isLoadmore } = this.state
    let res = {}
    try {
      res = await uploadFileList(params)
      if (res.status === 200) {
        if (!isEmpty(res.data)) {
          historyList.push(...res.data)
          this.setState({
            historyList: historyList,
            historyStatus: true,
            isLoadmore: false,
            lastLoad: false
          })
        } else {
          if (!isLoadmore) {
            this.setState({ historyStatus: true, isLoadmore: false, lastLoad: true })
          } else {
            this.setState({ historyStatus: false, isLoadmore: false })
          }
        }
      }
    } catch (error) {
      this.setState({ historyStatus: true, isLoadmore: false })
    }
  }

  convertTime = date => {
    let time = new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    return time
  }

  convertDate = date => {
    let day = new Date(date).getDate()
    let month = new Date(date).toLocaleString('default', { month: 'short' })
    let year = new Date(date).getFullYear()

    let res = day + ' ' + month + ' ' + year

    return res
  }

  componentWillMount() {
    const { history } = this.props
    if (history.location.search !== '?step=select-type') {
      history.push('/imports')
    }
    window.addEventListener('scroll', this.loadMore)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore)
  }

  loadMore = async () => {
    const { historyList, lastLoad } = this.state
    if (!lastLoad) {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.scrollingElement.scrollHeight
      ) {
        let params = {
          pageSize: 25,
          lastField: !isEmpty(historyList) ? historyList[historyList.length - 1].uploaded_at : ''
        }
        this.setState({ isLoadmore: false, historyStatus: true })

        setTimeout(async () => {
          await this.getFileList(params)
        }, 2000)
      }
    }
  }

  setStepMap = () => {
    this.setState({ stepMap: true })
  }

  render() {
    const { isShowMenu, history } = this.props
    const {
      historyList,
      isLoadmore,
      lastLoad,
      uploadError,
      historyStatus,
      step,
      refID,
      fileInfo,
      dataType,
      reviewDetail,
      resTable,
      isLoadding,
      rawData,
      openModel,
      groupIsPass,
      groupName,
      groupList,
      fieldlist
    } = this.state
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
        transition: 'width 0.5s',
        width: '470px',
        height: 'auto',
        border: '2px solid #0698BE',
        borderRadius: '10px'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
    return (
      <ImportTypeBox isShowMenu={isShowMenu}>
        <section className="main">
          {historyStatus ? (
            <div className="breadcrumb-box">
              <p onClick={() => history.push('/imports')} className="navigation-bar">
                Data management <span> / Imports / Create new</span>
              </p>
            </div>
          ) : (
            <Fragment />
          )}

          {step !== 'start' ? (
            <ProgressbarBox>
              <div className="section-progress">
                <ul className="progressbar">
                  <li
                    className={`list-type-box ${
                      step === 'selectType' || step === 'groupping' ? 'active' : 'step-pass'
                    }`}
                  >
                    <span>Select type</span>
                  </li>
                  <li
                    className={`list-type-box ${
                      step === 'uploadfile' ||
                      step === 'renderTable' ||
                      step === 'selectSource' ||
                      step === 'bigquery' ||
                      step === 'cloud'
                        ? !this.state.stepMap
                          ? 'active'
                          : 'step-pass'
                        : step === 'mapping' || step === 'review'
                        ? 'step-pass'
                        : ''
                    }`}
                  >
                    <span>Select source</span>
                  </li>
                  <li
                    className={`list-type-box ${
                      step === 'mapping' || this.state.stepMap
                        ? 'active'
                        : step === 'review'
                        ? 'step-pass'
                        : ''
                    }`}
                  >
                    <span>Mapping</span>
                  </li>
                  {(step === 'uploadfile' ||
                    step === 'renderTable' ||
                    step === 'mapping' ||
                    step === 'review') && (
                    <li className={`list-type-box ${step === 'review' ? 'active' : ''}`}>
                      <span>Review</span>
                    </li>
                  )}
                </ul>
              </div>
            </ProgressbarBox>
          ) : (
            <Fragment />
          )}
          {step === 'start' ? (
            <Fragment>
              {!historyStatus ? (
                <div className="section-main-landding">
                  <div className="col-6 -right">
                    <img src="/assets/images/landding2.png" alt="predictive" />
                  </div>
                  <div className="col-6">
                    <div className="center-verticle">
                      <p className="title">Start building</p>
                      <p className="title">your data</p>
                      <p className="desc">
                        Importing data by uploading <br /> a <b>CSV file</b> into the system.
                      </p>
                      <a className="btn-basic bg-darkgreen font-white" onClick={() => this.nextStep('selectType')}>
                        {`+ CREATE NEW`}
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
            </Fragment>
          ) : step === 'selectType' ? (
            <Fragment>
              <div className="section-main">
                <p className="title">What do you want to upload ?</p>
                <div className="section-body">
                  <div
                    className={`list-type-box ${
                      dataType === 'customer' ? 'list-type-box-active' : ''
                    }`}
                    onClick={() => this.selectType('customer')}
                  >
                    <div className="section-tooltip">
                      <div className="tooltip">
                        <img
                          className="icon-tooltip"
                          src="/assets/icons/tooltips.png"
                          alt="information"
                        />
                        <span className="tooltiptext">
                          <p>
                            <b>Customer</b>
                          </p>
                          Import your customer lists, such as customer_id, email, firstname,
                          lastname, gender,etc. This will help us to build them to an audience.
                        </span>
                      </div>
                    </div>

                    <div className="list-icon">
                      <img src="/assets/icons/people.png" alt="predictive" />
                    </div>
                    <p className="title-type">Customers</p>
                    <p className="desc-type">Import your customer data to our platform</p>
                  </div>
                  <div
                    className={`list-type-box ${
                      dataType === 'event' ? 'list-type-box-active' : ''
                    }`}
                    onClick={() => this.selectType('event')}
                  >
                    <div className="section-tooltip">
                      <div className="tooltip">
                        <img
                          className="icon-tooltip"
                          src="/assets/icons/tooltips.png"
                          alt="information"
                        />
                        <span className="tooltiptext">
                          <p>
                            <b>Event</b>
                          </p>
                          Import your events, such as Purchase, Add to cart, View item,
                          Search,Transaction, etc. This information will help us to bring you better
                          recommendations for your customer.
                        </span>
                      </div>
                    </div>

                    <div className="list-icon">
                      <img src="/assets/icons/event.png" alt="predictive" />
                    </div>
                    <p className="title-type">Events</p>
                    <p className="desc-type">Import your events or activities to our platform</p>
                  </div>
                  <div
                    className={`list-type-box ${
                      dataType === 'catelog' ? 'list-type-box-active' : ''
                    }`}
                    onClick={() => this.selectType('catelog')}
                  >
                    <div className="section-tooltip">
                      <div className="tooltip">
                        <img
                          className="icon-tooltip"
                          src="/assets/icons/tooltips.png"
                          alt="information"
                        />
                        <span className="tooltiptext">
                          <p>
                            <b>Catalog</b>
                          </p>
                          Import your events, such as Purchase, Add to cart, View item,
                          Search,Transaction, etc. This information will help us to bring you better
                          recommendations for your customer.
                        </span>
                      </div>
                    </div>
                    <div className="list-icon">
                      <img src="/assets/icons/document.png" alt="predictive" />
                    </div>
                    <p className="title-type">Catalog</p>
                    <p className="desc-type">
                      Import your product or content catalog to our platform{' '}
                    </p>
                  </div>
                </div>

                {/* <a className={`btn-blue ${!isEmpty(dataType) ? '' : '-active-btn'}`} onClick={() => (dataType === 'customer' || dataType === 'event' ? this.nextStep('groupping') : this.nextStep('uploadfile'))}>
              Continue
            </a> */}
              </div>
              <div className="section-footer">
                <div className="col-6 align-left"></div>
                <div className="col-6 align-right">
                  <a
                    className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
                    onClick={() => (window.location.href = '/imports')}
                  >
                    Cancel
                  </a>
                  <a
                    className={`btn-basic ${dataType ? 'bg-darkgreen font-white' : 'bg-gray font-white'}`}
                    onClick={() =>
                      dataType === 'customer' || dataType === 'event'
                        ? this.nextStep('groupping')
                        : this.nextStep('uploadfile')
                    }
                  >
                    Next
                  </a>
                </div>
              </div>
            </Fragment>
          ) : step === 'groupping' ? (
            <Fragment>
              <div className="section-main">
                <p className="title">What do you want to upload ?</p>
                <div className="section-body">
                  <div className="section-group">
                    <div className="list-icon">
                      <div className="list-center-box">
                        {dataType === 'customer' ? (
                          <img src="/assets/icons/people.png" alt="predictive" />
                        ) : (
                          <img src="/assets/icons/event.png" alt="predictive" />
                        )}

                        <p className="title-type">{dataType}</p>
                        <span className="mini">
                          Import your {dataType === 'customer' ? 'customer data' : 'event'} to our
                          platform
                        </span>
                        <br />
                        <LoadingOverlay active={isLoadding} spinner>
                          {!isEmpty(groupList) ? (
                            <BasicDropdownComponent
                              dataType={dataType}
                              groupName={groupName}
                              groupList={groupList}
                              setGroupFn={this.setGroup}
                              openModalFn={() => this.openModal()}
                            />
                          ) : (
                            <div className="btn-create-group" onClick={() => this.openModal()}>
                              <p>
                                <img
                                  className="icon-create"
                                  src="/assets/icons/create.png"
                                  alt="predictive"
                                />
                                Create {dataType === 'customer' ? 'Customer Group' : 'Event'}
                              </p>
                            </div>
                          )}
                        </LoadingOverlay>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <a className={`btn-gray`} onClick={() => this.nextStep('selectType')}>
                  Back
                </a>
                <a
                  className={`btn-blue ${!isEmpty(groupName) ? '' : '-active-btn'}`}
                  onClick={() =>
                    dataType === 'customer' && isEmpty(groupName)
                      ? this.nextStep('groupping')
                      : // : this.nextStep('uploadfile')
                        this.nextStep('selectSource')
                  }
                >
                  Continue
                </a> */}
                <Modal
                  closeTimeoutMS={500}
                  ariaHideApp={false}
                  isOpen={openModel}
                  onRequestClose={() => this.closeModal()}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <LoadingOverlay active={isLoadding} spinner>
                    <div onClick={() => this.closeModal()} className="close">
                      <img src="/assets/icons/close.png" alt="close" />
                    </div>
                    <div className="body-model">
                      <b>Create New {dataType === 'customer' ? 'Customer Group' : 'Event'} </b>
                      {dataType === 'customer' ? (
                        <Fragment>
                          <div className="tooltip -fix-right">
                            <img
                              className="icon-tooltip"
                              src="/assets/icons/tooltips.png"
                              alt="information"
                            />
                            <span className="tooltiptext">
                              This form lets you create customer group. By using customer groups,
                              you can easily identify specified groups of customer such as online,
                              offline, web, app, etc.{' '}
                            </span>
                          </div>
                          <div className="section-tooltip"></div>
                        </Fragment>
                      ) : (
                        <Fragment />
                      )}

                      <input
                        name="groupName"
                        onFocus={e => (e.target.placeholder = '')}
                        onBlur={e =>
                          (e.target.placeholder = `Enter your ${
                            dataType === 'customer'
                              ? 'group'
                              : dataType === 'event'
                              ? 'event'
                              : 'product'
                          } name...`)
                        }
                        onChange={this.handleChange}
                        value={this.state.groupName}
                        className="input_modal"
                        type="text"
                        placeholder={`Enter your ${
                          dataType === 'customer'
                            ? 'group'
                            : dataType === 'event'
                            ? 'event'
                            : 'product'
                        } name...`}
                      />
                      {/* <img onClick={() => this.clearGroupName()} className="circle-close" src="/assets/icons/circleClose.png" alt="close" /> */}
                      {groupIsPass && (
                        <p className="-red">
                          This name is already exist. Please choose another name
                        </p>
                      )}
                      <br />
                      <a className={`btn-basic bg-gray font-white mx-2`} onClick={() => this.closeModal()}>
                        Cancel
                      </a>
                      <a
                        className={`btn-basic ${
                          !isEmpty(groupName) || !groupIsPass ? 'bg-darkgreen font-white' : 'bg-gray font-white'
                        }`}
                        onClick={this.saveGroup}
                      >
                        Create
                      </a>
                    </div>
                  </LoadingOverlay>
                </Modal>
              </div>
              <div className="section-footer">
                <div className="col-6 align-left">
                  <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => this.nextStep('selectType')}>
                    Previous
                  </a>
                </div>
                <div className="col-6 align-right">
                  <a
                    className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
                    onClick={() => (window.location.href = '/imports')}
                  >
                    Cancel
                  </a>
                  <a
                    className={`btn-basic ${groupName ? 'bg-darkgreen font-white' : 'bg-gray font-white'}`}
                    onClick={() =>
                      groupName
                        ? dataType === 'customer' && isEmpty(groupName)
                          ? this.nextStep('groupping')
                          : this.nextStep('selectSource')
                        : ''
                    }
                  >
                    Next
                  </a>
                </div>
              </div>
            </Fragment>
          ) : step === 'selectSource' ? (
            <SelectSourceComponent nextStepFn={this.nextStep} />
          ) : step === 'bigquery' ? (
            <Fragment>
              <ConnectBigQueryComponent
                dataType={dataType}
                setReviewDetailFn={this.setReviewDetail}
                refID={refID}
                groupName={groupName}
                masterField={fieldlist}
                nextStepFn={this.nextStep}
                resTable={resTable}
                rawData={rawData}
                setStepMapFn={this.setStepMap}
              />
            </Fragment>
          ) : step === 'cloud' ? (
            <Fragment>
              <ConnectGoogleStorageComponent
                dataType={dataType}
                setReviewDetailFn={this.setReviewDetail}
                refID={refID}
                groupName={groupName}
                masterField={fieldlist}
                nextStepFn={this.nextStep}
                resTable={resTable}
                rawData={rawData}
                setStepMapFn={this.setStepMap}
              />
            </Fragment>
          ) : step === 'uploadfile' ? (
            <div className="section-main -upload">
              <p className="title">
                Upload your <span className="-blue -upper">{dataType}</span> data
              </p>
              <span className="-small">
                Add custom data file. Acceptable file format up to 10 MB are : CSV
              </span>
              <br />
              <a onClick={() => this.downloadSample()} className="-lightBlue -download">
                Download sample CSV <img src="/assets/icons/download.png" alt="predictive" />
              </a>
              <br />
              <div className="section-body">
                <div className={`section-upload ${uploadError ? '-uploadError' : ''} `}>
                  <LoadingOverlay active={isLoadding} spinner>
                    <div className="backdrop">
                      <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        addRemoveButton
                        onRemoveFile={() => this.nextStep('uploadfile')}
                      >
                        <img src="/assets/icons/upload.png" alt="predictive" />
                        <span className="font-darkblue f-16 font-w-600">Drag and drop file here</span>
                        <span className="-gray f-14 my-2">Or</span>
                        <a className={`btn-basic bg-darkgreen font-white`} onClick={() => this.nextStep('uploadfile')}>
                          Browse File
                        </a>
                      </CSVReader>
                      {uploadError ? (
                        <p className="text-error">
                          This file exceeds the maximum upload size. <br /> <b>Please try again</b>{' '}
                          <img src="/assets/icons/upload-red.png" alt="upload" />
                        </p>
                      ) : (
                        <Fragment />
                      )}
                    </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          ) : step === 'renderTable' ? (
            <TableComponent
              nextStepFn={this.nextStep}
              resTable={resTable}
              isShowMenu={isShowMenu}
              dataType={dataType}
            />
          ) : step === 'mapping' ? (
            <MappingComponent
              dataType={dataType}
              setReviewDetailFn={this.setReviewDetail}
              refID={refID}
              groupName={groupName}
              masterField={fieldlist}
              nextStepFn={this.nextStep}
              resTable={resTable}
              rawData={rawData}
            />
          ) : (
            <ReviewComponent
              isShowMenu={isShowMenu}
              fileInfo={fileInfo}
              reviewDetail={reviewDetail}
              refID={refID}
              groupName={groupName}
              dataType={dataType}
              nextStepFn={this.nextStep}
            />
          )}
        </section>
      </ImportTypeBox>
    )
  }
}

export default withRouter(ImportComponent)
