import * as axios from 'axios'
import { redirectLogin } from '../../helper/helper'
const { REACT_APP_API_AUTHENTICATION } = process.env

let token = localStorage.getItem('token')

const config = {
  headers: {
    'content-Type': 'application/json',
    Accept: '/',
    'Cache-Control': 'no-cache',
    Authorization: `Bearer ${token}`
  },
  credentials: 'same-origin'
}

const inviteMember = async obj => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/invite-user`
    res = await axios.post(URL, obj, config)
  } catch (error) {
    console.log('inviteMember error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const deleteMember = async email => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/delete-user/${email}`
    res = await axios.delete(URL, config)
  } catch (error) {
    console.log('deleteMember error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const resendInviteMember = async email => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/resend-invite-user`
    res = await axios.post(URL, { email: email }, config)
  } catch (error) {
    console.log('resendInviteMember error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

const memberList = async () => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/users`
    axios.defaults.withCredentials = true
    const response = await axios.get(URL, config)

    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const getDataUser = async (email) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/user/${email}`
    axios.defaults.withCredentials = true
    const response = await axios.get(URL, config)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data
      }
    }
  } catch (error) {
    if (error.response?.status === 401 || error.response?.status === 403) {
      redirectLogin()
    } else {
      res = {
        status: error.response?.status,
        data: error.message
      }
    }
  }
  return res
}

const editMember = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/edit-user`
    res = await axios.patch(URL, obj, config)
  } catch (error) {
    res = {
      status: error.response?.status,
      data: error.message
    }
  }
  return res
}

export { inviteMember, resendInviteMember, memberList, deleteMember, getDataUser, editMember }
