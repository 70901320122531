import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { previewRFM } from '../../apis/dataActivation/api'
import { createRFMSegment } from '../../apis/customer/api'
import { RFMBox } from './style'
import ReactApexChart from 'apexcharts'
import Charts from 'react-apexcharts'
import Modal from 'react-modal'

const TreemapComponent = props => {
  const ref = useRef()
  const history = useHistory()
  const [xseries, setXseries] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupCreate, setOpenPopupCreate] = useState(false)
  const [openPopupSuccess, setOpenPopupSuccess] = useState(false)
  const [segmentName, setSegmentName] = useState('')

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      borderRadius: '10px',
      display: 'inline-table',
      padding: '20px',
      border: 'solid 1px #aabaca !important'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  const [popupDetail, setPopupDetail] = useState({ name: '', value: '' })

  useEffect(async () => {
    await generateTreemap(props.data)
  }, [])

  const generateTreemap = async data => {
    let segment = data.segment
    let _temp = {}
    let res = []
    for (let i = 0; i < Object.keys(segment).length; i++) {
      _temp = {
        x: Object.keys(segment)[i],
        y: segment[Object.keys(segment)[i]].percentage,
        z: segment[Object.keys(segment)[i]].total_customer,
        fillColor:
          Object.keys(segment)[i] === 'New Customers'
            ? '#cbdf7c'
            : Object.keys(segment)[i] === 'At Risk'
            ? '#f0a381'
            : Object.keys(segment)[i] === 'Loyal Customers'
            ? '#a19aae'
            : Object.keys(segment)[i] === 'Needs Attention'
            ? '#f9da87'
            : Object.keys(segment)[i] === 'About to Sleep'
            ? '#a0d8da'
            : Object.keys(segment)[i] === 'Potential Loyalist'
            ? '#73d9a5'
            : Object.keys(segment)[i] === 'Promising'
            ? '#ddc679'
            : Object.keys(segment)[i] === 'Can’t Lose Them'
            ? '#fa8186'
            : Object.keys(segment)[i] === 'Hibernating'
            ? '#c7d3dd'
            : '#76bbf9' //Champions
      }
      res.push(_temp)
    }
    let obj = [{ data: res }]
    setSeries(obj)
  }

  const xoptions = {
    legend: {
      show: false
    },
    fill: {
      opacity: 1
    },
    chart: {
      height: 350,
      type: 'treemap',
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: async (event, chartContext, config) => {
          let objPreview = {
            ref_id: props.data.ref_id,
            segment: config.w.globals.categoryLabels[config.dataPointIndex]
          }
          const { data, status } = await previewRFM(objPreview)
          if (data && status === 200) {
            objPreview['metadata'] = data.data
            setPopupDetail(objPreview)
            setOpenPopup(true)
          }
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5
        }
      }
    },
    plotOptions: {
      treemap: {
        horizontal: false
      }
    },
    title: {
      text: ''
    },

    dataLabels: {
      enabled: true,
      style: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px'
      },
      rotate: 0,
      // textAnchor: 'start',
      // textAnchor: 'right',
      // offsetY: 10,
      // offsetX: 10,
      // offsetX: -30,
      offsetY: -10,
      // dropShadow: {
      //   enabled: true
      // },
      formatter: function (text, { seriesIndex, dataPointIndex, w }) {
        return [
          text,
          w.config.series[0].data[dataPointIndex].z,
          w.config.series[0].data[dataPointIndex].y.toFixed(2) + '%'
        ]
      }
    },
    tooltip: {
      enabled: false,
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          '<span>' +
          w.globals.categoryLabels[dataPointIndex] +
          series[seriesIndex][dataPointIndex] +
          '</span>' +
          '</div>'
        )
      }
    }
  }
  useOnClickOutside(ref, () => setOpenPopup(false))

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('click', listener)
      return () => {
        document.removeEventListener('click', listener)
      }
    }, [ref, handler])
  }
  const [position, setPosition] = useState({ x: 0, y: 0 })
  useEffect(() => {
    const setFromEvent = e => {
      setPosition({ x: e.clientX, y: e.clientY })
    }

    if (document.getElementById('chart')) {
      document.getElementById('chart').addEventListener('click', setFromEvent)
    }

    return () => {
      if (document.getElementById('chart')) {
        document.getElementById('chart').removeEventListener('click', setFromEvent)
      }
    }
  }, [])

  const [series, setSeries] = useState(xseries)
  const [options, setOptions] = useState(xoptions)
  useEffect(() => {
    ReactApexChart.exec(`Chart:chart`, 'updateSeries', series)
    ReactApexChart.exec(`Chart:chart`, 'updateOptions', options, false, true, true)
  }, [series, options])

  const CreateSegmentPopup = () => {
    setSegmentName('')
    setOpenPopupCreate(true)
    // setOpenPopupSuccess(false)
  }

  const HandlingSuccessPopup = () => {
    setOpenPopupCreate(false)
    setOpenPopupSuccess(true)
  }

  const ModalCreateSegmentSuccess = () => {
    return (
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupSuccess}
        onRequestClose={() => setOpenPopupSuccess(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <div className="px-5 text-center">
            <img src="/assets/icons/Success.png" className="px-5 w-50 pt-3" />

            <p className="title-16 mt-3">Success</p>
            <p className="sub-title-12 text-secondary">Your segment was successfully created</p>
          </div>

          <div className="footer-popup text-center">
            <button onClick={() => setOpenPopupSuccess(false)} className="btn-condition w-50 mb-3">
              OK
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  const createSegment = async () => {
    let obj = {
      segment_name: segmentName,
      rfm_ref_id: props.data.ref_id,
      rfm_segment_name: popupDetail.segment
    }
    console.log('obj : ', obj)
    const { data, status } = await createRFMSegment(obj)
    if (data && status === 200) {
      console.log('status : ', status)
      console.log('datass : ', data)
      HandlingSuccessPopup(true)
    }
  }

  return (
    <RFMBox isShowMenu={props.isShowMenu}>
      <div id="chart" ref={ref}>
        <Charts options={options} series={series} type="treemap" height={350} />
      </div>
      {openPopup && (
        <div
          className="custom_popup"
          style={{
            marginTop: `-${window.screen.height - position.y - 200}px`,
            marginLeft: `${position.x - 350}px`
          }}
        >
          <p>{popupDetail.segment}</p>
          <label>{popupDetail.metadata.description}</label>
          <p>Average score</p>
          <label className="-left">Recency score</label>
          <label className="-right">{popupDetail.metadata.recency_score.toFixed(2)}</label>
          <label className="-left">{`Frequency & Monetary score`}</label>
          <label className="-right">
            {popupDetail.metadata.frequency_x_monetary_score.toFixed(2)}
          </label>
          <div className="clear-both" />
          <hr />
          <p>Users</p>
          <label className="-left">Unique users</label>
          <label className="-right">{popupDetail.metadata.total_customer}</label>
          <div className="clear-both" />
          <hr />
          <button className="btn-create-static" onClick={() => CreateSegmentPopup()}>
            Create a Static Segment
          </button>
        </div>
      )}
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupCreate}
        onRequestClose={() => setOpenPopupCreate(false)}
        style={customStyles}
      >
        <div className="close px-2" onClick={() => setOpenPopupCreate(false)}>
          <img src="/assets/icons/close.png" alt="close" />
        </div>
        
        <div className="body-popup">
          <p className="title-popup text-center  text-black title-rfm-popup">
            RFM Segment name
          </p>
          <div className="row px-5 center">
            <input
              type="text"
              className="modal-input f-14"
              placeholder="Enter segment name …"
              value={segmentName}
              onChange={e => setSegmentName(e.target.value)}
            />
          </div>

          <div className="footer-popup text-center my-4">
            <a
              onClick={() => setOpenPopupCreate(false)}
              className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
            >
              Cancel
            </a>
            <a onClick={() => createSegment()} className="btn-basic bg-darkgreen font-white">
              Save
            </a>
          </div>
        </div>
      </Modal>
      <ModalCreateSegmentSuccess />
    </RFMBox>
  )
}

export default TreemapComponent
