import styled from 'styled-components'

const FacebookBox = styled.div`
.main {
  padding: ${props => (props.isShowMenu ? ' 0px 0px 10px 0px' : ' 0px 0px 10px 60px')};
  color: #6a707e;
  width: ${props => (props.isShowMenu ? 'auto;' : '100%')};
  margin-left: ${props => (props.isShowMenu ? '300px' : '0px')};
  transition: margin 700ms;
}
.card{
  border-radius: 8px;
  border: 0;
  box-shadow: 2px -2px 10px 3px rgb(0 0 0 / 9%);
  min-height: 560px;
  padding: 10%;
}

`

export {FacebookBox}
