import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { editTaskBigQuery } from '../../apis/filestatus/api'

import { useHistory, useLocation } from 'react-router-dom'
import { OptionDropdownBox } from '../dropdown/style'
import useOutsideClick from './useOutsideClick'
import { DropdownBox } from './style'
import { propTypes } from 'react-bootstrap/esm/Image'

const ScheduleDropdownOptions = props => {
  const ref = useRef()
  const history = useHistory()
  let location = useLocation()
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupEdit, setOpenPopupEdit] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)

  const [timeSchedule, setTimeSchedule] = useState()
  const [repeatTime, setRepeatTime] = useState()
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [refId, setRefId] = useState()

  useEffect(() => {
    setRefId(props.id)
  }, [])

  const [openDropdownTimeSet, setOpenDropdownTimeSet] = useState(false)
  const [openDropdownRepeatTimeSet, setOpenDropdownRepeatTimeSet] = useState(false)
  const [openDropdownDaySet, setOpenDropdownDaySet] = useState(false)
  const [openDropdownDateSet, setOpenDropdownDateSet] = useState(false)

  let numberTime = []
  const repeatTimes = ['Daily', 'Weekly', 'Monthly']
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let dates = []
  for (let x = 1; x < 32; x++) {
    dates.push(x)
  }
  for (let t = 0; t < 24; t++) {
    numberTime.push((t < 10 ? '0' + t : t) + ':00')
  }

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      borderRadius: '10px',
      display: 'inline-table',
      padding: '0px',
      border: 'solid 1px #aabaca !important'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  useOutsideClick(ref, () => {
    setOpenOptions(false)
  })

  const removeSchedulePopup = () => {
    setOpenPopup(true)
  }

  const handleCloseAll = () => {
    setOpenDropdownTimeSet(false)
    setOpenDropdownRepeatTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const setValueRepeatTime = time => {
    setRepeatTime(time)
    handleCloseAll()
  }

  const setValueDay = day => {
    setDay(day)
    handleCloseAll()
  }
  const setValueDate = date => {
    setDate(date)
    handleCloseAll()
  }
  const setValueTime = time => {
    setTimeSchedule(time)
    handleCloseAll()
  }

  const UseSetOpenDropdownRepeatTimeSet = open => {
    setOpenDropdownRepeatTimeSet(open)
    setOpenDropdownTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownDateSet(false)
  }

  const UseSetOpenDropdownDaySet = open => {
    setOpenDropdownDaySet(open)
    setOpenDropdownTimeSet(false)
    setOpenDropdownDateSet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownTimeSet = open => {
    setOpenDropdownTimeSet(open)
    setOpenDropdownDateSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const UseSetOpenDropdownDateSet = open => {
    setOpenDropdownDateSet(open)
    setOpenDropdownTimeSet(false)
    setOpenDropdownDaySet(false)
    setOpenDropdownRepeatTimeSet(false)
  }

  const deleteSchedule = () => {
    props.deleteScheduleFn(props.id)
    setOpenPopup(false)
  }

  const editSchedulePopup = () => {
    const { editObj } = props

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let now = new Date()
    now.setHours(parseInt(editObj.time.split(':')[0]) + 7, 0, 0)
    let hours = now.getHours()
    if (hours < 10) {
      hours = '0' + hours + ':00'
    } else {
      hours = hours + ':00'
    }
    setTimeSchedule(hours)
    setOpenPopupEdit(true)
    setRepeatTime(editObj.type.charAt(0).toUpperCase() + editObj.type.slice(1))
    setValueDate(editObj.day_of_type)
    setDay(days[editObj.day_of_type - 1])
  }

  const editSchedule = async () => {
    let obj = {}
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let now = new Date()
    now.setHours(timeSchedule.split(':')[0], 0, 0)
    let utc_hours = now.getUTCHours()
    if (utc_hours < 10) {
      utc_hours = '0' + utc_hours + ':00'
    } else {
      utc_hours = utc_hours + ':00'
    }

    if (repeatTime === 'Daily') {
      obj = {
        ref_id: props.id,
        schedule_detail: {
          type: repeatTime.toLowerCase(),
          time: utc_hours
        }
      }
    } else if (repeatTime === 'Weekly') {
      obj = {
        ref_id: props.id,
        schedule_detail: {
          type: repeatTime.toLowerCase(),
          time: utc_hours,
          day_of_type: days.indexOf(day) + 1
        }
      }
    } else {
      obj = {
        ref_id: props.id,
        schedule_detail: {
          type: repeatTime.toLowerCase(),
          time: utc_hours,
          day_of_type: date
        }
      }
    }
    let response = await editTaskBigQuery(obj)
    if (response && response.status === 200) {
      setOpenPopupEdit(false)
      window.location.reload()
    }
  }

  const DropdownRepeatTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field -no-box-shadow `}
          onClick={() => UseSetOpenDropdownRepeatTimeSet(!openDropdownRepeatTimeSet)}
        >
          <p className="text-dropdown">
            {repeatTime ? (
              repeatTime
            ) : (
              <span className="head-sub-title m-0 p-0">Select repeat time</span>
            )}
          </p>
          <i className={`arrow-${openDropdownRepeatTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownRepeatTimeSet && (
          <div className="box-dropdown box-dropdown-md">
            <ul>
              {repeatTimes.map((listrepeatTime, i) => {
                return (
                  <li key={i} onClick={() => setValueRepeatTime(listrepeatTime)}>
                    {listrepeatTime}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDateSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  -no-box-shadow`}
          onClick={() => UseSetOpenDropdownDateSet(!openDropdownDateSet)}
        >
          <p className="text-dropdown">
            {date ? date : <span className="head-sub-title m-0 p-0">Select date</span>}
          </p>
          <i className={`arrow-${openDropdownDateSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDateSet && (
          <div className="box-dropdown box-dropdown-md">
            <ul>
              {dates.map((listdates, i) => {
                return (
                  <li key={i} onClick={() => setValueDate(listdates)}>
                    {listdates}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownDaySet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  -no-box-shadow`}
          onClick={() => UseSetOpenDropdownDaySet(!openDropdownDaySet)}
        >
          <p className="text-dropdown">
            {day ? day : <span className="head-sub-title m-0 p-0">Select day of week</span>}
          </p>
          <i className={`arrow-${openDropdownDaySet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownDaySet && (
          <div className="box-dropdown  -no-box-shadow  box-dropdown-md">
            <ul>
              {days.map((listdays, i) => {
                return (
                  <li key={i} onClick={() => setValueDay(listdays)}>
                    {listdays}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }

  const DropdownTimeSet = () => {
    return (
      <DropdownBox>
        <div
          className={`box_field  -no-box-shadow`}
          onClick={() => UseSetOpenDropdownTimeSet(!openDropdownTimeSet)}
        >
          <p className="text-dropdown">
            {timeSchedule ? timeSchedule : <span className="head-sub-title m-0 p-0">00:00</span>}
          </p>
          <i className={`arrow-${openDropdownTimeSet ? 'up' : 'down'}`}></i>
        </div>
        {openDropdownTimeSet && (
          <div className="box-dropdown box-dropdown-small">
            <ul>
              {numberTime.map((listTime, i) => {
                return (
                  <li key={i} onClick={() => setValueTime(listTime)}>
                    {listTime}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </DropdownBox>
    )
  }
  return (
    <OptionDropdownBox>
      <div className={`box_field-sm p-0`} ref={ref}>
        <div className={`icon-option`} onClick={() => setOpenOptions(!openOptions)} />
      </div>
      {openOptions && (
        <div className="box-dropdown">
          <ul>
            {!props.optionEdit && (
              <li onClick={() => editSchedulePopup()}>
                <img src="/assets/icons/edit_trans.png" /> Edit schedule
              </li>
            )}

            <li className="-del" onClick={() => removeSchedulePopup()}>
              <img src="/assets/icons/bin-red.png" /> Cancel syncing data
            </li>
          </ul>
        </div>
      )}

      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
      >
        <div className="body-popup p-4">
          <b className="title-popup text-black">Cancel Schedule ?</b>
          <p className="desc-popup text-body-popup my-4">
            You are about to cancel importing data into our platform. If you continue, data will no
            longer be imported into platform. Are you sure you want to cancel schedule?
          </p>
          <div className="footer-popup">
            <a onClick={() => setOpenPopup(false)} className=" btn-basic bg-whiter border-darkpink font-darkpink mx-2">
              Cancel
            </a>
            <a onClick={() => deleteSchedule()} className=" btn-basic  bg-darkpink font-white w-50">
              Yes, cancel syncing data
            </a>
          </div>
        </div>
      </Modal>

      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopupEdit || props.openEditPopup}
        // isOpen={true}
        onRequestClose={() => setOpenPopupEdit(false)}
        style={customStyles}
      >
        <div className="header-popup">
          <p className="text-center m-0 text-black">Edit Schedule</p>
        </div>
        <div className="body-popup p-4">
          <div className="row">
            <div className="col-7">
              <div className="form-group">
                <label htmlFor="" className="title-small text-left">
                  Repeat
                </label>

                <DropdownRepeatTimeSet />
              </div>
            </div>

            <div className={repeatTime === 'Weekly' ? 'd-block col-7 ' : 'd-none'}>
              <label className="title-small text-left">Day of week</label>

              <div className="form-group">
                <DropdownDaySet />
              </div>
            </div>
            <div className={repeatTime === 'Monthly' ? 'd-block col-7' : 'd-none'}>
              <label className="title-small text-left">On the</label>

              <div className="form-group">
                <DropdownDateSet />
              </div>
            </div>

            <div
              className={
                repeatTime === 'Only once' ||
                repeatTime === 'Daily' ||
                repeatTime === 'Weekly' ||
                repeatTime === 'Monthly'
                  ? 'd-block col-4'
                  : 'd-none'
              }
            >
              <label className="title-small text-left">Time</label>

              <div className="form-group">
                <DropdownTimeSet />
              </div>
            </div>
          </div>

          <div className="footer-popup text-center pt-4">
            <a onClick={() => setOpenPopupEdit(false)} className="btn-basic bg-whiter border-darkgreen font-darkgreen mx-2">
              Cancel
            </a>
            <a onClick={() => editSchedule()} className="btn-basic bg-darkgreen font-white">
              Save
            </a>
          </div>
        </div>
      </Modal>
    </OptionDropdownBox>
  )
}

export default ScheduleDropdownOptions
