// import React, { useState } from 'react'
import { Component } from 'react'

import ImportComponent from '../import/import'
import LoginComponent from '../login/login'
import ProfileComponent from '../profile/profile'
import AudienceComponent from '../audience/audience'
import AudienceDetailComponent from '../audience/detail'
import DashboardComponent from '../dashboard/dashboard'
import SegmentationComponent from '../segmentation/segmentation'
import BuilderComponent from '../segmentation/builder'
import ImportListComponent from '../import/importList'
import NotFoundComponent from '../error/notFound'
import MemberListComponent from '../team/memberList'
import IntegrationListComponent from '../team/integrationList'
import RFMComponent from '../RFM/rfm'
import CreateRmfComponent from '../RFM/createRFM'
import MemberComponent from '../team/member'
import CampaignComponent from '../campaign/campaign'
import SelectSourceComponent from '../campaign/selectSource'
import SmsCampaignComponent from '../campaign/sms/smsCampaign'
import FacebookCampaignComponent from '../campaign/facebook/facebookCampaign'
import HomeComponent from '../home/home'
import { isEmpty } from '../../helper/helper'
import { NavBox } from './style'
import MenuComponent from './menu'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { Container } from 'react-bootstrap'

class NavComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isShowMenu: true,
      isActive: false,
      outerSegment: false
    }
  }

  handleChange = () => {
    this.setState(prevState => ({
      isShowMenu: !prevState.isShowMenu
    }))
  }

  checkActive = (match, location) => {
    if (!location) return false
    const { pathname } = location
    const { url } = match
    return pathname === url ? true : false
  }

  setActive = path => {
    if (isEmpty(path)) {
      this.setState({ pathname: window.location.pathname })
    } else if (window.location.pathname === path) {
      this.setState({ pathname: path })
    }
  }

  setOver = path => {
    if (!isEmpty(path)) {
      this.setState({ onOver: path })
    } else {
      this.setState({ onOver: '' })
    }
  }
  setOver = path => {
    if (!isEmpty(path)) {
      this.setState({ onOver: path })
    } else {
      this.setState({ onOver: '' })
    }
  }
  setOuterSegment = () => {
    this.setState({ outerSegment: true })
  }

  componentDidMount = () => {
    this.setActive()
    localStorage.removeItem('su')
  }
  render() {
    const { isShowMenu, activeLink, pathname, onOver, outerSegment, outerPath } = this.state
    const { setLoginFn } = this.props
    return (
      <NavBox isShowMenu={isShowMenu}>
        <Router>
          <div id="outer-container">
            <MenuComponent
              setOuterSegmentFn={this.setOuterSegment}
              isOpen={isShowMenu}
              handleChangeFn={this.handleChange}
            />
            <Container fluid id="page-wrap" className="nopadding">
              <ProfileComponent isShowMenu={isShowMenu} />
              <Switch>
                <Route exact path="/home">
                  <HomeComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/dashboard">
                  <DashboardComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/imports">
                  <ImportListComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/imports/create">
                  <ImportComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/imports/create?step=:step">
                  <ImportComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/audience">
                  <AudienceComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/audience/:id">
                  <AudienceDetailComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/segmentation">
                  <SegmentationComponent
                    isFromMenu={true}
                    isShowMenu={isShowMenu}
                    outerSegment={outerSegment}
                  />
                </Route>
                <Route exact path="/segmentation/create">
                  <BuilderComponent isShowMenu={isShowMenu} outerSegment={outerSegment} />
                </Route>
                <Route exact path="/segmentation/:id/edit">
                  <BuilderComponent isShowMenu={isShowMenu} outerSegment={outerSegment} />
                </Route>
                <Route exact path="/campaign">
                  <CampaignComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/campaign/sms">
                  <CampaignComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/campaign/facebookads">
                  <FacebookCampaignComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/campaign/create">
                  <CampaignComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/campaign/create?step=typeSource">
                  <CampaignComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/campaign/:option/:id">
                  <CampaignComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/campaign/create?step=smsCampaign">
                  <CampaignComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/rfm">
                  <RFMComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/rfm/create">
                  <CreateRmfComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route>
                <Route exact path="/document">
                  <HomeComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/rfm">
                  <RFMComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/setting/generals/">
                  <MemberListComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/setting/generals/:tab">
                  <MemberListComponent isShowMenu={isShowMenu} />
                </Route>
                <Route exact path="/setting/integrations/">
                  <IntegrationListComponent isShowMenu={isShowMenu}/>
                </Route>
                <Route exact path="/setting/integrations/:tab">
                  <IntegrationListComponent isShowMenu={isShowMenu}/>
                </Route>
                {/* <Route exact path="/setting/generals">
                  <MemberListComponent isShowMenu={isShowMenu} />
                </Route> */}
                {/* <Route exact path="/setting/generals/invite">
                  <MemberComponent isShowMenu={isShowMenu} isCreate={true} />
                </Route> */}
                <Route exact path="/setting/generals/:id/edit">
                  <MemberComponent isShowMenu={isShowMenu} isCreate={false} />
                </Route>
                {/* <Route component={<NotFoundComponent errorType={404} />} />{' '} */}
              </Switch>
            </Container>
          </div>
        </Router>
      </NavBox>
    )
  }
}

export default NavComponent
