import React, { Fragment, useState, useEffect } from 'react'
import { MemberBox } from './style'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import IntegrationsComponent from './integrations'
import FacebookComponent from './facebook'
const Tab = styled.button`
  font-size: 14px;
  padding: 10px 27px;
  background: white;
  border: 0;
  outline: 0;
  color: #334d6e;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: -10px;
  border-radius: 1px;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #24b0c3;
    opacity: 1;
    color: #24b0c3;
    font-weight: 600;
  `}
`

const ButtonGroup = styled.div`
  display: flex;
`

const types = ['sms', 'facebookads', 'googleads']
const IntegrationListComponent = props => {
  const history = useHistory()
  const { tab: activeTab } = useParams('sms')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.isShowMenu])

  const [active, setActive] = useState(types[0])

  const handleTab = tab => {
    setActive(tab[0])
    history.push(`/setting/integrations/${tab}`)
  }
  return (
    <MemberBox isShowMenu={props.isShowMenu}>
      <section className="main">
        <div className="row top-menu m-0 pt-3 pb-0">
          <div className="col-12 text-left">
            <p className="top-head-title">Integration</p>
          </div>
          <div className="col-12 text-left px-0">
            <ButtonGroup>
              {types.map(type => (
                <Tab
                  key={type}
                  active={!activeTab || activeTab === type}
                  onClick={() => handleTab(type)}
                >
                  <span className="-capitalize">{
                      (() => {
                      if (type === 'sms') {
                        return type.toUpperCase()
                      } else if (type === 'facebookads') {
                        return 'facebook ads'
                      } else if (type === 'googleads') {
                        return 'google ads'
                      }
                    })()}
                  </span>
                </Tab>
              ))}
            </ButtonGroup>
            <p />
          </div>
        </div>
        <div className="py-4 px-4">
          {activeTab === 'sms' ? <IntegrationsComponent /> : ''}
          {activeTab === 'facebookads' ? <FacebookComponent /> : ''}
          
          {activeTab === 'googleads' ? <p>googleads</p> : ''}
        </div>
      </section>
    </MemberBox>
  )
}

export default IntegrationListComponent
