import React, { Component, Fragment } from 'react'
import { isEmpty } from '../../helper/helper'
import { NotFoundBox } from './style'

export default class NotFoundComponent extends Component {
  render() {
    const { errorType, callback } = this.props
    let backLink = !isEmpty(callback) ? callback : '/'
    console.log('errorType : ', errorType)
    return (
      <NotFoundBox>
        {errorType === '401' ? (
          <Fragment>
            <img src="/assets/images/notfound.png" alt="predictive" />
            <p className="-dark -medium -bold">Page Not found</p>
            <p className="-gray -small">No file or directory found.</p>
            <br />
            <a href={backLink} className="btn-basic bg-darkgreen font-white">
              Back to Home page
            </a>{' '}
          </Fragment>
        ) : errorType === '404' ? (
          <Fragment>
            <img src="/assets/images/notfound.png" alt="predictive" />
            <p className="-dark -medium -bold">No results found</p>
            <p className="-gray -small">
              Try adjusting your search or filter to find what you are looking for.
            </p>
            <br />
            <a href={backLink} className="btn-basic bg-darkgreen font-white">
              Back to Home page
            </a>{' '}
          </Fragment>
        ) : errorType === '500' ? (
          <Fragment>
            <img src="/assets/images/notfound.png" alt="predictive" />
            <p className="-dark -medium -bold">Sorry, Something went wrong</p>
            <p className="-gray -small">Please refresh the screen or try again later.</p>
            <br />
            <a href={backLink} className="btn-basic bg-darkgreen font-white">
              Back to Home page
            </a>
          </Fragment>
        ) : (
          <Fragment />
        )}
      </NotFoundBox>
    )
  }
}
