import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import { useHistory, useLocation } from 'react-router-dom'
import { OptionDropdownBox } from '../dropdown/style'
import useOutsideClick from './useOutsideClick'

const DropdownOptions = props => {
  const ref = useRef()
  const history = useHistory()
  let location = useLocation()
  const [openPopup, setOpenPopup] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)
  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: props.isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
      transition: 'width 0.5s',
      width: '470px',
      height: 'auto',
      border: '2px solid #0698BE',
      borderRadius: '10px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  }
  useOutsideClick(ref, () => {
    setOpenOptions(false)
  })

  const removeMemberPopup = () => {
    setOpenPopup(true)
  }

  return (
    <OptionDropdownBox>
      <div className={`box_field `} ref={ref}>
        <div className={`icon-option`} onClick={() => setOpenOptions(!openOptions)} />
      </div>
      {openOptions && (
        <div className="box-dropdown">
          <ul>
            {!props.isVerified && (
              <li onClick={() => props.resentInviteFn()}>
                <img src="/assets/icons/email-white.png" /> Resent Invite
              </li>
            )}

            <li onClick={() => props.editMemberFn()}>
              <img src="/assets/icons/edit_trans.png" /> Edit
            </li>
            <li className="-del" onClick={() => removeMemberPopup()}>
              <img src="/assets/icons/bin-red.png" /> Remove
            </li>
          </ul>
        </div>
      )}

      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={openPopup || props.openDeletePopup}
        onRequestClose={() => setOpenPopup(false)}
        style={customStyles}
      >
        <div className="body-popup">
          <b className="title-popup font-darkblue">Remove member ?</b>
          <p className="desc-popup">
            {props.id} will lose all access to this workspace Are you sure you want to remove this
            user?
          </p>
          <div className="footer-popup">
            <a onClick={() => setOpenPopup(false)} className=" btn-basic bg-whiter border-darkpink font-darkpink mx-2">
              Cancel
            </a>
            <a onClick={() => props.deleteMemberFn()} className=" btn-basic  bg-darkpink font-white">
            Yes, remove
            </a>
          </div>
        </div>
      </Modal>
    </OptionDropdownBox>
  )
}

export default DropdownOptions
