import { Component, Fragment } from 'react'
import { isEmpty } from '../../helper/helper'
import { withRouter } from 'react-router'
import { push as Menu } from 'react-burger-menu'
import { verifyToken } from '../../apis/login/api'
import { redirectLogin } from '../../helper/helper'
import Modal from 'react-modal'

import { NavBox } from './style'
import { BrowserRouter as Router, NavLink } from 'react-router-dom'
class MenuComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowMenu: true,
      isActive: false,
      isBlocking: false,
      role: '',
      isLogout: false,
      analyticsCollapseMenu: false,
      campaignCollapseMenu: false,
      settingsCollapseMenu: false
    }
  }

  openCollapseMenu = (menuType, stateStatus) => {
    let setMenuState = `${menuType}CollapseMenu`
    this.setState({ [setMenuState]: !stateStatus })
  }
  checkActive = (match, location) => {
    if (!location) return false
    const { pathname } = location
    const { url } = match
    return pathname === url ? true : false
  }

  setActive = path => {
    const { history, setOuterSegmentFn } = this.props
    localStorage.setItem('p', path)
    history.block(() => {
      let key = localStorage.getItem('su')
      if (!isEmpty(key) && key === 'Y') {
        setOuterSegmentFn()
        return false
      } else {
        return true
      }
    })

    if (isEmpty(path)) {
      this.setState({ pathname: window.location.pathname })
    } else if (window.location.pathname === path) {
      history.push(path)
    }
  }

  setOver = path => {
    if (!isEmpty(path)) {
      this.setState({ onOver: path })
    } else {
      this.setState({ onOver: '' })
    }
  }
  setRole = async () => {
    let role = await verifyToken()
    this.setState({ role: role.role })
  }
  componentDidMount = async () => {
    await this.setRole()
    this.setActive()
  }

  openModel = () => {
    this.setState({ isLogout: true })
  }
  closeModal = () => {
    this.setState({ isLogout: false })
  }

  render() {
    const { isShowMenu, activeLink, onOver, role, isLogout } = this.state
    const { history } = this.props

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: isShowMenu ? 'translate(-20%, -50%)' : 'translate(-45%, -50%)',
        transition: 'width 0.5s',
        width: '470px',
        height: 'auto',
        border: '2px solid #0698BE',
        borderRadius: '10px'
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }
    }
    return (
      <NavBox isShowMenu={isShowMenu}>
        <Menu
          disableCloseOnEsc
          onStateChange={() => this.props.handleChangeFn()}
          isOpen={isShowMenu}
          customCrossIcon={<img src={'/assets/images/menu-toggle.png'} alt="predictive" />}
          noOverlay
          disableAutoFocus
          disableOverlayClick
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
        >
          <div className="logo">
            <h1>CDP</h1>
          </div>
          <div className="menu_list border-line" style={{overflowY: 'auto'}}>
            <li
              onClick={() => this.setActive('/')}
              onMouseOver={() => this.setOver('/')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink exact activeClassName="active" to="/home">
                <span className="material-icons-outlined">home</span>
                <label
                  className={
                    onOver === '/' || history.location.pathname.includes('/home') ? '-active' : ''
                  }
                >
                  Home
                </label>
              </NavLink>
            </li>
            <li
              onClick={() => this.setActive('/dashboard')}
              onMouseOver={() => this.setOver('/dashboard')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink activeClassName="active" to="/dashboard">
                <span class="material-icons-outlined">show_chart</span>
                <label
                  className={
                    onOver === '/dashboard' || history.location.pathname.includes('/dashboard')
                      ? '-active'
                      : ''
                  }
                >
                  Dashboard
                </label>
              </NavLink>
            </li>
            <li
              onClick={() => this.setActive('/audience')}
              onMouseOver={() => this.setOver('/audience')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink activeClassName="active" to="/audience">
                <span class="material-icons-outlined">groups</span>

                <label
                  className={
                    onOver === '/audience' || history.location.pathname.includes('/audience')
                      ? '-active'
                      : ''
                  }
                >
                  Audience
                </label>
              </NavLink>
            </li>
            <li onClick={() => this.openCollapseMenu('analytics',this.state.analyticsCollapseMenu)}>
              <a href='#!' className="sub-text-menu">
                <span class="material-icons-outlined">bar_chart</span>

                <label>
                  Analytics<span className="mx-5"></span>
                  <i
                    className={`sub-icon-menu ${
                      this.state.analyticsCollapseMenu === true ? 'arrow-right' : 'arrow-down-no-m'
                    }`}
                  ></i>
                </label>
              </a>
            </li>

            {this.state.analyticsCollapseMenu === true ? (
              <Fragment>
                <li
                  className="pl-5"
                  onClick={() => this.setActive('/rfm')}
                  onMouseOver={() => this.setOver('/rfm')}
                  onMouseOut={() => this.setOver('')}
                >
                  <NavLink activeClassName={activeLink} to="/rfm/create">
                    <span class="material-icons-outlined">dashboard</span>
                    <label
                      className={
                        onOver === '/rfm' || history.location.pathname.includes('/rfm/create')
                          ? '-active'
                          : ''
                      }
                    >
                      RFM
                    </label>
                  </NavLink>
                </li>
              </Fragment>
            ) : (
              ''
            )}

            <li
              onClick={() => this.setActive('/segmentation')}
              onMouseOver={() => this.setOver('/segmentation')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink activeClassName="active" to="/segmentation">
                <span class="material-icons-outlined">gps_fixed</span>

                <label
                  className={
                    onOver === '/segmentation' ||
                    history.location.pathname.includes('/segmentation')
                      ? '-active'
                      : ''
                  }
                >
                  Segmentation
                </label>
              </NavLink>
            </li>

            <li
              onClick={() => this.setActive('/imports')}
              onMouseOver={() => this.setOver('/imports')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink activeClassName="active" to="/imports">
                <span class="material-icons-outlined">pie_chart</span>

                <label
                  className={
                    onOver === '/imports' || history.location.pathname.includes('/imports')
                      ? '-active'
                      : ''
                  }
                >
                  Data Management
                </label>
              </NavLink>
            </li>

            <li onClick={() => this.openCollapseMenu('campaign',this.state.campaignCollapseMenu)}>
              <a href='#!' className="sub-text-menu">
                <span class="material-icons-outlined">campaign</span>

                <label>
                  Campaign<span className="mx-5"></span>
                  <i
                    className={`sub-icon-menu ${
                      this.state.campaignCollapseMenu === true ? 'arrow-right' : 'arrow-down-no-m'
                    }`}
                  ></i>
                </label>
              </a>
            </li>
            {this.state.campaignCollapseMenu === true ? (
              <Fragment>
                <li
                  className="pl-5"
                  onClick={() => this.setActive('/campaign')}
                  onMouseOver={() => this.setOver('/campaign/sms')}
                  onMouseOut={() => this.setOver('')}
                >
                  <NavLink activeClassName={activeLink} to="/campaign/sms">
                    <span class="material-icons-outlined">sms</span>
                    <label
                      className={(() => {
                        if (onOver === '/campaign/sms' || history.location.pathname.includes('/campaign/sms')) {
                          return '-active'
                        }
                        return ''
                      })()}
                    >
                      SMS
                    </label>
                  </NavLink>
                </li>

                <li
                  className="pl-5"
                  onClick={() => this.setActive('/facebook')}
                  onMouseOver={() => this.setOver('/campaign/facebookads')}
                  onMouseOut={() => this.setOver('')}
                >
                  <NavLink activeClassName={activeLink} to="/campaign/">
                    <span class="material-icons-outlined">facebook</span>
                    <label
                      className={(() => {
                        if (onOver === '/campaign/facebookads' || history.location.pathname.includes('/campaign/facebookads')) {
                          return '-active'
                        }
                        return ''
                      })()}
                    >
                      Facebook Ads
                    </label>
                  </NavLink>
                </li>
              </Fragment>
            ) : (
              ''
            )}

            <li
              onClick={() => this.setActive('/document')}
              onMouseOver={() => this.setOver('/document')}
              onMouseOut={() => this.setOver('')}
            >
              <NavLink activeClassName={activeLink} to="/document">
                <span class="material-icons-outlined">textsms</span>
                <label
                  className={
                    onOver === '/document' || history.location.pathname.includes('/document')
                      ? '-active'
                      : ''
                  }
                >
                  Documentation
                </label>
              </NavLink>
            </li>

            {role === 'admin' && (
              <Fragment>
              <li className="border-t" onClick={() => this.openCollapseMenu('settings',this.state.settingsCollapseMenu)}>
                <a href='#!' className="sub-text-menu">
                  <span class="material-icons-outlined">settings</span>

                  <label>
                    Setting<span className="mx-5"></span>
                    <i
                      className={`sub-icon-menu ${
                        this.state.settingsCollapseMenu === true ? 'arrow-right' : 'arrow-down-no-m'
                      }`}
                    ></i>
                  </label>
                </a>
              </li>

              {this.state.settingsCollapseMenu === true ? (
                <Fragment>
                  <li
                    className="pl-5"
                    onClick={() => this.setActive('/setting')}
                    onMouseOver={() => this.setOver('/setting/generals/')}
                    onMouseOut={() => this.setOver('')}
                  >
                    <NavLink activeClassName={activeLink} to="/setting/generals/account">
                      <span class="material-icons-outlined">tune</span>
                      <label
                        className={(() => {
                          if (onOver === '/setting/generals/' || history.location.pathname.includes('/setting/generals/')) {
                            return '-active'
                          }
                          return ''
                        })()}
                      >
                        General
                      </label>
                    </NavLink>
                  </li>

                  <li
                    className="pl-5"
                    onClick={() => this.setActive('/setting')}
                    onMouseOver={() => this.setOver('/setting/integrations/')}
                    onMouseOut={() => this.setOver('')}
                  >
                    <NavLink activeClassName={activeLink} to="/setting/integrations/sms">
                      <span class="material-icons-outlined">cable</span>
                      <label
                        className={(() => {
                          if (onOver === '/setting/integrations/' || history.location.pathname.includes('/setting/integrations/')) {
                            return '-active'
                          }
                          return ''
                        })()}
                      >
                        Integration
                      </label>
                    </NavLink>
                  </li>
                </Fragment>
              ) : (
                ''
              )}
              </Fragment>

              // <li
              //   className="border-t"
              //   onClick={() => this.setActive('/setting')}
              //   onMouseOver={() => this.setOver('/setting')}
              //   onMouseOut={() => this.setOver('')}
              // >
              //   <NavLink activeClassName={activeLink} to="/setting/generals/account">
              //     <span class="material-icons-outlined">settings</span>
              //     <label
              //       className={
              //         onOver === '/setting' || history.location.pathname.includes('/setting')
              //           ? '-active'
              //           : ''
              //       }
              //     >
              //       Setting
              //     </label>
              //   </NavLink>
              // </li>
            )}
          </div>
          <ul className="bm-item menu_list -logout">
            <li onMouseOver={() => this.setOver('/logout')} onMouseOut={() => this.setOver('')}>
              <span class="material-icons-outlined">logout</span>
              <label onClick={() => this.openModel()}>Logout</label>
            </li>
          </ul>
        </Menu>
        <Modal closeTimeoutMS={500} ariaHideApp={false} isOpen={isLogout} style={customStyles}>
          <div className="body-popup">
            <p className="title-popup text-center py-3 my-4  font-weight-lighter font-darkblue">
              Are you sure you want to log out ?{' '}
            </p>

            <div className="footer-popup text-center my-4">
              <a
                href='#!'
                onClick={() => this.closeModal()}
                className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              >
                Cancel
              </a>
              <a href='#!' onClick={() => redirectLogin()} className="btn-basic bg-darkgreen font-white">
                Logout
              </a>
            </div>
          </div>
        </Modal>
      </NavBox>
    )
  }
}

export default withRouter(MenuComponent)
