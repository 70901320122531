import React, { Fragment, useState, useEffect, useRef } from 'react'
import { FacebookBox } from './style'
import { useHistory, useParams } from 'react-router-dom'
const FacebookComponent = props => {
  const history = useHistory()

  return (
    <FacebookBox isShowMenu={props.isShowMenu}>
      
    
          <div className="row">
            <div className="col-6 text-left">
              <h2 className="top-head-title">Facebook Ad Account</h2>
            </div>
            <div className="col-6 text-right">
              <a
                className="btn-basic bg-darkgreen font-white"
               
              >
                <img src="/assets/icons/create-white-2.png" className="w-px-14 mx-1" />
                Add Ad Account
              </a>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-body">
              <div className="text-center">
              <img className="w-px-300 m-5" src={`/assets/images/fbads.png`} alt="logo" />
                <p className='top-head-title-20 font-darkblue'>Connect Facebook Ad account</p>
                <p className="sub-title-14 font-gray w-40 mx-auto">
                Start connecting your first Facebook Ad account by clicking the <span className='font-darkblue font-w-500'>“Add Ad Account”</span> button.
                </p>
              </div>
            </div>
          </div>
   
      
    </FacebookBox>
  )
}

export default FacebookComponent
