import * as axios from 'axios'

let token = localStorage.getItem('token')
const testConnectProvider = async (api_key, api_secret, provider_url, payload = {}) => {
  console.log('testConnectProvider configure : ', api_key, api_secret, provider_url)
  let res = {}
  try {
    let auth = api_key + ':' + api_secret
    let base64 = Buffer.from(auth).toString('base64')
    const config = {
      method: 'post',
      url: provider_url,
      headers: { 
        Authorization: `Basic ${base64}`, 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, Authorization, X-Requested-With'
      },
      data: JSON.stringify(payload)
    }
    console.log('testConnectProvider config : ', config)
    return await axios(config)
  } catch (error) {
    console.log('testConnectProvider error : ', error)
    res = {
      status: error.response?.status,
      data: error.message
    }
    //   if (error.response?.data) {
    //     // logger.error(`thaiBulkSMSService.sendSMS :: ${error.response?.data.error.description}`)
    //     await thaiBulkSMSService.updateStatus(
    //       ref_id,
    //       task_id,
    //       'fail',
    //       error.response?.data.error.description
    //     )
    //     return
    //   }
    //   logger.error(`thaiBulkSMSService.sendSMS :: ${error.message}`)
    //   await handleErrorService.handleError(ref_id, 'fail', error.message)
    //   throw new Error(error)
  }
}

export { testConnectProvider }
