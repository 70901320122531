import { Component } from 'react'
import { isEmpty } from '../../helper/helper'

import { TableBox } from './style'

export default class TableComponent extends Component {
  render() {
    const { isShowMenu, resTable, dataType } = this.props
    let columns = resTable.resColumns
    let rows = resTable.resRows.filter((item, i) => i < 10)
    let fileName = resTable.resFileInfo.name
    const { nextStepFn } = this.props
    return (
      <TableBox isShowMenu={isShowMenu}>
        <section className="main-import">
          <div className="section-info">
            <p className="title">
              Upload your <span className="-blue -upper">{dataType}</span> data
            </p>
            <span className="-small">
              Add custom data file. Acceptable file format up to 10 MB are : CSV
            </span>
            <div className="section-filename">
              <span>
                <img className="collect-icon" src="/assets/icons/collect.png" alt="predictive" />
                {fileName}{' '}
                <img
                  className="btn-del-file"
                  onClick={() => nextStepFn('uploadfile')}
                  src="/assets/icons/trash.png"
                  alt="predictive"
                />
              </span>
            </div>
          </div>
        </section>
        <div className="section-main">
          <div className="section-option p-2">
            <div className="top-row  pt-0">
              <div className="col-12 section-title-sample pt-0 pl-0">
                <span>
                  Preview : <span className="-blue">{fileName} </span>
                </span>
                Showing only the first 10 row
              </div>

              {/* <a className="btn-back" onClick={() => nextStepFn('uploadfile')}>
              <span>Back</span>
              </a>
              <a className="btn-next" onClick={() => nextStepFn('mapping')}>
              <span>Next</span>
             </a> */}
            </div>
          </div>

          <div className="scroll-x">
            <table className="w-100">
              <thead>
                <tr>
                  {columns.map((column, l) => {
                    return (
                      <th key={l} className={column.title}>
                        {column.title}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => {
                  return (
                    <tr key={i}>
                      {row.map((list, x) => {
                        return !isEmpty(list.value) ? (
                          <td key={x} className={list.title}>
                            {list.value}
                          </td>
                        ) : (
                          <td key={x}>-</td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 text-right section-footer">
          <div className="col-6 align-left">
            <a className=" btn-basic bg-whiter border-darkgreen font-darkgreen " onClick={() => nextStepFn('uploadfile')}>
              Previous
            </a>
          </div>
          <div className="col-6 align-right">
            <a
              className=" btn-basic bg-whiter border-darkgreen font-darkgreen mx-2"
              onClick={() => (window.location.href = '/imports')}
            >
              Cancel
            </a>
            <a className="btn-basic bg-darkgreen font-white" onClick={() => nextStepFn('mapping')}>
              Next
            </a>
          </div>
        </div>
      </TableBox>
    )
  }
}
